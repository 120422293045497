import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { DropDown, Text, CONSTANTS } from '@myie/interact-dom';

import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class TaxIDComponent extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const data = this.props.data;

    return {
      form: {
        title: {
          rules: {
            stop: true,
            required: {
              message: 'Please select title.',
            },
          },
          value: '',
        },
        closeAccount: {
          rules: {
            title: 'Close Account',
          },
          value: data && data['closeAccount'] ? data['closeAccount'] : null,
        },
      },
    };
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    const { data, setStateDynamic } = this.props;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { setStateDynamic, onSubmit } = this.props;
    let { form, amount } = this.state;

    if (form.closeAccount.value) {
      form = {
        closeAccount: form.closeAccount,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
        };
      }
    }

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    setStateDynamic({
      combinedForm: { ...form },
    });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, amount } = this.state;
    const { data, setStateDynamic } = this.props;

    if (checked) {
      form = {
        closeAccount: form.closeAccount,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
        };
      }
    }
    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  dropdownOptions(item, index) {
    let keys = Object.keys(item);
    const { idCountry } = this.props;
    return (
      <option id={`${idCountry}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  render() {
    const { props } = this;
    const {
      validationCountry,
      validationTax,
      cmsTagCountry,
      labelCountry,
      idCountry,
      labelTax,
      cmsTagTax,
      fieldTax,
      idTax,
      fieldCountry,
      USResident,
    } = props;
    //const { form } = this.state;
    let countryList = [];
    if (CONSTANTS && CONSTANTS.COUNTRYLIST) {
      countryList = CONSTANTS.COUNTRYLIST.sort(function(a, b) {
        if (Object.keys(a)[1] < Object.keys(b)[1]) {
          return -1;
        }
        if (Object.keys(a)[1] > Object.keys(b)[1]) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <React.Fragment>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <DropDown
              cmsTag={cmsTagCountry}
              label={labelCountry}
              labelClassName="sr-only-md"
              id={idCountry}
              field={fieldCountry}
              onChange={this.props.onchangeCountry}
              onBlur={this.props.onBlur}
              validation={validationCountry}
              disabled={USResident}
            >
              <option
                id={`${idCountry}-option-please-select`}
                key="1"
                value=""
                defaultText="Please select"
                className={idCountry}
              ></option>
              {countryList.map((item, index, idCountry) => {
                return this.dropdownOptions(item, index, idCountry);
              })}
            </DropDown>
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <Text
              label={labelTax}
              cmsTag={cmsTagTax}
              labelClassName="sr-only-md"
              id={idTax}
              field={fieldTax}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              validation={validationTax}
            />
          </Col>
        </Row>
        <hr className="d-md-none d-lg-none d-xl-none" />
      </React.Fragment>
    );
  }
}

TaxIDComponent.propTypes = {
  USResident: PropTypes.any,
  setStateDynamic: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validationCountry: PropTypes.any,
  validationTax: PropTypes.any,
  cmsTagCountry: PropTypes.any,
  labelCountry: PropTypes.any,
  idCountry: PropTypes.string,
  labelTax: PropTypes.any,
  cmsTagTax: PropTypes.any,
  onchangeCountry: PropTypes.func,
  fieldTax: PropTypes.any,
  idTax: PropTypes.any,
  fieldCountry: PropTypes.any,
};

export default TaxIDComponent;
