import React from 'react';
import PropTypes from 'prop-types';

import contentManaged, { ContentType } from '../HO_components/contentManaged';

class LinkTag extends React.Component {
  constructor(props) {
    super(props);
    const { href, managedContent } = props;
    managedContent({
      href: { defaultValue: href },
    });
  }

  render() {
    const { props } = this;

    const {
      children,
      className = '',
      id,
      target,
      contentValue,
      managedContent,

      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });

    const href = contentValue(ContentType.Text, 'href');

    return (
      <a
        className={`${className}`.trim()}
        href={href}
        target={target}
        id={id}
        {...rest}
      >
        {children}
      </a>
    );
  }
}

LinkTag.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.any,
  /**
   * Text href text
   */
  href: PropTypes.string,
  /**
   * Text className text
   */
  className: PropTypes.string,
  /**
   * Text id text
   */
  id: PropTypes.string,

  target: PropTypes.string,
};

export default contentManaged(LinkTag);
