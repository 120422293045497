import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';

class BankHolidays extends React.Component {
  constructor(props) {
    super(props);
    const { holidayList, managedContent } = props;
    this.state = {};
    managedContent(
      {
        holidayList: { defaultValue: holidayList },
      },
      true,
    );
  }

  static getDerivedStateFromProps = nextProps => {
    const {
      holidays,
      setBankHolidays,
      contentValue,
      managedContent,
      ...rest
    } = nextProps;

    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const copytextValue = contentValue(ContentType.Text, 'holidayList');
    if (holidays !== copytextValue) {
      setBankHolidays(copytextValue);
    }

    return null;
  };

  render() {
    const { contentValue, managedContent, tag, ...rest } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const holidayList = contentValue(ContentType.Text, 'holidayList');

    if (tag) {
      let Component = tag;

      return <Component {...rest}>{holidayList}</Component>;
    }
    return <React.Fragment>{holidayList}</React.Fragment>;
  }
}

BankHolidays.propTypes = {
  contentValue: PropTypes.func.isRequired,
  holidayList: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.any,
  template: PropTypes.any,
  setBankHolidays: PropTypes.any,
};
const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(contentManaged(BankHolidays));
