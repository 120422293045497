import {
  SETUP_CREDENTIALS_REQUEST,
  SETUP_CREDENTIALS_SUCCESS,
  SETUP_CREDENTIALS_FAILURE,
  RESET_SETUP_CREDENTIALS,
  SETUP_CREDENTIALS_ACTIVATE_REQUEST,
  SETUP_CREDENTIALS_ACTIVATE_SUCCESS,
  SETUP_CREDENTIALS_ACTIVATE_FAILURE,
  RESET_SETUP_CREDENTIALS_ACTIVATE,
} from '../actions/activationTypes';

const brandSetupCredentialsDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_SETUP_CREDENTIALS_ACTIVATE:
      return {
        ...state,
        credentialsActivate: {},
      };
    case SETUP_CREDENTIALS_ACTIVATE_SUCCESS:
      return {
        ...state,
        credentialsActivate: action.setupcredentials,
        isFetching: action.isFetching,
      };
    case SETUP_CREDENTIALS_ACTIVATE_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case SETUP_CREDENTIALS_ACTIVATE_REQUEST:
    case RESET_SETUP_CREDENTIALS:
      return {
        ...state,
        setupCredentialsActivate: {},
      };
    case SETUP_CREDENTIALS_SUCCESS:
      return {
        ...state,
        setupCredentialsActivate: action.setupCredentialsActivate,
        isFetching: action.isFetching,
      };
    case SETUP_CREDENTIALS_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case SETUP_CREDENTIALS_REQUEST:
    default:
      return state;
  }
};

export default brandSetupCredentialsDefinition;
