import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'primary-fill',
  fill2 = 'light-fill',
  width = '1080',
  height = '1080',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 1080 1080"
    width={width}
    height={height}
  >
    <path
      className={fill2}
      d="M973.9,135H106.1c-47.9,0-86.8,38.9-86.8,86.8v636.4c0,47.9,38.9,86.8,86.8,86.8h867.9
	c47.9,0,86.8-38.9,86.8-86.8V221.8C1060.7,173.9,1021.8,135,973.9,135z"
    />
    <path
      className="white-fill"
      d="M973.9,858.2H567.5c1.6-8.1,1.4,6.5,1.4-40.5c0-57.5-54.4-104.1-121.5-104.1c-19.5,0-33.8,14.5-81,14.5
	c-48.6,0-60.4-14.5-81-14.5c-67.1,0-121.5,46.6-121.5,104.1c0,47-0.4,32.4,1.4,40.5h-59.3V337.5h867.9L973.9,858.2L973.9,858.2z"
    />
    <path
      className={fill}
      d="M670.2,713.6h202.5c8,0,14.5-6.5,14.5-14.5v-28.9c0-8-6.5-14.5-14.5-14.5H670.2c-8,0-14.5,6.5-14.5,14.5v28.9
	C655.7,707.1,662.2,713.6,670.2,713.6z"
    />
    <path
      className={fill}
      d="M670.2,597.9h202.5c8,0,14.5-6.5,14.5-14.5v-28.9c0-8-6.5-14.5-14.5-14.5H670.2c-8,0-14.5,6.5-14.5,14.5v28.9
	C655.7,591.3,662.2,597.9,670.2,597.9z"
    />
    <path
      className={fill}
      d="M670.2,482.1h202.5c8,0,14.5-6.5,14.5-14.5v-28.9c0-8-6.5-14.5-14.5-14.5H670.2c-8,0-14.5,6.5-14.5,14.5v28.9
	C655.7,475.6,662.2,482.1,670.2,482.1z"
    />
    <circle className={fill2} cx="366.4" cy="540" r="115.7" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
