import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class RegisterSidebarComponent extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <Card color="primary" className="register-card">
        <CardHeader>
          <h2>
            <Content
              cmsTag="Shared:Components:Register-sidebar-component:h2-1"
              copytext="Not registered yet?"
            />
          </h2>
        </CardHeader>
        <CardBody>
          <Markdown
            cmsTag="Shared:Components:Register-sidebar-component:p1"
            markdown={`\nIt's easy to register for an account with us. Click the "Register" button to begin.`}
          />
        </CardBody>
        <CardFooter>
          <Link
            id="register-button"
            className="btn btn-primary"
            role="button"
            to="/registration/register"
          >
            <Content cmsTag="GLOBAL:Register" copytext="Register" />
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

RegisterSidebarComponent.propTypes = {
  items: PropTypes.string,
};

export default RegisterSidebarComponent;
