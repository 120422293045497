import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill2 = 'goal-fill-2',
  fillStroke = 'goal-fill-stroke',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fillStroke}
      d="M9.9,0A9.9,9.9,0,1,1,0,9.9,9.9,9.9,0,0,1,9.9,0Z"
      transform="translate(2.1 2.1)"
      strokeWidth="2"
    />
    <path
      className={fill2}
      d="M12,10.7A1.3,1.3,0,1,1,10.7,12,1.324,1.324,0,0,1,12,10.7Z"
    />
    <path
      className={fill2}
      d="M15.9,10.7A1.3,1.3,0,1,1,14.6,12,1.324,1.324,0,0,1,15.9,10.7Z"
    />
    <path
      className={fill2}
      d="M8.1,10.7A1.3,1.3,0,1,1,6.8,12,1.324,1.324,0,0,1,8.1,10.7Z"
    />
  </svg>
);

SVG.propTypes = {
  fillStroke: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
