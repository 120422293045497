import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'primary-fill',
  fill2 = 'light-fill',
  width = '100%',
  height = '100%',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 867.9 925.7"
    viewBox="0 0 867.9 925.7"
  >
    <path
      className={fill2}
      d="M814.5,151.3L467.3,6.6C458.5,2.9,443.5,0,433.9,0c-9.6,0-24.5,3-33.4,6.6l-347,144.7
	C21.1,164.7,0,196.3,0,231.4c0,358.9,207,607,400.5,687.6c21.3,8.9,45.4,8.9,66.7,0c154.9-64.5,400.7-287.5,400.7-687.6
	C867.8,196.3,846.7,164.7,814.5,151.3L814.5,151.3z"
    />
    <path
      className="white-fill"
      d="M433.9,838.9C257.6,765.5,86.8,537.5,86.8,231.4L433.9,86.7L781,231.3C781,545.6,603.1,768.4,433.9,838.9
	L433.9,838.9z"
    />
    <path
      className={fill}
      d="M266.1,416.7c-8.5-8.5-22.2-8.5-30.7-0.2l-41,40.7c-8.5,8.5-8.5,22.2-0.2,30.7l164.2,165.4
	c8.5,8.5,22.2,8.5,30.7,0.2L701.2,344c8.5-8.5,8.5-22.2,0.2-30.7l-40.7-41c-8.5-8.5-22.2-8.5-30.7-0.2L374.2,525.5L266.1,416.7
	L266.1,416.7z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
