import React from 'react';
import PropTypes from 'prop-types';
import { TwoFactor } from '@myie/interact';
import { Validate, Session } from '@myie/interact';
import { Alert, Button } from 'reactstrap';
import {
  Text,
  Content,
  Markdown,
  FormErrorList,
  BackToButton,
  stateUtility,
} from '@myie/interact-dom';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-two-factor';
import { returnPath } from './ReturnPages';
import { RULES } from '../shared/constants/rules';
class CodeForm extends React.Component {
  constructor(props) {
    super(props);
    const storage = stateUtility.getPageState();
    const storageName =
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '';
    this.state = {
      storageName,
      count: 0,
      form: {
        code: {
          rules: {
            title: 'Code',
            stop: true,
            required: {
              message: 'Please enter your authentication code.',
            },
            format: {
              regex: RULES.activationCode2,
              message: 'Please enter a valid authentication code.',
            },
          },
        },
      },
    };
  }

  getInitialState = () => {
    let state = {
      code: {
        rules: {
          title: 'Code',
          stop: true,
          required: {
            message: 'Please enter your authentication code.',
          },
          format: {
            regex: RULES.activationCode2,
            message: 'Please enter a valid authentication code.',
          },
        },
      },
    };

    return state;
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  removeCode = () => {
    let { form } = this.state;
    form.code.value = '';
    form.code.state.dirty = false;
    form.code.state.approval = {};
    this.setState({ ...this.state, form });
  };

  sendAnothercode = () => {
    const {
      sendChallenge,
      selectedDestination,
      setCodeResendStatus,
    } = this.props;
    const initialFormState = this.getInitialState();
    setCodeResendStatus(true);
    const request = {
      Destination: selectedDestination,
      ChallengeKey: TwoFactor.getKey(),
    };
    sendChallenge(request);
    this.setState({ ...this.state, form: initialFormState });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  cancel = () => {
    const { finishTwoFactor, history } = this.props;
    const { storageName } = this.state;
    const returnTo = returnPath(storageName);
    finishTwoFactor({});
    if (Session.isAuthenticated() && returnTo) {
      stateUtility.resetComponentState(storageName);
      history.push(returnTo);
    }
  };

  continue = e => {
    e.preventDefault();
    const { addCount, history } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({
      ...this.state,
      form,
    });

    if (!form.approved) {
      return;
    }
    TwoFactor.setStatus('');
    addCount();
    TwoFactor.replay(form.code.value);
    const storage = stateUtility.getPageState();

    let stateData = {
      url: { twoFaStatus: 'finished' },
    };
    if (storage && storage.global && storage.global.storage) {
      let page = storage[storage.global.storage];
      if (page && page.url && page.url.pageStage) {
        stateData.url.pageStage = page.url.pageStage;
      }
    }

    stateUtility.directlyUpdateUrl(
      stateData,
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '',
      history,
    );
    stateUtility.setPageState(
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '',
      'twoFaStatus',
      'finished',
    );
    this.removeCode();
  };

  render() {
    const { form, storageName } = this.state;
    const { failed, selectedDestination, count } = this.props;

    return (
      <div id="enter-code-content">
        {count === 1 && failed ? (
          <Alert id="code-failed" color="danger">
            <Content
              cmsTag="Two-factor:Code-form:alert-1"
              copytext="Please be aware as this is your second attempt. You have one attempt left and after that you will be locked out of your account. If you are locked out, you can try again in 24 hours."
            />
          </Alert>
        ) : (
          ''
        )}
        {failed && count !== 1 ? (
          <Alert id="code-failed" color="danger">
            <Content
              cmsTag="Two-factor:Code-form:alert-2"
              copytext="You have entered an incorrect authentication code. Please check and try again."
            />
          </Alert>
        ) : (
          ''
        )}
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          showErrors={true}
        />

        <form onSubmit={this.continue}>
          <label>
            <Markdown
              cmsTag="Two-factor:Code-label"
              template={{
                markdown: {
                  destination: selectedDestination,
                },
              }}
              markdown={`Enter the authentication code we have sent to $[destination].*`}
            />
          </label>
          <Text
            label="Authentication code"
            cmsTag="LABEL:Authentication-code"
            labelClassName="sr-only"
            id="code"
            autoFocus
            field="code"
            validation={form.code}
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={'6'}
          />
          <div className="alert alert-info">
            <p>
              <Content
                cmsTag="Two-factor:Code-form:p2"
                copytext="Please allow up to three minutes for your code to arrive."
              />
            </p>
            <p>
              <Content
                cmsTag="Two-factor:Code-form:p3-part-1"
                copytext="If you haven't received it after this time, please "
              />
              <button
                onClick={() => this.sendAnothercode(0)}
                className="btn btn-link"
              >
                <Content
                  cmsTag="Two-factor:Code-form:send-another-code"
                  copytext="request another code"
                />
              </button>
              <Content cmsTag="Two-factor:Code-form:p3-part-2" copytext="." />
            </p>
          </div>
          <div className="form-button-group">
            <Button id="code-submit" color="primary" type="submit">
              <Content
                cmsTag="Two-factor:Code-form:Conform"
                copytext="Confirm code"
              />
            </Button>
            {Session.isAuthenticated() ? (
              <BackToButton
                color="secondary"
                id="twofa-btn-back-to-my-details"
                onClick={this.cancel}
                origin={storageName}
              />
            ) : (
              <Link
                id="twofa-btn-back-to-sign-in"
                className="btn btn-secondary"
                role="button"
                to="/sign-in/step-1"
              >
                <Content
                  cmsTag="GLOBAL:Back-to-sign-in"
                  copytext="Back to sign in"
                />
              </Link>
            )}
          </div>
        </form>
      </div>
    );
  }
}

CodeForm.propTypes = {
  failed: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  sendChallenge: PropTypes.any,
  count: PropTypes.any,
  selectedDestination: PropTypes.string,
  retry: PropTypes.func,
  setCodeResendStatus: PropTypes.func,
  setRequestCount: PropTypes.any,
  resetActivation: PropTypes.func,
  history: PropTypes.any,
  addCount: PropTypes.func,
};

const mapStateToProps = state => {
  const { twoFactorDefinition, customUserManagementDefinition } = state;
  return {
    ...twoFactorDefinition,
    ...customUserManagementDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps },
  )(CodeForm),
);
