import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const { managedContent } = props;
    managedContent({
      text: { defaultValue: 'Page $[currentPage] of $[totalPages]' },
    });
  }

  render() {
    const {
      Total,
      Offset,
      Size,
      getNextPage,
      getPreviousPage,
      contentValue,
      loading,
    } = this.props;
    const totalPages =
      Math.ceil(Total / Size) === 0 ? 1 : Math.ceil(Total / Size);
    const currentPage = Math.ceil(Offset / Size) + 1;
    const text = contentValue(ContentType.Text, 'text', {
      currentPage: currentPage,
      totalPages: totalPages,
    });

    return (
      <div className="pagination-container">
        <p>
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="Shared:Components:Pagination:You-are-on-"
            copytext="You are on "
          />
          {text}
        </p>
        <div className="btn-group">
          <Button
            id="previous-page-btn"
            color={'light'}
            onClick={() => getPreviousPage(Offset, Size)}
            disabled={loading || currentPage === 1 ? true : false}
            className="pagination-btn"
          >
            <Content
              tag="span"
              tagClassName="sr-only"
              cmsTag="GLOBAL:View-previous-messages"
              copytext="View previous messages"
            />
          </Button>
          <Button
            id="next-page-btn"
            color={'light'}
            onClick={() => getNextPage(Offset, Size, Total)}
            disabled={loading || currentPage === totalPages ? true : false}
            className="pagination-btn"
          >
            <Content
              tag="span"
              tagClassName="sr-only"
              cmsTag="GLOBAL:View-next-messages"
              copytext="View next messages"
            />
          </Button>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  Offset: PropTypes.number.isRequired,
  Size: PropTypes.number.isRequired,
  Total: PropTypes.number.isRequired,
  contentValue: PropTypes.any,
  getNextPage: PropTypes.func.isRequired,
  getPreviousPage: PropTypes.func.isRequired,
  managedContent: PropTypes.any,
  subLabel: PropTypes.any,
  loading: PropTypes.any,
};

export default contentManaged(Pagination);
