import React from 'react';
import PropTypes from 'prop-types';
import { Content, AppMeta } from '@myie/interact-dom';

const NoMatch = props => {
  return (
    <div id="not-found">
      <AppMeta
        id="meta-data"
        stage="child"
        contacts={props.contacts}
        title="Error"
        metaDescription="Resource not found"
      />
      <Content
        tag="h1"
        cmsTag="Shared:Routing:No-match:h1"
        copytext="Resource not found"
      />
      <Content
        tag="p"
        cmsTag="Shared:Routing:No-match:p1"
        copytext="The requested resource could not be found."
      />
      <p>
        {props.location.pathname}
        {props.location.hash}
      </p>
    </div>
  );
};

NoMatch.propTypes = {
  location: PropTypes.object,
  contacts: PropTypes.object,
};

export default NoMatch;
