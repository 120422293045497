import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M11.1,3.7C6.2,4,1.7,7,0.5,11.9c-0.2,0.9,0.9,1.8,1.7,1l0,0c2-1.8,3.6-1.1,5,1.1c0.5,0.7,1.4,0.7,1.8,0 c0.5-0.8,1.1-1.6,1.9-2"
    />
    <path
      className={fill}
      d="M11,12.1l1.9,0c1.1,0.5,1.5,1.3,1.9,1.9c0.4,0.7,1.4,0.7,1.8,0c1.4-2.2,3.1-2.9,5-1.1l0,0c0.8,0.7,2-0.1,1.7-1 C22.4,7,16.9,3.1,11,3.7"
    />
    <path
      className={fillNegative}
      d="M15.8,12c-1-1.2-2.3-2-3.8-2c-1.6,0-2.8,0.7-3.8,2c-1.4-1.8-3.3-2.3-4.9-1.9C5.1,7.1,8.6,5.6,12,5.6 c3.4,0,7,1.6,8.7,4.5C18.8,9.6,17.1,10.4,15.8,12z"
    />
    <path className={fill2} d="M13,3.1V2.7c0-0.5-0.4-1-1-1s-1,0.4-1,1v0.4" />
    <path
      className={fill2}
      d="M11,12.8v6.5c0,0.5-0.4,1-1,1c-0.4,0-0.8-0.3-0.9-0.6c-0.2-0.5-0.7-0.8-1.2-0.6c-0.5,0.2-0.8,0.7-0.6,1.2 c0.4,1.1,1.5,1.9,2.7,1.9c1.6,0,2.9-1.3,2.9-2.9v-6.5"
    />
    <path className={fill2} d="M2.2,12.9" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
