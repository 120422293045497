import React, { Component } from 'react';
import { Date } from '@myie/interact-dom';

import PropTypes from 'prop-types';

class ResidentialStatus extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {};

  render() {
    const { form, addressId, addressLabel, formName } = this.props;
    // let legendValue = '';
    //  let cmsTagValue = '';

    // if (formName === 'current') {
    //   legendValue = 'Are you the homeowner?';
    //   cmsTagValue = 'LABEL:Are-you-the-homeowner';
    // } else {
    //   legendValue = 'Were you the homeowner?';
    //   cmsTagValue = 'LABEL:Were-you-the-homeowner';
    // }

    const formNameValue = formName === 'current' ? '' : formName;
    return (
      <div className="residential-status-container">
        {/* <RadioGroup
          legend={legendValue}
          cmsTag={cmsTagValue}
          required={true}
          field={`${addressId}Homeowner${formNameValue}`}
          validation={form[`${addressId}Homeowner${formNameValue}`]}
          id={`${addressId}Homeowner${formNameValue}`}
          groupClassName="mb-0"
        >
          <Radio
            label="Yes"
            cmsTag="LABEL:Yes"
            id={`${addressId}HomeownerYes${formNameValue}`}
            field={`${addressId}Homeowner${formNameValue}`}
            onChange={this.props.onChange}
            checked={
              form[`${addressId}Homeowner${formNameValue}`].value === 'yes'
            }
            validation={form[`${addressId}Homeowner${formNameValue}`]}
            value="yes"
          />
          <Radio
            label="No"
            cmsTag="LABEL:No"
            id={`${addressId}HomeownerNo${formNameValue}`}
            field={`${addressId}Homeowner${formNameValue}`}
            onChange={this.props.onChange}
            checked={
              form[`${addressId}Homeowner${formNameValue}`].value === 'no'
            }
            validation={form[`${addressId}Homeowner${formNameValue}`]}
            value="no"
          />
        </RadioGroup> */}
        <Date
          label={`When did you start living at your ${addressLabel} address?`}
          cmsTag="GLOBAL:When-did-you-start-living-at-this-address"
          id={`${addressId}AddressLivingSince${formNameValue}`}
          field={`${addressId}AddressLivingSince${formNameValue}`}
          groupClassName="half-field mb-0"
          onChange={this.props.onChangeDate}
          onBlur={this.props.onBlur}
          validation={form[`${addressId}AddressLivingSince${formNameValue}`]}
        />
      </div>
    );
  }
}

ResidentialStatus.propTypes = {
  account: PropTypes.object,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeDate: PropTypes.func,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
  addressId: PropTypes.string.isRequired,
  addressLabel: PropTypes.string.isRequired,
  form: PropTypes.object,
  formName: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default ResidentialStatus;
