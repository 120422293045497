import SaveApplicationSidebar from './save-and-resume/Sidebar';
import DefaultSidebar from './DefaultSidebar';

const LongApplySidebars = [
  { url: '/resume-application', Sidebar: SaveApplicationSidebar },
  { url: '/long-apply/eligibility/', Sidebar: null },
  { url: '/long-apply/apply/', Sidebar: null },
  { url: 'saveForLater', Sidebar: SaveApplicationSidebar },
  { url: 'SaveSuccess', Sidebar: DefaultSidebar },
  { url: '/long-apply/not-available', Sidebar: DefaultSidebar },
];

export default LongApplySidebars;
