import React from 'react';
import { Content, AppMeta, Markdown } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import SaveForm from './SaveForm';
import SaveSuccess from './SaveSuccess';
import { CONTACTS } from '@myie/interact-local-dom';
import { Alert } from 'reactstrap';

const META_TITLE = {
  SaveForm: 'Save your application',
  SaveSuccess: 'Save successful',
};

class SaveIndex extends React.Component {
  constructor(props) {
    super(props);
    const { urlParams, refreshUrl } = props;
    const stateList = ['saveForLater', 'SaveSuccess', 'Failed'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'saveForLater';
    let subStage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage &&
      this.props.urlParams.subStage
        ? this.props.urlParams.subStage
        : stage;
    if (!hasStage) {
      stage = 'saveForLater';
      refreshUrl();
    } else {
      stage = subStage;
    }
    this.state = {
      stage: stage,
    };
  }

  componentWillUnmount() {
    const { resetSaveLongApplyForLater } = this.props;
    resetSaveLongApplyForLater();
    window.scrollTo(0, 0);
  }

  initialState = () => {
    return {
      stage: 'saveForLater',
    };
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { savedData = {}, updateUrl, formData } = nextProps;
    const savedReferenceNumber =
      savedData &&
      savedData.Ticket &&
      savedData.Ticket.Data &&
      savedData.Ticket.Data.Reference
        ? savedData.Ticket.Data.Reference
        : '';
    if (
      savedData &&
      savedData.Status === 'Success' &&
      state.stage !== 'SaveSuccess'
    ) {
      let stateData = {
        formData: {
          ...formData,
          referenceNumber: savedReferenceNumber,
        },
        url: {
          pageStage: 'saveForLater',
          subStage: 'SaveSuccess',
        },
      };

      updateUrl(stateData);
      return {
        stage: 'SaveSuccess',
      };
    }

    if (
      savedData &&
      savedData.Status === 'failed' &&
      state.stage !== 'Failed'
    ) {
      let stateData = {
        formData: {
          ...formData,
          referenceNumber: savedReferenceNumber,
        },
        url: {
          pageStage: 'saveForLater',
          subStage: 'Failed',
        },
      };

      updateUrl(stateData);
      return {
        stage: 'Failed',
      };
    }

    return null;
  };

  resumeForm = stage => {
    const { setStage } = this.props;
    setStage(stage);
  };

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // select current stage
  currentStage = () => {
    const { savedData = {}, updateUrl, formData, selectedProduct } = this.props;
    switch (this.state.stage) {
      case 'saveForLater':
        return (
          <SaveForm
            resumeForm={this.resumeForm}
            updateUrl={updateUrl}
            selectedProduct={selectedProduct}
            formData={formData}
            longApplyStage={this.props.longApplyStage}
            setStage={this.setStage}
            savedData={savedData}
            isResumedApplication={this.props.isResumedApplication}
          />
        );
      case 'SaveSuccess':
        return (
          <SaveSuccess
            updateUrl={updateUrl}
            formData={formData}
            resumeForm={this.resumeForm}
            savedData={savedData}
            setStage={this.setStage}
          />
        );

      default:
    }
  };

  render() {
    const { savedData, isResumedApplication } = this.props;
    const { stage } = this.state;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={META_TITLE[stage]}
          metaDescription={META_TITLE[stage]}
        />
        <h1>
          {stage === 'saveForLater' && !isResumedApplication ? (
            <Content
              cmsTag="Save-and-resume:Save-application:Save-index:Save-form:h1"
              copytext="Save your application"
            />
          ) : (
            ''
          )}
          {stage === 'SaveSuccess' ? (
            <Content
              cmsTag="Save-and-resume:Save-application:Save-index:Save-success:h1"
              copytext="Application saved"
            />
          ) : (
            ''
          )}

          {stage === 'Failed' ? (
            <Content
              cmsTag="Save-and-resume:Save-application:Save-index:Save-failed:h1"
              copytext="Unable to save the application at this time, please try again!"
            />
          ) : (
            ''
          )}
        </h1>
        {savedData?.Status === 'Invalid' && (
          <Alert color="danger">
            <Markdown
              cmsTag="Save-and-resume:Save-application:Save-index:alert-1"
              markdown={`You have entered a common password, please try again.`}
            />
          </Alert>
        )}
        {this.currentStage()}
      </React.Fragment>
    );
  }
}

SaveIndex.propTypes = {
  accounts: PropTypes.object,
  match: PropTypes.any,
  longApplyStage: PropTypes.string,
  error: PropTypes.any,
  resetSaveLongApplyForLater: PropTypes.func,
  selectedProduct: PropTypes.object,
  savedData: PropTypes.object,
  setStage: PropTypes.func,
  stateData: PropTypes.object,
  formData: PropTypes.object,
  refreshUrl: PropTypes.func,
  updateUrl: PropTypes.func,
  urlParams: PropTypes.object,
  isFetching: PropTypes.any,
  isResumedApplication: PropTypes.bool,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapSavingsServicingDispatchToProps },
)(withRouter(SaveIndex));
