import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown, AppMeta, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';

class TranchExceeded extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.resetGetDepositProduct();
  }

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Product no longer available`}
          metaDescription={`Product no longer available`}
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Tranch-exceeded:h1"
          copytext={`Product no longer available`}
        />
        <Markdown
          cmsTag="Long-apply:Tranch-exceeded:section-1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nWe are sorry this product is currently unavailable. Please select another from our range. \n\nIf you have any questions, please call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          <LinkTag
            id="product-not-available-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.savingsAccountsPage}
          >
            <Content
              cmsTag="GLOBAL:View-other-accounts"
              copytext="View other accounts"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

TranchExceeded.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
  setStage: PropTypes.any,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  resetGetDepositProduct: PropTypes.func,
};

export default connect(
  null,
  mapDispatchToProps,
)(TranchExceeded);
