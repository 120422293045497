import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Collapse } from 'reactstrap';
import { Icon, Content } from '@myie/interact-dom';

class OurProducts extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div className="our-products-container">
        <Button color="products" onClick={this.toggle}>
          <Icon
            name="BoxProduct"
            fill="light-gray-fill"
            width="30"
            aria-hidden="true"
          />
          <Content
            cmsTag="Shared:Components:Our-Products:Our-products"
            copytext="Our products"
          />
        </Button>
        <Collapse isOpen={this.state.collapse}>
          <Row>
            <Col xs={6}>
              <a href="/savings-account">
                <Content
                  cmsTag="Shared:Components:Our-Products:Savings-accounts"
                  copytext="Savings account"
                />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/current-account">
                <Content
                  cmsTag="Shared:Components:Our-Products:Current-account"
                  copytext="Current account"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="/creditcard">
                <Content
                  cmsTag="Shared:Components:Our-Products:Credit-card"
                  copytext="Credit card"
                />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/loan">
                <Content
                  cmsTag="Shared:Components:Our-Products:Loan"
                  copytext="Loan"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="#/mortgage">
                <Content
                  cmsTag="Shared:Components:Our-Products:Mortgage"
                  copytext="Mortgage"
                />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/insurance">
                <Content
                  cmsTag="Shared:Components:Our-Products:Insurance"
                  copytext="Insurance"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <a href="/travel-money">
                <Content
                  cmsTag="Shared:Components:Our-Products:Travel-Money"
                  copytext="Travel Money"
                />
              </a>
            </Col>
            <Col xs={6}>
              <a href="/cash-isa">
                <Content
                  cmsTag="Shared:Components:Our-Products:Cash-ISA"
                  copytext="Cash ISA"
                />
              </a>
            </Col>
          </Row>
        </Collapse>
      </div>
    );
  }
}

OurProducts.propTypes = {
  items: PropTypes.string,
};

export default OurProducts;
