import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
import {
  Content,
  Markdown,
  DropDown,
  CONSTANTS,
  Currency,
  FormErrorList,
  RULES,
} from '@myie/interact-dom';
import _ from 'lodash';
import { CONTACTS } from '@myie/interact-local-dom';
import ContactFooter from './page-components/ContactFooter';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class AccountPreferences extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  initialState = props => {
    const { formData, selectedProduct } = props;
    let data = null;
    if ('accountPreferences' in formData) {
      data = formData['accountPreferences'];
    }
    const formItems = {
      depositMethod: {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us how you will be making your initial deposit.',
          },
        },
        value: data ? data.depositMethod : '',
      },

      depositAmount: {
        rules: {
          stop: true,
          required: {
            message: 'Please enter an initial deposit amount.',
          },
          format: {
            regex: RULES.ammountFormat,
            message: 'Please enter a valid amount.',
          },
          minAmountCheckWithInputValue: {
            message: ` The minimum amount you can have in this type of account is £${selectedProduct.Minimum_Operating_Balance}.`,
            comparison: () => {
              return selectedProduct.Minimum_Operating_Balance;
            },
          },
          maxAmountCheckWithInputValue: {
            message: `The maximum amount you can have in this type of account is £${selectedProduct.Maximum_Operating_Balance}.`,
            comparison: () => {
              return selectedProduct.Maximum_Operating_Balance;
            },
          },
          maxDepositMonthly: {
            message: `The maximum amount you can deposit monthly is £${selectedProduct.Maximum_monthly_deposit}.`,
            comparison: () => {
              return '' + selectedProduct.Maximum_monthly_deposit;
            },
          },
          maxDepositYearly: {
            message: `The maximum amount you can deposit yearly is £${selectedProduct.Maximum_yearly_deposit}.`,
            comparison: () => {
              return '' + selectedProduct.Maximum_yearly_deposit;
            },
          },
        },
        value: data ? data.depositAmount : '',
      },

      // interestPaymentLocation: {
      //   rules: {
      //     stop: true,
      //     required: {
      //       message:
      //         'Please tell us where you would like us to pay your interest to.',
      //     },
      //   },
      //   value: data ? data.interestPaymentLocation : '',
      // },
    };

    if (
      selectedProduct &&
      selectedProduct.Interest_Frequency &&
      selectedProduct.Interest_Frequency === 'both'
    ) {
      formItems.interestPaymentFrequency = {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us when you would like us to pay your interest.',
          },
        },
        value: data ? data.interestPaymentFrequency : '',
      };
    }
    return {
      data: data ? data : {},
      form: formItems,
    };
  };

  componentDidUpdate() {
    const { formData } = this.props;
    const { data } = this.state;

    if (
      !_.isEmpty(formData['accountPreferences']) &&
      JSON.stringify(data) !== JSON.stringify(formData['accountPreferences'])
    ) {
      const newState = this.initialState(this.props);
      this.setState({ ...newState });
    }
  }

  setStage = () => {
    const { setStage } = this.props;
    setStage('yourFinances');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  saveForLater = () => {
    const { setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        ...formData,
        accountPreferences: data,
        stage: 'accountPreferences',
      },
      url: {
        pageStage: 'saveForLater',
      },
    };

    updateUrl(stateData);
    setStage('saveForLater');
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data } = this.state;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;

    this.setState({ ...this.state, form });
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onSubmitHandler = e => {
    e.preventDefault();

    const { onSubmit, setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        accountPreferences: data,
        ...formData,
      },
      url: {
        pageStage: 'reviewAndSubmit',
      },
    };

    updateUrl(stateData);
    window.scrollTo(0, 0);
    setStage('reviewAndSubmit');
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  render() {
    const currencyCode = 'GBP';
    const { selectedProduct } = this.props;
    const { form } = this.state;

    return (
      <React.Fragment>
        <Form
          autoComplete="off"
          id="apply-form"
          onSubmit={this.onSubmitHandler}
        >
          <Content
            tag="h2"
            tagClassName="breadcrumb-title step-4"
            cmsTag="BREADCRUMB:Account-preferences"
            copytext="Account preferences"
          />
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <section id="deposit-details-section">
            <Content
              tag="h3"
              cmsTag="Long-apply:Apply-pages:Account-preferences:h3-1"
              copytext="Deposit details"
            />
            <DropDown
              cmsTag="GLOBAL:How-will-you-be-making-your-initial-deposit"
              label="How will you be making your initial deposit?"
              id="depositMethod"
              field="depositMethod"
              onChange={this.onChange}
              validation={form.depositMethod}
            >
              <option
                id={`deposit-method-option-please-select`}
                key="1"
                value=""
                defaultText="Please select"
              ></option>
              {CONSTANTS &&
                CONSTANTS.DEPOSIT_METHOD.map((item, index) => {
                  return this.dropdownOptions(item, index, 'depositMethod');
                })}
            </DropDown>
            <Currency
              showErrors={true}
              cmsTag="GLOBAL:How-much-will-this-initial-deposit-be"
              label={'How much will this initial deposit be?'}
              validation={form.depositAmount}
              onChange={this.updateCurrency}
              onBlur={this.updateCurrency}
              code={currencyCode}
              field="depositAmount"
              id="depositAmount"
              dp={2}
            />
          </section>
          <section id="interest-payments-section" className="mb-0">
            <Content
              tag="h3"
              cmsTag="Long-apply:Apply-pages:Account-preferences:h3-2"
              copytext="Interest payments"
            />
            <Markdown
              template={{
                markdown: {
                  phone: CONTACTS.phone,
                  phoneLink: CONTACTS.phoneLink,
                },
              }}
              cmsTag="Long-apply:Apply-pages:Account-preferences:p1"
              markdown={`\nYour interest will automatically be paid into this account. If you want your interest to be paid elsewhere, check the terms and conditions of the account and contact our Savings Support Team on <a target="_blank" href="$[phoneLink]">$[phone]</a>.`}
            />
            {/* <DropDown
              cmsTag="LABEL:Where-shall-we-pay-the-interest-to"
              label="Where shall we pay the interest to?"
              id="interestPaymentLocation"
              field="interestPaymentLocation"
              onChange={this.onChange}
              validation={form.interestPaymentLocation}
            >
              <option
                id={`interest-payment-location-option-please-select`}
                key="1"
                value=""
                defaultText="Please select"
              ></option>
              {CONSTANTS &&
                CONSTANTS.INTEREST_DESTINATION.map((item, index) => {
                  return this.dropdownOptions(
                    item,
                    index,
                    'interestPaymentLocation',
                  );
                })}
            </DropDown> */}
            {selectedProduct &&
            selectedProduct.Interest_Frequency &&
            selectedProduct.Interest_Frequency !== 'both' ? (
              <Row
                tag="dl"
                title="Interest payment frequency details"
                className="clear-both"
              >
                <Col tag="dt" sm="5" md="4" lg="4">
                  <Content
                    cmsTag="GLOBAL:Interest-payment-frequency"
                    copytext="Interest payment frequency"
                  />
                </Col>
                <Col tag="dd" sm="7" md="8" lg="8">
                  {selectedProduct.Interest_Frequency}
                </Col>
              </Row>
            ) : (
              <DropDown
                cmsTag="GLOBAL:How-often-should-we-make-interest-payments"
                label="How often should we make interest payments?"
                id="interestPaymentFrequency"
                field="interestPaymentFrequency"
                onChange={this.onChange}
                validation={form.interestPaymentFrequency}
              >
                <option
                  id={`interest-payment-frequency-option-please-select`}
                  key="1"
                  value=""
                  defaultText="Please select"
                ></option>
                {CONSTANTS &&
                  CONSTANTS.INTEREST_PAYMENT_FREQUENCY.map((item, index) => {
                    return this.dropdownOptions(
                      item,
                      index,
                      'interestPaymentFrequency',
                    );
                  })}
              </DropDown>
            )}
          </section>
          <div className="form-button-group apply-form-buttons">
            <Button id="continue-to-next-step" type="submit" color="primary">
              <Content
                cmsTag="Long-apply:Apply-pages:Account-preferences:Continue-to-step-5"
                copytext="Continue to step 5"
              />
            </Button>
            <Button
              id="back-to-previous-step"
              onClick={this.setStage}
              color="secondary"
            >
              <Content
                cmsTag="Long-apply:Apply-pages:Account-preferences:Back-to-step-3"
                copytext="Back to step 3"
              />
            </Button>
            <Button
              id="save-for-later"
              type="button"
              color="light"
              onClick={this.saveForLater}
            >
              <Content
                cmsTag="GLOBAL:Save-for-later"
                copytext="Save for later"
              />
            </Button>
            <ContactFooter />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

AccountPreferences.propTypes = {
  account: PropTypes.object,
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  formData: PropTypes.any,
  saveData: PropTypes.func,
  selectedProduct: PropTypes.object,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  updateUrl: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AccountPreferences;
