import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-two-factor';
import { CONTACTS } from '@myie/interact-local-dom';
import CodeForm from './CodeForm';

class Code extends React.Component {
  render() {
    const { setCodeResendStatus, addCount, count } = this.props;
    return (
      <div id="enter-code">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Two Factor Authentication Step 2"
          metaDescription="Two Factor Authentication Step 2"
        />
        <Content
          tag="h1"
          cmsTag="Two-factor:Code:h1"
          copytext="Additional Authentication"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active={false} className="completed">
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Select-mobile-number"
                copytext="Select mobile number"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
              <span className="sr-only">
                <Content cmsTag="BREADCRUMB:Active" copytext="Active: " />
              </span>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Enter-code" copytext="Enter code" />
            </BreadcrumbItem>
          </ol>
        </div>
        <CodeForm
          aria-live="polite"
          selectedDestination={this.props.selectedDestination}
          setCodeResendStatus={setCodeResendStatus}
          retry={this.props.reSet}
          addCount={addCount}
          count={count}
          failed={this.props.failed}
        />
      </div>
    );
  }
}

Code.propTypes = {
  failed: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  sendChallenge: PropTypes.any,
  selectedDestination: PropTypes.string,
  retry: PropTypes.func,
  setCodeResendStatus: PropTypes.func,
  reSet: PropTypes.any,
  addCount: PropTypes.any,
  count: PropTypes.any,
};

const mapStateToProps = state => {
  const { twoFactorDefinition, custombrandAuthenticationDefinition } = state;
  return {
    ...twoFactorDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Code);
