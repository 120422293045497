import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M14.8,15.2c0.8-4.3,0.2-7.3,0.1-7.8h2.9l0.6-1.3l0.6,1.3h1.4c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5 c0,0,0-0.1,0-0.1c-0.3-1.9-2.2-3.3-4.5-3.3c0,0,0,0,0,0c-0.8,0-1.9,0.3-2.6,0.7c-0.9-1.2-2.5-2-4.5-2c-2.6,0-4.7,1.4-5.1,3.3 C4.3,5.9,4.6,6.1,4.9,6.1h1.3l0.6-1.3l0.8,1.5C7.4,6.7,7.2,7.2,7.1,7.6C6.8,8.7,6.9,10,8,11.1c0.1,0.1,0.2,0.1,0.2,0.1 c0.1,0,0.2,0,0.2-0.1l2.7-2.7c-0.2,3.1-1.6,6.7-1.6,6.7"
    />
    <path
      className={fillNegative}
      d="M13,7.8L13,7.8c0.1,1.4,0.3,4.8-0.4,8.1h-1.5C11.9,13.8,12.8,10.8,13,7.8z"
    />

    <path
      className={fill2}
      d="M9,15.8H8.2C5.3,15.8,3,18.1,3,21c0,0,0,0.1,0,0.1c0,0.7,0.6,1.2,1.3,1.2h12.8c0.7,0,1.3-0.5,1.3-1.2c0-2.4-1.6-4.5-3.9-5"
    />
    <path
      className={fillNegative}
      d="M16.4,20.3H5c0.3-1.4,1.7-2.6,3.1-2.6h5.1C14.7,17.8,16.1,18.9,16.4,20.3z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
