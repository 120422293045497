import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'reactstrap';
import {
  Content,
  Radio,
  RadioGroup,
  FormErrorList,
  RULES,
} from '@myie/interact-dom';
import _ from 'lodash';
import TaxIDComponent from './finance-components/TaxIDComponent';
import ContactFooter from './page-components/ContactFooter';

import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class YourFinances extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  initialState = props => {
    const { formData } = props;

    let data = {
      USCitizen: '',
      OtherTaxResident: '',
      TaxCountry1: '',
      TaxIdentification1: '',
      TaxCountry2: '',
      TaxIdentification2: '',
      TaxCountry3: '',
      TaxIdentification3: '',
      TaxCountry4: '',
      TaxIdentification4: '',
    };
    if ('yourFinances' in formData) {
      data = formData['yourFinances'];
    }
    let otherTaxResidentValue = data
      ? data.OtherTaxResident === 'yes'
        ? 'yes'
        : data.OtherTaxResident === 'no'
        ? 'no'
        : ''
      : '';
    let usCitizenValue = data
      ? data.USCitizen === 'yes'
        ? 'yes'
        : data.USCitizen === 'no'
        ? 'no'
        : ''
      : '';
    const newState = {
      data: data,
      form: {
        USCitizen: {
          rules: {
            required: {
              message: 'Please tell us if you are a US citizen.',
            },
          },
          value: usCitizenValue,
        },
        OtherTaxResident: {
          rules: {
            required: {
              message:
                'Please tell us if you are a resident for tax purposes in any other country or territory other than the UK.',
            },
          },
          value: otherTaxResidentValue,
        },
        TaxCountry1: {
          rules: {
            stop: true,
          },
          value: data && data.TaxCountry1 ? data.TaxCountry1 : '',
        },
        TaxCountry2: {
          rules: {
            stop: true,
          },
          value: data && data.TaxCountry2 ? data.TaxCountry2 : '',
        },
        TaxCountry3: {
          rules: {
            stop: true,
          },
          value: data && data.TaxCountry3 ? data.TaxCountry3 : '',
        },
        TaxCountry4: {
          rules: {
            stop: true,
          },
          value: data && data.TaxCountry4 ? data.TaxCountry4 : '',
        },
        TaxIdentification1: {
          rules: {
            stop: true,
            format: {
              regex: RULES.TaxIdentification,
              message: 'Please enter a valid tax identification number.',
            },
          },
          value: data && data.TaxIdentification1 ? data.TaxIdentification1 : '',
        },
        TaxIdentification2: {
          rules: {
            stop: true,
            format: {
              regex: RULES.TaxIdentification,
              message: 'Please enter a valid tax identification number.',
            },
          },
          value: data && data.TaxIdentification2 ? data.TaxIdentification2 : '',
        },
        TaxIdentification3: {
          rules: {
            stop: true,
            format: {
              regex: RULES.TaxIdentification,
              message: 'Please enter a valid tax identification number.',
            },
          },
          value: data && data.TaxIdentification3 ? data.TaxIdentification3 : '',
        },
        TaxIdentification4: {
          rules: {
            stop: true,
            format: {
              regex: RULES.TaxIdentification,
              message: 'Please enter a valid tax identification number.',
            },
          },
          value: data && data.TaxIdentification4 ? data.TaxIdentification4 : '',
        },
      },
    };

    return { ...newState };
  };

  componentDidUpdate() {
    const { formData } = this.props;
    const { data } = this.state;

    if (
      !_.isEmpty(formData['yourFinances']) &&
      JSON.stringify(data) !== JSON.stringify(formData['yourFinances'])
    ) {
      const newState = this.initialState(this.props);
      this.setState(state => {
        return { state, ...newState };
      });
    }
  }

  saveForLater = () => {
    const { setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        ...formData,
        yourFinances: data,
        stage: 'yourFinances',
      },
      url: {
        pageStage: 'saveForLater',
      },
    };

    updateUrl(stateData);
    setStage('saveForLater');
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  onchangeCountry = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    form = this.changeTaxRules(name, form, value);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  changeTaxRules = (name, form, value) => {
    switch (name) {
      case 'TaxCountry1':
        if (value) {
          form.TaxIdentification1.rules = {
            ...form.TaxIdentification1.rules,
            required: {
              message: 'Please enter your American Tax identification Number.',
            },
          };
        } else {
          form.TaxIdentification1.rules = {
            stop: form.TaxIdentification1.rules.stop,
            format: form.TaxIdentification1.rules.format,
          };
        }
        form = Validate.input('TaxIdentification1', '', form, true);
        break;
      case 'TaxCountry2':
        if (value) {
          form.TaxIdentification2.rules = {
            ...form.TaxIdentification2.rules,
            required: {
              message:
                'Please enter your first alternative Tax identification Number.',
            },
          };
        } else {
          form.TaxIdentification2.rules = {
            stop: form.TaxIdentification2.rules.stop,
            format: form.TaxIdentification2.rules.format,
          };
        }
        form = Validate.input('TaxIdentification2', '', form, true);
        break;
      case 'TaxCountry3':
        if (value) {
          form.TaxIdentification3.rules = {
            ...form.TaxIdentification3.rules,
            required: {
              message:
                'Please enter your second alternative Tax identification Number.',
            },
          };
        } else {
          form.TaxIdentification3.rules = {
            stop: form.TaxIdentification3.rules.stop,
            format: form.TaxIdentification3.rules.format,
          };
        }
        form = Validate.input('TaxIdentification3', '', form, true);
        break;
      case 'TaxCountry4':
        if (value) {
          form.TaxIdentification4.rules = {
            ...form.TaxIdentification4.rules,
            required: {
              message:
                'Please enter your third alternative Tax identification Number.',
            },
          };
        } else {
          form.TaxIdentification4.rules = {
            stop: form.TaxIdentification4.rules.stop,
            format: form.TaxIdentification4.rules.format,
          };
        }
        form = Validate.input('TaxIdentification4', '', form, true);
        break;
      default:
    }
    if (
      (name === 'TaxCountry2' ||
        name === 'TaxCountry3' ||
        name === 'TaxCountry4') &&
      value
    ) {
      form.TaxCountry2 = {
        rules: {
          stop: true,
        },
        value: form.TaxCountry2?.value,
      };
      form = Validate.input('TaxCountry2', form.TaxCountry2?.value, form, true);
    }

    if (
      !form.TaxCountry2.value &&
      !form.TaxCountry3.value &&
      !form.TaxCountry4.value &&
      !value
    ) {
      form.TaxCountry2.rules = {
        ...form.TaxCountry2.rules,
        required: {
          message: 'Please select at least one other country you pay tax in.',
        },
      };
      form = Validate.input('TaxCountry2', '', form, true);
    }
    return form;
  };

  onchangeRadio = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;

    if (name === 'OtherTaxResident' && value === 'yes') {
      form.TaxCountry2.rules = {
        ...form.TaxCountry2.rules,
        required: {
          message: 'Please select at least one other country you pay tax in.',
        },
      };
    }

    if (name === 'OtherTaxResident' && value === 'no') {
      form.TaxCountry2.rules = {
        stop: true,
      };
      form.TaxIdentification2.rules = {
        stop: form.TaxIdentification2.rules.stop,
        format: form.TaxIdentification2.rules.format,
      };

      for (let index = 2; index < 5; index++) {
        data[`TaxCountry${index}`] = '';
        data[`TaxIdentification${index}`] = '';
        form = Validate.input(`TaxCountry${index}`, '', form, true);
        form = Validate.input(`TaxIdentification${index}`, '', form, true);
      }
    }

    if (name === 'USCitizen' && value === 'yes') {
      data['TaxCountry1'] = 'US';
      form = Validate.input('TaxCountry1', 'US', form, true);
      form.TaxIdentification1.rules = {
        ...form.TaxIdentification1.rules,
        required: {
          message:
            'Please enter the Tax identification number of the country where you are resident.',
        },
      };
    }

    if (name === 'USCitizen' && value === 'no') {
      form.TaxIdentification1.rules = {
        stop: form.TaxIdentification1.rules.stop,
        format: form.TaxIdentification1.rules.format,
      };
      data['TaxCountry1'] = '';
      data['TaxIdentification1'] = '';
      form = Validate.input('TaxCountry1', '', form, true);
      form = Validate.input('TaxIdentification1', '', form, true);
    }

    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { onSubmit, setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        ...formData,
        yourFinances: data,
      },
      url: {
        pageStage: 'accountPreferences',
      },
    };

    updateUrl(stateData);
    window.scrollTo(0, 0);
    setStage('accountPreferences');
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setStage = () => {
    const { setStage } = this.props;
    setStage('addressDetails');
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  render() {
    const { form } = this.state;

    return (
      <React.Fragment>
        <Form
          autoComplete="off"
          id="apply-form"
          onSubmit={this.onSubmitHandler}
        >
          <Content
            tag="h2"
            tagClassName="breadcrumb-title step-3"
            cmsTag="BREADCRUMB:Your-finances"
            copytext="Your finances"
          />
          <Content
            tag="h3"
            cmsTag="Long-apply:Apply-pages:Your-finances:h3-1"
            copytext="Tax residency"
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Your-finances:p1"
            copytext="If you answer yes to either of the questions in this section, please provide the Tax Identification Number for each country you are a tax resident in. Without this information we will not be able to open your account."
          />
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <RadioGroup
            legend="Are you a US citizen?"
            cmsTag="GLOBAL:Are-you-a-US-citizen"
            required={true}
            field="USCitizen"
            validation={form?.USCitizen}
            id="USCitizen"
          >
            <Radio
              label="Yes"
              cmsTag="GLOBAL:Yes"
              id="YesUSCitizen"
              field="USCitizen"
              onChange={this.onchangeRadio}
              validation={form?.USCitizen}
              value="yes"
            />
            <Radio
              label="No"
              cmsTag="GLOBAL:No"
              id="NoUSCitizen"
              field="USCitizen"
              onChange={this.onchangeRadio}
              validation={form?.USCitizen}
              value="no"
            />
          </RadioGroup>
          <RadioGroup
            legend="Are you a resident for tax purposes in any other country or territory other than the UK?"
            cmsTag="GLOBAL:Are-you-a-resident-for-tax-purposes-in-any-other-country-or-territory-other-than-the-UK"
            required={true}
            field="OtherTaxResident"
            validation={form?.OtherTaxResident}
            id="OtherTaxResident"
            groupClassName="mb-0"
          >
            <Radio
              label="Yes"
              cmsTag="GLOBAL:Yes"
              id="YesOtherTaxResident"
              field="OtherTaxResident"
              onChange={this.onchangeRadio}
              validation={form?.OtherTaxResident}
              value="yes"
            />
            <Radio
              label="No"
              cmsTag="GLOBAL:No"
              id="NoOtherTaxResident"
              field="OtherTaxResident"
              onChange={this.onchangeRadio}
              validation={form?.OtherTaxResident}
              value="no"
            />
          </RadioGroup>
          <React.Fragment>
            {form?.USCitizen.value === 'yes' ||
            form?.OtherTaxResident.value === 'yes' ? (
              <fieldset className="mb-0 mt-5">
                <legend>
                  <Content
                    cmsTag="Long-apply:Apply-pages:Your-finances:legend-1"
                    copytext="Please enter your other countries of tax residency"
                  />
                  <span aria-hidden="true">*</span>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="GLOBAL:-required-field"
                    copytext=" (required field)"
                  />
                </legend>
                <Row
                  id="tax-id-header"
                  className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"
                  aria-hidden="true"
                >
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="Long-apply:Apply-pages:Your-finances:Country"
                      copytext="Country"
                    />
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="Long-apply:Apply-pages:Your-finances:Tax-ID-number"
                      copytext="Tax Identification Number"
                    />
                  </Col>
                </Row>
                {form.USCitizen.value === 'yes' ? (
                  <TaxIDComponent
                    USResident={true}
                    idCountry="TaxCountry1"
                    fieldCountry="TaxCountry1"
                    labelCountry={`American tax country`}
                    cmsTagCountry="GLOBAL:American-tax-country"
                    idTax="TaxIdentification1"
                    fieldTax="TaxIdentification1"
                    labelTax={`American Tax Identification Number`}
                    cmsTagTax="GLOBAL:American-Tax-Identification-Number"
                    onChange={this.onChange}
                    onchangeCountry={this.onchangeCountry}
                    onBlur={this.onBlur}
                    validationCountry={form.TaxCountry1}
                    validationTax={form.TaxIdentification1}
                  />
                ) : (
                  ''
                )}
                {form.OtherTaxResident.value === 'yes' ? (
                  <React.Fragment>
                    <TaxIDComponent
                      idCountry="TaxCountry2"
                      fieldCountry="TaxCountry2"
                      labelCountry={`Other tax country 1`}
                      cmsTagCountry="GLOBAL:Other-tax-country-1"
                      idTax="TaxIdentification2"
                      fieldTax="TaxIdentification2"
                      labelTax={`Other Tax Identification Number 1`}
                      cmsTagTax="GLOBAL:Other-Tax-Identification-Number-1"
                      onChange={this.onChange}
                      onchangeCountry={this.onchangeCountry}
                      onBlur={this.onBlur}
                      validationCountry={form.TaxCountry2}
                      validationTax={form.TaxIdentification2}
                    />
                    <TaxIDComponent
                      idCountry="TaxCountry3"
                      fieldCountry="TaxCountry3"
                      labelCountry={`Other tax country 2`}
                      cmsTagCountry="GLOBAL:Other-tax-country-2"
                      idTax="TaxIdentification3"
                      fieldTax="TaxIdentification3"
                      labelTax={`Other Tax Identification Number 2`}
                      cmsTagTax="GLOBAL:Other-Tax-Identification-Number-2"
                      onChange={this.onChange}
                      onchangeCountry={this.onchangeCountry}
                      onBlur={this.onBlur}
                      validationCountry={form.TaxCountry3}
                      validationTax={form.TaxIdentification3}
                    />
                    <TaxIDComponent
                      idCountry="TaxCountry4"
                      fieldCountry="TaxCountry4"
                      labelCountry={`Other tax country 3`}
                      cmsTagCountry="GLOBAL:Other-tax-country-3"
                      idTax="TaxIdentification4"
                      fieldTax="TaxIdentification4"
                      labelTax={`Other Tax Identification Number 3`}
                      cmsTagTax="GLOBAL:Other-Tax-Identification-Number-3"
                      onChange={this.onChange}
                      onchangeCountry={this.onchangeCountry}
                      onBlur={this.onBlur}
                      validationCountry={form.TaxCountry4}
                      validationTax={form.TaxIdentification4}
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}
              </fieldset>
            ) : (
              ''
            )}
          </React.Fragment>
          <div className="form-button-group apply-form-buttons">
            <Button id="continue-to-next-step" type="submit" color="primary">
              <Content
                cmsTag="Long-apply:Apply-pages:Your-finances:Continue-to-step-4"
                copytext="Continue to step 4"
              />
            </Button>
            <Button
              id="back-to-previous-step"
              onClick={this.setStage}
              color="secondary"
            >
              <Content
                cmsTag="Long-apply:Apply-pages:Your-finances:Back-to-step-1"
                copytext="Back to step 2"
              />
            </Button>
            <Button
              id="save-for-later"
              type="button"
              color="light"
              onClick={this.saveForLater}
            >
              <Content
                cmsTag="GLOBAL:Save-for-later"
                copytext="Save for later"
              />
            </Button>

            <ContactFooter />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

YourFinances.propTypes = {
  account: PropTypes.object,
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  formData: PropTypes.any,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  updateUrl: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default YourFinances;
