import { Connection } from '@myie/interact';

import {
  RESET_GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_REQUEST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_FALIURE,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FALIURE,
} from './addressLookupTypes';
export const resetGetAddressList = () => ({
  type: RESET_GET_ADDRESS_LIST,
});

export const getAddressListRequest = () => ({
  type: GET_ADDRESS_LIST_REQUEST,
  isFetching: true,
  error: null,
});

export const getAddressListSuccess = (json, request) => ({
  type: GET_ADDRESS_LIST_SUCCESS,
  getAddressListResponse: json,
  currentAddressIndex: request.addressIndex,
  isFetching: false,
  error: null,
});

export const getAddressListFaliure = error => ({
  type: GET_ADDRESS_LIST_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getAddressRequest = () => ({
  type: GET_ADDRESS_REQUEST,
  isFetching: true,
  error: null,
});

export const getAddressSuccess = (json, request) => ({
  type: GET_ADDRESS_SUCCESS,
  getAddressListResponse: json,
  currentAddressIndex: request.addressIndex,
  isFetching: false,
  error: null,
});

export const getAddressFaliure = error => ({
  type: GET_ADDRESS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getAddressList = request => dispatch => {
  dispatch(getAddressListRequest());
  return fetch(Connection.baseUrl('custombrand') + 'v1.0/address/search', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify({
      country_iso: 'GBR',
      components: { unspecified: [request.value] },
      datasets: ['gb-address'],
      max_suggestions: 25,
    }),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getAddressListSuccess(json, request)))
    .catch(error => dispatch(getAddressListFaliure(error)));
};

export const getAddress = (request, selectGetAddress) => dispatch => {
  dispatch(getAddressRequest());
  fetch(Connection.baseUrl('custombrand') + 'v1.0/address/format', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify({
      url: request,
    }),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => {
      dispatch(resetGetAddressList());
      dispatch(getAddressSuccess(json, request));
      selectGetAddress();
    })
    .catch(error => dispatch(getAddressFaliure(error)));
};
