import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  //fill = 'primary-fill',
  fill2 = 'light-fill',
  width = '1080',
  height = '1080',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 1080 1080"
  >
    <path
      className={fill2}
      d="M974.1,387.2L710,348.7L591.9,109.3c-21.2-42.7-82.4-43.2-103.8,0L370,348.7l-264.2,38.5c-47.4,6.9-66.4,65.3-32,98.7L265,672.2l-45.2,263.1c-8.1,47.6,41.9,83.2,83.9,60.9L540,872l236.3,124.2c41.9,22.1,92-13.4,83.9-60.9L815,672.2L1006.1,486C1040.5,452.5,1021.5,394.1,974.1,387.2L974.1,387.2z"
    />
    <polygon
      className="white-fill"
      points="721.9,641.8 764.7,892 540,774 315.3,892 358.2,641.8 176.2,464.6 427.5,428.1 540,200.3 652.5,428.1 903.8,464.6"
    />
  </svg>
);

SVG.propTypes = {
  //fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
