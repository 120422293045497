export const RESET_GET_DEPOSIT_PRODUCTS = 'RESET_GET_DEPOSIT_PRODUCTS';
export const GET_DEPOSIT_PRODUCTS_REQUEST = 'GET_DEPOSIT_PRODUCTS_REQUEST';
export const GET_DEPOSIT_PRODUCTS_SUCCESS = 'GET_DEPOSIT_PRODUCTS_SUCCESS';
export const GET_DEPOSIT_PRODUCTS_FALIURE = 'GET_DEPOSIT_PRODUCTS_FALIURE';

export const RESET_GET_DEPOSIT_PRODUCT = 'RESET_GET_DEPOSIT_PRODUCT';
export const GET_DEPOSIT_PRODUCT_REQUEST = 'GET_DEPOSIT_PRODUCT_REQUEST';
export const GET_DEPOSIT_PRODUCT_SUCCESS = 'GET_DEPOSIT_PRODUCT_SUCCESS';
export const GET_DEPOSIT_PRODUCT_FALIURE = 'GET_DEPOSIT_PRODUCT_FALIURE';

export const RESET_RESUME_APPLICATION = 'RESET_RESUME_APPLICATION';
export const RESUME_APPLICATION_REQUEST = 'RESUME_APPLICATION_REQUEST';
export const RESUME_APPLICATION_SUCCESS = 'RESUME_APPLICATION_SUCCESS';
export const RESUME_APPLICATION_FALIURE = 'RESUME_APPLICATION_FALIURE';
export const RESUME_APPLICATION_DATA = 'RESUME_APPLICATION_DATA';
export const RESET_RESUME_APPLICATION_DATA = 'RESET_RESUME_APPLICATION_DATA';

export const RESET_SAVE_LONG_APPLY = 'RESET_SAVE_LONG_APPLY';
export const SAVE_LONG_APPLY_REQUEST = 'SAVE_LONG_APPLY_REQUEST';
export const SAVE_LONG_APPLY_SUCCESS = 'SAVE_LONG_APPLY_SUCCESS';
export const SAVE_LONG_APPLY_FALIURE = 'SAVE_LONG_APPLY_FALIURE';

export const RESET_SAVE_APPLY_FOR_LATER = 'RESET_SAVE_APPLY_FOR_LATER';
export const GET_SAVE_APPLY_FOR_LATER_REQUEST =
  'GET_SAVE_APPLY_FOR_LATER_REQUEST';
export const GET_SAVE_APPLY_FOR_LATER_SUCCESS =
  'GET_SAVE_APPLY_FOR_LATER_SUCCESS';
export const GET_SAVE_APPLY_FOR_LATER_FALIURE =
  'GET_SAVE_APPLY_FOR_LATER_FALIURE';

export const SAVE_DATA_FOR_SECURITY = 'SAVE_DATA_FOR_SECURITY';

export const SET_RESUME_APPLICATION_MEMORABLE_WORD =
  'SET_RESUME_APPLICATION_MEMORABLE_WORD';
export const RESET_RESUME_APPLICATION_MEMORABLE_WORD =
  'RESET_RESUME_APPLICATION_MEMORABLE_WORD';

export const RESET_GET_APPLICATION_STATUS = 'RESET_GET_APPLICATION_STATUS';
export const GET_APPLICATION_STATUS_REQUEST = 'GET_APPLICATION_STATUS_REQUEST';
export const GET_APPLICATION_STATUS_SUCCESS = 'GET_APPLICATION_STATUS_SUCCESS';
export const GET_APPLICATION_STATUS_FALIURE = 'GET_APPLICATION_STATUS_FALIURE';
