import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  width = '512px',
  height = '512px',
  fill = 'primary-fill',
  fill2 = 'light-fill',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 239.1 239.1"
  >
    <path
      className={fill2}
      d="M119.6,0C53.5,0,0,53.5,0,119.6s53.5,119.6,119.6,119.6s119.6-53.5,119.6-119.6S185.6,0,119.6,0L119.6,0z"
    />
    <path
      className="white-fill"
      d="M119.6,216c-53.2,0-96.4-43.3-96.4-96.4s43.3-96.4,96.4-96.4S216,66.4,216,119.6S172.7,216,119.6,216L119.6,216z"
    />
    <circle className={fill2} cx="119.6" cy="119.6" r="73.3" />
    <ellipse className="white-fill" cx="119.6" cy="119.6" rx="50.1" ry="50.1" />
    <path
      className={fill}
      d="M119.6,92.6c-14.9,0-27,12.1-27,27s12.1,27,27,27s27-12.1,27-27S134.5,92.6,119.6,92.6L119.6,92.6z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SVG;
