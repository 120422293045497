import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';

class Badges extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="breadcrumbs-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/breadcrumbs.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>breadcrumb</strong> defined
          within reactstrap. To see an interactive technical specification for
          this component, go to{' '}
          <Link to="https://reactstrap.github.io/components/breadcrumbs/">
            https://reactstrap.github.io/components/breadcrumbs/
          </Link>
          .
        </p>
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={
                this.state.stage === 'ViewDetails' ||
                this.state.stage === 'Details'
              }
              className="completed"
            >
              <span className="sr-only">
                <Content id="step1-indicator" copytext="Step 1 of " />
                3:
              </span>
              Payment details
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
              <span className="sr-only">
                <Content id="step2-indicator" copytext="Step 2 of " />
                3:
              </span>
              Review
            </BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'Confirmation'}>
              <span className="sr-only">
                <Content id="step3-indicator" copytext="Step 3 of " />
                3:
              </span>
              Confirmation
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
      </section>
    );
  }
}

Badges.propTypes = {
  items: PropTypes.string,
};

export default Badges;
