import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SaveSuccess extends Component {
  resume = () => {
    const { updateUrl, formData, resumeForm } = this.props;
    let stateData = {
      formData: formData,
      url: {
        pageStage: 'resumeApplication',
      },
    };
    const { resetSaveLongApplyForLater } = this.props;
    resetSaveLongApplyForLater();
    updateUrl(stateData);
    resumeForm('resumeApplication');
  };

  componentWillUnmount() {
    const { resetSaveLongApplyForLater } = this.props;
    window.scrollTo(0, 0);
    resetSaveLongApplyForLater();
  }

  // componentDidMount() {
  //   const { formData, updateUrl } = this.props;
  //   let stateData = {
  //     formData: {
  //       ...formData,
  //       referenceNumber: formData.referenceNumber,
  //     },
  //     url: {
  //       pageStage: 'saveForLater',
  //     },
  //   };

  //   updateUrl(stateData);
  // }

  render() {
    const { formData, savedData } = this.props;
    if (!formData) {
      return null;
    }
    const savedReferenceNumber =
      savedData &&
      savedData.Ticket &&
      savedData.Ticket.Data &&
      savedData.Ticket.Data.Reference
        ? savedData.Ticket.Data.Reference
        : '';
    return (
      <React.Fragment>
        <Content
          tag="p"
          cmsTag="Long-apply:Save-and-resume:Save-application:Save-success:p1"
          copytext="Your application has been saved."
        />
        <Markdown
          cmsTag="Long-apply:Save-and-resume:Save-application:Save-success:p2"
          template={{
            markdown: {
              referenceNumber: savedReferenceNumber,
            },
          }}
          markdown={`\nYou can also continue application by entering the reference number <strong>$[referenceNumber]</strong> and your password on the "Resume application" page.`}
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Save-and-resume:Save-application:Save-success:p3"
          copytext="You must return to a saved application within 30 days."
        />
        <p>
          <Content
            tag="p"
            cmsTag="Long-apply:Save-and-resume:Save-application:Save-success:p4"
            copytext="We look forward to receiving your completed application soon."
          />
        </p>
        <div className="form-button-group">
          <Link
            className={`btn btn-primary`}
            role="button"
            to={`/resume-application`}
          >
            <Content
              cmsTag="GLOBAL:Resume-application"
              copytext="Resume application"
            />
          </Link>
          <LinkTag
            id="save-application-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

SaveSuccess.propTypes = {
  match: PropTypes.any,
  resetSaveLongApplyForLater: PropTypes.func,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  resumeForm: PropTypes.func,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveSuccess);
