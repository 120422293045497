import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DefaultSidebar from './DefaultSidebar';
import { withRouter } from 'react-router-dom';
import RegisteredSidebars from './CustomSidebars';
import queryString from 'query-string';

const HasSidebar = props => {
  const pathname = props.location.search;
  const urlPath = props.location.pathname;
  let Sidebar = DefaultSidebar;
  const { location } = props;
  const currentState = queryString.parse(location.search);

  RegisteredSidebars.forEach(sb => {
    const searchUrl = urlPath.search(sb.url);
    if (urlPath && searchUrl > -1) {
      Sidebar = sb.Sidebar;
    } else {
      if (pathname && pathname.endsWith(sb.url)) {
        if (
          currentState.pageStage === sb.url ||
          currentState.subStage === sb.url
        ) {
          Sidebar = sb.Sidebar;
        }
      }
    }
  });

  if (Sidebar !== null) {
    return true;
  } else {
    return false;
  }
};

class Sidebar extends Component {
  render() {
    const pathname = this.props.location.search;
    const urlPath = this.props.location.pathname;
    let Sidebar = DefaultSidebar;
    const { location } = this.props;
    const currentState = queryString.parse(location.search);

    RegisteredSidebars.forEach(sb => {
      const searchUrl = urlPath.search(sb.url);
      if (urlPath && searchUrl > -1) {
        Sidebar = sb.Sidebar;
      } else {
        if (pathname && pathname.endsWith(sb.url)) {
          if (
            currentState.pageStage === sb.url ||
            currentState.subStage === sb.url
          ) {
            Sidebar = sb.Sidebar;
          }
        }
      }
    });

    if (Sidebar !== null) {
      return <Sidebar />;
    } else {
      return null;
    }
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
};

export default withRouter(Sidebar);
export { HasSidebar };
