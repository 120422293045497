import React from 'react';
import Logo from './icons/Logo';
import FooterLogo from './icons/FooterLogo';

const LocalIcon = props => {
  switch (props.name) {
    case 'Logo':
      return <Logo {...props} />;
    case 'FooterLogo':
      return <FooterLogo {...props} />;
    default:
      return;
  }
};

export default LocalIcon;
