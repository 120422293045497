import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillStroke = 'goal-fill-stroke',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <circle className={fillStroke} cx="12" cy="5" r="3.2" />
    <path
      className={fill}
      d="M14.3,18.1l0.3,0.3l-1.3,1.8c-0.4,0.6-0.3,1.4,0.3,1.8c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.8-0.2,1-0.5l1.9-2.6 c0.4-0.5,0.3-1.1,0-1.6l-1-1.2"
    />
    <path
      className={fill}
      d="M7.8,16.4l-1,1.2c-0.4,0.5-0.4,1.1,0,1.6l1.9,2.6c0.3,0.3,0.6,0.5,1,0.5c0.3,0,0.5-0.1,0.8-0.3c0.6-0.4,0.7-1.2,0.3-1.8 l-1.3-1.8l0.3-0.3"
    />
    <path
      className={fill}
      d="M15.2,12.5l0-1c0.8-0.3,1.5-0.7,2.2-1.1l1.7-1.2c0.6-0.4,0.7-1.2,0.3-1.8c-0.4-0.6-1.2-0.7-1.8-0.3L16,8.3 C13.6,10,10.4,10,8,8.3L6.3,7.1C5.7,6.7,4.9,6.8,4.5,7.4C4.1,8,4.3,8.8,4.9,9.2l1.7,1.2c0.7,0.5,1.4,0.9,2.2,1.1l0,1"
    />
    <path
      className={fill2}
      d="M8.8,13.2c-0.4,0.2-0.6,0.6-0.6,1.1V15c0,0.4,0.2,0.7,0.2,0.7l0,0l0.5,0.6l0.4,0.4L9.6,17l0.3,0.2l0.1,0.1l0.1,0.1l0.2,0.1 c0.5,0.4,1,0.6,1.6,0.6h0.4c0.6,0,1.1-0.2,1.6-0.6l0.2-0.2l0.3-0.2l0.3-0.3l0.2-0.1l0.1-0.2l0.3-0.4l0.2-0.2l0.2-0.2 c0.1-0.2,0.2-0.4,0.2-0.7v-0.7c0-0.5-0.3-0.9-0.6-1.1"
    />
    <path
      className={fillNegative}
      d="M14.6,15l-1.4,1.4c-0.2,0.2-0.6,0.4-0.9,0.4h-0.4c-0.3,0-0.7-0.1-0.9-0.4L9.4,15v-0.7h5.1V15z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillStroke: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fillNegative: PropTypes.string,
};

export default SVG;
