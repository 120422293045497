import React from 'react';
import PropTypes from 'prop-types';
import { Session } from '@myie/interact';
import { Switch } from '@myie/interact-dom';

function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    render() {
      const { showAuthError = true } = this.props;
      const showErrorMessage = showAuthError ? 'ShowError' : '';

      return Session.isAuthenticated() ? (
        <Component {...this.props} />
      ) : (
        <Switch
          tag="div"
          className="alert alert-danger mt-3"
          value={showErrorMessage}
          scrolltotop={true}
          contents={{
            ShowError: {
              defaultValue: 'Please sign in to load this feature',
            },
          }}
        />
      );
    }
  }
  AuthenticatedComponent.propTypes = {
    showAuthError: PropTypes.any,
  };

  return AuthenticatedComponent;
}

export default requireAuthentication;
