import moment from 'moment';
const applyPagesUtility = (function() {
  const getTimeDiff = date => {
    let dateofvisit = moment(date);
    let today = moment();

    let diff = moment(today, 'YYYY-MM-DD HH:mm:ss').diff(
      moment(dateofvisit, 'YYYY-MM-DD HH:mm:ss'),
      'days',
    );
    return diff;
  };

  const saveAccount = account => {
    window.sessionStorage.setItem('account', account);
  };

  const getAccount = () => {
    return window.sessionStorage.getItem('account');
  };
  return {
    getTimeDiff: getTimeDiff,
    saveAccount: saveAccount,
    getAccount: getAccount,
  };
})();

export default applyPagesUtility;
