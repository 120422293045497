import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  width = '512px',
  height = '512px',
  fill = 'primary-fill',
  fill2 = 'light-fill',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        className={fill2}
        d="M853 5105 c-81 -22 -132 -52 -193 -114 -65 -65 -104 -140 -120 -232 -8 -45 -10 -692 -8 -2239 l3 -2175 22 -58 c43 -115 139 -214 250 -258 l58 -24 1665 -3 c1164 -2 1681 1 1717 8 168 35 300 173 332 344 15 84 15 4328 0 4412 -31 167 -150 296 -314 339 -52 13 -258 15 -1710 14 -1389 0 -1658 -3 -1702 -14z m3403 -222 c51 -27 90 -72 104 -121 8 -26 10 -692 8 -2224 l-3 -2186 -30 -44 c-17 -23 -51 -54 -75 -68 l-45 -25 -1655 0 -1655 0 -40 22 c-50 27 -80 58 -100 105 -13 33 -15 272 -15 2218 0 1946 2 2185 15 2218 26 60 73 101 145 123 14 4 763 7 1665 6 l1640 -2 41 -22z"
      />
      <path
        className={fill}
        d="M1023 4575 c-65 -28 -64 -8 -61 -695 l3 -620 28 -27 27 -28 1540 0 1540 0 27 28 28 27 3 620 c3 690 5 667 -63 696 -51 21 -3024 20 -3072 -1z m2925 -682 l-3 -478 -1385 0 -1385 0 -3 478 -2 477 1390 0 1390 0 -2 -477z"
      />
      <path d="M1025 2871 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -36 -20 -281 l0 -259 28 -27 27 -28 260 0 260 0 27 28 28 27 0 260 0 260 -28 27 -27 28 -248 2 c-136 1 -256 -2 -267 -6z m360 -311 l0 -105 -105 0 -105 0 -3 94 c-1 52 0 101 2 108 4 11 29 13 108 11 l103 -3 0 -105z" />
      <path d="M1864 2861 c-17 -11 -36 -34 -43 -52 -17 -50 -15 -460 3 -504 26 -61 47 -65 311 -65 257 0 280 4 304 57 16 34 15 494 -1 528 -23 51 -46 55 -305 55 -216 0 -241 -2 -269 -19z m376 -301 l0 -110 -105 0 -105 0 0 110 0 110 105 0 105 0 0 -110z" />
      <path d="M2718 2863 c-14 -9 -31 -27 -37 -40 -15 -32 -15 -494 0 -526 24 -53 47 -57 304 -57 195 0 241 3 266 16 53 27 59 59 59 304 0 245 -6 277 -59 304 -46 24 -494 23 -533 -1z m372 -303 l0 -110 -105 0 -105 0 0 110 0 110 105 0 105 0 0 -110z" />
      <path d="M3585 2871 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -36 -20 -281 l0 -259 28 -27 27 -28 260 0 260 0 27 28 28 27 0 260 0 260 -28 27 -27 28 -248 2 c-136 1 -256 -2 -267 -6z m360 -311 l0 -105 -105 0 -105 0 -3 94 c-1 52 0 101 2 108 4 11 29 13 108 11 l103 -3 0 -105z" />
      <path d="M1023 2015 c-58 -25 -63 -47 -63 -310 0 -257 4 -280 57 -304 32 -15 494 -15 526 0 53 24 57 47 57 304 0 264 -4 285 -65 311 -48 20 -465 20 -512 -1z m367 -310 l0 -105 -110 0 -110 0 0 105 0 105 110 0 110 0 0 -105z" />
      <path d="M1873 2014 c-57 -28 -63 -56 -63 -303 0 -238 6 -273 51 -305 20 -14 59 -16 277 -16 l254 0 29 29 29 29 0 254 c0 281 -1 287 -66 314 -51 21 -467 20 -511 -2z m367 -309 l0 -105 -105 0 -105 0 0 105 0 105 105 0 105 0 0 -105z" />
      <path d="M2734 2016 c-63 -28 -64 -34 -64 -314 l0 -254 29 -29 29 -29 254 0 c218 0 257 2 277 16 45 32 51 67 51 305 0 331 12 319 -322 319 -165 -1 -231 -4 -254 -14z m356 -311 l0 -105 -105 0 -105 0 0 105 0 105 105 0 105 0 0 -105z" />
      <path d="M3583 2015 c-65 -28 -63 -5 -63 -735 0 -733 -2 -708 65 -736 49 -20 461 -20 510 0 67 28 65 3 65 736 0 733 2 708 -65 736 -48 20 -465 20 -512 -1z m367 -735 l0 -530 -110 0 -110 0 0 530 0 530 110 0 110 0 0 -530z" />
      <path d="M1014 1159 c-49 -25 -54 -50 -54 -304 0 -264 4 -285 65 -311 49 -20 461 -20 510 0 61 26 65 47 65 311 0 257 -4 280 -57 304 -31 14 -500 14 -529 0z m376 -304 l0 -105 -110 0 -110 0 0 105 0 105 110 0 110 0 0 -105z" />
      <path d="M1861 1154 c-45 -32 -51 -67 -51 -305 0 -330 -11 -319 319 -319 238 0 273 6 305 51 14 20 16 59 16 277 l0 254 -29 29 -29 29 -254 0 c-218 0 -257 -2 -277 -16z m379 -299 l0 -105 -105 0 -105 0 0 105 0 105 105 0 105 0 0 -105z" />
      <path d="M2699 1141 l-29 -29 0 -254 c0 -218 2 -257 16 -277 32 -45 67 -51 305 -51 330 0 319 -11 319 319 0 238 -6 273 -51 305 -20 14 -59 16 -277 16 l-254 0 -29 -29z m391 -286 l0 -105 -105 0 -105 0 0 105 0 105 105 0 105 0 0 -105z" />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SVG;
