import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Lists extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="lists-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/typography.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>unordered lists</strong>{' '}
          defined within reactstrap. To see an interactive technical
          specification for this component, go to{' '}
          <Link to="https://reactstrap.github.io/components/listgroup/">
            https://reactstrap.github.io/components/listgroup/
          </Link>
          .
        </p>
        <Row>
          <Col xs={4}>
            <h3>Unstyled list</h3>
            <ul className="list-unstyled">
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
            </ul>
          </Col>
          <Col xs={4}>
            <h3>Bullet list</h3>
            <ul className="bullet-list">
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
            </ul>
          </Col>
          <Col xs={4}>
            <h3>Check list</h3>
            <ul className="check-list">
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <h3>Ordered list</h3>
            <ol>
              <li>Cras justo odio</li>
              <li>Dapibus ac facilisis in</li>
              <li>Morbi leo risus</li>
              <li>Porta ac consectetur ac</li>
              <li>Vestibulum at eros</li>
            </ol>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <h3>Description list - vertical</h3>
            <Row tag="dl" title="all dls need titles">
              <Col tag="dt" xs={12}>
                &pound;314.09
              </Col>
              <Col tag="dd" xs={12}>
                available
              </Col>
              <Col tag="dt" xs={12}>
                &pound;5000.00
              </Col>
              <Col tag="dd" xs={12}>
                credit limit
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <h3>Description list using columns</h3>
            <Row tag="dl" title="all dls need titles">
              <Col tag="dt" xs={12} sm={4} lg={3}>
                &pound;314.09
              </Col>
              <Col tag="dd" xs={12} sm={8} lg={9}>
                available
              </Col>
              <Col tag="dt" xs={12} sm={4} lg={3}>
                &pound;5000.00
              </Col>
              <Col tag="dd" xs={12} sm={8} lg={9}>
                credit limit
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <h3>Description list using dl-horizontal</h3>
            <dl className="dl-horizontal">
              <dt>Balance due</dt>
              <dd>&pound;2300.13</dd>
              <dt>Minimum</dt>
              <dd>&pound;100.00</dd>
            </dl>
          </Col>
          <Col xs={6}>
            <h3>Description list reversed</h3>
            <Row tag="dl" className="dl-reversed" title="all dls need titles">
              <Col tag="dt" xs={12} sm={4} lg={3}>
                &pound;314.09
              </Col>
              <Col tag="dd" xs={12} sm={8} lg={9}>
                available
              </Col>
              <Col tag="dt" xs={12} sm={4} lg={3}>
                &pound;5000.00
              </Col>
              <Col tag="dd" xs={12} sm={8} lg={9}>
                credit limit
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    );
  }
}

Lists.propTypes = {
  items: PropTypes.string,
};

export default Lists;
