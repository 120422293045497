import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import {
  Content,
  Text,
  Radio,
  DropDown,
  Date,
  Check,
  RadioGroup,
  CONSTANTS,
  FormErrorList,
  RULES,
} from '@myie/interact-dom';
import ContactDetails from './contact-components/ContactDetails';
import _ from 'lodash';
import ContactPreferences from './contact-components/ContactPreferences';
import ContactFooter from './page-components/ContactFooter';

import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class PersonalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  initialState = props => {
    const { formData } = props;
    let data = null;
    if ('personalDetails' in formData) {
      data = formData['personalDetails'];
    }
    return {
      data: data ? data : {},
      form: {
        title: {
          rules: {
            stop: true,
            required: {
              message: 'Please tell us your title.',
            },
          },
          value: data ? data.title : '',
        },
        firstName: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your first name.',
            },
            format: {
              regex: RULES.firstName,
              message: 'Please enter a valid first name.',
            },
          },
          value: data ? data.firstName : '',
        },
        middleName: {
          rules: {
            stop: true,
            format: {
              regex: RULES.middleName,
              message: 'Please enter a valid middle name.',
            },
          },
          value: data ? data.middleName : '',
        },
        lastName: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your last name.',
            },
            format: {
              regex: RULES.lastName,
              message: 'Please enter a valid last name.',
            },
          },
          value: data ? data.lastName : '',
        },
        gender: {
          rules: {
            required: {
              message: 'Please tell us your gender.',
            },
          },
          value: data ? data.gender : '',
        },
        b_day: {
          rules: {
            title: 'bday',
            stop: true,
            required: {
              message: 'Please tell us your date of birth.',
            },
            format: {
              regex: RULES.dateNotBefore1900,
              message: 'Please enter a valid date.',
            },
            minAge18: {
              message: 'You must be 18 years or older to apply.',
            },
          },
          value: data ? data.b_day : '',
        },
        maritalStatus: {
          rules: {
            stop: true,
            required: {
              message: 'Please tell us your marital status.',
            },
          },
          value: data ? data.maritalStatus : '',
        },
        nationality: {
          rules: {
            stop: true,
            required: {
              message: 'Please select your nationality.',
            },
          },
          value: data ? data.nationality : '',
        },
        nationalInsuranceNumber: {
          rules: {
            stop: true,
            required: {
              message: 'Please tell us your National Insurance Number.',
            },
            format: {
              regex: RULES.nationalInsuranceNumber,
              message: 'Please provide a valid National Insurance Number.',
            },
          },
          value: data ? data.nationalInsuranceNumber : '',
        },
        confirmUK: {
          rules: {
            required: {
              message: 'Please confirm that you are a UK resident.',
            },
          },
          value: data ? !!data.confirmUK : false,
        },
        mobilePhoneNumber: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your mobile phone number.',
            },
            format: {
              regex: RULES.mobilePhoneNumber,
              message: 'Please enter a valid mobile number.',
            },
          },
          value: data ? data.mobilePhoneNumber : '',
        },
        daytimePhoneNumber: {
          rules: {
            stop: true,
            format: {
              regex: RULES.daytimePhoneNumber,
              message: 'Please enter a valid daytime phone number.',
            },
          },
          value: data && data.daytimePhoneNumber ? data.daytimePhoneNumber : '',
        },
        eveningPhoneNumber: {
          rules: {
            stop: true,
            format: {
              regex: RULES.eveningPhoneNumber,
              message: 'Please enter a evening phone number.',
            },
          },
          value: data && data.eveningPhoneNumber ? data.eveningPhoneNumber : '',
        },
        emailAddress: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your email address.',
            },
            format: {
              regex: RULES.emailAddress,
              message: 'Please enter a valid email address.',
            },
          },
          value: data ? data.emailAddress : '',
        },
        confirmEmailAddress: {
          rules: {
            stop: true,
            required: {
              message: 'Please confirm your email address.',
            },
            format: {
              regex: RULES.emailAddress,
              message: 'Please enter a valid email address.',
            },
            compare: {
              message: 'Email addresses do not match.',
              field: 'EmailAddress',
              comparison: () => {
                return this.state.form.emailAddress.value;
              },
            },
          },
          value: data ? data.confirmEmailAddress : '',
        },
        contactPreferences: {
          rules: {
            title: 'contact preferences',
            stop: true,
            required: {
              message: 'Please select how you would like us to contact you.',
            },
          },
          value: data ? data.contactPreferences : '',
        },
      },
    };
  };

  componentDidUpdate() {
    const { formData } = this.props;
    const { data } = this.state;

    if (
      !_.isEmpty(formData['personalDetails']) &&
      JSON.stringify(data) !== JSON.stringify(formData['personalDetails'])
    ) {
      const state = this.initialState(this.props);
      this.setState({ ...state });
    }
  }

  saveForLater = () => {
    const { setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        ...formData,
        personalDetails: data,
        stage: 'personalDetails',
      },
      url: {
        pageStage: 'saveForLater',
      },
    };

    updateUrl(stateData);
    setStage('saveForLater');
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateMarketingPreferences = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { onSubmit, setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        personalDetails: data,
        ...formData,
      },
      url: {
        pageStage: 'addressDetails',
      },
    };

    updateUrl(stateData);
    window.scrollTo(0, 0);
    setStage('addressDetails');
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { form } = this.state;

    return (
      <React.Fragment>
        <Form
          autoComplete="off"
          id="apply-form"
          onSubmit={this.onSubmitHandler}
        >
          <Content
            tag="h2"
            tagClassName="breadcrumb-title step-1"
            cmsTag="BREADCRUMB:Personal-details"
            copytext="Personal details"
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Personal-details:p1"
            copytext="Tell us about yourself."
          />
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <section id="personal-details-section">
            <DropDown
              cmsTag="GLOBAL:Title"
              label="Title"
              id="title"
              field="title"
              onChange={this.onChange}
              validation={form.title}
            >
              <option
                id={`title-option-please-select`}
                key="1"
                value=""
                defaultText="Please select"
              ></option>
              {CONSTANTS &&
                CONSTANTS.TITLES.map((item, index) => {
                  return this.dropdownOptions(item, index, 'title');
                })}
            </DropDown>
            <Text
              label="First name"
              cmsTag="GLOBAL:First-name"
              id="firstName"
              field="firstName"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.firstName}
            />
            <Text
              label="Middle name"
              cmsTag="GLOBAL:Middle-name"
              id="middleName"
              field="middleName"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.middleName}
            />
            <Text
              label="Last name"
              cmsTag="GLOBAL:Last-name"
              id="lastName"
              field="lastName"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.lastName}
            />
            <RadioGroup
              legend="Gender"
              cmsTag="GLOBAL:Gender"
              required={true}
              field="gender"
              validation={form.gender}
              id="gender"
            >
              <Radio
                label="Female"
                cmsTag="GLOBAL:Female"
                id="female"
                field="gender"
                onChange={this.onChange}
                validation={form.gender}
                value="female"
              />
              <Radio
                label="Male"
                cmsTag="GLOBAL:Male"
                id="male"
                field="gender"
                onChange={this.onChange}
                validation={form.gender}
                value="male"
              />
            </RadioGroup>
            <Date
              label="Date of birth"
              cmsTag="GLOBAL:Date-of-birth"
              id="b_day"
              field="b_day"
              groupClassName="half-field"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.b_day}
              min="1900-01-01"
            />
            <DropDown
              cmsTag="GLOBAL:Marital-status"
              label="Marital Status"
              id="maritalStatus"
              field="maritalStatus"
              onChange={this.onChange}
              validation={form.maritalStatus}
            >
              <option
                id={`maritalStatus-option-empty-1`}
                key="1"
                value=""
                defaultText="Please select"
              ></option>
              {CONSTANTS &&
                CONSTANTS.MARITAL_STATUS_CODE.map((item, index) => {
                  return this.dropdownOptions(item, index, 'maritalStatus');
                })}
            </DropDown>
            <DropDown
              cmsTag="GLOBAL:Nationality"
              label="Nationality"
              id="nationality"
              field="nationality"
              onChange={this.onChange}
              validation={form.nationality}
            >
              <option
                id={`nationality-option-empty-1`}
                key="1"
                value=""
                defaultText="Please select"
              ></option>
              {CONSTANTS &&
                CONSTANTS.NATIONALITY.map((item, index) => {
                  return this.dropdownOptions(item, index, 'nationality');
                })}
            </DropDown>
            <Text
              label="National Insurance Number"
              cmsTag="GLOBAL:National-Insurance-Number"
              id="nationalInsuranceNumber"
              field="nationalInsuranceNumber"
              maxLength={'9'}
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.nationalInsuranceNumber}
              groupClassName="half-field"
            />
            <Check
              cmsTag="GLOBAL:I-confirm-I-am-a-UK-resident"
              label="I confirm I am a UK resident."
              id="confirmUK"
              field="confirmUK"
              checked={!!form.confirmUK.value}
              value="true"
              validation={form.confirmUK}
              onChange={this.onClick}
            />
          </section>
          <section id="contact-details-section">
            <ContactDetails onChange={this.onChange} form={this.state.form} />
          </section>
          <section id="contact-preferences-section">
            <ContactPreferences
              form={this.state.form}
              updateMarketingPreferences={this.updateMarketingPreferences}
            />
          </section>
          <div className="form-button-group apply-form-buttons">
            <Button id="continue-to-next-step" type="submit" color="primary">
              <Content
                cmsTag="Long-apply:Apply-pages:Personal-details:Continue-to-step-2"
                copytext="Continue to step 2"
              />
            </Button>
            <Button
              id="save-for-later"
              type="button"
              color="light"
              onClick={this.saveForLater}
            >
              <Content
                cmsTag="GLOBAL:Save-for-later"
                copytext="Save for later"
              />
            </Button>
            <ContactFooter />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

PersonalDetails.propTypes = {
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.any,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  updateUrl: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default PersonalDetails;
