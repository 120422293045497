import * as React from 'react';
import { Settings } from '@myie/interact';
import { Content } from '@myie/interact-dom';

class SignOut extends React.Component {
  logout = () => {
    Settings.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    Settings.userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.sessionStorage.clear();
      window.location.replace('/');
    });
    window.localStorage.clear();
    window.sessionStorage.clear();
    Settings.userManager.clearStaleState();
  };
  render() {
    this.logout();
    return (
      <p aria-live="polite">
        <Content cmsTag="GLOBAL:signing-out" copytext="Signing out..." />
      </p>
    );
  }
}

export default SignOut;
