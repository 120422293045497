import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content } from '@myie/interact-dom';
import { Button } from 'reactstrap';

class Error extends React.Component {
  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    const { status } = this.props;
    return (
      <div id="second-factor-error">
        <Content
          cmsTag="Two-factor:Error:alert-1"
          template={{
            copytext: {
              status,
            },
          }}
          copytext="Unsupported challenge status $[status]."
        />
        <div className="form-button-group">
          <Button id="error-cancel" color="secondary" onClick={this.cancel}>
            <Content cmsTag="Two-factor:Error:Cancel" copytext="Cancel" />
            <span className="sr-only">
              <Content
                cmsTag="Two-factor:Error:Sr-only:-authentication"
                copytext=" authentication"
              />
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

Error.propTypes = {
  finishTwoFactor: PropTypes.any,
  status: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Error);
