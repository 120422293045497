import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducers';
import { Settings } from '@myie/interact';
import secureFetch from './lib/secure-fetch/secureFetch';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './serviceWorker';
import './styling.css';
import { loadUser, OidcProvider } from 'redux-oidc';

// apply configuration
import Config from './config.js';

// Wrap global fetch function with a check to ensure the original
// session is the same when the response returns.
// eslint-disable-next-line no-global-assign
fetch = secureFetch(fetch);

const persistConfig = {
  key: 'persistedState',
  storage,
  blacklist: [
    'accountsDefinition',
    'cmsDefinition',
    'twoFactorDefinition',
    'payByCardDefinition',
  ],
};

const middleware = [thunk];
if (process.env.NODE_ENV === 'notset') {
  // Set to development to enable logging in dev
  middleware.push(createLogger());
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const persistedReducer = persistReducer(persistConfig, reducer);

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);

(async () => {
  await Config();

  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  loadUser(store, Settings.userManager);
  render(
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <OidcProvider store={store} userManager={Settings.userManager}>
            <App />
          </OidcProvider>
        </PersistGate>
      </Provider>
    </Router>,
    document.getElementById('root'),
  );
})();

//registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
