//HOC
import requireAuthentication from './HO_components/requireAuthentication';
import contentContainer from './HO_components/contentContainer';
import loadStateHOC from './stateManager/loadStateHOC';
import stateUtility from './stateManager/stateUtility';

// components
import Check from './components/Check';
import Errors, { HasErrors } from './components/Errors';
import ErrorPanel from './components/ErrorPanel';
import CommonError from './CommonError';
import FormattedDate from './components/FormattedDate';
import FormattedCurrency from './components/FormattedCurrency';
import Radio from './components/Radio';
import RadioButton from './components/RadioButton';
import RadioGroup from './components/RadioGroup';
import RoundBadge from './components/RoundBadge';
import Text from './components/Text';
import TextArea from './components/TextArea';
import DropDown from './components/DropDown';
import Numeric from './components/Numeric';
import Currency from './components/Currency';
import Slider from './components/Slider';
import Date from './components/Date';
import Mask from './components/InputMask';
import Value from './components/Value';
import AddressLookupDemo from './components/AddressLookupDemo.js';
import GoogleTagManager from './components/GoogleTagManager';
import IfAuthenticated from './components/IfAuthenticated';
import Dialog from './components/Dialog';
import FormErrorList from './components/formErrors/FormErrorList';
import ListItem from './components/formErrors/ListItem';
import BackToButton from './components/BackToButton';
import Iframe from './components/Iframe';
import CheckboxGroup from './components/CheckboxGroup';
import AddressLookupQAS from './components/AddressLookupQAS.js';

//Symbols
import symbols from './components/symbols';
import Pagination from './components/Pagination';

// Adverts & Sidebar widgets
import HelpContactSidebar from './components/HelpContactSidebar';
import SignInSidebarComponent from './components/SignInSidebarComponent';
import RegisterSidebarComponent from './components/RegisterSidebarComponent';
import OurProducts from './components/OurProducts';
import CustomAlert from './components/CustomAlert';

// timeout
import TimeOutModal from './timeout/TimeOutModal';
import TimeOut from './timeout/timeout';
import sessionTimer from './timeout/sessionTimer';
// routing
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import NoMatch from './routing/NoMatch';
import NetworkError from './routing/NetworkError';
import AvailableRoutes from './routing/AvailableRoutes';
import Interceptor from './routing/Interceptor';
import AuthButton from './components/AuthButton';
import AppMeta from './components/AppMeta';
import ScrollToTop from './components/ScrollToTop';
import TabNavigation from './components/TabNavigation';
import Content from './components/Content';
import Markdown from './components/Markdown';
import Switch from './components/Switch';
import BankHolidays from './components/BankHolidays';

import { confirm } from './components/confirmdialog';
import SideLink from './components/SideLink';
import ErrorScroll from './ErrorScroll';
import './form-validation-tests';
import ContactNumber from './components/ContactNumber';
import AERAbbreviation from './components/AERAbbreviation';
import ExampleAbbreviation from './components/ExampleAbbreviation';
import ContactOpenTimes from './components/ContactOpenTimes';
import LinkTag from './components/LinkTag';
import EmailAddress from './components/EmailAddress';
import sharedUtility from './components/sharedUtility';
import appUtility from './utility';
import dateValidation from './dateValidation';
import SystemDowntimeBanner from './components/SystemDowntimeBanner';
import SystemDowntimeBlockedPage from './components/SystemDowntimeBlockedPage';
// setup initial moment config
import moment from 'moment';
import 'moment/locale/en-gb';

import Icon from './svgs';

import { RULES, CONSTANTS, CONTACTS } from './constants/';

moment.locale(navigator.language);

export {
  Iframe,
  RULES,
  loadStateHOC,
  CustomAlert,
  dateValidation,
  stateUtility,
  CONSTANTS,
  CONTACTS,
  appUtility,
  ListItem,
  Switch,
  Content,
  FormErrorList,
  sharedUtility,
  Markdown,
  ErrorScroll,
  BankHolidays,
  HasErrors,
  TabNavigation,
  IfAuthenticated,
  Check,
  Errors,
  ErrorPanel,
  CommonError,
  FormattedDate,
  FormattedCurrency,
  Radio,
  RoundBadge,
  Text,
  TextArea,
  LinkTag,
  Currency,
  Numeric,
  Slider,
  Date,
  Mask,
  Value,
  DropDown,
  RadioGroup,
  RadioButton,
  AddressLookupDemo,
  AddressLookupQAS,
  GoogleTagManager,
  PublicRoute,
  PrivateRoute,
  NoMatch,
  NetworkError,
  Interceptor,
  AvailableRoutes,
  TimeOutModal,
  TimeOut,
  sessionTimer,
  symbols,
  Pagination,
  requireAuthentication,
  contentContainer,
  AuthButton,
  AppMeta,
  ScrollToTop,
  Dialog,
  confirm,
  SideLink,
  HelpContactSidebar,
  SignInSidebarComponent,
  RegisterSidebarComponent,
  OurProducts,
  ContactNumber,
  ContactOpenTimes,
  Icon,
  EmailAddress,
  ExampleAbbreviation,
  AERAbbreviation,
  BackToButton,
  CheckboxGroup,
  SystemDowntimeBanner,
  SystemDowntimeBlockedPage,
};
