import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { InputGroup, InputGroupText, Button, Input, Label } from 'reactstrap';
import { Validate } from '@myie/interact';
import { Content, Markdown } from '@myie/interact-dom';
import Errors, { HasErrors } from './Errors';

class AddressLookupDemo extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      placeholder,
      suffix,
      validation,
      managedContent,
      lookupForm,
    } = props;

    this.state = {
      error: false,
      results: [],
      isSelected: lookupForm.addressInput.value ? true : false,
    };

    this.searchAddressInput = React.createRef(null);

    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  componentDidUpdate() {
    const { addressList } = this.props;
    const { results } = this.state;

    if (JSON.stringify(results) !== JSON.stringify(addressList)) {
      this.setState({ results: addressList });
    }
  }

  clearSelectedAddress = () => {
    const { resetGetAddressList } = this.props;
    resetGetAddressList();
    this.setState(
      {
        results: [],
        isSelected: false,
      },
      () => {
        this.props.onSelectAddress('addressInput', '');
        this.searchAddressInput.current.focus();
      },
    );
  };

  onClickAddress = address => {
    let addressStr = Object.values(address).join(', ');
    const { resetGetAddressList } = this.props;
    resetGetAddressList();
    this.setState(
      {
        results: [],
        isSelected: true,
      },
      () => {
        this.props.onSelectAddress('addressInput', addressStr);
      },
    );
  };

  handleChange = e => {
    let inputValue = e.target.value;
    const { onChangeAddressSearchInput, resetGetAddressList } = this.props;

    if (inputValue && inputValue.trim()) {
      this.props.onSelectAddress('addressInput', inputValue);
      onChangeAddressSearchInput(inputValue);
    } else {
      this.clearSelectedAddress();
      resetGetAddressList();
    }
  };

  stringToHTML = str => {
    let dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  };

  render() {
    const { props } = this;

    const {
      inputGroupClassName = '',
      suffixIsDiv,
      suffixAlert,
      groupClassName = '',
      disabled,
      required,
      validation = {},
      contentValue,
      labelClassName = '',
      field,
      id,
      // managedContent,
      children,
      showErrors = true,
      lookupForm,
      ...rest
    } = props;

    const { results, isSelected } = this.state;
    const { state = {} } = validation;

    // if (!managedContent()) return '';
    // Object.keys(managedContent()).forEach(k => {
    //   delete rest[k];
    // });

    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const suffix = contentValue(ContentType.Text, 'suffix');

    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;

    // const isRequired =
    //   required || (validation && validation.rules && validation.rules.required);

    const isRequired =
      required ||
      (lookupForm &&
        lookupForm.addressInput &&
        lookupForm.addressInput.rules &&
        lookupForm.addressInput.rules.required);

    let dynamicProps = {};
    if (placeholder) {
      dynamicProps.placeholder = placeholder;
    }

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }

    return (
      <div
        className={`form-group address-form-group ${groupClassName} ${
          disabled ? 'disabled-input' : ''
        } ${isRequired ? 'required-input' : ''}`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}

        {
          <InputGroup className={inputGroupClassName}>
            <Input
              invalid={Validate.isInvalid(state)}
              valid={Validate.isValid(state)}
              id={id}
              name={fieldName}
              value={lookupForm.addressInput.value}
              type="text"
              disabled={disabled}
              innerRef={this.searchAddressInput}
              {...dynamicProps}
              {...dynamicAriaDescribedBy}
              {...rest}
              onChange={this.handleChange}
            />
            {suffix ? (
              suffixAlert ? (
                <div
                  className="alert alert-info mt-3 w-auto d-inline-block"
                  id={`${id}-desc`}
                >
                  <p>{suffix}</p>
                </div>
              ) : suffixIsDiv ? (
                <div className="suffix" id={`${id}-desc`}>
                  {suffix}
                </div>
              ) : (
                <p className="suffix" id={`${id}-desc`}>
                  {suffix}
                </p>
              )
            ) : (
              ''
            )}
            {children}
            {showErrors ? (
              <Errors
                contentValue={contentValue}
                validation={validation}
                field={field}
              />
            ) : (
              ''
            )}

            {lookupForm.addressInput.value && (
              <InputGroupText>
                <Button
                  disabled={disabled}
                  className="address-clear-button btn-link"
                  onClick={() => this.clearSelectedAddress()}
                >
                  <span aria-hidden="true">×</span>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="GLOBAL:Clear-address"
                    copytext="Clear address"
                  />
                </Button>
              </InputGroupText>
            )}
          </InputGroup>
        }

        <div aria-live="polite" className="address-lookup-results-container">
          {results?.length > 0 && (
            <div className="results custom-scrollbar">
              <div
                className="h5 results-list-title"
                id={`${id}-result-list-title`}
              >
                <Markdown
                  cmsTag="Address-Lookup:Results-list-title"
                  template={{
                    markdown: { resultsLength: results.length },
                  }}
                  markdown={`You have $[resultsLength] results:`}
                />
              </div>
              <ul aria-describedby={`${id}-result-list-title`}>
                {results.map(result => (
                  <li key={result.currentFlatNumber}>
                    <button
                      type="button"
                      onClick={() => this.onClickAddress(result)}
                    >
                      {result.currentFlatNumber}, {result.currentHouseName},{' '}
                      {result.currentHouseNumber}, {result.currentStreet},{' '}
                      {result.currentVillage}, {result.currentTown},{' '}
                      {result.currentCounty}, {result.currentPostcode}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {lookupForm.addressInput.value && isSelected && (
            <div className="chosen-address-container">
              <address
                aria-describedby={`${id}-result-selected-title`}
                className="mt-4"
              >
                {lookupForm.addressInput.value.split(',').map(item => (
                  <p className={'mb-0'} key={item}>
                    {item}
                  </p>
                ))}
              </address>

              <Button
                className="address-clearselected--button btn btn-light btn-sm"
                onClick={() => this.clearSelectedAddress()}
              >
                <Content
                  cmsTag="GLOBAL:Change-address"
                  copytext="Change address"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AddressLookupDemo.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.element,
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  description: PropTypes.string,
  groupClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  subLabel: PropTypes.string,
  addresses: PropTypes.object,
  inputGroupClassName: PropTypes.string,
  validation: PropTypes.object,
  disabled: PropTypes.bool,
  resetGetAddressList: PropTypes.func,
  addressList: PropTypes.array,
  required: PropTypes.bool,
  onChangeAddressSearchInput: PropTypes.func,
  id: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  suffixAlert: PropTypes.bool,
  suffixIsDiv: PropTypes.bool,
  onSelectAddress: PropTypes.func,
  lookupForm: PropTypes.object,
};

export default contentManaged(AddressLookupDemo);
