import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Content, Markdown, LinkTag, stateUtility } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import SelectDestination from './SelectDestination';

class ExpiredCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobNumberNotFound: false,
      sendCode: false,
      retry: false,
    };
  }

  retry = e => {
    const { resetDestinations, resetChallenge } = this.props;
    e.preventDefault();
    resetDestinations();
    resetChallenge();
  };

  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  setMobNumberNotFound = () => {
    this.setState({ ...this.state, mobNumberNotFound: true });
  };

  setResend = () => {
    this.setState({ ...this.state, sendCode: true });
  };

  setRetry = () => {
    const { resetChallenge, history } = this.props;
    resetChallenge();
    const storage = stateUtility.getPageState();
    let stateData = {
      destination: null,
      twoFapage: 'active',
      destinationReq: null,
      url: {
        twoFa: 'active',
      },
    };

    stateUtility.directlyUpdateUrl(
      stateData,
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '',
      history,
    );
    this.setState({ ...this.state, retry: true });
  };
  render() {
    const { setDestination } = this.props;

    return (
      <React.Fragment>
        {!this.state.retry ? (
          <React.Fragment>
            <h1 id="expired-code">
              <Content
                cmsTag="Two-factor:expired-code:h1"
                copytext="Authentication code expired"
              />
            </h1>
            <Markdown
              cmsTag="Two-factor:expired-code:p2"
              template={{
                markdown: {
                  phone: CONTACTS.phone,
                  phoneLink: CONTACTS.phoneLink,
                  openTimes: CONTACTS.openTimes,
                  email: CONTACTS.email,
                },
              }}
              markdown={`\nThe authentication code you entered has expired and is no longer valid. \n\nPlease try again or contact our Online Support Team. You can email <a href="tel:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
            />
            <div className="form-button-group">
              <Button
                color="primary"
                onClick={() => this.setRetry()}
                id="try-again"
              >
                <Content cmsTag="GLOBAL:Try-again" copytext="Try again" />
              </Button>

              {this.props.isAuthenticated ? (
                <Link
                  id="request-cancelled-back-to-home"
                  className={`btn btn-secondary`}
                  to={'/accounts/list'}
                >
                  <Content
                    id="GLOBAL:Back-to-home-page"
                    copytext="Back to home page"
                  />
                </Link>
              ) : (
                <LinkTag
                  id="request-cancelled-back-to-home"
                  className={`btn btn-secondary`}
                  role="button"
                  href={CONTACTS.externalHomeURL}
                >
                  <Content
                    id="GLOBAL:Back-to-home-page"
                    copytext="Back to home page"
                  />
                </LinkTag>
              )}
            </div>
          </React.Fragment>
        ) : (
          ''
        )}

        {this.state.retry ? (
          <React.Fragment>
            <SelectDestination setDestination={setDestination} />
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

ExpiredCode.propTypes = {
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
  setDestination: PropTypes.any,
  selectedDestination: PropTypes.any,
  reSet: PropTypes.any,
  status: PropTypes.any,
  failed: PropTypes.any,
  challenge: PropTypes.any,
  history: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExpiredCode));
