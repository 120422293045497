import { Connection } from '@myie/interact';
import {
  SYSTEM_DOWNTIME_MESSAGE_REQUEST,
  SYSTEM_DOWNTIME_MESSAGE_FAILURE,
  GET_SYSTEM_DOWNTIME_MESSAGE_SUCCESS,
  RESET_SYSTEM_DOWNTIME_NEXT_STEP,
} from './systemDowntimeTypes';

export const commonSystemMsgRequest = () => ({
  type: SYSTEM_DOWNTIME_MESSAGE_REQUEST,
  isFetching: true,
  error: null,
});

export const commonSystemMsgFailure = error => ({
  type: SYSTEM_DOWNTIME_MESSAGE_FAILURE,
  isFetching: false,
  error: error.message,
});

export const getSystemMessagesSuccess = (json, nextStep) => ({
  type: GET_SYSTEM_DOWNTIME_MESSAGE_SUCCESS,
  systemMessages: json,
  nextStep,
  isFetching: false,
  error: null,
});

export const resetNextStepFormSubmit = () => ({
  type: RESET_SYSTEM_DOWNTIME_NEXT_STEP,
  nextStep: null,
  isFetching: false,
  error: null,
});

export const isSystemReadOnly = (nextStep = null) => dispatch => {
  dispatch(commonSystemMsgRequest());
  return fetch(
    Connection.baseUrl('custombrand') + `v1.0/checkSystemDowntime/`,
    {
      method: 'post',
      headers: Connection.headers(),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getSystemMessagesSuccess(json, nextStep)))
    .catch(error => dispatch(commonSystemMsgFailure(error)));
};
