import React from 'react';
import { Settings } from '@myie/interact';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = { referer: state && state.referer ? state.referer : null };
  }

  signinRedirect = () => {
    localStorage.setItem('redirectUri', this.state.referer);
    Settings.userManager.signinRedirect({});
  };

  render() {
    this.signinRedirect();
    return (
      <p aria-live="polite">
        <Content cmsTag="GLOBAL:signing-in" copytext="Signing in..." />
      </p>
    );
  }
}

SignIn.propTypes = {
  location: PropTypes.any,
};

export default SignIn;
