import React, { Component } from 'react';
import { contentContainer } from '@myie/interact-dom';
import { Acquire } from '@myie/interact-acquire-dom';
import { withRouter } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Acquire />
      </div>
    );
  }
}

export default withRouter(contentContainer(App));
