import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import { Content, FormErrorList, RULES, Markdown } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Validate } from '@myie/interact';
import {
  form as formObject,
  lookupForm as lookupFormObject,
  lookupFormPrevious1 as lookupFormPrevious1Object,
  lookupFormPrevious2 as lookupFormPrevious2Object,
  PreviousAddressForm1,
  PreviousAddressForm2,
} from './address-components/addressForm';
import applyPagesUtility from './utility';
import AddressContainer from './address-components/AddressContainer';
import ContactFooter from './page-components/ContactFooter';
import { CONTACTS } from '@myie/interact-local-dom';

class AddressDetails extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  componentDidUpdate() {
    const { formData } = this.props;
    const { data, data1, data2, setFormData } = this.state;

    if (
      formData &&
      !setFormData &&
      formData['addressDetails'] &&
      !_.isEmpty(formData['addressDetails']) &&
      (JSON.stringify(data) !==
        JSON.stringify(formData['addressDetails'].form) ||
        JSON.stringify(data1) !==
          JSON.stringify(formData['addressDetails'].form1) ||
        JSON.stringify(data2) !==
          JSON.stringify(formData['addressDetails'].form2))
    ) {
      const initState = this.initialState(this.props);
      this.setState({ ...initState, setFormData: true });
      window.scrollTo(0, 0);
    }
  }

  removeFlatNumberValidation = form => {
    let newForm = form;
    let currentFlatNumber = {
      rules: {
        title: 'currentFlatNumber',
        stop: true,
        format: {
          regex: RULES.flatNumber,
          message: 'Please enter a valid flat number.',
        },
      },
      value: form.currentFlatNumber.value,
    };
    newForm.currentFlatNumber = currentFlatNumber;
    return newForm;
  };

  addFlatNumberValidation = form => {
    let newForm = form;
    newForm.currentFlatNumber.rules = {
      title: 'currentFlatNumber',
      stop: true,
      required: {
        message: 'Please enter a flat number, house number or house name.',
      },
      format: {
        regex: RULES.flatNumber,
        message: 'Please enter a valid flat number.',
      },
    };
    return newForm;
  };

  initialState = props => {
    const { formData, isResumedApplication } = props;
    let data = null;
    let data1 = null;
    let data2 = null;
    let lookupData = null;

    let lookupDataPrevious1 = null;
    let lookupDataPrevious2 = null;
    let isFirstPrevAddressNeeded = false;
    let isSecondPrevAddressNeeded = false;

    if ('addressDetails' in formData) {
      if (!_.isEmpty(formData['addressDetails'].lookupForm)) {
        lookupData = formData['addressDetails'].lookupForm;
      }
      if (!_.isEmpty(formData['addressDetails'].lookupFormPrevious1)) {
        lookupDataPrevious1 = formData['addressDetails'].lookupFormPrevious1;
      }
      if (!_.isEmpty(formData['addressDetails'].lookupFormPrevious2)) {
        lookupDataPrevious2 = formData['addressDetails'].lookupFormPrevious2;
      }
      if (!_.isEmpty(formData['addressDetails'].form)) {
        data = formData['addressDetails'].form;
      }
      if (!_.isEmpty(formData['addressDetails'].form1)) {
        data1 = formData['addressDetails'].form1;
      }
      if (!_.isEmpty(formData['addressDetails'].form2)) {
        data2 = formData['addressDetails'].form2;
      }
      if (formData['addressDetails'].needFirstPrevAddress) {
        isFirstPrevAddressNeeded = true;
      }
      if (formData['addressDetails'].needSecondPrevAddress) {
        isSecondPrevAddressNeeded = true;
      }
    }
    let form = formObject;
    let lookupForm = _.cloneDeep(lookupFormObject);
    let lookupFormPrevious1 = _.cloneDeep(lookupFormPrevious1Object);
    let lookupFormPrevious2 = _.cloneDeep(lookupFormPrevious2Object);
    if (data) {
      if (data.currentFlatNumber) {
        form.currentFlatNumber.value = data.currentFlatNumber;
      }
      if (data.currentHouseName) {
        form.currentHouseName.value = data.currentHouseName;
      }
      if (data.currentHouseNumber) {
        form.currentHouseNumber.value = data.currentHouseNumber;
      }
      if (data.currentStreet) {
        form.currentStreet.value = data.currentStreet;
      }
      if (data.currentVillage) {
        form.currentVillage.value = data.currentVillage;
      }
      if (data.currentTown) {
        form.currentTown.value = data.currentTown;
      }
      if (data.currentCounty) {
        form.currentCounty.value = data.currentCounty;
      }
      if (data.currentPostcode) {
        form.currentPostcode.value = data.currentPostcode;
      }

      // if (data.currentHomeowner) {
      //   form.currentHomeowner.value = data.currentHomeowner;
      // }
      if (data.currentAddressLivingSince) {
        form.currentAddressLivingSince.value = data.currentAddressLivingSince;
      }
    }

    if (lookupData) {
      lookupForm.addressInput.value = lookupData.addressInput;
      lookupForm.currentAddressLivingSince.value =
        lookupData.currentAddressLivingSince;
    }

    if (lookupDataPrevious1) {
      lookupFormPrevious1.addressInput.value = lookupDataPrevious1.addressInput;
      lookupFormPrevious1.previousAddressLivingSinceform1.value =
        lookupDataPrevious1.previousAddressLivingSinceform1;
    }

    if (lookupDataPrevious2) {
      lookupFormPrevious2.addressInput.value = lookupDataPrevious2.addressInput;
      lookupFormPrevious2.previousAddressLivingSinceform2.value =
        lookupDataPrevious2.previousAddressLivingSinceform2;
    }

    const form1 = this.generateInitialform(data1, 'form1');
    const form2 = this.generateInitialform(data2, 'form2');

    return {
      setFormData: false,
      data: data ? data : {},
      data1: data1 ? data1 : {},
      data2: data2 ? data2 : {},
      lookupData: lookupData ? lookupData : {},
      lookupDataPrevious1: lookupDataPrevious1 ? lookupDataPrevious1 : {},
      lookupDataPrevious2: lookupDataPrevious2 ? lookupDataPrevious2 : {},
      form,
      form1,
      form2,
      lookupForm,
      lookupFormPrevious1,
      lookupFormPrevious2,
      showManualAddress: isResumedApplication
        ? formData?.Address?.Address?.ManualAddress
        : formData['addressDetails'] && !formData['addressDetails'].lookupForm
        ? true
        : false,
      showManualAddressPrevious1: isResumedApplication
        ? formData?.PreviousAddresses &&
          formData.PreviousAddresses[0].Address.ManualAddress
        : formData['addressDetails'] &&
          !formData['addressDetails'].lookupFormPrevious1 &&
          !_.isEmpty(formData['addressDetails'].form1)
        ? true
        : false,
      showManualAddressPrevious2: isResumedApplication
        ? formData?.PreviousAddresses &&
          formData?.PreviousAddresses[1].Address?.ManualAddress
        : formData['addressDetails'] &&
          !formData['addressDetails'].lookupFormPrevious2 &&
          !_.isEmpty(formData['addressDetails'].form2)
        ? true
        : false,
      needFirstPrevAddress: isFirstPrevAddressNeeded,
      needSecondPrevAddress: isSecondPrevAddressNeeded,
    };
  };

  generateInitialform = (data, formName) => {
    let form = null;
    if (formName === 'form1') {
      form = PreviousAddressForm1;
    }
    if (formName === 'form2') {
      form = PreviousAddressForm2;
    }

    if (data) {
      if (data[`previousUKAddress${formName}`]) {
        form[`previousUKAddress${formName}`].value =
          data[`previousUKAddress${formName}`];
      }
      if (data[`previousUKAddress${formName}`] === 'yes') {
        form[`previousPostcode${formName}`] = {};
        form[`previousPostcode${formName}`].rules = {
          title: `previousPostcode${formName}`,
          stop: true,
          required: {
            message: 'Please enter a postcode.',
          },
          format: {
            regex: RULES.postcode,
            message: 'Please enter a valid postcode.',
          },
        };
        if (data[`previousPostcode${formName}`]) {
          form[`previousPostcode${formName}`].value =
            data[`previousPostcode${formName}`];
        }

        form[`previousCountry${formName}`] = {
          rules: {
            title: 'previousCountryform2',
            stop: true,
          },
          value: '',
        };
      } else {
        form[`previousPostcode${formName}`] = {
          rules: {
            title: 'previousCountryform1',
            stop: true,
          },
          value: '',
        };

        form[`previousCountry${formName}`] = {
          rules: {
            title: `previousCountry${formName}`,
            stop: true,
            required: {
              message: 'Please select a country.',
            },
          },
          value: data[`previousCountry${formName}`],
        };

        form[`previousPostZipcode${formName}`].rules = {
          title: `previousPostZipcode${formName}`,
          stop: true,
        };
        if (data[`previousPostZipcode${formName}`]) {
          form[`previousPostZipcode${formName}`].value =
            data[`previousPostZipcode${formName}`];
        }
      }
      if (data[`previousFlatNumber${formName}`]) {
        form[`previousFlatNumber${formName}`].value =
          data[`previousFlatNumber${formName}`];
      }
      if (data[`previousHouseName${formName}`]) {
        form[`previousHouseName${formName}`].value =
          data[`previousHouseName${formName}`];
      }
      if (data[`previousHouseNumber${formName}`]) {
        form[`previousHouseNumber${formName}`].value =
          data[`previousHouseNumber${formName}`];
      }
      if (data[`previousStreet${formName}`]) {
        form[`previousStreet${formName}`].value =
          data[`previousStreet${formName}`];
      }
      if (data[`previousVillage${formName}`]) {
        form[`previousVillage${formName}`].value =
          data[`previousVillage${formName}`];
      }
      if (data[`previousTown${formName}`]) {
        form[`previousTown${formName}`].value = data[`previousTown${formName}`];
      }
      if (data[`previousCounty${formName}`]) {
        form[`previousCounty${formName}`].value =
          data[`previousCounty${formName}`];
      }
      if (data[`previousPostcode${formName}`]) {
        form[`previousPostcode${formName}`].value =
          data[`previousPostcode${formName}`];
      }

      // if (data[`previousHomeowner${formName}`]) {
      //   form[`previousHomeowner${formName}`].value =
      //     data[`previousHomeowner${formName}`];
      // }
      if (data[`previousAddressLivingSince${formName}`]) {
        form[`previousAddressLivingSince${formName}`].value =
          data[`previousAddressLivingSince${formName}`];
      }
    } else {
      return null;
    }

    return form;
  };

  onSelectAddress = (
    name = 'addressInput',
    value = '',
    addressIndex = 'current',
  ) => {
    let {
      lookupForm,
      lookupFormPrevious1,
      lookupFormPrevious2,
      lookupData,
      lookupDataPrevious1,
      lookupDataPrevious2,
    } = this.state;
    if (addressIndex === 'current') {
      lookupForm = Validate.input(name, value, lookupForm, true);
      lookupData = { ...lookupData, addressInput: value };
      this.setState({ ...this.state, lookupForm, lookupData });
    }
    if (addressIndex === 'form1') {
      lookupFormPrevious1 = Validate.input(
        name,
        value,
        lookupFormPrevious1,
        true,
      );
      lookupDataPrevious1 = { ...lookupDataPrevious1, addressInput: value };
      this.setState({
        ...this.state,
        lookupFormPrevious1,
        lookupDataPrevious1,
      });
    }
    if (addressIndex === 'form2') {
      lookupFormPrevious2 = Validate.input(
        name,
        value,
        lookupFormPrevious2,
        true,
      );
      lookupDataPrevious2 = { ...lookupDataPrevious2, addressInput: value };
      this.setState({
        ...this.state,
        lookupFormPrevious2,
        lookupDataPrevious2,
      });
    }
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  onChangeDate = e => {
    const { name, value } = e.target;
    let {
      form,
      form1,
      form2,
      data,
      data1,
      data2,
      lookupForm,
      lookupData,
      showManualAddress,
      lookupDataPrevious1,
      lookupDataPrevious2,
      lookupFormPrevious1,
      lookupFormPrevious2,
    } = this.state;
    if (showManualAddress) {
      form = Validate.input(name, value, form, true);
      data[name] = value;
    } else {
      lookupForm = Validate.input(name, value, lookupForm, true);
      lookupData[name] = value;
    }

    let isFirstPrevAddressNeeded = false;
    let isSecondPrevAddressNeeded = this.state.needSecondPrevAddress;
    let year = moment(value).year();
    if (year > 1000) {
      let diff = applyPagesUtility.getTimeDiff(value);
      if (diff < 1095) {
        if (!form1) {
          form1 = { ...PreviousAddressForm1 };
        }
        if (!lookupFormPrevious1) {
          lookupFormPrevious1 = { ...lookupFormPrevious1Object };
        }

        isFirstPrevAddressNeeded = true;
      }
      if (diff >= 1095) {
        form1 = null;
        form2 = null;
        data1 = {};
        data2 = {};
        lookupFormPrevious1 = null;
        lookupFormPrevious2 = null;
        lookupDataPrevious1 = {};
        lookupDataPrevious2 = {};

        isFirstPrevAddressNeeded = false;
        isSecondPrevAddressNeeded = false;
      }
    } else {
      form1 = null;
      form2 = null;
      data1 = {};
      data2 = {};
      lookupFormPrevious1 = null;
      lookupFormPrevious2 = null;
      lookupDataPrevious1 = {};
      lookupDataPrevious2 = {};

      isFirstPrevAddressNeeded = false;
      isSecondPrevAddressNeeded = false;
    }
    this.setState({
      ...this.state,
      form,
      form1,
      form2,
      data,
      data1,
      data2,
      lookupForm,
      lookupData,
      lookupFormPrevious1,
      lookupFormPrevious2,
      lookupDataPrevious1,
      lookupDataPrevious2,
      needFirstPrevAddress: isFirstPrevAddressNeeded,
      needSecondPrevAddress: isSecondPrevAddressNeeded,
    });
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data, showManualAddress, lookupForm } = this.state;
    if (showManualAddress) {
      form = Validate.input(name, value, form, true);
    } else {
      lookupForm = Validate.input(name, value, lookupForm, true);
    }
    data[name] = value;
    this.setState({ ...this.state, form, lookupForm });
  };

  // onSelectAddress = (name = 'addressInput', value = '') => {
  //   let { lookupForm, lookupData } = this.state;
  //   lookupForm = Validate.input(name, value, lookupForm, true);
  //   lookupData = { addressInput: value };
  //   this.setState({ ...this.state, lookupForm, lookupData });
  // };

  onChangeFlatNumber = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    if (
      !form.currentHouseName.value &&
      !form.currentHouseNumber.value &&
      !value
    ) {
      form = this.addFlatNumberValidation(form);
    } else {
      form = this.removeFlatNumberValidation(form);
    }

    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form });
  };

  onChangeHouseDetails = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    if (value) {
      form = this.removeFlatNumberValidation(form);
    }
    form = Validate.input(name, value, form, true);
    if (!form.currentHouseName.value && !form.currentHouseNumber.value) {
      form = this.addFlatNumberValidation(form);
    }
    form = Validate.input(
      'currentFlatNumber',
      form.currentFlatNumber.value,
      form,
      true,
    );
    data[name] = value;
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const {
      onSubmit,
      setStage,
      formData,
      updateUrl,
      isResumedApplication,
    } = this.props;
    let {
      form,
      lookupForm,
      lookupData,
      data,
      data1,
      data2,
      form1,
      form2,
      showManualAddressPrevious1,
      showManualAddressPrevious2,
      lookupFormPrevious1,
      lookupFormPrevious2,
      showManualAddress,
      lookupDataPrevious1,
      lookupDataPrevious2,
      needFirstPrevAddress,
      needSecondPrevAddress,
    } = this.state;
    let updatedFormData = _.cloneDeep(formData);
    if (showManualAddress) {
      form = Validate.form(form);
    } else if (lookupForm) {
      lookupForm = Validate.form(lookupForm);
    }

    if (needFirstPrevAddress) {
      if (showManualAddressPrevious1 && form1) {
        form1 = Validate.form(form1);
      } else {
        lookupFormPrevious1 = Validate.form(lookupFormPrevious1);
      }
    }

    if (needSecondPrevAddress) {
      if (showManualAddressPrevious2 && form2) {
        form2 = Validate.form(form2);
      } else {
        lookupFormPrevious2 = Validate.form(lookupFormPrevious2);
      }
    }

    this.setState({
      ...this.state,
      form,
      form1,
      form2,
      lookupForm,
      lookupFormPrevious2,
      lookupFormPrevious1,
    });
    if (!form.approved && showManualAddress) {
      window.scrollTo(0, 0);
      return;
    } else if (lookupForm && !lookupForm.approved && !showManualAddress) {
      window.scrollTo(0, 0);
      return;
    }
    if (needFirstPrevAddress) {
      if (form1 && !form1.approved && showManualAddressPrevious1) {
        window.scrollTo(0, 0);
        return;
      } else if (
        lookupFormPrevious1 &&
        !lookupFormPrevious1.approved &&
        !showManualAddressPrevious1
      ) {
        window.scrollTo(0, 0);
        return;
      }
    }
    if (needSecondPrevAddress) {
      if (form2 && !form2.approved && showManualAddressPrevious2) {
        window.scrollTo(0, 0);
        return;
      } else if (
        lookupFormPrevious2 &&
        !lookupFormPrevious2.approved &&
        !showManualAddressPrevious2
      ) {
        window.scrollTo(0, 0);
        return;
      }
    }
    if (isResumedApplication) {
      if (updatedFormData?.Address?.Address) {
        updatedFormData.Address.Address.ManualAddress = showManualAddress;
      }

      if (updatedFormData?.PreviousAddresses[0]?.Address) {
        updatedFormData.PreviousAddresses[0].Address.ManualAddress = showManualAddressPrevious1;
      }

      if (updatedFormData?.PreviousAddresses[1]?.Address) {
        updatedFormData.PreviousAddresses[1].Address.ManualAddress = showManualAddressPrevious2;
      }
    }
    let stateData = {
      formData: {
        ...updatedFormData,
        addressDetails: {
          form: data,
          form1: data1,
          form2: data2,
          ...(!showManualAddress && { lookupForm: lookupData }),
          ...(!showManualAddressPrevious1 && {
            lookupFormPrevious1: lookupDataPrevious1,
          }),
          ...(!showManualAddressPrevious2 && {
            lookupFormPrevious2: lookupDataPrevious2,
          }),
          needFirstPrevAddress,
          needSecondPrevAddress,
        },
      },
      url: {
        pageStage: 'yourFinances',
      },
    };
    window.scrollTo(0, 0);
    updateUrl(stateData);
    setStage('yourFinances');
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }
  };

  setStage = () => {
    const { setStage } = this.props;
    setStage('personalDetails');
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, amount } = this.state;
    const { data, setStateDynamic } = this.props;

    if (checked) {
      form = {
        closeAccount: form.closeAccount,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
        };
      }
    }
    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  updateState = formObject => {
    this.setState({ ...this.state, ...formObject, setFormData: true });
  };

  saveForLater = () => {
    const { setStage, updateUrl, formData, isResumedApplication } = this.props;
    let {
      form,
      lookupForm,
      lookupData,
      data,
      data1,
      data2,
      form1,
      form2,
      showManualAddressPrevious1,
      showManualAddressPrevious2,
      lookupFormPrevious1,
      lookupFormPrevious2,
      showManualAddress,
      lookupDataPrevious1,
      lookupDataPrevious2,
      needFirstPrevAddress,
      needSecondPrevAddress,
    } = this.state;
    let updatedFormData = _.cloneDeep(formData);
    if (showManualAddress) {
      form = Validate.form(form);
    } else if (lookupForm) {
      lookupForm = Validate.form(lookupForm);
    }

    if (needFirstPrevAddress) {
      if (showManualAddressPrevious1 && form1) {
        form1 = Validate.form(form1);
      } else {
        lookupFormPrevious1 = Validate.form(lookupFormPrevious1);
      }
    }

    if (needSecondPrevAddress) {
      if (showManualAddressPrevious2 && form2) {
        form2 = Validate.form(form2);
      } else {
        lookupFormPrevious2 = Validate.form(lookupFormPrevious2);
      }
    }

    this.setState({
      ...this.state,
      form,
      form1,
      form2,
      lookupForm,
      lookupFormPrevious2,
      lookupFormPrevious1,
    });

    if (!form.approved && showManualAddress) {
      window.scrollTo(0, 0);
      return;
    } else if (lookupForm && !lookupForm.approved && !showManualAddress) {
      window.scrollTo(0, 0);
      return;
    }

    if (needFirstPrevAddress) {
      if (form1 && !form1.approved && showManualAddressPrevious1) {
        window.scrollTo(0, 0);
        return;
      } else if (
        lookupFormPrevious1 &&
        !lookupFormPrevious1.approved &&
        !showManualAddressPrevious1
      ) {
        window.scrollTo(0, 0);
        return;
      }
    }
    if (needSecondPrevAddress) {
      if (form2 && !form2.approved && showManualAddressPrevious2) {
        window.scrollTo(0, 0);
        return;
      } else if (
        lookupFormPrevious2 &&
        !lookupFormPrevious2.approved &&
        !showManualAddressPrevious2
      ) {
        window.scrollTo(0, 0);
        return;
      }
    }
    if (isResumedApplication) {
      if (updatedFormData?.Address?.Address) {
        updatedFormData.Address.Address.ManualAddress = showManualAddress;
      }

      if (updatedFormData?.PreviousAddresses[0]?.Address) {
        updatedFormData.PreviousAddresses[0].Address.ManualAddress = showManualAddressPrevious1;
      }

      if (updatedFormData?.PreviousAddresses[1]?.Address) {
        updatedFormData.PreviousAddresses[1].Address.ManualAddress = showManualAddressPrevious2;
      }
    }
    let stateData = {
      formData: {
        ...updatedFormData,
        addressDetails: {
          form: data,
          form1: data1,
          form2: data2,
          ...(!showManualAddress && { lookupForm: lookupData }),
          ...(!showManualAddressPrevious1 && {
            lookupFormPrevious1: lookupDataPrevious1,
          }),
          ...(!showManualAddressPrevious2 && {
            lookupFormPrevious2: lookupDataPrevious2,
          }),
          needFirstPrevAddress,
          needSecondPrevAddress,
        },
        stage: 'addressDetails',
      },
      url: {
        pageStage: 'saveForLater',
      },
    };

    updateUrl(stateData);
    setStage('saveForLater');
  };

  getErrorCount = validations => {
    if (!validations) {
      return 0;
    }
    let keys = Object.keys(validations);
    let errors = [];
    let items = 0;

    keys.forEach(key => {
      if (typeof validations[key] !== 'boolean') {
        errors = Validate.errors(validations[key]);
      }
      if (key !== 'approved' && errors.length > 0) {
        items = items + 1;
      }
    });
    return items;
  };

  activatePreviousAddressForm = (date, form) => {
    let { data } = this.state;

    if (data && date) {
      let year = moment(date).year();
      if (year > 1000) {
        let diff = applyPagesUtility.getTimeDiff(date);
        if (!form && diff < 1095) {
          form = { ...form };
        }
      }
    }
    return form;
  };

  resetUnSelectedAddressInput = defaultState => {
    if (
      defaultState.lookupForm?.addressInput &&
      !_.isObject(defaultState.lookupForm.addressInput.value)
    ) {
      defaultState.lookupForm.addressInput.value = '';
    }
    if (
      defaultState.lookupData?.addressInput &&
      !_.isObject(defaultState.lookupData.addressInput)
    ) {
      defaultState.lookupData = {};
    }

    if (
      defaultState.lookupFormPrevious1?.addressInput &&
      !_.isObject(defaultState.lookupFormPrevious1.addressInput.value)
    ) {
      defaultState.lookupFormPrevious1.addressInput.value = '';
    }
    if (
      defaultState.lookupDataPrevious1?.addressInput &&
      !_.isObject(defaultState.lookupDataPrevious1.addressInput)
    ) {
      defaultState.lookupDataPrevious1 = {};
    }

    if (
      defaultState.lookupFormPrevious2?.addressInput &&
      !_.isObject(defaultState.lookupFormPrevious2.addressInput.value)
    ) {
      defaultState.lookupFormPrevious2.addressInput.value = '';
    }
    if (
      defaultState.lookupDataPrevious2?.addressInput &&
      !_.isObject(defaultState.lookupDataPrevious2.addressInput)
    ) {
      defaultState.lookupDataPrevious2.addressInput = {};
    }
  };

  resetPrevForm = (formIndex, defaultState) => {
    if (formIndex === 'form1' && !defaultState.form1) {
      defaultState.form1 = { ...PreviousAddressForm1 };
    } else if (formIndex === 'form2' && !defaultState.form2) {
      defaultState.form2 = { ...PreviousAddressForm2 };
    }

    let prevAddressFlag = false;
    let secondPrevAddressFlag = false;
    if (formIndex === 'current') {
      if (defaultState.showManualAddress === true) {
        if (defaultState.lookupForm.currentAddressLivingSince.value !== '') {
          prevAddressFlag = this.isPreviousAddressRequired(
            defaultState.lookupForm.currentAddressLivingSince.value,
          );
          defaultState.needFirstPrevAddress = prevAddressFlag;
          if (prevAddressFlag === true) {
            if (defaultState.showManualAddressPrevious1) {
              secondPrevAddressFlag = this.isPreviousAddressRequired(
                defaultState.form1.previousAddressLivingSinceform1.value,
              );
            } else {
              secondPrevAddressFlag = this.isPreviousAddressRequired(
                defaultState.lookupFormPrevious1.previousAddressLivingSinceform1
                  .value,
              );
            }

            defaultState.needSecondPrevAddress = secondPrevAddressFlag;
          } else {
            defaultState.needSecondPrevAddress = false;
          }
        } else {
          defaultState.needFirstPrevAddress = false;
          defaultState.needSecondPrevAddress = false;
        }
      } else {
        if (defaultState.form.currentAddressLivingSince.value !== '') {
          prevAddressFlag = this.isPreviousAddressRequired(
            defaultState.form.currentAddressLivingSince.value,
          );
          defaultState.needFirstPrevAddress = prevAddressFlag;
          if (prevAddressFlag === true) {
            if (defaultState.showManualAddressPrevious1) {
              secondPrevAddressFlag = this.isPreviousAddressRequired(
                defaultState.form1.previousAddressLivingSinceform1.value,
              );
            } else {
              secondPrevAddressFlag = this.isPreviousAddressRequired(
                defaultState.lookupFormPrevious1.previousAddressLivingSinceform1
                  .value,
              );
            }

            defaultState.needSecondPrevAddress = secondPrevAddressFlag;
          } else {
            defaultState.needSecondPrevAddress = false;
          }
        } else {
          defaultState.needFirstPrevAddress = false;
          defaultState.needSecondPrevAddress = false;
        }
      }
    } else if (formIndex === 'form1') {
      if (defaultState.showManualAddressPrevious1 === true) {
        if (
          defaultState.lookupFormPrevious1.previousAddressLivingSinceform1
            .value !== ''
        ) {
          prevAddressFlag = this.isPreviousAddressRequired(
            defaultState.lookupFormPrevious1.previousAddressLivingSinceform1
              .value,
          );
          defaultState.needSecondPrevAddress = prevAddressFlag;
        } else {
          defaultState.needSecondPrevAddress = false;
        }
      } else {
        if (defaultState.form1.previousAddressLivingSinceform1.value !== '') {
          prevAddressFlag = this.isPreviousAddressRequired(
            defaultState.form1.previousAddressLivingSinceform1.value,
          );
          defaultState.needSecondPrevAddress = prevAddressFlag;
        } else {
          defaultState.needSecondPrevAddress = false;
        }
      }
    }
  };
  isPreviousAddressRequired = value => {
    let output = false;
    let year = moment(value).year();
    if (year > 1000) {
      let diff = applyPagesUtility.getTimeDiff(value);
      if (diff < 1095) {
        output = true;
      }
      if (diff >= 1095) {
        output = false;
      }
    }
    return output;
  };
  toggleManualAddress = formIndex => {
    let defaultState = this.state;
    this.resetUnSelectedAddressInput(defaultState);
    this.resetPrevForm(formIndex, defaultState);
    switch (formIndex) {
      case 'current':
        this.setState(
          prevState => {
            defaultState.showManualAddress = !prevState.showManualAddress;
            return {
              ...defaultState,
            };
          },
          () => {
            //window.scrollTo(0, 0);
          },
        );
        break;
      case 'form1': {
        this.setState(
          prevState => {
            defaultState.showManualAddressPrevious1 = !prevState.showManualAddressPrevious1;
            return {
              ...defaultState,
            };
          },
          () => {
            //window.scrollTo(0, 0);
          },
        );
        break;
      }

      case 'form2':
        this.setState(
          prevState => {
            defaultState.showManualAddressPrevious2 = !prevState.showManualAddressPrevious2;
            return {
              ...defaultState,
            };
          },
          () => {
            // window.scrollTo(0, 0);
          },
        );
        break;
      default:
    }
    // let defaultState = this.initialState(this.props);
    // const { data } = this.state;
    // const form = this.activatePreviousAddressForm(
    //   data.currentAddressLivingSince,
    //   form,
    // );
  };

  render() {
    const {
      form,
      form1,
      form2,
      lookupForm,
      showManualAddressPrevious1,
      lookupFormPrevious1,
      lookupFormPrevious2,
      showManualAddressPrevious2,
      showManualAddress,
      needFirstPrevAddress,
      needSecondPrevAddress,
    } = this.state;
    let form1Count = 0;
    let form2Count = 0;
    let form3Count = 0;
    let lookupFormCount = 0;
    let lookupFormPrevious1FormCount = 0;
    let lookupFormPrevious2FormCount = 0;

    form1Count = showManualAddress ? this.getErrorCount(form) : 0;
    form2Count =
      form1 && showManualAddressPrevious1 ? this.getErrorCount(form1) : 0;
    form3Count =
      form2 && showManualAddressPrevious2 ? this.getErrorCount(form2) : 0;
    lookupFormCount =
      lookupForm && !showManualAddress ? this.getErrorCount(lookupForm) : 0;
    lookupFormPrevious1FormCount =
      lookupFormPrevious1 && !showManualAddressPrevious1
        ? this.getErrorCount(lookupFormPrevious1)
        : 0;
    lookupFormPrevious2FormCount =
      lookupFormPrevious2 && !showManualAddressPrevious2
        ? this.getErrorCount(lookupFormPrevious2)
        : 0;
    let totalErrors =
      form1Count +
      form2Count +
      form3Count +
      lookupFormCount +
      lookupFormPrevious1FormCount +
      lookupFormPrevious2FormCount;

    return (
      <Form autoComplete="off" id="apply-form" onSubmit={this.onSubmitHandler}>
        <Content
          tag="h2"
          tagClassName="breadcrumb-title step-2"
          cmsTag="BREADCRUMB:Address-details"
          copytext="Address details"
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Address-details:p1"
          copytext="Please enter your address details. We support BFPO (British Forces Post Office) too, so if you are a member of the British Armed Forces, please use your BFPO postcode. If your previous address is not a UK address, you will need to enter your address details manually."
        />
        <Markdown
          cmsTag="Long-apply:Apply-pages:Address-details:p2"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nIf you need any assistance, please contact our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>.`}
        />
        {totalErrors > 0 ? (
          <div
            className="multiple-form-error-list-container"
            aria-atomic="true"
            role="alert"
          >
            <h2>
              {totalErrors > 1 ? (
                <React.Fragment>
                  <Markdown
                    cmsTag="Shared:Components:Form-errors:Form-error-list:h-part-1"
                    markdown="There are "
                  />
                  {totalErrors}
                  <Markdown
                    cmsTag="Shared:Components:Form-errors:Form-error-list:h-part-2"
                    markdown=" errors in this form:"
                  />
                </React.Fragment>
              ) : (
                ''
              )}
              {totalErrors === 1 ? (
                <Markdown
                  cmsTag="Shared:Components:Form-errors:Form-error-list:h-singular"
                  markdown="There is 1 error in this form:"
                />
              ) : (
                ''
              )}
            </h2>

            {showManualAddress ? (
              <FormErrorList
                validations={form}
                disabled={false}
                required={true}
                groupClassName=""
                title="h3"
                showErrors={true}
                bespokeTitle="Current address"
                continainingElement="section"
                addressId="current"
              />
            ) : (
              ''
            )}

            {lookupForm && !showManualAddress ? (
              <React.Fragment>
                <FormErrorList
                  validations={lookupForm}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h3"
                  showErrors={true}
                  bespokeTitle="Current address"
                  continainingElement="section"
                  addressId="current"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {needFirstPrevAddress && form1 && showManualAddressPrevious1 ? (
              <React.Fragment>
                <FormErrorList
                  validations={form1}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h3"
                  showErrors={true}
                  bespokeTitle="First previous address"
                  continainingElement="section"
                  addressId="previous1"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {needFirstPrevAddress &&
            lookupFormPrevious1 &&
            !showManualAddressPrevious1 ? (
              <React.Fragment>
                <FormErrorList
                  validations={lookupFormPrevious1}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h3"
                  showErrors={true}
                  bespokeTitle="First previous address"
                  continainingElement="section"
                  addressId="previous1"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {needSecondPrevAddress && form2 && showManualAddressPrevious2 ? (
              <React.Fragment>
                <FormErrorList
                  validations={form2}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h3"
                  showErrors={true}
                  bespokeTitle="Second previous address"
                  continainingElement="section"
                  addressId="previous2"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {needSecondPrevAddress &&
            lookupFormPrevious2 &&
            !showManualAddressPrevious2 ? (
              <React.Fragment>
                <FormErrorList
                  validations={lookupFormPrevious2}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h3"
                  showErrors={true}
                  bespokeTitle="Second previous address"
                  continainingElement="section"
                  addressId="previous2"
                />
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        <AddressContainer
          onChangeFlatNumber={this.onChangeFlatNumber}
          onChangeHouseDetails={this.onChangeHouseDetails}
          onChangeDate={this.onChangeDate}
          updateState={this.updateState}
          formData={this.props.formData}
          addressTitle="Current"
          groupClassName={'address-details-container'}
          addressLabel="current"
          form1={form1}
          form2={form2}
          addressId="current"
          form={this.state.form}
          onChange={this.onChange}
          onBlur={this.onBlur}
          showManualAddress={this.state.showManualAddress}
          showManualAddressPrevious1={this.state.showManualAddressPrevious1}
          showManualAddressPrevious2={this.state.showManualAddressPrevious2}
          lookupForm={lookupForm}
          lookupFormPrevious1={lookupFormPrevious1}
          lookupFormPrevious2={lookupFormPrevious2}
          data1={this.state.data1}
          data2={this.state.data2}
          lookupDataPrevious1={this.state.lookupDataPrevious1}
          lookupDataPrevious2={this.state.lookupDataPrevious2}
          onSelectAddress={this.onSelectAddress}
          toggleManualAddress={this.toggleManualAddress}
          needFirstPrevAddress={this.state.needFirstPrevAddress}
          needSecondPrevAddress={this.state.needSecondPrevAddress}
          isResumedApplication={this.props.isResumedApplication}
        />
        <div className="form-button-group apply-form-buttons">
          <Button id="continue-to-next-step" type="submit" color="primary">
            <Content
              cmsTag="Long-apply:Apply-pages:Address-details:Continue-to-step-3"
              copytext="Continue to step 3"
            />
          </Button>
          <Button
            onClick={this.setStage}
            id="back-to-previous-step"
            color="secondary"
          >
            <Content
              cmsTag="Long-apply:Apply-pages:Address-details:Back-to-step-1"
              copytext="Back to step 1"
            />
          </Button>
          <Button
            id="save-for-later"
            type="button"
            color="light"
            onClick={this.saveForLater}
          >
            <Content cmsTag="GLOBAL:Save-for-later" copytext="Save for later" />
          </Button>
          <ContactFooter />
        </div>
      </Form>
    );
  }
}

AddressDetails.propTypes = {
  account: PropTypes.object,
  setStage: PropTypes.func,
  formData: PropTypes.object,
  updateUrl: PropTypes.func,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
  //addressId: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func,
  //addressLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isResumedApplication: PropTypes.bool,
};

export default AddressDetails;
