import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 21.117 32.197"
    viewBox="0 0 210 210"
  >
    <path
      className={fill}
      d="M188.996,17.813l-9.742,-0c-2.37,-0 -4.274,1.95 -4.218,4.319l0.825,34.655c-15.619,-23.49 -42.322,-38.974 -72.646,-38.974c-42.605,-0 -78.071,30.559 -85.677,70.953c-0.488,2.591 1.514,4.984 4.151,4.984l10.046,0c1.995,0 3.706,-1.4 4.126,-3.35c6.689,-31.058 34.278,-54.306 67.354,-54.306c27.965,-0 52.011,16.623 62.815,40.536l-44.433,-1.058c-2.369,-0.056 -4.32,1.848 -4.32,4.218l0,9.741c0,2.33 1.889,4.219 4.219,4.219l67.5,0c2.33,0 4.219,-1.889 4.219,-4.219l-0,-67.5c-0,-2.329 -1.889,-4.218 -4.219,-4.218Zm-4.255,98.437l-10.046,-0c-1.995,-0 -3.706,1.4 -4.127,3.35c-6.688,31.058 -34.277,54.306 -67.353,54.306c-27.965,0 -52.011,-16.623 -62.816,-40.536l44.434,1.058c2.369,0.056 4.319,-1.848 4.319,-4.218l0,-9.741c0,-2.33 -1.889,-4.219 -4.218,-4.219l-67.5,-0c-2.33,-0 -4.219,1.889 -4.219,4.219l0,67.5c0,2.329 1.889,4.218 4.219,4.218l9.742,0c2.369,0 4.274,-1.95 4.218,-4.319l-0.825,-34.655c15.618,23.49 42.322,38.974 72.646,38.974c42.604,0 78.07,-30.559 85.677,-70.953c0.488,-2.591 -1.515,-4.984 -4.151,-4.984Z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
