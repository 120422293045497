import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'black-fill', width = '30', height = '25' }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 113 113"
    width={width}
    height={height}
  >
    <path className={fill} d="M7.5,19.9h98.2v21.2H7.5V19.9z" />
    <path
      className={fill}
      d="M28.4,51.7h-7.1v7.1h7.1V51.7z M39,51.7h-7v7.1h7V51.7z"
    />
    <path
      className={fill}
      d="M49.7,51.7h-7.1v7.1h7.1V51.7z M60.3,51.7h-7.1v7.1h7.1V51.7z M70.9,51.7h-7.1v7.1h7.1V51.7L70.9,51.7z M81.5,51.7h-7.1v7.1h7.1V51.7z M92.1,51.7H85v7.1h7.1V51.7z M28.4,62.3h-7.1v7.1h7.1V62.3z M39,62.3h-7v7.1h7V62.3z M49.7,62.3 h-7.1v7.1h7.1V62.3z M60.3,62.3h-7.1v7.1h7.1V62.3z"
    />
    <path
      className={fill}
      d="M70.9,62.3h-7.1v7.1h7.1V62.3L70.9,62.3z M81.5,62.3h-7.1v7.1h7.1V62.3z"
    />
    <path
      className={fill}
      d="M92.1,62.3H85v7.1h7.1V62.3z M28.4,72.9h-7.1V80h7.1V72.9z M39,72.9h-7V80h7V72.9z"
    />
    <path className={fill} d="M49.7,72.9h-7.1V80h7.1V72.9z" />
    <path
      className={fill}
      d="M60.3,72.9h-7.1V80h7.1V72.9z M70.9,72.9h-7.1V80h7.1V72.9L70.9,72.9z M81.5,72.9h-7.1V80h7.1V72.9z M92.1,72.9 H85V80h7.1V72.9z M28.4,83.6h-7.1v7.1h7.1C28.4,90.6,28.4,83.6,28.4,83.6z M39,83.6h-7v7.1h7V83.6z M49.7,83.6h-7.1v7.1h7.1V83.6z M60.3,83.6h-7.1v7.1h7.1V83.6z M70.9,83.6h-7.1v7.1h7.1V83.6L70.9,83.6z M81.5,83.6h-7.1v7.1h7.1V83.6z"
    />
    <path className={fill} d="M92.1,83.6H85v7.1h7.1V83.6z" />
    <path
      className={fill}
      d="M105.7,103.3H7.5V41.2h4v58.1h90.2V41.2h4V103.3z"
    />
    <path d="M21.6,11.5h8.2V30h-8.2C21.6,30,21.6,11.5,21.6,11.5z" />
    <path
      className={fill}
      d="M31.8,32H19.6V9.5h12.2V32z M23.6,28h4.2V13.5h-4.2C23.6,13.5,23.6,28,23.6,28z"
    />
    <path d="M83.3,11.5h8.2V30h-8.2V11.5z" />
    <path
      className={fill}
      d="M93.6,32H81.3V9.5h12.2L93.6,32L93.6,32z M85.3,28h4.2V13.5h-4.2V28z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
