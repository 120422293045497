import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M21.8,9.4h-2.1l-1.3-3.1c-0.5-1.2-1.7-2-3-2H8.6c-1.3,0-2.5,0.8-3,2L4.3,9.4H2.2c-0.3,0-0.5,0.3-0.5,0.6L2,11 c0.1,0.2,0.3,0.4,0.5,0.4h0.5c-0.3,0.6-0.5,1.2-0.5,1.9v5.4c0,0.5,0.4,1,1,1H4c0.5,0,1-0.4,1-1v-1h14.1v1c0,0.5,0.4,1,1,1h0.6 c0.5,0,1-0.4,1-1v-5.4c0-0.7-0.2-1.4-0.5-1.9h0.5c0.2,0,0.4-0.2,0.5-0.4l0.2-1C22.3,9.7,22.1,9.4,21.8,9.4z"
    />
    <path
      className={fillNegative}
      d="M7.4,7C7.6,6.5,8,6.2,8.6,6.2h6.9c0.5,0,1,0.3,1.2,0.8l1,2.4H6.4C6.4,9.4,7.4,7,7.4,7z"
    />
    <path
      className={fillNegative}
      d="M19.7,15.2c0,0.4-0.3,0.6-0.6,0.6H5c-0.4,0-0.6-0.3-0.6-0.6v-1.9c0-1.1,0.9-1.9,1.9-1.9h11.5c1.1,0,1.9,0.9,1.9,1.9V15.2z"
    />
    <path
      className={fill2}
      d="M6.9,12.3c-0.8,0-1.3,0.5-1.3,1.3c0,0.8,0.5,1.3,1.3,1.3c0.8,0,1.9,0.1,1.9-0.6C8.8,13.5,7.6,12.3,6.9,12.3z"
    />
    <path
      className={fill2}
      d="M17.1,12.3c-0.8,0-1.9,1.1-1.9,1.9c0,0.8,1.2,0.6,1.9,0.6c0.8,0,1.3-0.5,1.3-1.3C18.4,12.8,17.9,12.3,17.1,12.3z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
