import { Connection } from '@myie/interact';

import {
  RESET_GET_DEPOSIT_PRODUCTS,
  GET_DEPOSIT_PRODUCTS_REQUEST,
  GET_DEPOSIT_PRODUCTS_SUCCESS,
  GET_DEPOSIT_PRODUCTS_FALIURE,
  RESET_SAVE_APPLY_FOR_LATER,
  GET_SAVE_APPLY_FOR_LATER_REQUEST,
  GET_SAVE_APPLY_FOR_LATER_SUCCESS,
  GET_SAVE_APPLY_FOR_LATER_FALIURE,
  RESET_RESUME_APPLICATION,
  RESUME_APPLICATION_REQUEST,
  RESUME_APPLICATION_SUCCESS,
  RESUME_APPLICATION_FALIURE,
  RESUME_APPLICATION_DATA,
  RESET_RESUME_APPLICATION_DATA,
  RESET_SAVE_LONG_APPLY,
  SAVE_LONG_APPLY_REQUEST,
  SAVE_DATA_FOR_SECURITY,
  SAVE_LONG_APPLY_SUCCESS,
  SAVE_LONG_APPLY_FALIURE,
  RESET_GET_DEPOSIT_PRODUCT,
  GET_DEPOSIT_PRODUCT_REQUEST,
  GET_DEPOSIT_PRODUCT_SUCCESS,
  GET_DEPOSIT_PRODUCT_FALIURE,
  SET_RESUME_APPLICATION_MEMORABLE_WORD,
  RESET_RESUME_APPLICATION_MEMORABLE_WORD,
  RESET_GET_APPLICATION_STATUS,
  GET_APPLICATION_STATUS_REQUEST,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FALIURE,
} from './depositProductsTypes';

export const resetLongapply = () => ({
  type: RESET_GET_DEPOSIT_PRODUCTS,
});

export const getDepositProductsRequest = () => ({
  type: GET_DEPOSIT_PRODUCTS_REQUEST,
  isFetching: true,
  error: null,
});

export const getDepositProductsSuccess = json => ({
  type: GET_DEPOSIT_PRODUCTS_SUCCESS,
  getDepositProductsResponse: json,
  isFetching: false,
  error: null,
});

export const getDepositProductsFaliure = error => ({
  type: GET_DEPOSIT_PRODUCTS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getDepositProducts = request => dispatch => {
  dispatch(getDepositProductsRequest());
  return fetch(
    //Connection.baseUrl('serverRoot') + '/customdar/' + 'v1.0/getallproducts',
    Connection.baseUrl('custombrand') + 'v1.0/getallproducts',
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getDepositProductsSuccess(json)))
    .catch(error => dispatch(getDepositProductsFaliure(error)));
};

export const resetSaveLongApplyForLater = () => ({
  type: RESET_SAVE_APPLY_FOR_LATER,
});

export const getSaveLongApplyForLaterRequest = () => ({
  type: GET_SAVE_APPLY_FOR_LATER_REQUEST,
  isFetching: true,
  error: null,
});

export const getSaveLongApplyForLaterSuccess = json => ({
  type: GET_SAVE_APPLY_FOR_LATER_SUCCESS,
  saveLongApplyForLaterResponse: json,
  isFetching: false,
  error: null,
});

export const getSaveLongApplyForLaterFaliure = error => ({
  type: GET_SAVE_APPLY_FOR_LATER_FALIURE,
  isFetching: false,
  error: error.message,
});

export const saveLongApplyForLater = request => dispatch => {
  dispatch(getSaveLongApplyForLaterRequest());
  return fetch(Connection.baseUrl('apply') + 'v1.0/save', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getSaveLongApplyForLaterSuccess(json)))
    .catch(error => dispatch(getSaveLongApplyForLaterFaliure(error)));
};

export const resetResumeApplication = () => ({
  type: RESET_RESUME_APPLICATION,
});

export const resumeApplicationRequest = () => ({
  type: RESUME_APPLICATION_REQUEST,
  isFetching: true,
  error: null,
});

export const resumeApplicationSuccess = json => ({
  type: RESUME_APPLICATION_SUCCESS,
  resumeResponse: json,
  isFetching: false,
  error: null,
});

export const resumeApplicationFaliure = error => ({
  type: RESUME_APPLICATION_FALIURE,
  isFetching: false,
  error: error.message,
});

export const resumeApplication = request => dispatch => {
  dispatch(resumeApplicationRequest());
  return fetch(Connection.baseUrl('apply') + 'v1.0/return', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(resumeApplicationSuccess(json)))
    .catch(error => dispatch(resumeApplicationFaliure(error)));
};

export const resumeApplicationData = json => ({
  type: RESUME_APPLICATION_DATA,
  resumeData: json,
  isFetching: false,
  error: null,
});

export const resetResumeApplicationData = () => ({
  type: RESET_RESUME_APPLICATION_DATA,
});

export const setResumeApplicationMemorableWord = data => ({
  type: SET_RESUME_APPLICATION_MEMORABLE_WORD,
  data,
});

export const resetResumeApplicationMemorableWord = () => ({
  type: RESET_RESUME_APPLICATION_MEMORABLE_WORD,
});

////
export const resetSaveLongApply = () => ({
  type: RESET_SAVE_LONG_APPLY,
});

export const saveLongApplyRequest = () => ({
  type: SAVE_LONG_APPLY_REQUEST,
  isFetching: true,
  error: null,
});

export const saveLongApplySuccess = json => ({
  type: SAVE_LONG_APPLY_SUCCESS,
  saveLongApplyResponse: json,
  isFetching: false,
  error: null,
});

export const saveLongApplyFaliure = error => ({
  type: SAVE_LONG_APPLY_FALIURE,
  isFetching: false,
  error: error.message,
});

export const saveLongApply = request => dispatch => {
  dispatch(resumeApplicationRequest());
  //return fetch(Connection.baseUrl('serverRoot') + '/apply/v1.0/savings', {
  return fetch(Connection.baseUrl('apply') + 'v1.0/savings', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(saveLongApplySuccess(json)))
    .catch(error => dispatch(saveLongApplyFaliure(error)));
};

export const preserveDataforSecurityDetails = json => ({
  type: SAVE_DATA_FOR_SECURITY,
  savedDataForSecurity: json,
});

export const resetGetDepositProduct = () => ({
  type: RESET_GET_DEPOSIT_PRODUCT,
});

export const getDepositProductRequest = () => ({
  type: GET_DEPOSIT_PRODUCT_REQUEST,
  isFetching: true,
  error: null,
});

export const getDepositProductSuccess = json => ({
  type: GET_DEPOSIT_PRODUCT_SUCCESS,
  getDepositProductResponse: json,
  isFetching: false,
  error: null,
});

export const getDepositProductFaliure = error => ({
  type: GET_DEPOSIT_PRODUCT_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getDepositProduct = request => dispatch => {
  dispatch(getDepositProductsRequest());
  return fetch(
    // Connection.baseUrl('serverRoot') + '/customdar/' + 'v1.0/getproduct',
    Connection.baseUrl('custombrand') + 'v1.0/getproduct',
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getDepositProductSuccess(json)))
    .catch(error => dispatch(getDepositProductFaliure(error)));
};

export const getApplicationStatusRequest = () => ({
  type: GET_APPLICATION_STATUS_REQUEST,
  isFetching: true,
  error: null,
});

export const getApplicationStatusFailure = error => ({
  type: GET_APPLICATION_STATUS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getApplicationStatusSuccess = json => ({
  type: GET_APPLICATION_STATUS_SUCCESS,
  ApplicationStatusResponse: json,
  isFetching: false,
  error: null,
});

export const resetGetApplicationStatus = () => ({
  type: RESET_GET_APPLICATION_STATUS,
  ApplicationStatusResponse: null,
  isFetching: false,
  error: null,
});

export const getApplicationStatus = request => dispatch => {
  dispatch(getApplicationStatusRequest());
  return fetch(Connection.baseUrl('apply') + '/v1.0/getonboardingdecision', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => {
      dispatch(getApplicationStatusSuccess(json));
    })
    .catch(error => dispatch(getApplicationStatusFailure(error)));
};
