/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { Routes as StyleguideRoutes } from '@myie/interact-styleguide-dom'
import { Routes as DocumentsRoutes } from '@myie/interact-documents-dom'
import { Routes as LongApplyRoutes } from '@myie/interact-long-apply-dom'


const AvailableRoutes = [];

AvailableRoutes.push(StyleguideRoutes)
AvailableRoutes.push(DocumentsRoutes)
AvailableRoutes.push(LongApplyRoutes)


export default AvailableRoutes;

