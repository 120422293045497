import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Content, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '../../local-shared/constants/contacts';

class SignInSidebarComponent extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <Card color="primary" className="signin-card">
        <CardHeader>
          <Content
            tag="h2"
            cmsTag="Shared:Components:SignInSidebarComponent:h2-1"
            copytext="Already registered?"
          />
        </CardHeader>
        <CardBody>
          <Markdown
            template={{
              markdown: {
                companySiteName: CONTACTS.companySiteName,
              },
            }}
            cmsTag="Shared:Components:SignInSidebarComponent:p1"
            markdown={`\nIf you have already have a $[companySiteName] account, click the "Sign in" button.`}
          />
        </CardBody>
        <CardFooter>
          <Link
            id="sign-in-button"
            className="btn btn-primary"
            role="button"
            to="/sign-in/step-1"
          >
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

SignInSidebarComponent.propTypes = {
  items: PropTypes.string,
};

export default SignInSidebarComponent;
