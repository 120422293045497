import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import {
  Content,
  Check,
  Markdown,
  RadioGroup,
  Radio,
  LinkTag,
  AppMeta,
  FormErrorList,
  SystemDowntimeBanner,
} from '@myie/interact-dom';
import { Button, Form, Alert } from 'reactstrap';
import { CONSTANTS } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import AccountDescription from './apply-pages/page-components/AccountDescription';

class Eligibility extends Component {
  constructor(props) {
    super(props);
    let validations = {
      existingMember: {
        rules: {
          required: {
            message: `Please tell us if you are an existing member of ${CONTACTS.companyName}.`,
          },
        },
      },
      residentsConfirm: {
        rules: {
          required: {
            message:
              'Please confirm that you are a resident in the UK and pay tax in the UK.',
          },
        },
        value: false,
      },
      confirmSummary: {
        rules: {
          required: {
            message: 'Please agree to the Terms and Conditions.',
          },
        },
        value: false,
      },
      privacyPolicyConfirm: {
        rules: {
          required: {
            message: 'Please read the Privacy Notice.',
          },
        },
        value: false,
      },
      fscsConfirm: {
        rules: {
          required: {
            message: 'Please read the FSCS information sheet.',
          },
        },
        value: false,
      },
    };
    const required = {
      message:
        'Please tell us if you want to transfer your ISA to this new account.',
    };

    this.state = {
      transferYourIsa: required,
      selectedProduct: false,
      validations: validations,
      form: null,
    };
  }

  static getDerivedStateFromProps = (nextProps, thisState) => {
    const { allDepositProducts, match, depositProduct } = nextProps;
    const { selectedProduct, validations } = thisState;
    let newForm = null;
    let product = null;
    let accountType = '';

    if (depositProduct && depositProduct.Product && !selectedProduct) {
      if (allDepositProducts && allDepositProducts.length) {
        allDepositProducts.forEach(element => {
          if (match.params && match.params.id === element.Product_Code) {
            product = element;
            accountType = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
              return product.Account_Type_Code === element;
            });
          }
        });

        if (accountType) {
          newForm = {
            existingMember: validations.existingMember,
            isThisTheOnlyIsa: {
              rules: {
                required: {
                  message:
                    'Please tell us if this is the only ISA you have opened or funded in the current tax year.',
                },
              },
              value: '',
            },
            transferYourIsa: {
              rules: {},
              value: '',
            },
            residentsConfirm: validations.residentsConfirm,
            confirmSummary: validations.confirmSummary,
            privacyPolicyConfirm: validations.privacyPolicyConfirm,
            fscsConfirm: validations.fscsConfirm,
          };
        } else {
          newForm = validations;
        }

        return {
          form: newForm,
          selectedProduct: product,
        };
      }
    }

    return null;
  };

  onChange = e => {
    const { name, checked, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked ? value : null, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    let { form, selectedProduct } = this.state;
    const { match, history } = this.props;
    const accountType = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
      return selectedProduct.Account_Type_Code === element;
    });
    let newform = Validate.form(form);
    if (!newform.approved) {
      window.scrollTo(0, 0);
      this.setState({ form: newform });
    } else if (match.params && match.params.id) {
      if (form.existingMember.value === 'no') {
        if (accountType) {
          if (
            !form.transferYourIsa.value ||
            (form.transferYourIsa.value && form.transferYourIsa.value === 'yes')
          ) {
            let url = `/long-apply/apply/${match.params.id}`;
            history.push(url);
          }
        } else {
          if (
            !form.existingMember.value ||
            (form.existingMember.value && form.existingMember.value === 'no')
          ) {
            let url = `/long-apply/apply/${match.params.id}`;
            history.push(url);
          }
        }
      }

      this.setState({ ...this.state, form: newform });
    }
  };

  componentDidMount() {
    const {
      getDepositProducts,
      getDepositProduct,
      match,
      resetResumeApplicationData,
      resetResumeApplicationMemorableWord,
      isSystemReadOnly,
    } = this.props;

    resetResumeApplicationData();
    resetResumeApplicationMemorableWord();
    getDepositProducts({
      checkLimitExceeded: true,
      isLongApply: true,
    });
    getDepositProduct({
      ProductId: match.params.id,
      AccountType: 2,
      OnlineAccountOnly: false,
      IncludeLimitExceeded: true,
    });
    isSystemReadOnly();
    window.scrollTo(0, 0);
  }

  onEvent = e => {
    const { name, value } = e.target;
    let { form, transferYourIsa } = this.state;
    form = Validate.input(name, value, form, true);
    if (name === 'isThisTheOnlyIsa' && value === 'no') {
      form.transferYourIsa.rules = {
        required: transferYourIsa,
      };
    }
    if (name === 'isThisTheOnlyIsa' && value === 'yes') {
      form.transferYourIsa.rules = {};
      form = Validate.input('transferYourIsa', '', form, true);

      //transferYourIsa should have no value
    }
    this.setState({ ...this.state, form });
  };

  resume = () => {
    const { updateUrl, formData, resumeApplication } = this.props;
    let stateData = {
      formData: formData,
      url: {
        pageStage: formData.stage,
      },
    };

    updateUrl(stateData);
    resumeApplication(formData.stage);
  };

  render() {
    const { form, selectedProduct } = this.state;
    const {
      depositProduct,
      allDepositProducts,
      systemDowntime,
      isFetching,
    } = this.props;
    if (
      systemDowntime &&
      systemDowntime.CurrentDowntimeStatus ===
        CONSTANTS.SYSTEM_DOWNTIME_STATUS_ONGOING
    ) {
      return <Redirect to="/long-apply/downtime" />;
    }

    if (allDepositProducts && depositProduct && !depositProduct.Product) {
      return <Redirect to="/long-apply/not-available" />;
    }

    if (isFetching || selectedProduct === false) {
      return (
        <div>
          <Content
            tag="h1"
            cmsTag="Long-apply:Apply-pages:Eligibility:fetching:h1"
            copytext="Loading product information"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Eligibility:fetching:p1"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                openTimes: CONTACTS.openTimes,
              },
            }}
            markdown={`\nPlease wait, retrieving product information...`}
          />
        </div>
      );
    }

    if (!selectedProduct) {
      return (
        <div>
          <Content
            tag="h1"
            cmsTag="Long-apply:Apply-pages:Eligibility:no-longer-available:h1"
            copytext="Product no longer available"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Eligibility:no-longer-available:p1"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                openTimes: CONTACTS.openTimes,
              },
            }}
            markdown={`\nUnfortunately that Savings product is no longer available. Please call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
          />
        </div>
      );
    }

    const accountType = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
      return selectedProduct.Account_Type_Code === element;
    });

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Apply for ${selectedProduct?.Product_Name}`}
          metaDescription={`Apply for ${selectedProduct?.Product_Name}`}
        />
        <Markdown
          cmsTag="Long-apply:Eligibility:h1"
          template={{
            markdown: {
              accountName: selectedProduct?.Product_Name,
            },
          }}
          markdown={`#Apply for $[accountName]`}
        />
        <SystemDowntimeBanner></SystemDowntimeBanner>
        <Content
          tag="h2"
          cmsTag="Long-apply:Eligibility:h2-1"
          copytext="Information about this account"
        />
        <AccountDescription selectedProduct={selectedProduct} />
        <div className="downloads-container mt-5">
          <Markdown
            cmsTag="Long-apply:Eligibility:p1"
            markdown={`\nPlease download and read our Darlingtonline Terms and Conditions and Privacy Notice before you apply.`}
          />
          <LinkTag
            id="download-product-terms-button"
            className={`btn btn-light`}
            target="_blank"
            role="button"
            href={CONTACTS?.termsAndConditionsURL}
          >
            <Content
              cmsTag="Long-apply:Eligibility:download-terms-and-conditions"
              copytext="Darlingtonline Terms and Conditions"
            />
            <Content
              tagClassName="sr-only"
              tag="span"
              cmsTag="GLOBAL:-opens-in-a-new-window"
              copytext=" (opens in a new window)"
            />
          </LinkTag>
          <LinkTag
            id="download-privacy-notice-button"
            className={`btn btn-light`}
            href={CONTACTS.privacyPolicyURL}
            target="blank"
          >
            <Content
              cmsTag="Long-apply:Eligibility:Download-privacy-notice"
              copytext="Privacy Notice"
            />
            <Content
              tagClassName="sr-only"
              tag="span"
              cmsTag="GLOBAL:-opens-in-a-new-window"
              copytext=" (opens in a new window)"
            />
          </LinkTag>
        </div>
        <Form autoComplete="off" onSubmit={this.onSubmitHandler}>
          <Content
            tag="h2"
            cmsTag="Long-apply:Eligibility:h2-2"
            copytext="Let's get started"
          />
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <RadioGroup
            legend={`Are you an existing member of ${CONTACTS.companyName}?`}
            cmsTag="GLOBAL:Are-you-an-existing-member"
            required={true}
            field="existingMember"
            id="existingMember"
            validation={form.existingMember}
          >
            <Radio
              label="Yes"
              cmsTag="GLOBAL:Yes"
              id="existingMember-yes"
              field="existingMember"
              onChange={this.onEvent}
              validation={form.existingMember}
              value="yes"
            />
            <Radio
              label="No"
              cmsTag="GLOBAL:No"
              id="existingMember-no"
              field="existingMember"
              onChange={this.onEvent}
              validation={form.existingMember}
              value="no"
            />
            {form.existingMember &&
            form.existingMember.value &&
            form.existingMember.value === 'yes' ? (
              <Alert color="danger">
                <Markdown
                  template={{
                    markdown: {
                      registerURL: CONTACTS.registerURL,
                      signInURL: CONTACTS.signInURL,
                    },
                  }}
                  cmsTag="Long-apply:Eligibility:alert-1"
                  markdown={`To apply for this account, please <a target="_blank" href="$[registerURL]">register for access</a> to our website or <a target="_blank" href="$[signInURL]">sign in to your existing account</a>.`}
                />
              </Alert>
            ) : (
              ''
            )}
          </RadioGroup>

          {accountType ? (
            <React.Fragment>
              <RadioGroup
                legend="Is this the only ISA opened or funded in the current tax year?"
                cmsTag="GLOBAL:Is-this-the-only-ISA-opened-or-funded-in-the-current-tax-year"
                required={true}
                field="isThisTheOnlyIsa"
                id="isThisTheOnlyIsa"
                validation={form.isThisTheOnlyIsa}
              >
                <Radio
                  label="Yes"
                  cmsTag="GLOBAL:Yes"
                  id="only-ISA-yes"
                  field="isThisTheOnlyIsa"
                  onChange={this.onEvent}
                  validation={form.isThisTheOnlyIsa}
                  value="yes"
                />
                <Radio
                  label="No"
                  cmsTag="GLOBAL:No"
                  id="only-ISA-no"
                  field="isThisTheOnlyIsa"
                  onChange={this.onEvent}
                  validation={form.isThisTheOnlyIsa}
                  value="no"
                />
              </RadioGroup>
              <RadioGroup
                legend="Do you want to transfer your ISA to this new account?"
                cmsTag="GLOBAL:Do-you-want-to-transfer-your-ISA-to-this-new-account"
                required={true}
                id="transferYourIsa"
                field="transferYourIsa"
                validation={form.transferYourIsa}
                disabled={
                  form.isThisTheOnlyIsa?.value === 'yes' ||
                  form.isThisTheOnlyIsa?.value === ''
                    ? true
                    : false
                }
              >
                <Radio
                  cmsTag="GLOBAL:Yes"
                  label="Yes"
                  id="transfer-ISA-yes"
                  field="transferYourIsa"
                  onChange={this.onEvent}
                  validation={form.transferYourIsa}
                  value="yes"
                  disabled={
                    form.isThisTheOnlyIsa?.value === 'yes' ||
                    form.isThisTheOnlyIsa?.value === ''
                      ? true
                      : false
                  }
                />
                <Radio
                  cmsTag="GLOBAL:No"
                  label="No"
                  id="transfer-ISA-no"
                  field="transferYourIsa"
                  onChange={this.onEvent}
                  validation={form.transferYourIsa}
                  value="no"
                  disabled={
                    form.isThisTheOnlyIsa?.value === 'yes' ||
                    form.isThisTheOnlyIsa?.value === ''
                      ? true
                      : false
                  }
                />
                {form.transferYourIsa &&
                form.transferYourIsa.value &&
                form.transferYourIsa.value === 'no' ? (
                  <Alert color="danger">
                    <Markdown
                      cmsTag="Long-apply:Eligibility:alert-2"
                      markdown={`You can only invest in one ISA in a tax year. You must transfer your existing ISA to us if you have invested in an ISA elsewhere.`}
                    />
                  </Alert>
                ) : (
                  ''
                )}

                {form.transferYourIsa &&
                form.transferYourIsa.value &&
                form.transferYourIsa.value === 'yes' ? (
                  <Alert color="info">
                    <Markdown
                      cmsTag="Long-apply:Eligibility:alert-3"
                      markdown={`To transfer your ISA please <a href="${CONTACTS.cashISAFormURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">download the ISA Transfer authority form<span className="sr-only"> (opens in a new window)</span></a>.`}
                    />
                  </Alert>
                ) : (
                  ''
                )}
              </RadioGroup>
            </React.Fragment>
          ) : (
            ''
          )}

          <Check
            autoFocus={false}
            cmsTag="GLOBAL:I-confirm-that-I-am-a-resident-in-the-UK-and-pay-tax-only-in-the-UK"
            label={`I confirm I am a UK resident.`}
            key={`residentsConfirm`}
            id={`residentsConfirm`}
            field="residentsConfirm"
            onChange={this.onChange}
            validation={form.residentsConfirm}
            checked={!!form.residentsConfirm.value}
            value={'new'}
          />
          <Check
            autoFocus={false}
            label={
              <Markdown
                cmsTag="GLOBAL:Agree-to-the-conditions"
                template={{
                  markdown: {
                    url: '',
                  },
                }}
                markdown={`I confirm that I have downloaded, read and agree with the <a href="${CONTACTS?.termsAndConditionsURL}" target="_blank" rel="noopener noreferrer"type="application/pdf">Terms and Conditions<span className="sr-only"> (Downloads a PDF)</span></a>.`}
              />
            }
            key={`confirmSummary`}
            id={`confirmSummary`}
            field="confirmSummary"
            onChange={this.onChange}
            validation={form.confirmSummary}
            checked={!!form.confirmSummary.value}
            value={'new'}
          />
          <Check
            autoFocus={false}
            label={
              <Markdown
                cmsTag="GLOBAL:Agree-privacy-policy"
                markdown={`I confirm that I have downloaded, read and agree with the <a href="${CONTACTS.privacyPolicyURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">Privacy policy<span className="sr-only"> (Downloads a PDF)</span></a>.`}
              />
            }
            key={`privacyPolicyConfirm`}
            id={`privacyPolicyConfirm`}
            field="privacyPolicyConfirm"
            onChange={this.onChange}
            validation={form.privacyPolicyConfirm}
            checked={!!form.privacyPolicyConfirm.value}
            value={'new'}
          />
          <Check
            autoFocus={false}
            label={
              <Markdown
                cmsTag="GLOBAL:Agree-statement-details"
                markdown={`I confirm that I have read the <a href="${CONTACTS.FSCSURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">Financial Services Compensation Scheme (FSCS) Information Sheet<span className="sr-only"> (Downloads a PDF)</span></a>.`}
              />
            }
            key={`fscsConfirm`}
            id={`fscsConfirm`}
            field="fscsConfirm"
            onChange={this.onChange}
            validation={form.fscsConfirm}
            checked={!!form.fscsConfirm.value}
            value={'entire'}
          />
          <Alert color="info">
            <Markdown
              cmsTag="Long-apply:Eligibility:alert-4"
              markdown={`\nThe personal information we will collect from you will be shared with fraud prevention agencies who will use it to prevent fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused certain services, finance, or employment. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found by <a href="${CONTACTS.privacyPolicyURL}" target="_blank" rel="noopener noreferrer">Privacy - Darlington Building Society/fraud prevention policy<span className="sr-only"> (Opens in a new window)</span></a>.`}
            />
          </Alert>
          <div className="form-button-group float-left w-100">
            <Button
              type="submit"
              id="continue-application-submit-button"
              color="primary"
            >
              <Content cmsTag="GLOBAL:Continue" copytext="Continue" />
            </Button>
            <LinkTag
              id="view-other-accounts"
              className={`btn btn-secondary`}
              target="_blank"
              role="button"
              href={CONTACTS.savingsAccountsPage}
            >
              <Content
                cmsTag="GLOBAL:View-other-accounts"
                copytext="View other accounts"
              />
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="GLOBAL:-opens-in-a-new-window"
                copytext=" (opens in a new window)"
              />
            </LinkTag>

            <Link
              className="btn btn-light float-right"
              role="button"
              to={`/resume-application/`}
            >
              <Content
                cmsTag="GLOBAL:Resume-application"
                copytext={'Resume application'}
              />
            </Link>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

Eligibility.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
  selectedProduct: PropTypes.any,
  getDepositProducts: PropTypes.any,
  depositProduct: PropTypes.object,
  allDepositProducts: PropTypes.any,
  form: PropTypes.any,
  setStage: PropTypes.any,
  getDepositProduct: PropTypes.func,
  setStateDynamic: PropTypes.any,
  contacts: PropTypes.object,
  resumeApplication: PropTypes.func,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  resetResumeApplicationData: PropTypes.func,
  isSystemReadOnly: PropTypes.func,
  systemDowntime: PropTypes.object,
  resetResumeApplicationMemorableWord: PropTypes.func,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Eligibility);
