import React from 'react';
import { Errors } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { Input, FormGroup, Row, Col } from 'reactstrap';

/** Errors example */
class ErrorsExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: {
          value: '',
          state: {},
          rules: {
            title: 'Name',
            required: 'Name is required',
          },
        },
      },
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Input
              invalid={Validate.isInvalid(this.state.form.name.state)}
              valid={Validate.isValid(this.state.form.name.state)}
              id="name"
              name="name"
              onChange={this.onChange}
              onBlur={this.onBlur}
              type="text"
            />
            <Errors validation={this.state.form.name} field={'name'} />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default ErrorsExample;
