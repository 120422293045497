import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Content, Markdown, LinkTag, stateUtility } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ExpiredChallenge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobNumberNotFound: false,
      sendCode: false,
      retry: false,
    };
  }

  retry = e => {
    const { resetDestinations, resetChallenge } = this.props;
    e.preventDefault();
    resetDestinations();
    resetChallenge();
  };

  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  setMobNumberNotFound = () => {
    this.setState({ ...this.state, mobNumberNotFound: true });
  };

  setResend = () => {
    this.setState({ ...this.state, sendCode: true });
  };

  retry = () => {
    const {
      history,
      finishTwoFactor,
      resetDestinations,
      resetChallenge,
    } = this.props;
    finishTwoFactor();
    resetDestinations();
    resetChallenge();
    const storage = stateUtility.getPageState();
    let stateData = {
      destination: null,
      destinationReq: null,
      url: {},
    };

    stateUtility.directlyUpdateUrl(
      stateData,
      storage && storage.global && storage.global.storage
        ? storage.global.storage
        : '',
      history,
    );
    // history.push(sharedUtility.getUrl());
  };

  setRetry = () => {
    const { resetChallenge } = this.props;
    resetChallenge();
    this.setState({ ...this.state, retry: true });
  };
  render() {
    return (
      <React.Fragment>
        {!this.state.retry ? (
          <React.Fragment>
            <h1 id="expired-challenge">
              <Content
                cmsTag="Two-factor:expired-challenge:h1"
                copytext="Authentication challenge expired"
              />
            </h1>
            <Markdown
              cmsTag="Two-factor:expired-challenge:p2"
              template={{
                markdown: {
                  phone: CONTACTS.phone,
                  phoneLink: CONTACTS.phoneLink,
                  openTimes: CONTACTS.openTimes,
                },
              }}
              markdown={`\nSorry, but the authentication challenge has expired. Please try again or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open  $[openTimes].`}
            />
            <div className="form-button-group">
              <Button
                color="primary"
                onClick={() => this.retry()}
                id="try-again"
              >
                <Content cmsTag="GLOBAL:Try-again" copytext="Try again" />
              </Button>
              {this.props.isAuthenticated ? (
                <Link
                  id="request-cancelled-back-to-home"
                  className={`btn btn-secondary`}
                  to={'/accounts/list'}
                >
                  <Content
                    id="GLOBAL:Back-to-home-page"
                    copytext="Back to home page"
                  />
                </Link>
              ) : (
                <LinkTag
                  id="request-cancelled-back-to-home"
                  className={`btn btn-secondary`}
                  role="button"
                  href={CONTACTS.externalHomeURL}
                >
                  <Content
                    id="GLOBAL:Back-to-home-page"
                    copytext="Back to home page"
                  />
                </LinkTag>
              )}
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

ExpiredChallenge.propTypes = {
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
  setDestination: PropTypes.any,
  selectedDestination: PropTypes.any,
  reSet: PropTypes.any,
  status: PropTypes.any,
  failed: PropTypes.any,
  challenge: PropTypes.any,
  history: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExpiredChallenge));
