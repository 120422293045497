import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Markdown, Content } from '@myie/interact-dom';
import { Session } from '@myie/interact';

/**
 * AuthButton component
 */
class AuthIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: Session.isAuthenticated(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isAuthenticated !== Session.isAuthenticated()) {
      this.setState({
        ...this.state,
        isAuthenticated: Session.isAuthenticated(),
      });
    }
  }

  render() {
    const { history, userLogout, url } = this.props;
    const customer = Session.customer();
    return this.state.isAuthenticated && customer ? (
      <div className="logged-in-nav">
        <Button
          color="link"
          size="sm"
          className="mr-0"
          onClick={() => {
            clearInterval(window.sessionStorage.getItem('timeoutID'));
            Session.abandon();
            userLogout();
            history.push(url);
          }}
        >
          <Markdown
            className="greeting"
            cmsTag="Shared:Components:Auth-button:Greeting"
            template={{
              markdown: {
                name: customer.Name,
              },
            }}
            markdown="Hi **$[name]** "
          />
          <Content cmsTag="GLOBAL:Sign-out" copytext="Sign out" />
        </Button>
      </div>
    ) : (
      ''
    );
  }
}

AuthIndicator.propTypes = {
  /**
   * Used for routing
   */
  history: PropTypes.object,
  /**
   * Used for redirecting
   */
  url: PropTypes.string.isRequired,
  /**
   * Used to logout current session
   */
  userLogout: PropTypes.func.isRequired,
};

const AuthButton = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(AuthIndicator),
);

export default AuthButton;
