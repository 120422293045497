import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

/**
 * MetaOverride component
 * can be used to override the MetaDefault values
 */
class MetaOverride extends React.Component {
  render() {
    const { metaDescription, title, contacts } = this.props;

    return (
      <Helmet>
        <title>
          {contacts?.companyName} - {title}
        </title>
        <meta name="description" content={metaDescription} />
      </Helmet>
    );
  }
}

MetaOverride.propTypes = {
  /**
   * Meta description
   */
  metaDescription: PropTypes.string.isRequired,
  /**
   * Title
   */
  title: PropTypes.string.isRequired,
  contacts: PropTypes.object,
};

export default MetaOverride;
