import { Connection } from '@myie/interact';

import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './registrationTypes';
import { RESET_REGISTRATION } from './registrationTypes';

export const resetRegistration = () => ({
  type: RESET_REGISTRATION,
});

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
  isFetching: true,
  error: null,
});

export const registerFailed = error => ({
  type: REGISTER_FAILURE,
  isFetching: false,
  error: error.message,
});

export const registerReceive = json => ({
  type: REGISTER_SUCCESS,
  registration: json,
  isFetching: false,
  error: null,
});

export const register = request => dispatch => {
  dispatch(registerRequest());
  return fetch(Connection.baseUrl('webauthentication') + `v2.0/user/register`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(registerReceive(json)))
    .catch(error => dispatch(registerFailed(error)));
};
