import React, { Component } from 'react';
import { BreadcrumbItem } from 'reactstrap';
import _ from 'lodash';
import { storage } from './storageName';
import PropTypes from 'prop-types';
import {
  Content,
  AppMeta,
  CONSTANTS,
  Iframe,
  SystemDowntimeBanner,
} from '@myie/interact-dom';
import { connect } from 'react-redux';
import Confirmation from './Confirmation';
import CreationSuccess from './CreationSuccess';
import PersonalDetails from './PersonalDetails';
import AddressDetails from './AddressDetails';
import YourFinances from './YourFinances';
import AccountPreferences from './AccountPreferences';
import { CONTACTS } from '@myie/interact-local-dom';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';
import { SaveIndex, ResumeIndex } from '../save-and-resume/index';
import DecisionSuccessISA from './application-responses/DecisionSuccessISA';
import Referred from './application-responses/Referred';
import Declined from './application-responses/Declined';
import DecisionSuccessNonISA from './application-responses/DecisionSuccessNonISA';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as mapDispatchToPropsAuthentication } from '@myie/interact-brand-authentication';
import { Redirect } from 'react-router-dom';
import requestPayload from './requestPayload';
import applyPagesUtility from './utility';
import RequestFailed from './application-responses/RequestFailed';
class ApplyIndex extends Component {
  constructor(props) {
    super(props);
    this.timeoutID = null;
    this.state = this.initialState(props);
  }

  initialState = props => {
    const { updateUrl } = props;
    const {
      urlParams,
      refreshUrl,
      resetSaveLongApply,
      resumeData = null,
      resetResumeApplicationData,
      resetResumeApplicationMemorableWord,
      resetGetApplicationStatus,
    } = props;
    const stateList = [
      'personalDetails',
      'addressDetails',
      'yourFinances',
      'accountPreferences',
      'reviewAndSubmit',
      'saveForLater',
      'resumeApplication',
      'applySuccess',
      'referredDocumentPending',
      'Decline',
      'Refer',
      'RequestFailed',
    ];
    let stage;
    let data = {};
    //let data =
    if (resumeData) {
      stage = resumeData?.Application?.stage;
      data = resumeData?.Application.Applicants[0];
      let stateData = {
        formData: {
          ...data,
          savedProductCode: resumeData?.Application?.ApplicationNumber,
          personalDetails: {
            title: data.Title,
            firstName: data.FirstName,
            middleName: data.MiddleName,
            lastName: data.LastName,
            gender: data.Gender === 'M' ? 'male' : 'female',
            b_day: data.BirthDetails.DateOfBirth,
            maritalStatus: data.MaritalStatus,
            nationality: data.CountryOfNationality,
            nationalInsuranceNumber: data.NationalInsuranceNumber,
            confirmUK: data.IsUKResident,
            mobilePhoneNumber: data?.CorrespondenceDetails?.PhoneNumbers?.find(
              number => number.Type === 'MOBILE',
            )?.Number,
            daytimePhoneNumber: data?.CorrespondenceDetails?.PhoneNumbers?.find(
              number => number.Type === 'DAYTIME',
            )?.Number,
            eveningPhoneNumber: data?.CorrespondenceDetails?.PhoneNumbers?.find(
              number => number.Type === 'EVENING',
            )?.Number,
            emailAddress: data?.CorrespondenceDetails?.EmailAddress,
            confirmEmailAddress: data?.CorrespondenceDetails?.EmailAddress,
            contactPreferences: data?.CorrespondenceDetails?.AllowContactByEmail
              ? CONSTANTS.CONTACT_PREFERENCE_EMAIL
              : CONSTANTS.CONTACT_PREFERENCE_POST,
          },
          addressDetails: {
            lookupForm:
              !data.Address.Address.ManualAddress &&
              data.Address.TimeInAddress?.FromDate
                ? {
                    addressInput: {
                      address_line_1: data.Address.Address.AddressLine1,
                      address_line_2: data.Address.Address.AddressLine2,
                      address_line_3: data.Address.Address.AddressLine3,
                      country: data.Address.Address.Country,
                      locality: data.Address.Address.PostTown,
                      postal_code: data.Address.Address.PostCode,
                      region: data.Address.Address.VillageOrDistrict,
                    },
                    currentAddressLivingSince:
                      data.Address.TimeInAddress?.FromDate,
                  }
                : {},
            lookupFormPrevious1:
              data.PreviousAddresses[0].Address.PostCode &&
              !data.PreviousAddresses[0].Address.ManualAddress
                ? {
                    addressInput: {
                      address_line_1:
                        data.PreviousAddresses[0].Address.AddressLine1,
                      address_line_2:
                        data.PreviousAddresses[0].Address.AddressLine2,
                      address_line_3:
                        data.PreviousAddresses[0].Address.AddressLine3,
                      country: data.PreviousAddresses[0].Address.Country,
                      locality: data.PreviousAddresses[0].Address.PostTown,
                      postal_code: data.PreviousAddresses[0].Address.PostCode,
                      region:
                        data.PreviousAddresses[0].Address.VillageOrDistrict,
                    },
                    previousAddressLivingSinceform1:
                      data.PreviousAddresses[0].TimeInAddress?.FromDate,
                  }
                : {},
            lookupFormPrevious2:
              data.PreviousAddresses[1].Address.PostCode &&
              !data.PreviousAddresses[1].Address.ManualAddress
                ? {
                    addressInput: {
                      address_line_1:
                        data.PreviousAddresses[1].Address.AddressLine1,
                      address_line_2:
                        data.PreviousAddresses[1].Address.AddressLine2,
                      address_line_3:
                        data.PreviousAddresses[1].Address.AddressLine3,
                      country: data.PreviousAddresses[1].Address.Country,
                      locality: data.PreviousAddresses[1].Address.PostTown,
                      postal_code: data.PreviousAddresses[1].Address.PostCode,
                      region:
                        data.PreviousAddresses[1].Address.VillageOrDistrict,
                    },
                    previousAddressLivingSinceform2:
                      data.PreviousAddresses[1].TimeInAddress?.FromDate,
                  }
                : {},
            form: data.Address.Address.ManualAddress
              ? {
                  currentFlatNumber: data.Address.Address.FlatNumber,
                  currentHouseName: data.Address.Address.BuildingName,
                  currentHouseNumber: data.Address.Address.BuildingNumber,
                  currentStreet: data.Address.Address.Street,
                  currentVillage: data.Address.Address.VillageOrDistrict,
                  currentTown: data.Address.Address.TownOrCity,
                  currentCounty: data.Address.Address.County,
                  currentPostcode: data.Address.Address.PostCode,
                  currentAddressLivingSince:
                    data.Address.TimeInAddress?.FromDate,
                }
              : {},
            form1: data.PreviousAddresses[0].Address.ManualAddress
              ? {
                  previousFlatNumberform1:
                    data.PreviousAddresses[0].Address.FlatNumber,
                  currentHouseNameform1:
                    data.PreviousAddresses[0].Address.BuildingName,
                  previousHouseNumberform1:
                    data.PreviousAddresses[0].Address.BuildingNumber,
                  previousStreetform1: data.PreviousAddresses[0].Address.Street,
                  previousVillageform1:
                    data.PreviousAddresses[0].Address.VillageOrDistrict,
                  previousTownform1:
                    data.PreviousAddresses[0].Address.TownOrCity,
                  previousCountyform1: data.PreviousAddresses[0].Address.County,
                  previousPostcodeform1:
                    data.PreviousAddresses[0].Address.PostCode,
                  previousAddressLivingSinceform1:
                    data.PreviousAddresses[0].TimeInAddress?.FromDate,
                  previousUKAddressform1: data.PreviousAddresses[0].Address
                    .UKAddress
                    ? 'yes'
                    : 'no',
                  previousPostZipcodeform1:
                    data.PreviousAddresses[0].Address.PostCode,
                  previousCountryform1:
                    data.PreviousAddresses[0].Address.Country,
                }
              : {},
            form2: data.PreviousAddresses[1].Address.ManualAddress
              ? {
                  previousFlatNumberform2:
                    data.PreviousAddresses[1].Address.FlatNumber,
                  previousHouseNameform2:
                    data.PreviousAddresses[1].Address.BuildingName,
                  previousHouseNumberform2:
                    data.PreviousAddresses[1].Address.BuildingNumber,
                  previousStreetform2: data.PreviousAddresses[1].Address.Street,
                  previousVillageform2:
                    data.PreviousAddresses[1].Address.VillageOrDistrict,
                  previousTownform2:
                    data.PreviousAddresses[1].Address.TownOrCity,
                  previousCountyform2: data.PreviousAddresses[1].Address.County,
                  previousPostcodeform2:
                    data.PreviousAddresses[1].Address.PostCode,
                  previousAddressLivingSinceform2:
                    data.PreviousAddresses[1].TimeInAddress?.FromDate,
                  previousUKAddressform2: data.PreviousAddresses[1].Address
                    .UKAddress
                    ? 'yes'
                    : 'no',
                  previousPostZipcodeform2:
                    data.PreviousAddresses[1].Address.PostCode,
                  previousCountryform2:
                    data.PreviousAddresses[1].Address.Country,
                }
              : {},
            needFirstPrevAddress:
              data.Address.ExtendedProperties[0].needFirstPrevAddress,
            needSecondPrevAddress:
              data.Address.ExtendedProperties[1].needSecondPrevAddress,
          },
          yourFinances: {
            USCitizen: data.TaxInformation.AdditionalOverseasTaxInformation[0]
              .TaxResidenceCountry
              ? 'yes'
              : 'no',
            OtherTaxResident:
              !!data.TaxInformation.AdditionalOverseasTaxInformation[1]
                .TaxResidenceCountry ||
              !!data.TaxInformation.AdditionalOverseasTaxInformation[2]
                .TaxResidenceCountry ||
              !!data.TaxInformation.AdditionalOverseasTaxInformation[3]
                .TaxResidenceCountry
                ? 'yes'
                : 'no',
            TaxCountry1:
              data.TaxInformation.AdditionalOverseasTaxInformation[0]
                .TaxResidenceCountry,
            TaxIdentification1:
              data.TaxInformation.AdditionalOverseasTaxInformation[0]
                .TaxpayerIdentificationNumber,
            TaxCountry2:
              data.TaxInformation.AdditionalOverseasTaxInformation[1]
                .TaxResidenceCountry,
            TaxIdentification2:
              data.TaxInformation.AdditionalOverseasTaxInformation[1]
                .TaxpayerIdentificationNumber,
            TaxCountry3:
              data.TaxInformation.AdditionalOverseasTaxInformation[2]
                .TaxResidenceCountry,
            TaxIdentification3:
              data.TaxInformation.AdditionalOverseasTaxInformation[2]
                .TaxpayerIdentificationNumber,
            TaxCountry4:
              data.TaxInformation.AdditionalOverseasTaxInformation[3]
                .TaxResidenceCountry,
            TaxIdentification4:
              data.TaxInformation.AdditionalOverseasTaxInformation[3]
                .TaxpayerIdentificationNumber,
          },
          accountPreferences: {
            depositMethod: resumeData?.Application?.DepositFundsOption,
            depositAmount: resumeData?.Application?.DepositAmount?.Amount,
          },
        },
        url: {
          pageStage: stage,
        },
      };
      updateUrl(stateData);
    } else {
      const hasStage = stateList.find(
        element => element === urlParams.pageStage,
      );
      stage =
        this.props.stateData &&
        this.props.stateData.url &&
        this.props.stateData.url.pageStage &&
        this.props.urlParams &&
        this.props.urlParams.pageStage
          ? this.props.urlParams.pageStage
          : 'personalDetails';
      if (!hasStage) {
        stage = 'personalDetails';
        resetSaveLongApply();
        resetGetApplicationStatus();
        refreshUrl();
      }
      data =
        this.props.stateData && this.props.stateData.formData
          ? this.props.stateData.formData
          : {};
      resetResumeApplicationData();
      resetResumeApplicationMemorableWord();
    }

    return {
      selectedProduct: null,
      stage: stage,
      data: data,
      responseStatus: null,
      isPolling: false,
    };
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.resumeData?.Application?.ApplicationNumber !==
      prevProps?.resumeData?.Application?.ApplicationNumber
    ) {
      this.setState({ ...this.initialState(this.props) });
    }
    if (
      this.props.saveLongApplyData &&
      ((this.props.saveLongApplyData.Status === 'Pending' &&
        !this.props.applicationStatus) ||
        (this.props.applicationStatus &&
          this.props.applicationStatus.DocumentUploadStatus !==
            CONSTANTS.STATUS_COMPLETE)) &&
      !this.state.isPolling
    ) {
      this.startTimer();
    }
    if (
      this.props.applicationStatus &&
      this.props.applicationStatus.DocumentUploadStatus ===
        CONSTANTS.STATUS_COMPLETE &&
      (this.props.applicationStatus.DecisionStatus ===
        CONSTANTS.STATUS_REFERRED ||
        this.props.applicationStatus.DecisionStatus ===
          CONSTANTS.STATUS_DECLINE ||
        this.props.applicationStatus.DecisionStatus === CONSTANTS.STATUS_ACCEPT)
    ) {
      this.clearTimer();
    }
  }

  static getDerivedStateFromProps = (nextProps, thisState) => {
    const {
      allDepositProducts,
      match,
      updateUrl,
      stateData,
      depositProduct,
      register,
      saveLongApplyData,
      applicationStatus,
    } = nextProps;
    const { selectedProduct, stage } = thisState;

    let product = null;
    if (depositProduct && depositProduct.Product && !selectedProduct) {
      if (allDepositProducts && allDepositProducts.length) {
        allDepositProducts.forEach(element => {
          if (match.params && match.params.id === element.Product_Code) {
            product = element;
          }
        });
        return {
          selectedProduct: product,
        };
      }
    }

    if (
      saveLongApplyData &&
      ((saveLongApplyData.Status === 'Pending' && !applicationStatus) ||
        (applicationStatus &&
          applicationStatus.DocumentUploadStatus !==
            CONSTANTS.STATUS_COMPLETE)) &&
      stage !== 'Refer'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'Refer',
        },
      };

      updateUrl(data);
      return {
        stage: 'Refer',
      };
    }

    if (
      saveLongApplyData &&
      saveLongApplyData.Status === 'Decline' &&
      stage !== 'Decline'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'Decline',
        },
      };

      updateUrl(data);
      return {
        stage: 'Decline',
      };
    }

    if (
      saveLongApplyData &&
      saveLongApplyData.Status === 'Failed' &&
      stage !== 'RequestFailed'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'RequestFailed',
        },
      };

      updateUrl(data);
      return {
        stage: 'RequestFailed',
      };
    }

    if (
      saveLongApplyData &&
      ((saveLongApplyData.Status === 'Referred' && !applicationStatus) ||
        (applicationStatus &&
          applicationStatus.DocumentUploadStatus ===
            CONSTANTS.STATUS_COMPLETE &&
          (applicationStatus.DecisionStatus === CONSTANTS.STATUS_REFERRED ||
            applicationStatus.DecisionStatus === CONSTANTS.STATUS_DECLINE))) &&
      stage !== 'referredWithDocs'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'referredWithDocs',
        },
      };

      updateUrl(data);
      return {
        stage: 'referredWithDocs',
        isPolling: false,
      };
    }

    if (
      saveLongApplyData &&
      ((saveLongApplyData.Status === 'Accept' && !applicationStatus) ||
        (applicationStatus &&
          applicationStatus.DocumentUploadStatus ===
            CONSTANTS.STATUS_COMPLETE &&
          applicationStatus.DecisionStatus === CONSTANTS.STATUS_ACCEPT)) &&
      stage !== 'applySuccess'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'applySuccess',
        },
      };
      let accountNumber = saveLongApplyData.ReferenceNumber;
      applyPagesUtility.saveAccount(accountNumber);
      let postCode = stateData?.formData?.addressDetails?.form?.currentPostcode;
      if (!postCode) {
        postCode =
          stateData?.formData?.addressDetails?.lookupForm?.addressInput
            ?.postal_code;
      }
      const request = {
        TermsAndConditionsDocumentVersion: 'v1.0',

        Entries: [
          {
            Type: 'firstName',
            Value: stateData?.formData?.personalDetails?.firstName,
          },
          {
            Type: 'lastName',
            Value: stateData?.formData?.personalDetails?.lastName,
          },
          {
            Type: 'dateOfBirth',
            Value: stateData?.formData?.personalDetails?.b_day,
          },
          {
            Type: 'postCode',
            Value: postCode,
          },
          {
            Type: 'accountNumber',
            Value: accountNumber,
          },
        ],
        ExtendedProperties: {},
      };

      register(request);

      updateUrl(data);
      return {
        stage: 'applySuccess',
        isPolling: false,
      };
    }

    return null;
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  backToPrevious = stage => {
    const { updateUrl } = this.props;
    const formData =
      this.props.stateData && this.props.stateData.formData
        ? this.props.stateData.formData
        : {};
    if (formData) {
      if (stage in formData) {
        let stateData = {
          formData: {
            ...formData,
          },
          url: {
            pageStage: stage,
          },
        };
        updateUrl(stateData);
        this.setState({ ...this.state, stage: stage });
      }
    }
  };

  componentDidMount() {
    const {
      getDepositProducts,
      allDepositProducts,
      getDepositProduct,
      match,
      depositProduct,
      resetSaveLongApplyForLater,
      isSystemReadOnly,
    } = this.props;
    resetSaveLongApplyForLater();
    if (!allDepositProducts) {
      getDepositProducts({
        checkLimitExceeded: true,
        isLongApply: true,
      });
    }
    if (!depositProduct || (depositProduct && !depositProduct.Product)) {
      getDepositProduct({
        ProductId: match.params.id,
      });
    }

    isSystemReadOnly();
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    const { resetSaveLongApply, resetGetApplicationStatus } = this.props;
    resetSaveLongApply();
    resetGetApplicationStatus();
    this.clearTimer && this.clearTimer();
  }

  // change the stage of the form
  setStage = stage => {
    this.setState({
      ...this.state,
      stage,
      message: null,
    });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state
  submitForm = (stage, form) => {
    this.setState({ ...this.state, stage, form });
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  submitPersonalDetails = () => {};
  submitAddressDetails = () => {};
  submitYourFinances = () => {};
  submitAccountPreferences = () => {};
  submitReviewAndSubmit = () => {
    const { saveLongApply } = this.props;
    const { selectedProduct } = this.state;
    const formData = this.props.stateData.formData;
    const data = formData['personalDetails'];
    const request = requestPayload.getPayload(
      {
        formData: formData,
        status: data.firstName,
        ISA: CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
          return selectedProduct.Account_Type_Code === element;
        }),
        selectedProduct,
      },
      false,
    );
    saveLongApply(request);
  };

  // select current stage
  currentStage = () => {
    const { updateUrl } = this.props;
    const { selectedProduct } = this.state;
    switch (this.state.stage) {
      case 'personalDetails':
        return (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title={`Apply for ${selectedProduct?.Product_Name} - Step 1 Personal Details`}
              metaDescription={`Apply for ${selectedProduct?.Product_Name} - Step 1 Personal Details`}
            />
            <PersonalDetails
              setForm={this.setForm}
              updateUrl={updateUrl}
              saveData={this.saveData}
              setStage={this.setStage}
              selectedProduct={selectedProduct}
              onSubmit={this.submitPersonalDetails}
              formData={
                this.props.stateData && this.props.stateData.formData
                  ? this.props.stateData.formData
                  : {}
              }
              submitForm={this.submitForm}
              setStateDynamic={this.setStateDynamic}
              onChangeAccount={this.onChangeAccount}
              data={this.state.data}
            />
          </React.Fragment>
        );
      case 'addressDetails':
        return (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title={`Apply for ${selectedProduct?.Product_Name} - Step 2 Address Details`}
              metaDescription={`Apply for ${selectedProduct?.Product_Name} - Step 2 Address Details`}
            />

            {!_.isEmpty(this.props.stateData) && (
              <AddressDetails
                setForm={this.setForm}
                updateUrl={updateUrl}
                saveData={this.saveData}
                setStage={this.setStage}
                onSubmit={this.submitAddressDetails}
                formData={
                  this.props.stateData && this.props.stateData.formData
                    ? this.props.stateData.formData
                    : {}
                }
                submitForm={this.submitForm}
                setStateDynamic={this.setStateDynamic}
                onChangeAccount={this.onChangeAccount}
                data={this.state.data}
                isResumedApplication={this.props.resumeData ? true : false}
              />
            )}
          </React.Fragment>
        );
      case 'yourFinances':
        return (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title={`Apply for ${selectedProduct?.Product_Name} - Step 3 Your Finances`}
              metaDescription={`Apply for ${selectedProduct?.Product_Name} - Step 3 Your Finances`}
            />
            <YourFinances
              setForm={this.setForm}
              updateUrl={updateUrl}
              saveData={this.saveData}
              setStage={this.setStage}
              onSubmit={this.submitYourFinances}
              formData={
                this.props.stateData && this.props.stateData.formData
                  ? this.props.stateData.formData
                  : {}
              }
              submitForm={this.submitForm}
              setStateDynamic={this.setStateDynamic}
              onChangeAccount={this.onChangeAccount}
              data={this.state.data}
            />
          </React.Fragment>
        );
      case 'accountPreferences':
        return (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title={`Apply for ${selectedProduct?.Product_Name} - Step 4 Account Preferences`}
              metaDescription={`Apply for ${selectedProduct?.Product_Name} - Step 4 Account Preferences`}
            />
            <AccountPreferences
              setForm={this.setForm}
              updateUrl={updateUrl}
              saveData={this.saveData}
              setStage={this.setStage}
              selectedProduct={selectedProduct}
              onSubmit={this.submitAccountPreferences}
              formData={
                this.props.stateData && this.props.stateData.formData
                  ? this.props.stateData.formData
                  : {}
              }
              submitForm={this.submitForm}
              setStateDynamic={this.setStateDynamic}
              onChangeAccount={this.onChangeAccount}
              data={this.state.data}
            />
          </React.Fragment>
        );
      case 'reviewAndSubmit':
        return (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title={`Apply for ${selectedProduct?.Product_Name} - Step 5 Review and Submit`}
              metaDescription={`Apply for ${selectedProduct?.Product_Name} - Step 5 Review and Submit`}
            />
            <Confirmation
              setForm={this.setForm}
              updateUrl={updateUrl}
              selectedProduct={selectedProduct}
              saveData={this.saveData}
              setStage={this.setStage}
              onSubmit={this.submitReviewAndSubmit}
              formData={
                this.props.stateData && this.props.stateData.formData
                  ? this.props.stateData.formData
                  : {}
              }
              submitForm={this.submitForm}
              setStateDynamic={this.setStateDynamic}
              onChangeAccount={this.onChangeAccount}
              data={this.state.data}
              backToPrevious={this.backToPrevious}
              isFetching={this.props.isFetching}
              nextStep={this.props.nextStep}
              resetNextStepFormSubmit={this.props.resetNextStepFormSubmit}
              isSystemReadOnly={this.props.isSystemReadOnly}
              systemDowntime={this.props.systemDowntime}
            />
          </React.Fragment>
        );
      default:
    }
  };
  updateApplicatoinStatus = () => {
    const request = {
      ClientApplicationReference: this.props.saveLongApplyData
        .ExtendedProperties[1].Value,
    };
    this.props.getApplicationStatus(request);
  };

  startTimer = () => {
    if (!this.state.isPolling) {
      this.timeoutID = setInterval(
        this.updateApplicatoinStatus,
        CONSTANTS.POLLING_INTERVAL_MILLISECONDS,
      );
      this.setState({ isPolling: true });
    }
  };
  clearTimer = () => {
    clearInterval(this.timeoutID);
  };
  render() {
    const { stage, responseStatus, selectedProduct } = this.state;
    const {
      updateUrl,
      resetComponentState,
      depositProduct,
      systemDowntime,
    } = this.props;
    if (depositProduct && !depositProduct.Product) {
      return <Redirect to="/long-apply/not-available" />;
    }
    let ISA = '';
    if (!selectedProduct) {
      return null;
    }

    if (responseStatus === 'Failed') {
      return <Redirect to="/network-error" />;
    }

    if (
      systemDowntime &&
      systemDowntime.CurrentDowntimeStatus ===
        CONSTANTS.SYSTEM_DOWNTIME_STATUS_ONGOING
    ) {
      return <Redirect to="/long-apply/downtime" />;
    }
    ISA = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
      return selectedProduct.Account_Type_Code === element;
    });

    if (stage === 'resumeApplication') {
      return (
        <ResumeIndex
          longApplyStage={this.state.stage}
          updateUrl={updateUrl}
          setStage={this.setStage}
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
        />
      );
    }
    if (stage === 'Refer') {
      return (
        <Iframe
          iframeclassname="w-100 trunarrative-iframe"
          title="Upload documents iframe. If you are having problems using this iframe, please contact Darlington Building Society."
          url={this.props.saveLongApplyData.ExtendedProperties[0].Value}
          iframepermissions={'camera'}
        />
      );
    }

    if (stage === 'referredWithDocs') {
      return <Referred saveLongApplyData={this.props.saveLongApplyData} />;
    }

    if (stage === 'Decline') {
      return <Declined saveLongApplyData={this.props.saveLongApplyData} />;
    }

    if (stage === 'RequestFailed') {
      return <RequestFailed />;
    }

    if (stage === 'applySuccess' && ISA) {
      return (
        <DecisionSuccessISA
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          saveLongApplyData={this.props.saveLongApplyData}
        />
      );
    }
    if (stage === 'applySuccess' && !ISA) {
      return (
        <DecisionSuccessNonISA
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          saveLongApplyData={this.props.saveLongApplyData}
        />
      );
    }

    if (stage === 'saveForLater') {
      return (
        <SaveIndex
          longApplyStage={this.state.stage}
          setStage={this.setStage}
          updateUrl={updateUrl}
          stateData={this.props.stateData}
          selectedProduct={selectedProduct}
          urlParams={this.props.urlParams}
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          isResumedApplication={this.props.resumeData ? true : false}
        />
      );
    }

    if (stage === 'CompleteNotice') {
      return (
        <CreationSuccess
          setStage={this.setStage}
          activate={this.activate}
          data={this.state.data}
          backToPrevious={this.backToPrevious}
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          updateUrl={updateUrl}
          resetComponentState={resetComponentState}
        />
      );
    }

    return (
      <React.Fragment>
        <Content
          cmsTag="Long-apply:Eligibility:h1"
          tag="h1"
          template={{
            copytext: {
              accountName: selectedProduct?.Product_Name,
            },
          }}
          copytext={`Apply for $[accountName]`}
        />
        <SystemDowntimeBanner></SystemDowntimeBanner>
        <div className="breadcrumb-container acquire-breadcrumb">
          <ol className="breadcrumb justify-content-center">
            <BreadcrumbItem
              active={stage === 'personalDetails'}
              className={
                stage === 'addressDetails' ||
                stage === 'yourFinances' ||
                stage === 'accountPreferences' ||
                stage === 'reviewAndSubmit'
                  ? 'completed'
                  : ''
              }
            >
              {stage === 'addressDetails' ||
              stage === 'yourFinances' ||
              stage === 'accountPreferences' ||
              stage === 'reviewAndSubmit' ? (
                <button onClick={() => this.backToPrevious('personalDetails')}>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Click-to-go-to-"
                    copytext="Click to go to "
                  />
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed-"
                    copytext="Completed: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Personal-details"
                    copytext="Personal details"
                  />
                </button>
              ) : (
                <div>
                  {this.state.stage === 'personalDetails' ? (
                    <Content
                      tag="span"
                      tagClassName="sr-only"
                      cmsTag="BREADCRUMB:Active"
                      copytext="Active: "
                    />
                  ) : (
                    ''
                  )}
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Personal-details"
                    copytext="Personal details"
                  />
                </div>
              )}
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'addressDetails'}
              className={
                stage === 'yourFinances' ||
                stage === 'accountPreferences' ||
                stage === 'reviewAndSubmit'
                  ? 'completed'
                  : ''
              }
            >
              {stage === 'yourFinances' ||
              stage === 'accountPreferences' ||
              stage === 'reviewAndSubmit' ? (
                <button onClick={() => this.backToPrevious('addressDetails')}>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Click-to-go-to-"
                    copytext="Click to go to "
                  />
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed-"
                    copytext="Completed: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Address-details"
                    copytext="Address details"
                  />
                </button>
              ) : (
                <div>
                  {this.state.stage === 'addressDetails' ? (
                    <Content
                      tag="span"
                      tagClassName="sr-only"
                      cmsTag="BREADCRUMB:Active"
                      copytext="Active: "
                    />
                  ) : (
                    ''
                  )}
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Address-details"
                    copytext="Address details"
                  />
                </div>
              )}
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'yourFinances'}
              className={
                stage === 'accountPreferences' || stage === 'reviewAndSubmit'
                  ? 'completed'
                  : ''
              }
            >
              {stage === 'accountPreferences' || stage === 'reviewAndSubmit' ? (
                <button onClick={() => this.backToPrevious('yourFinances')}>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Click-to-go-to-"
                    copytext="Click to go to "
                  />
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed-"
                    copytext="Completed: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Your-finances"
                    copytext="Your finances"
                  />
                </button>
              ) : (
                <div>
                  {this.state.stage === 'yourFinances' ? (
                    <Content
                      tag="span"
                      tagClassName="sr-only"
                      cmsTag="BREADCRUMB:Active"
                      copytext="Active: "
                    />
                  ) : (
                    ''
                  )}
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Your-finances"
                    copytext="Your finances"
                  />
                </div>
              )}
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'accountPreferences'}
              className={stage === 'reviewAndSubmit' ? 'completed' : ''}
            >
              {stage === 'reviewAndSubmit' ? (
                <button
                  onClick={() => this.backToPrevious('accountPreferences')}
                >
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Click-to-go-to-"
                    copytext="Click to go to "
                  />
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed-"
                    copytext="Completed: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-4-" copytext="Step 4 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Account-preferences"
                    copytext="Account preferences"
                  />
                </button>
              ) : (
                <div>
                  {this.state.stage === 'accountPreferences' ? (
                    <Content
                      tag="span"
                      tagClassName="sr-only"
                      cmsTag="BREADCRUMB:Active"
                      copytext="Active: "
                    />
                  ) : (
                    ''
                  )}
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-4-" copytext="Step 4 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    5:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Account-preferences"
                    copytext="Account preferences"
                  />
                </div>
              )}
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'reviewAndSubmit'}>
              {this.state.stage === 'reviewAndSubmit' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-5-" copytext="Step 5 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                5:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:p1"
            copytext={`You can click "Save for later" at any time to save your details. You can return to your application within the next 30 days. Saved applications will be deleted after 30 days. We cannot guarantee the product you applied for will be available when you return to your application.`}
          />
        </div>
        {this.currentStage()}
      </React.Fragment>
    );
  }
}

ApplyIndex.propTypes = {
  accounts: PropTypes.any,
  history: PropTypes.any,
  isFetching: PropTypes.bool,
  allDepositProducts: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  account: PropTypes.any,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  resetSaveLongApplyForLater: PropTypes.func,
  resumeData: PropTypes.object,
  updateUrl: PropTypes.any,
  getDepositProduct: PropTypes.func,
  depositProduct: PropTypes.object,
  resetResumeApplicationData: PropTypes.func,
  getDepositProducts: PropTypes.func,
  saveLongApply: PropTypes.func,
  resetComponentState: PropTypes.func,
  selectedProduct: PropTypes.any,
  resetSaveLongApply: PropTypes.func,
  saveLongApplyData: PropTypes.object,
  systemDowntime: PropTypes.object,
  isSystemReadOnly: PropTypes.func,
  nextStep: PropTypes.string,
  resetNextStepFormSubmit: PropTypes.func,
  resetResumeApplicationMemorableWord: PropTypes.func,
  getApplicationStatus: PropTypes.func,
  resetGetApplicationStatus: PropTypes.func,
  applicationStatus: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapDispatchToPropsAuthentication },
  )(ApplyIndex),
  storage.name,
);
