import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Content } from '@myie/interact-dom';

class BackToButton extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { origin, onClick } = this.props;
    switch (origin) {
      case 'accountsList':
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-my-details"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-accounts"
              copytext="Back to accounts"
            />
          </Button>
        );
      case 'EditnominatedAccountDetails':
      case 'nominatedAccountDetails':
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-nominated-account-details"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-nominated-account-details"
              copytext="Back to nominated account details"
            />
          </Button>
        );
      case 'editUserDetails':
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-my-details"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-personal-details"
              copytext="Back to personal details"
            />
          </Button>
        );
      case 'editSecurityDetails':
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-my-details"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-my-details"
              copytext="Back to my details"
            />
          </Button>
        );
      case 'transferIntoNewAccount':
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-my-details"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-my-details"
              copytext="Review & Submit Transfer"
            />
          </Button>
        );
      default:
        return (
          <Button
            color="secondary"
            id="twofa-btn-back-to-home"
            onClick={onClick}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </Button>
        );
    }
  }
}

BackToButton.propTypes = {
  origin: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackToButton;
