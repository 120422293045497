import depositProductsDefinition from './reducers/depositProductsDefinition';
import addressLookupDefinition from './reducers/addressLookupDefinition';
import systemDowntimeDefinition from './reducers/systemDowntimeDefinition';

const longApplyDefinition = (state = {}, action) => {
  let newState = state;
  newState = depositProductsDefinition(newState, action);
  newState = addressLookupDefinition(newState, action);
  newState = systemDowntimeDefinition(newState, action);

  return newState;
};

export default longApplyDefinition;
