import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M16.3,12.5l-4.2-0.6L10.3,8c-0.2-0.3-0.5-0.5-0.8-0.5C9.1,7.5,8.8,7.7,8.6,8l-1.9,3.8l-4.2,0.6c-0.8,0.1-1.1,1-0.5,1.6l3,3 l-0.7,4.2c-0.1,0.6,0.4,1.1,0.9,1.1c0.1,0,0.3,0,0.4-0.1l3.8-2l3.8,2c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,1-0.5,0.9-1.1L13.8,17l3-3 C17.4,13.5,17.1,12.6,16.3,12.5z"
    />
    <polygon
      className={fillNegative}
      points="12.5,15.6 11.7,16.3 11.9,17.3 12.3,19.5 10.3,18.5 9.4,18 8.5,18.5 6.6,19.5 7,17.3 7.1,16.3 6.4,15.6 4.8,14.1 7,13.7 8,13.6 8.5,12.7 9.4,10.7 10.4,12.7 10.9,13.6 11.9,13.7 14.1,14.1 "
    />
    <polygon
      className={fill2}
      points="15.2,8.2 16.3,6 18.4,5 16.3,3.9 15.2,1.7 14.1,3.9 12,5 14.1,6 "
    />
    <polygon
      className={fill2}
      points="21,9.4 20.3,8.2 19.7,9.4 18.4,10.1 19.7,10.7 20.3,12 21,10.7 22.3,10.1 "
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
