export const CONTACTS = {
  phone: '011xyz',
  phoneLink: '011xyz',
  email: 'onlinesupport@default.co.uk',
  openTimes: 'Monday to Friday, 9.00am to 5.00pm',
  companyName: 'Default',
  companyNameShorthand: 'Default',
  companyNamePosessive: 'Default',
  companySiteName: 'EffortlessOnline',
  companySiteNamePossessive: "EffortlessOnline's",
  companyAddress: 'Effortless Bank 4 Riverview House Kingston KT1 4BU',
  companyFormattedAddress:
    'Effortless Bank<br />4 Riverview House<br />Kingston<br />KT1 4BU',
  externalHomeURL: 'https://www.xyz/',
  termsAndConditionsURL: 'https://savings.xyz.pdf',
  privacyPolicyURL: 'https://www.xyz/privacy/',
  savingsTermsAndConditionsURL: 'https://www.xyz.pdf',
  FSCSURL: 'https://savings.xyz.pdf',
  helpURL: 'https://www.xyz/help-and-contact/',
  sidebarAdvertURL: 'https://www.xyz/advert',
  registerURL: 'https://www.xyz/',
  signInURL: 'https://www.xyz/',
  cashISAFormURL: 'https://www.xyz.pdf',
  externalApplyHomeURL: 'https://www.xyz.co.uk/',
  savingsAccountsPage: 'https://www.xyz.co.uk/savings-accounts/',
  minimumAge: '18',
  sortcode: '11-11-11',
  accountNumber: '12345678',
};
