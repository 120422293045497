import React from 'react';
import LongApplyRoutes from './LongApplyRoutes';
import ResumeIndex from './save-and-resume/resume-application/ResumeIndex';
import UpdateSecurityDetailsIndex from '../update-security-details';

import { Route } from 'react-router-dom';

const Routes = [
  <Route key="/long-apply" path="/long-apply" component={LongApplyRoutes} />,
  <Route
    key="/security-details"
    path="/security-details"
    component={UpdateSecurityDetailsIndex}
  />,

  <Route
    key="/resume-application"
    path="/resume-application/:id?"
    component={ResumeIndex}
  />,
];

export default Routes;
