import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
  >
    <path
      className={fill}
      d="M16,15.1c0.1,0,0.1,0,0.2-0.1c0.7-0.5,1.3-1,2-1.5c0.4-0.3,0.9-0.5,1.4-0.6c0.6-0.1,1.2,0.1,1.7,0.4c0.4,0.3,0.8,0.7,0.9,1.2c0.1,0.3,0.2,0.6,0.1,1c0,0.4-0.2,0.8-0.4,1.1c-0.2,0.3-0.4,0.5-0.6,0.7c-1.2,0.9-2.3,1.8-3.5,2.7c-0.6,0.5-1.3,0.7-2.1,0.7c-4.4,0-8.7,0-13.1,0c-0.3,0-0.6-0.1-0.8-0.5c-0.2-0.5,0-1.1,0.6-1.2c0.1,0,0.2,0,0.3,0c4.3,0,8.6,0,13,0c0.4,0,0.7-0.1,1-0.3c0.1-0.1,0.3-0.2,0.4-0.3c1.1-0.8,2.1-1.6,3.2-2.3c0.3-0.2,0.4-0.5,0.3-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.3-0.1-0.6-0.1-0.9,0.1c-0.7,0.5-1.4,1-2.1,1.6c-0.3,0.2-0.7,0.4-1.1,0.4c-1.6,0-3.1,0-4.7,0c-0.2,0-0.4,0-0.5-0.2c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.2,0.3-0.3,0.5-0.3c0.7,0,1.3,0,2,0c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.4-0.5,0.3-0.8c-0.1-0.3-0.3-0.4-0.6-0.5c-0.1,0-0.2,0-0.3,0c-1.6,0-3.2,0-4.9,0c-0.8,0-1.5,0.2-2.1,0.7c-0.4,0.3-0.8,0.6-1.3,1C5.2,16,5.1,16,5,16c-0.8,0-1.6,0-2.4,0c-0.3,0-0.6-0.1-0.7-0.3c-0.3-0.4-0.2-1,0.2-1.2c0.2-0.1,0.4-0.2,0.6-0.2c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2-0.1C5,14,5.4,13.7,5.7,13.4c0.5-0.4,1.1-0.6,1.8-0.7c0.3,0,0.6-0.1,0.9-0.1c1.7,0,3.3,0,5,0c0.7,0,1.3,0.2,1.8,0.7c0.4,0.3,0.6,0.8,0.7,1.3C15.9,14.7,16,14.9,16,15.1"
    />
    <path
      className={fill}
      d="M9.5,6.2L9.5,6.2C9.3,6.2,9.1,6.3,9,6.4C8.8,6.6,8.7,6.7,8.7,6.9c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2H10c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.5,0.1,0.8c0,0.2,0,0.4-0.1,0.6c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.5,0.1C9,9.7,8.8,9.8,8.7,10c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1,0,0.1-0.1,0.3-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.5,0.1c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.4-0.1,0.5-0.1c0.3-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.5,0-0.8-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.2,0-0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.5,0.1-0.9c0-0.2,0-0.4-0.1-0.6h0.9c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-1.4c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.3-0.5-0.4c0,0,0,0,0,0c-0.2-0.1-0.5-0.2-0.8-0.3C12.8,3,12.5,3,12.2,3c-0.6,0-1,0.1-1.5,0.3C10.3,3.6,10,3.9,9.8,4.3C9.5,4.6,9.4,5.1,9.4,5.6C9.4,5.8,9.5,6,9.5,6.2z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
