import { RULES } from '../../../shared/constants/rules';

export const form = {
  currentFlatNumber: {
    rules: {
      title: 'currentFlatNumber',
      stop: true,
      required: {
        message: 'Please enter a flat number, house number or house name.',
      },
      format: {
        regex: RULES.flatNumber,
        message: 'Please enter a valid flat number.',
      },
    },
    value: '',
  },
  currentHouseName: {
    rules: {
      title: 'currentHouseName',
      stop: true,
      format: {
        regex: RULES.houseName,
        message: 'Please enter a valid house name.',
      },
    },
    value: '',
  },
  currentHouseNumber: {
    rules: {
      title: 'currentHouseNumber',
      stop: true,
      format: {
        regex: RULES.houseNumber,
        message: 'Please enter a valid house number.',
      },
    },
    value: '',
  },
  currentStreet: {
    rules: {
      title: 'currentStreet',
      stop: true,
      required: {
        message: 'Please enter a street name.',
      },
      format: {
        regex: RULES.street,
        message: 'Please enter a valid street name.',
      },
    },
    value: '',
  },
  currentVillage: {
    rules: {
      title: 'currentVillage',
      stop: true,
      format: {
        regex: RULES.village,
        message: 'Please enter a valid village or district.',
      },
    },
    value: '',
  },
  currentTown: {
    rules: {
      title: 'currentTown',
      stop: true,
      required: {
        message: 'Please enter a town or city.',
      },
      format: {
        regex: RULES.town,
        message: 'Please enter a valid town or city.',
      },
    },
    value: '',
  },
  currentCounty: {
    rules: {
      title: 'currentCounty',
      stop: true,
      format: {
        regex: RULES.county,
        message: 'Please enter a valid county.',
      },
    },
    value: '',
  },
  currentPostcode: {
    rules: {
      title: 'currentPostcode',
      stop: true,
      required: {
        message: 'Please enter a postcode.',
      },
      format: {
        regex: RULES.postcode,
        message: 'Please enter a valid postcode.',
      },
    },
    value: '',
  },
  // currentHomeowner: {
  //   rules: {
  //     title: 'currentHomeowner',
  //     stop: true,
  //     required: {
  //       message: 'Please tell us if you are the homeowner.',
  //     },
  //   },
  //   value: '',
  // },

  currentAddressLivingSince: {
    rules: {
      title: 'currentAddressLivingSince',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};

export const lookupForm = {
  addressInput: {
    rules: {
      title: 'addressInput',
      stop: true,
      required: {
        message: 'Please select an address.',
      },
    },
    value: '',
  },
  // currentHomeowner: {
  //   rules: {
  //     title: 'currentHomeowner',
  //     stop: true,
  //     required: {
  //       message: 'Please tell us if you are the homeowner.',
  //     },
  //   },
  //   value: '',
  // },
  currentAddressLivingSince: {
    rules: {
      title: 'currentAddressLivingSince',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};

export const lookupFormPrevious1 = {
  addressInput: {
    rules: {
      title: 'addressInput',
      stop: true,
      required: {
        message: 'Please select an address.',
      },
    },
    value: '',
  },
  // previousHomeownerform1: {
  //   rules: {
  //     title: 'previousHomeownerform1',
  //     stop: true,
  //     required: {
  //       message: 'Please tell us if you were the homeowner.',
  //     },
  //   },
  //   value: '',
  // },

  previousAddressLivingSinceform1: {
    rules: {
      title: 'previousAddressLivingSinceform1',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};

export const lookupFormPrevious2 = {
  addressInput: {
    rules: {
      title: 'addressInput',
      stop: true,
      required: {
        message: 'Please select an address.',
      },
    },
    value: '',
  },
  // previousHomeownerform2: {
  //   rules: {
  //     title: 'previousHomeownerform2',
  //     stop: true,
  //     required: {
  //       message: 'Please tell us if you were the homeowner.',
  //     },
  //   },
  //   value: '',
  // },

  previousAddressLivingSinceform2: {
    rules: {
      title: 'previousAddressLivingSinceform2',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};

export const PreviousAddressForm1 = {
  previousUKAddressform1: {
    rules: {
      required: {
        message: `Please tell us if this is a UK address.`,
      },
    },
  },
  previousFlatNumberform1: {
    rules: {
      title: 'previousFlatNumberform1',
      stop: true,
      required: {
        message: 'Please enter a flat number, house number or house name.',
      },
      format: {
        regex: RULES.flatNumber,
        message: 'Please enter a valid flat number.',
      },
    },
    value: '',
  },
  previousHouseNameform1: {
    rules: {
      title: 'previousHouseNameform1',
      stop: true,
      format: {
        regex: RULES.houseName,
        message: 'Please enter a valid house name.',
      },
    },
    value: '',
  },
  previousHouseNumberform1: {
    rules: {
      title: 'previousHouseNumberform1',
      stop: true,
      format: {
        regex: RULES.houseNumber,
        message: 'Please enter a valid house number.',
      },
    },
    value: '',
  },
  previousStreetform1: {
    rules: {
      title: 'previousStreetform1',
      stop: true,
      required: {
        message: 'Please enter a street name.',
      },
      format: {
        regex: RULES.street,
        message: 'Please enter a valid street name.',
      },
    },
    value: '',
  },
  previousVillageform1: {
    rules: {
      title: 'previousVillageform1',
      stop: true,
      format: {
        regex: RULES.village,
        message: 'Please enter a valid village or district.',
      },
    },
    value: '',
  },
  previousTownform1: {
    rules: {
      title: 'previousTownform1',
      stop: true,
      required: {
        message: 'Please enter a town or city.',
      },
      format: {
        regex: RULES.town,
        message: 'Please enter a valid town or city.',
      },
    },
    value: '',
  },
  previousCountyform1: {
    rules: {
      title: 'previousCountyform1',
      stop: true,
      format: {
        regex: RULES.county,
        message: 'Please enter a valid county.',
      },
    },
    value: '',
  },
  previousPostcodeform1: {
    rules: {
      title: 'previousPostcodeform1',
      stop: true,
    },
    value: '',
  },

  previousCountryform1: {
    rules: {
      title: 'previousCountryform1',
      stop: true,
    },
    value: '',
  },

  previousPostZipcodeform1: {
    rules: {
      title: 'previousPostZipcodeform1',
      stop: true,
    },
    value: '',
  },
  // previousHomeownerform1: {
  //   rules: {
  //     title: 'previousHomeownerform1',
  //     stop: true,
  //     required: {
  //       message: 'Please tell us if you were the homeowner.',
  //     },
  //   },
  //   value: '',
  // },
  previousAddressLivingSinceform1: {
    rules: {
      title: 'previousAddressLivingSinceform1',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};

export const PreviousAddressForm2 = {
  previousUKAddressform2: {
    rules: {
      required: {
        message: `Please tell us if this is a UK address.`,
      },
    },
  },
  previousFlatNumberform2: {
    rules: {
      title: 'previousFlatNumberform2',
      stop: true,
      required: {
        message: 'Please enter a flat number, house number or house name.',
      },
      format: {
        regex: RULES.flatNumber,
        message: 'Please enter a valid flat number.',
      },
    },
    value: '',
  },
  previousHouseNameform2: {
    rules: {
      title: 'previousHouseNameform2',
      stop: true,
      format: {
        regex: RULES.houseName,
        message: 'Please enter a valid house name.',
      },
    },
    value: '',
  },
  previousHouseNumberform2: {
    rules: {
      title: 'previousHouseNumberform2',
      stop: true,
      format: {
        regex: RULES.houseNumber,
        message: 'Please enter a valid house number.',
      },
    },
    value: '',
  },
  previousStreetform2: {
    rules: {
      title: 'previousStreetform2',
      stop: true,
      required: {
        message: 'Please enter a street name.',
      },
      format: {
        regex: RULES.street,
        message: 'Please enter a valid street name.',
      },
    },
    value: '',
  },
  previousVillageform2: {
    rules: {
      title: 'previousVillageform2',
      stop: true,
      format: {
        regex: RULES.village,
        message: 'Please enter a valid village or district.',
      },
    },
    value: '',
  },
  previousTownform2: {
    rules: {
      title: 'previousTownform2',
      stop: true,
      required: {
        message: 'Please enter a town or city.',
      },
      format: {
        regex: RULES.town,
        message: 'Please enter a valid town or city.',
      },
    },
    value: '',
  },
  previousCountyform2: {
    rules: {
      title: 'previousCountyform2',
      stop: true,
      format: {
        regex: RULES.county,
        message: 'Please enter a valid county.',
      },
    },
    value: '',
  },
  previousPostcodeform2: {
    rules: {
      title: 'previousPostcodeform2',
      stop: true,
    },
    value: '',
  },

  previousCountryform2: {
    rules: {
      title: 'previousCountryform2',
      stop: true,
    },
    value: '',
  },

  previousPostZipcodeform2: {
    rules: {
      title: 'previousPostZipcodeform2',
      stop: true,
    },
    value: '',
  },

  previousAddressLivingSinceform2: {
    rules: {
      title: 'previousAddressLivingSinceform2',
      stop: true,

      required: {
        message: 'Please tell us the date you started living at this address.',
      },
      pastFormatLiving: {
        message: 'Please select a date in the past.',
      },
      format: {
        regex: RULES.dateNotBefore1900,
        message: 'Please enter a valid date.',
      },
    },
    value: '',
  },
};
