import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import {
  Content,
  Markdown,
  Check,
  FormErrorList,
  FormattedCurrency,
  FormattedDate,
  CONSTANTS,
} from '@myie/interact-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AccountDescription from './page-components/AccountDescription';
import ContactFooter from './page-components/ContactFooter';
import { CONTACTS } from '@myie/interact-local-dom';
import { Validate } from '@myie/interact';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.interestFrequencyMapper = {
      MO: 'Monthly',
      YE: 'Yearly',
    };
    this.state = this.initialState(props);
  }

  componentDidUpdate() {
    this.submitFormAfterDowntimeCheck();
  }

  submitFormAfterDowntimeCheck() {
    const {
      onSubmit,
      setStage,
      systemDowntime,
      nextStep,
      resetNextStepFormSubmit,
    } = this.props;

    if (
      systemDowntime &&
      systemDowntime.CurrentDowntimeStatus &&
      systemDowntime.CurrentDowntimeStatus !==
        CONSTANTS.SYSTEM_DOWNTIME_STATUS_ONGOING &&
      nextStep &&
      nextStep === CONSTANTS.NEXT_STEP_FORM_SUBMIT
    ) {
      resetNextStepFormSubmit();

      setStage('reviewAndSubmit');
      if (typeof onSubmit === 'function') {
        onSubmit();
      }
    }
  }

  initialState = props => {
    const { formData } = props;
    let data = null;
    if ('reviewAndSubmit' in formData) {
      data = formData['reviewAndSubmit'];
    }
    return {
      data: data ? data : {},
      form: {
        accuracyConfirm: {
          rules: {
            required: {
              message:
                'Please confirm that the information you have provided is accurate.',
            },
          },
          value: data ? !!data.accuracyConfirm : false,
        },
      },
    };
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { isSystemReadOnly } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    isSystemReadOnly(CONSTANTS.NEXT_STEP_FORM_SUBMIT);
  };

  dropdownOptionValue(item, value) {
    let keys = Object.keys(item);
    if (keys[0] === value) {
      return item[keys[0]];
    }
  }

  onChange = e => {
    const { name, checked, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, checked ? value : null, form);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  saveForLater = () => {
    const { setStage, updateUrl, formData } = this.props;
    let { form, data } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: {
        ...formData,
        reviewAndSubmit: data,
        stage: 'reviewAndSubmit',
      },
      url: {
        pageStage: 'saveForLater',
      },
    };

    updateUrl(stateData);
    setStage('saveForLater');
  };

  submit = () => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
  };

  backToPrevious = stage => {
    const { updateUrl } = this.props;
    const formData =
      this.props.stateData && this.props.stateData.formData
        ? this.props.stateData.formData
        : {};
    if (formData) {
      if (stage in formData) {
        let stateData = {
          formData: {
            ...formData,
          },
          url: {
            pageStage: stage,
          },
        };
        updateUrl(stateData);
        this.setState({ ...this.state, stage: stage });
      }
    }
  };

  extractTitle = titleCode => {
    let title = titleCode;

    // Find the title array entry with the given code
    let titleObject = CONSTANTS.TITLES.find(
      t => Object.keys(t)[0] === titleCode,
    );

    // Extract title text
    if (titleObject) {
      title = titleObject[Object.keys(titleObject)[0]];
    }
    return title;
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  setStage = stage => {
    const { setStage } = this.props;
    setStage(stage);
  };

  render() {
    const { form } = this.state;
    const { formData, selectedProduct, isFetching } = this.props;
    let personalDetails = null;
    let address1 = null;
    let address2 = null;
    let address3 = null;
    let addressDetails = null;
    let yourFinances = null;
    let accountPreferences = null;
    let lookupAddress = null;
    let lookupFirstPrevAddress = null;
    let lookupSecondPrevAddress = null;
    if ('personalDetails' in formData) {
      personalDetails = formData['personalDetails'];
    }
    if ('addressDetails' in formData) {
      addressDetails = formData['addressDetails'];
      if (addressDetails.form) {
        address1 = formData['addressDetails'].form;
      }
      if (!_.isEmpty(addressDetails.form1)) {
        address2 = formData['addressDetails'].form1;
      }
      if (!_.isEmpty(addressDetails.form2)) {
        address3 = formData['addressDetails'].form2;
      }
      if (addressDetails.lookupForm) {
        lookupAddress = formData['addressDetails'].lookupForm;
      }
      if (
        addressDetails.lookupFormPrevious1 &&
        !_.isEmpty(addressDetails.lookupFormPrevious1)
      ) {
        lookupFirstPrevAddress = formData['addressDetails'].lookupFormPrevious1;
      }
      if (
        addressDetails.lookupFormPrevious2 &&
        !_.isEmpty(addressDetails.lookupFormPrevious2)
      ) {
        lookupSecondPrevAddress =
          formData['addressDetails'].lookupFormPrevious2;
      }
    }
    if ('yourFinances' in formData) {
      yourFinances = formData['yourFinances'];
    }
    if ('accountPreferences' in formData) {
      accountPreferences = formData['accountPreferences'];
    }

    return (
      <form onSubmit={this.onSubmitHandler} id="apply-form">
        <Content
          tag="h2"
          tagClassName="breadcrumb-title step-5"
          cmsTag="BREADCRUMB:Review-and-submit"
          copytext="Review and submit"
        />
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <AccountDescription selectedProduct={selectedProduct} />
        <div className="review-sections-container">
          <section className="summary-section">
            <div className="summary-section-title-container">
              <Content
                tag="h3"
                cmsTag="BREADCRUMB:Personal-details"
                copytext="Personal details"
              />
              <Button
                className="edit-section-button"
                size="sm"
                color="secondary"
                onClick={() => this.setStage('personalDetails')}
              >
                <Content cmsTag="GLOBAL:Edit" copytext="Edit" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Personal-details"
                  copytext="Personal details"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-section"
                  copytext=" section"
                />
              </Button>
            </div>
            <div className="d-md-flex clear-both">
              <div className="summary-column">
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Basic-details"
                  copytext="Basic details"
                />
                <Row tag="dl" title="Basic details">
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content cmsTag="GLOBAL:Name" copytext="Name" />
                  </Col>
                  <Col
                    tag="dd"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    className="text-capitalize"
                  >
                    {personalDetails
                      ? `${this.extractTitle(personalDetails.title)} 
                      ${personalDetails.firstName}`
                      : ''}
                    {personalDetails?.middleName
                      ? ` ${personalDetails.middleName}`
                      : ''}
                    {personalDetails ? ` ${personalDetails.lastName}` : ''}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content cmsTag="GLOBAL:Gender" copytext="Gender" />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails ? (
                      personalDetails.gender === 'male' ? (
                        <Content cmsTag="GLOBAL:Male" copytext="Male" />
                      ) : (
                        <Content cmsTag="GLOBAL:Female" copytext="Female" />
                      )
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Date-of-birth"
                      copytext="Date of birth"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails ? (
                      <FormattedDate
                        format="Do MMMM YYYY"
                        date={personalDetails.b_day}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Marital-status"
                      copytext="Marital status"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails
                      ? CONSTANTS.MARITAL_STATUS_CODE.map(item => {
                          return this.dropdownOptionValue(
                            item,
                            personalDetails.maritalStatus,
                          );
                        })
                      : ''}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Nationality"
                      copytext="Nationality"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails
                      ? CONSTANTS.NATIONALITY.map(item => {
                          return this.dropdownOptionValue(
                            item,
                            personalDetails.nationality,
                          );
                        })
                      : ''}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:National-Insurance-Number"
                      copytext="National Insurance Number"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails
                      ? `${personalDetails.nationalInsuranceNumber} `
                      : ''}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Are-you-a-UK-resident?"
                      copytext="Are you a UK resident?"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails?.confirmUK ? 'Yes' : 'No'}
                  </Col>
                </Row>
              </div>
              <div className="summary-column">
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Contact-details"
                  copytext="Contact details"
                />
                <Row tag="dl" title="Contact details">
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Mobile-number"
                      copytext="Mobile number"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails
                      ? `${personalDetails.mobilePhoneNumber} `
                      : ''}
                  </Col>
                  {personalDetails?.daytimePhoneNumber ? (
                    <React.Fragment>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Daytime-phone-number"
                          copytext="Daytime phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {personalDetails
                          ? `${personalDetails.daytimePhoneNumber} `
                          : ''}
                      </Col>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {personalDetails?.eveningPhoneNumber ? (
                    <React.Fragment>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Evening-phone-number"
                          copytext="Evening phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {personalDetails
                          ? `${personalDetails.eveningPhoneNumber} `
                          : ''}
                      </Col>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:Email-address"
                      copytext="Email address"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {personalDetails ? `${personalDetails.emailAddress} ` : ''}
                  </Col>
                </Row>
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Contact-preferences"
                  copytext="Contact preferences"
                />
                <Content
                  tag="p"
                  tagID="contact-agree-title"
                  cmsTag="Long-apply:Apply-pages:Confirmation:p1"
                  markdown="You have agreed that we can contact you by:"
                />
                <ul aria-labelledby="contact-agree-title">
                  {personalDetails?.contactPreferences ===
                  CONSTANTS.CONTACT_PREFERENCE_EMAIL ? (
                    <li>
                      <Content cmsTag="GLOBAL:Email" copytext="Email" />
                    </li>
                  ) : (
                    <li>
                      <Content cmsTag="GLOBAL:Post" copytext="Post" />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </section>
          <section className="summary-section">
            <div className="summary-section-title-container">
              <Content
                tag="h3"
                cmsTag="BREADCRUMB:Address-details"
                copytext="Address details"
              />
              <Button
                className="edit-section-button"
                size="sm"
                color="secondary"
                onClick={() => this.setStage('addressDetails')}
              >
                <Content cmsTag="GLOBAL:Edit" copytext="Edit" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Address-details"
                  copytext="Address details"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-section"
                  copytext=" section"
                />
              </Button>
            </div>
            <div className="d-md-flex clear-both">
              <div className="summary-column">
                {lookupAddress?.addressInput ? (
                  <React.Fragment>
                    <Content
                      tag="h4"
                      cmsTag="GLOBAL:Current-address"
                      copytext={`Current address`}
                    />
                    <address>
                      {Object.keys(lookupAddress.addressInput).map(
                        (keyname, index) => {
                          if (
                            lookupAddress.addressInput[keyname] &&
                            lookupAddress.addressInput[keyname] !== ''
                          ) {
                            return (
                              <p className={'mb-0'} key={keyname + '-' + index}>
                                {lookupAddress.addressInput[keyname]}
                              </p>
                            );
                          }
                        },
                      )}
                    </address>
                    <Row tag="dl" title="Current address details">
                      <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                        <Content
                          msTag="LABEL:Start-date"
                          copytext={`Start date`}
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                        <FormattedDate
                          format="Do MMMM YYYY"
                          date={lookupAddress.currentAddressLivingSince}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {address1 && (
                      <React.Fragment>
                        <Content
                          tag="h4"
                          cmsTag="GLOBAL:Current-address"
                          copytext={`Current address`}
                        />
                        <address>
                          {address1.currentFlatNumber ? (
                            <>
                              {address1.currentFlatNumber}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address1.currentHouseName ? (
                            <>
                              {`${address1.currentHouseName}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address1?.currentHouseNumber
                            ? `${address1.currentHouseNumber}`
                            : ''}{' '}
                          {address1?.currentStreet
                            ? `${address1.currentStreet}`
                            : ''}
                          <br />
                          {address1.currentVillage ? (
                            <>
                              {`${address1.currentVillage}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address1?.currentTown
                            ? `${address1.currentTown}`
                            : ''}
                          <br />
                          {address1?.currentCounty
                            ? `${address1.currentCounty}`
                            : ''}
                          <br />
                          {address1.currentPostcode
                            ? `${address1.currentPostcode}`
                            : ''}
                        </address>
                        <Row tag="dl" title="Current address details">
                          {/* <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                        <Content
                          cmsTag="LABEL:Are-you-the-homeowner?"
                          copytext={`Are you the homeowner?`}
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                        {`${
                          address1.currentHomeowner === 'yes' ? 'Yes' : 'No'
                        }`}
                      </Col> */}
                          <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                            <Content
                              msTag="LABEL:Start-date"
                              copytext={`Start date`}
                            />
                          </Col>
                          <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                            <FormattedDate
                              format="Do MMMM YYYY"
                              date={address1.currentAddressLivingSince}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {lookupFirstPrevAddress?.addressInput ? (
                  <React.Fragment>
                    <Content
                      tag="h4"
                      cmsTag="GLOBAL:First-previous-address"
                      copytext={`First previous address`}
                    />
                    <address>
                      {lookupFirstPrevAddress &&
                        Object.keys(lookupFirstPrevAddress.addressInput).map(
                          (keyname, index) => {
                            if (
                              lookupFirstPrevAddress.addressInput[keyname] &&
                              lookupFirstPrevAddress.addressInput[keyname] !==
                                ''
                            ) {
                              return (
                                <p
                                  className={'mb-0'}
                                  key={keyname + '-' + index}
                                >
                                  {lookupFirstPrevAddress.addressInput[keyname]}
                                </p>
                              );
                            }
                          },
                        )}
                    </address>
                    <Row tag="dl" title="First previous address details">
                      <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                        <Content
                          msTag="LABEL:Start-date"
                          copytext={`Start date`}
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                        <FormattedDate
                          date={
                            lookupFirstPrevAddress.previousAddressLivingSinceform1
                          }
                          format="Do MMMM YYYY"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {address2 && (
                      <React.Fragment>
                        <Content
                          tag="h4"
                          cmsTag="GLOBAL:First-previous-address"
                          copytext={`First previous address`}
                        />
                        <address>
                          {address2.previousFlatNumberform1 ? (
                            <>
                              {`${address2.previousFlatNumberform1}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address2.previousHouseNameform1 ? (
                            <>
                              {`${address2.previousHouseNameform1}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address2.previousHouseNumberform1
                            ? `${address2.previousHouseNumberform1}`
                            : ''}{' '}
                          {address2.previousStreetform1
                            ? `${address2.previousStreetform1}`
                            : ''}
                          <br />
                          {address2.previousVillageform1 ? (
                            <>
                              {`${address2.previousVillageform1}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address2.previousTownform1
                            ? `${address2.previousTownform1}`
                            : ''}
                          <br />
                          {address2.previousCountyform1
                            ? `${address2.previousCountyform1}`
                            : ''}
                          <br />
                          {address2.previousUKAddressform1 === 'no' ? (
                            <>
                              {CONSTANTS.COUNTRYLIST.map(item => {
                                return item[address2.previousCountryform1];
                              })}
                              <br />
                              {address2.previousPostZipcodeform1
                                ? `${address2.previousPostZipcodeform1}`
                                : ''}
                            </>
                          ) : address2.previousPostcodeform1 ? (
                            `${address2.previousPostcodeform1}`
                          ) : (
                            ''
                          )}
                        </address>
                        <Row tag="dl" title="First previous address details">
                          {/* <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                        <Content
                          cmsTag="LABEL:Were-you-the-homeowner"
                          copytext={`Were you the homeowner?`}
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                        {`${
                          address2.previousHomeownerform1 === 'yes'
                            ? 'Yes'
                            : 'No'
                        }`}
                      </Col> */}
                          <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                            <Content
                              msTag="LABEL:Start-date"
                              copytext={`Start date`}
                            />
                          </Col>
                          <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                            <FormattedDate
                              date={address2.previousAddressLivingSinceform1}
                              format="Do MMMM YYYY"
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {lookupSecondPrevAddress?.addressInput ? (
                  <React.Fragment>
                    <Content
                      tag="h4"
                      cmsTag="GLOBAL:Second-previous-address"
                      copytext={`Second previous address`}
                    />
                    <address>
                      {lookupSecondPrevAddress &&
                        Object.keys(lookupSecondPrevAddress.addressInput).map(
                          (keyname, index) => {
                            if (
                              lookupSecondPrevAddress.addressInput[keyname] &&
                              lookupSecondPrevAddress.addressInput[keyname] !==
                                ''
                            ) {
                              return (
                                <p
                                  className={'mb-0'}
                                  key={keyname + '-' + index}
                                >
                                  {
                                    lookupSecondPrevAddress.addressInput[
                                      keyname
                                    ]
                                  }
                                </p>
                              );
                            }
                          },
                        )}
                    </address>
                    <Row tag="dl" title="Second previous address details">
                      <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                        <Content
                          msTag="LABEL:Start-date"
                          copytext={`Start date`}
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                        <FormattedDate
                          date={
                            lookupSecondPrevAddress.previousAddressLivingSinceform2
                          }
                          format="Do MMMM YYYY"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {address3 && (
                      <React.Fragment>
                        <Content
                          tag="h4"
                          cmsTag="GLOBAL:Second-previous-address"
                          copytext={`Second previous address`}
                        />
                        <address>
                          {address3.previousFlatNumberform2 ? (
                            <>
                              {`${address3.previousFlatNumberform2}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address3.previousHouseNameform2 ? (
                            <>
                              {`${address3.previousHouseNameform2}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address3.previousHouseNumberform2
                            ? `${address3.previousHouseNumberform2}`
                            : ''}{' '}
                          {address3.previousStreetform2
                            ? `${address3.previousStreetform2}`
                            : ''}
                          <br />
                          {address3.previousVillageform2 ? (
                            <>
                              {`${address3.previousVillageform2}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {address3.previousTownform2
                            ? `${address3.previousTownform2}`
                            : ''}
                          <br />
                          {address3.previousCountyform2
                            ? `${address3.previousCountyform2}`
                            : ''}
                          <br />
                          {address3.previousUKAddressform2 === 'no' ? (
                            <>
                              {CONSTANTS.COUNTRYLIST.map(item => {
                                return item[address3.previousCountryform2];
                              })}
                              <br />
                              {address3.previousPostZipcodeform2
                                ? `${address3.previousPostZipcodeform2}`
                                : ''}
                            </>
                          ) : address3.previousPostcodeform2 ? (
                            `${address3.previousPostcodeform2}`
                          ) : (
                            ''
                          )}
                        </address>
                        <Row tag="dl" title="Second previous address details">
                          {/* <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                          <Content
                            cmsTag="LABEL:Were-you-the-homeowner"
                            copytext={`Were you the homeowner?`}
                          />
                        </Col>
                        <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                          {`${
                            address3.previousHomeownerform2 === 'yes'
                              ? 'Yes'
                              : 'No'
                          }`}
                        </Col> */}
                          <Col tag="dt" xs="12" sm="6" md="4" lg="4" xl="4">
                            <Content
                              msTag="LABEL:Start-date"
                              copytext={`Start date`}
                            />
                          </Col>
                          <Col tag="dd" xs="12" sm="6" md="8" lg="8" xl="8">
                            <FormattedDate
                              date={address3.previousAddressLivingSinceform2}
                              format="Do MMMM YYYY"
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
          <section className="summary-section">
            <div className="summary-section-title-container">
              <Content
                tag="h3"
                cmsTag="BREADCRUMB:Your-finances"
                copytext="Your finances"
              />
              <Button
                className="edit-section-button"
                size="sm"
                color="secondary"
                onClick={() => this.setStage('yourFinances')}
              >
                <Content cmsTag="GLOBAL:Edit" copytext="Edit" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Your-finances"
                  copytext="Your finances"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-section"
                  copytext=" section"
                />
              </Button>
            </div>
            <div className="d-md-flex clear-both">
              <div className="summary-column">
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Tax-residency"
                  copytext="Tax residency"
                />
                {yourFinances?.USCitizen === 'no' &&
                yourFinances?.OtherTaxResident === 'no' ? (
                  <Content
                    tag="p"
                    cmsTag="Long-apply:Apply-pages:Confirmation:Your-finances:Tax-residency:No-Resident:p1"
                    copytext="You have confirmed you are not a tax resident in any other country."
                  />
                ) : (
                  <Content
                    tag="p"
                    cmsTag="Long-apply:Apply-pages:Confirmation:Your-finances:Tax-residency:Yes-Resident:p1"
                    copytext="You have confirmed you are a tax resident in other countries:"
                  />
                )}
                {yourFinances?.TaxCountry1 ||
                yourFinances?.TaxCountry2 ||
                yourFinances?.TaxCountry3 ||
                yourFinances?.TaxCountry4 ? (
                  <Row tag="dl" title="List of tax countries" className="mt-3">
                    {yourFinances?.TaxCountry1 ? (
                      <React.Fragment>
                        <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                          {CONSTANTS.COUNTRYLIST.map(item => {
                            return this.dropdownOptionValue(
                              item,
                              yourFinances.TaxCountry1,
                            );
                          })}
                          <Content
                            cmsTag="GLOBAL:-Tax-Identification-Number"
                            copytext=" Tax Identification Number"
                          />
                        </Col>
                        <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                          {yourFinances.TaxIdentification1}
                        </Col>
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    {yourFinances?.TaxCountry2 ? (
                      <React.Fragment>
                        <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                          {CONSTANTS.COUNTRYLIST.map(item => {
                            return this.dropdownOptionValue(
                              item,
                              yourFinances.TaxCountry2,
                            );
                          })}
                          <Content
                            cmsTag="GLOBAL:-Tax-Identification-Number"
                            copytext=" Tax Identification Number"
                          />
                        </Col>
                        <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                          {yourFinances.TaxIdentification2}
                        </Col>
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    {yourFinances?.TaxCountry3 ? (
                      <React.Fragment>
                        <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                          {CONSTANTS.COUNTRYLIST.map(item => {
                            return this.dropdownOptionValue(
                              item,
                              yourFinances.TaxCountry3,
                            );
                          })}
                          <Content
                            cmsTag="GLOBAL:-Tax-Identification-Number"
                            copytext=" Tax Identification Number"
                          />
                        </Col>
                        <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                          {yourFinances.TaxIdentification3}
                        </Col>
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    {yourFinances?.TaxCountry4 ? (
                      <React.Fragment>
                        <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                          {CONSTANTS.COUNTRYLIST.map(item => {
                            return this.dropdownOptionValue(
                              item,
                              yourFinances.TaxCountry4,
                            );
                          })}
                          <Content
                            cmsTag="GLOBAL:-Tax-Identification-Number"
                            copytext=" Tax Identification Number"
                          />
                        </Col>
                        <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                          {yourFinances.TaxIdentification4}
                        </Col>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </Row>
                ) : (
                  ''
                )}
              </div>
            </div>
          </section>
          <section className="summary-section">
            <div className="summary-section-title-container">
              <Content
                tag="h3"
                cmsTag="BREADCRUMB:Account-preferences"
                copytext="Account preferences"
              />
              <Button
                className="edit-section-button"
                size="sm"
                color="secondary"
                onClick={() => this.setStage('accountPreferences')}
              >
                <Content cmsTag="GLOBAL:Edit" copytext="Edit" />{' '}
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Account-preferences"
                  copytext="Account preferences"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-section"
                  copytext=" section"
                />
              </Button>
            </div>
            <div className="d-md-flex clear-both">
              <div className="summary-column">
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Deposit-details"
                  copytext="Deposit details"
                />
                <Row tag="dl" title="Deposit details">
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:How-will-you-be-making-your-initial-deposit"
                      copytext="How will you be making your initial deposit?"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {accountPreferences
                      ? CONSTANTS.DEPOSIT_METHOD.map(item => {
                          return this.dropdownOptionValue(
                            item,
                            accountPreferences.depositMethod,
                          );
                        })
                      : ''}
                  </Col>
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:How-much-will-this-initial-deposit-be"
                      copytext="How much will this initial deposit be?"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {accountPreferences ? (
                      <FormattedCurrency
                        quantity={accountPreferences.depositAmount.replace(
                          /,/g,
                          '',
                        )}
                        currency={'GBP'}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Content
                  tag="h4"
                  cmsTag="GLOBAL:Interest-payments"
                  copytext="Interest payments"
                />
                <Markdown
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                    },
                  }}
                  cmsTag="Long-apply:Apply-pages:Account-preferences:p1"
                  markdown={`\nYour interest will automatically be paid into this account. If you want your interest to be paid elsewhere, check the terms and conditions of the account and contact our Savings Support Team on <a target="_blank" href="$[phoneLink]">$[phone]</a>.`}
                />
                <Row tag="dl" title="Interest payment details">
                  {/* <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="LABEL:Where-shall-we-pay-the-interest-to"
                      copytext="Where shall we pay the interest to?"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {accountPreferences
                      ? CONSTANTS.INTEREST_DESTINATION.map(item => {
                          return this.dropdownOptionValue(
                            item,
                            accountPreferences.interestPaymentLocation,
                          );
                        })
                      : ''}
                  </Col> */}
                  <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                    <Content
                      cmsTag="GLOBAL:How-often-should-we-make-interest-payments"
                      copytext="How often should we make interest payments?"
                    />
                  </Col>
                  <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                    {selectedProduct &&
                    selectedProduct.Interest_Frequency &&
                    selectedProduct.Interest_Frequency.toLowerCase() !== 'both'
                      ? selectedProduct.Interest_Frequency
                      : accountPreferences
                      ? this.interestFrequencyMapper[
                          accountPreferences.interestPaymentFrequency
                        ]
                      : ''}
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Confirmation:Summary:p1"
          copytext='Please make sure you review the information you have entered. You can click on "Edit" to change any of these details. When you are sure that the information you have entered is correct, please submit your application.'
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Confirmation:Summary:p2"
          copytext="You can save your information and submit it at a later time. You must return to a saved application within 30 days."
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Confirmation:Summary:p3"
          copytext="Please note that your application will not be considered until it has been submitted."
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Confirmation:Summary:p4"
          copytext="If we are unable to verify your identity we may need more documentation before we can proceed with the opening of your new Savings Account. This can be a Passport, Driving License or Identity card. Please submit the documentation before closing the browser. You will be unable to return to this application once the browser is closed."
        />
        <Check
          cmsTag="GLOBAL:I-confirm-the-details-I-have-given-are-accurate"
          label={`I confirm the details I have given are accurate.`}
          id={`accuracyConfirm`}
          field="accuracyConfirm"
          checked={!!form.accuracyConfirm.value}
          //value="true"
          validation={form.accuracyConfirm}
          onChange={this.onChange}
        />
        <div className="form-button-group apply-form-buttons">
          <Button
            id="continue-to-next-step"
            type="submit"
            color="primary"
            disabled={isFetching}
          >
            <Content
              cmsTag="Long-apply:Apply-pages:Confirmation:Submit-application"
              copytext="Submit application"
            />
          </Button>
          <Button
            id="back-to-previous-step"
            onClick={() => this.setStage('accountPreferences')}
            disabled={isFetching}
            color="secondary"
          >
            <Content
              cmsTag="Long-apply:Apply-pages:Confirmation:Back-to-step-4"
              copytext="Back to step 4"
            />
          </Button>
          <Button
            id="save-for-later"
            type="button"
            color="light"
            disabled={isFetching}
            onClick={this.saveForLater}
          >
            <Content cmsTag="GLOBAL:Save-for-later" copytext="Save for later" />
          </Button>
          <ContactFooter />
        </div>
      </form>
    );
  }
}

Confirmation.propTypes = {
  account: PropTypes.any,
  setStage: PropTypes.any,
  isFetching: PropTypes.bool,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.object,
  selectedProduct: PropTypes.object,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  updateUrl: PropTypes.func,
  onSubmit: PropTypes.func,
  stateData: PropTypes.any,
  isSystemReadOnly: PropTypes.func,
  systemDowntime: PropTypes.object,
  nextStep: PropTypes.string,
  resetNextStepFormSubmit: PropTypes.func,
};

export default Confirmation;
