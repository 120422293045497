import Styleguide from './Styleguide';
import Routes from './Routes';
import StyleguideSidebars from './Sidebars';
/*import Colours from './Colours';
import Logo from './Logo';
import Fonts from './Fonts';
import Typography from './Typography';
import Lists from './Lists';
import Badges from './Badges';
import Buttons from './Buttons';
import Breadcrumbs from './Breadcrumbs';
import Pagination from './Pagination';
import Alerts from './Alerts';
import ProgressBars from './ProgressBars';
import Tabs from './Tabs';
import Tables from './Tables';
import Inputs from './Inputs';
import DateInputs from './DateInputs';
import RadiosCheckboxes from './RadiosCheckboxes';
import Carousels from './Carousels';
import Modals from './Modals';
import Panels from './Panels';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';
import AccountCard from './AccountCard';
import FAQ from './FAQ';
import InternalLinks from './InternalLinks';
import NPS from './NPS';
import Messages from './Messages';
Colours,
  Logo,
  Fonts,
  Typography,
  Lists,
  Badges,
  Buttons,
  Breadcrumbs,
  Pagination,
  Alerts,
  ProgressBars,
  Tabs,
  Tables,
  Inputs,
  DateInputs,
  RadiosCheckboxes,
  Carousels,
  Modals,
  Panels,
  Header,
  Navigation,
  Footer,
  AccountCard,
  FAQ,
  InternalLinks,
  NPS,
  Messages,
*/

export { Styleguide, Routes, StyleguideSidebars };
