import React from 'react';
import PropTypes from 'prop-types';
import { Markdown } from '@myie/interact-dom';

class ContactOpenTimes extends React.Component {
  render() {
    const { props } = this;

    const {
      brackets = false,
      bankHoliday = false,
      longHand = false,
      contacts,
    } = props;

    return (
      <React.Fragment>
        {longHand ? (
          <Markdown
            cmsTag="GLOBAL:Contact-open-times-full-sentence"
            template={{
              markdown: {
                openTimes: contacts.openTimes,
              },
            }}
            markdown={`Our operating hours are $[openTimes].`}
          />
        ) : brackets ? (
          bankHoliday ? (
            <Markdown
              cmsTag="GLOBAL:Contact-open-times-excluding-bank-holidays-with-brackets"
              template={{
                markdown: {
                  openTimes: contacts.openTimes,
                },
              }}
              markdown={`($[openTimes], excluding Bank Holidays)`}
            />
          ) : (
            <Markdown
              cmsTag="GLOBAL:Contact-open-times-with-brackets"
              template={{
                markdown: {
                  openTimes: contacts.openTimes,
                },
              }}
              markdown={`($[openTimes])`}
            />
          )
        ) : bankHoliday ? (
          <Markdown
            cmsTag="GLOBAL:Contact-open-times-excluding-bank-holidays"
            template={{
              markdown: {
                openTimes: contacts.openTimes,
              },
            }}
            markdown={`$[openTimes], excluding Bank Holidays`}
          />
        ) : (
          <Markdown
            cmsTag="GLOBAL:Contact-open-times"
            template={{
              markdown: {
                openTimes: contacts.openTimes,
              },
            }}
            markdown={`$[openTimes]`}
          />
        )}
      </React.Fragment>
    );
  }
}

ContactOpenTimes.propTypes = {
  brackets: PropTypes.bool,
  bankHoliday: PropTypes.bool,
  longHand: PropTypes.bool,
  contacts: PropTypes.object,
};

export default ContactOpenTimes;
