import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppMeta, Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SaveExpired extends Component {
  resume = () => {
    const { updateUrl, formData, resumeApplication } = this.props;
    let stateData = {
      formData: formData,
      url: {
        pageStage: formData.stage,
      },
    };

    updateUrl(stateData);
    resumeApplication(formData.stage);
  };
  setStage = () => {
    const { setStateDynamic, resetResumeApplication } = this.props;
    resetResumeApplication();
    setStateDynamic({ stage: 'ResumeForm' });
  };
  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Application Expired`}
          metaDescription={`Unfortunately your application has expired.`}
        />
        <Markdown
          cmsTag="Long-apply:Save-and-resume:Responses:Expired:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nWe cannot find a saved application with this reference number. This may be because the application has expired and you will need to restart. If you believe that your application has not expired, please check the reference number and try again.\n\n If you have any questions, please call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          <Button
            id="back-to-previous-step"
            onClick={this.setStage}
            color="primary"
          >
            <Content cmsTag="GLOBAL:Try-again" copytext="Try again" />
          </Button>
          <LinkTag
            id="expired-application-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalApplyHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

SaveExpired.propTypes = {
  match: PropTypes.any,
  resetSaveLongApplyForLater: PropTypes.func,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  setStateDynamic: PropTypes.func,
  resetResumeApplication: PropTypes.func,
  resumeApplication: PropTypes.func,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveExpired);
