import React from 'react';
import PropTypes from 'prop-types';
import Colours from './Colours';
import Typography from './Typography';
import Fonts from './Fonts';
import Lists from './components/Lists';
import Badges from './components/Badges';
import Buttons from './components/Buttons';
import Breadcrumbs from './components/Breadcrumbs';
import Pagination from './components/Pagination';
import Alerts from './components/Alerts';
import Tabs from './components/Tabs';
import Tables from './components/Tables';
import Inputs from './components/Inputs';
import Panels from './components/Panels';
import { Content } from '@myie/interact-dom';
import { Label, Input, FormGroup } from 'reactstrap';

class StyleguideIndex extends React.Component {
  constructor(props) {
    super(props);
    const toggleData = [];
    toggleData[1] = true;
    this.state = {
      toggleData,
    };
  }

  toggleDescription(index) {
    let toggleData = this.state;
    toggleData[index] = !toggleData[index];
    if (toggleData.length > 1) {
      toggleData = toggleData.map((toggleValue, arrayIndex) => {
        return arrayIndex !== index ? false : toggleValue;
      });
    }
    this.setState({
      toggleData,
    });
  }

  render() {
    return (
      <div className="styleguide-container">
        <h1>Styleguide</h1>
        <h2>Colours</h2>
        <Colours />
        <h2>Fonts &amp; Typography</h2>
        <Fonts />
        <Typography />
        <h2>Lists</h2>
        <Lists />
        <h2>Badges</h2>
        <Badges />
        <h2>Buttons</h2>
        <Buttons />
        <h2>Breadcrumbs</h2>
        <Breadcrumbs />
        <h2>Pagination</h2>
        <Pagination />
        <h2>Alerts</h2>
        <Alerts />
        <h2>Cards</h2>
        <Panels />
        <h2>Tabs</h2>
        <Tabs />
        <h2>Tables</h2>
        <Tables />
        <h2>Inputs</h2>
        <FormGroup>
          <Label
            id={`file-upload-label`}
            htmlFor={`file-upload`}
            className="custom-file-upload"
          >
            This is a file upload label
            <span aria-hidden="true">*</span>
            <span className="sr-only">
              <Content
                cmsTag="GLOBAL:-required-field"
                copytext=" (required field)"
              />
            </span>
          </Label>
          <Input id={`file-upload`} type="file" name="file" />
        </FormGroup>
        <Inputs />
      </div>
    );
  }
}

StyleguideIndex.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  i18n: PropTypes.any,
  colours: PropTypes.any,
};

export default StyleguideIndex;
