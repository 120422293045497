import {
  RESET_GET_DEPOSIT_PRODUCTS,
  GET_DEPOSIT_PRODUCTS_REQUEST,
  GET_DEPOSIT_PRODUCTS_SUCCESS,
  GET_DEPOSIT_PRODUCTS_FALIURE,
  RESET_SAVE_APPLY_FOR_LATER,
  GET_SAVE_APPLY_FOR_LATER_REQUEST,
  GET_SAVE_APPLY_FOR_LATER_SUCCESS,
  GET_SAVE_APPLY_FOR_LATER_FALIURE,
  RESUME_APPLICATION_REQUEST,
  RESUME_APPLICATION_FALIURE,
  RESUME_APPLICATION_SUCCESS,
  RESET_RESUME_APPLICATION,
  RESUME_APPLICATION_DATA,
  RESET_RESUME_APPLICATION_DATA,
  RESET_SAVE_LONG_APPLY,
  SAVE_LONG_APPLY_REQUEST,
  SAVE_LONG_APPLY_SUCCESS,
  SAVE_LONG_APPLY_FALIURE,
  SAVE_DATA_FOR_SECURITY,
  RESET_GET_DEPOSIT_PRODUCT,
  GET_DEPOSIT_PRODUCT_REQUEST,
  GET_DEPOSIT_PRODUCT_SUCCESS,
  GET_DEPOSIT_PRODUCT_FALIURE,
  SET_RESUME_APPLICATION_MEMORABLE_WORD,
  RESET_RESUME_APPLICATION_MEMORABLE_WORD,
  RESET_GET_APPLICATION_STATUS,
  GET_APPLICATION_STATUS_REQUEST,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FALIURE,
} from '../actions/depositProductsTypes';

const depositProductsDefinition = (state = {}, action) => {
  switch (action.type) {
    case GET_DEPOSIT_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_DEPOSIT_PRODUCT_SUCCESS:
      return {
        ...state,
        depositProduct: action.getDepositProductResponse,
        isFetching: action.isFetching,
      };
    case GET_DEPOSIT_PRODUCT_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_GET_DEPOSIT_PRODUCT:
      return {
        ...state,
        depositProduct: null,
        isFetching: false,
        error: null,
      };

    //
    case GET_DEPOSIT_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_DEPOSIT_PRODUCTS_SUCCESS:
      return {
        ...state,
        allDepositProducts: action.getDepositProductsResponse,
        isFetching: action.isFetching,
      };
    case GET_DEPOSIT_PRODUCTS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_GET_DEPOSIT_PRODUCTS:
      return {
        ...state,
        savingslongapply: null,
        isFetching: false,
        error: null,
      };

    //
    case GET_SAVE_APPLY_FOR_LATER_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_SAVE_APPLY_FOR_LATER_SUCCESS:
      return {
        ...state,
        savedData: action.saveLongApplyForLaterResponse,
        isFetching: action.isFetching,
      };
    case GET_SAVE_APPLY_FOR_LATER_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SAVE_APPLY_FOR_LATER:
      return {
        ...state,
        savedData: null,
        isFetching: false,
        error: null,
      };

    //

    case RESUME_APPLICATION_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case RESUME_APPLICATION_SUCCESS:
      return {
        ...state,
        resume: action.resumeResponse,
        isFetching: action.isFetching,
      };
    case RESUME_APPLICATION_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_RESUME_APPLICATION:
      return {
        ...state,
        resume: null,
        isFetching: false,
        error: null,
      };

    case RESUME_APPLICATION_DATA:
      return {
        ...state,
        resumeData: action.resumeData,
        isFetching: action.isFetching,
      };

    case RESET_RESUME_APPLICATION_DATA:
      return {
        ...state,
        resumeData: null,
        isFetching: false,
        error: null,
      };

    case SET_RESUME_APPLICATION_MEMORABLE_WORD:
      return {
        ...state,
        resumeMemorableWord: action.data,
      };
    case RESET_RESUME_APPLICATION_MEMORABLE_WORD:
      return {
        ...state,
        resumeMemorableWord: null,
      };
    //
    case SAVE_LONG_APPLY_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SAVE_DATA_FOR_SECURITY:
      return {
        ...state,
        savedDataForSecurity: action.savedDataForSecurity,
      };
    case SAVE_LONG_APPLY_SUCCESS:
      return {
        ...state,
        saveLongApplyData: action.saveLongApplyResponse,
        isFetching: action.isFetching,
      };
    case SAVE_LONG_APPLY_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SAVE_LONG_APPLY:
      return {
        ...state,
        saveLongApplyData: null,
        isFetching: false,
        error: null,
      };

    case GET_APPLICATION_STATUS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case GET_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        applicationStatus: action.ApplicationStatusResponse,
        isFetching: action.isFetching,
      };
    case GET_APPLICATION_STATUS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_GET_APPLICATION_STATUS:
      return {
        ...state,
        applicationStatus: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default depositProductsDefinition;
