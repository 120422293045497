import { Connection, TwoFactor } from '@myie/interact';

import {
  UPDATE_SECURITY_DETAILS_REQUEST,
  UPDATE_SECURITY_DETAILS_SUCCESS,
  UPDATE_SECURITY_DETAILS_FAILURE,
  RESET_UPDATE_SECURITY_DETAILS,
} from './updateSecurityDetailsTypes.js';

export const resetUpdateSecurityDetails = () => ({
  type: RESET_UPDATE_SECURITY_DETAILS,
});

export const updateSecurityDetailsRequest = () => ({
  type: UPDATE_SECURITY_DETAILS_REQUEST,
  isFetching: true,
  error: null,
});

export const updateSecurityDetailsFailed = error => ({
  type: UPDATE_SECURITY_DETAILS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const updateSecurityDetailsReceive = json => ({
  type: UPDATE_SECURITY_DETAILS_SUCCESS,
  updateSecurityDetails: json,
  isFetching: false,
  error: null,
});

export const setCredentials = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(updateSecurityDetailsRequest());
    return fetch(
      Connection.baseUrl('webauthentication') + `v2.0/user/credentials/setup`,
      {
        method: 'post',
        headers: Connection.headers(),
        body: JSON.stringify(request),
      },
    )
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(updateSecurityDetailsReceive(json)))
      .catch(error => dispatch(updateSecurityDetailsFailed(error)));
  });
};
