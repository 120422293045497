import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 23.1 13.85"
    enableBackground="new 0 0 23.1 13.85"
    width={width}
    height={height}
  >
    <g id="Education2" transform="translate(-0.5 -5.05)">
      <path
        className={fill}
        d="M22.3,8.1,13,5.2a3.408,3.408,0,0,0-2,0L1.7,8.1a1.667,1.667,0,0,0,0,3.2l.8.3a1.421,1.421,0,0,0-.2.6,1.2,1.2,0,0,0-.7,1,1.05,1.05,0,0,0,.5.9l-.9,4.1a.563.563,0,0,0,.6.7h2c.4,0,.6-.3.6-.7l-.9-4.1a1.06,1.06,0,0,0,0-1.8c0-.1.1-.2.2-.4l2,.6-.5,4.1c0,1.3,3.1,2.3,6.9,2.3s6.9-1,6.9-2.3l-.5-4.1,3.9-1.2a1.712,1.712,0,0,0,1.2-1.6A1.81,1.81,0,0,0,22.3,8.1Z"
      />
      <path
        d="M17.1,16.3c-2.2,1.2-8.1,1.2-10.3,0L7.3,13,11,14.1a3.638,3.638,0,0,0,2.1,0L16.8,13C16.7,13,17.1,16.3,17.1,16.3Z"
        className={fillNegative}
      />
      <path
        d="M12.5,12.5a2.045,2.045,0,0,1-1.1,0L6,10.8l6.1-1.1a.752.752,0,0,0,.5-.7.752.752,0,0,0-.7-.5L4.6,9.9a1.854,1.854,0,0,0-.7.2L2.4,9.7l9.1-2.8a2.045,2.045,0,0,1,1.1,0l9.1,2.8Z"
        className={fillNegative}
      />
      <path
        d="M12.125,9.575c.691-.344.734-1.156-.281-1.187L4.234,9.809C3.156,10.044,2.5,11.5,2.5,11.5a1.421,1.421,0,0,0-.2.6,1.2,1.2,0,0,0-.7,1,1.05,1.05,0,0,0,.5.9l-.9,4.1a.563.563,0,0,0,.6.7h2c.4,0,.6-.3.6-.7L3.5,14a1.06,1.06,0,0,0,0-1.8c0-.1.1-.2.2-.4a3.982,3.982,0,0,1,.7-.881"
        transform="translate(0 0.1)"
        className={fill2}
      />
      <g
        transform="translate(4.446 10.922) rotate(-9)"
        className={fillNegative}
      >
        <path d="M-.889.859A3.276,3.276,0,0,1-.024.095L4,.083l.039.75S.939.706.457.756A.817.817,0,0,0-.2,1.19Z" />
        <path
          d="M -0.1975483596324921 1.190291881561279 L -0.8886483907699585 0.859251856803894 C -0.8886483907699585 0.859251856803894 -0.4544983506202698 0.3093918263912201 -0.02399836108088493 0.09458183497190475 L 3.99610161781311 0.08269183337688446 L 4.03555154800415 0.8324618339538574 C 4.03555154800415 0.8324618339538574 0.9391016960144043 0.7058915495872498 0.456571638584137 0.7560218572616577 C -0.02596836164593697 0.806151807308197 -0.1975483596324921 1.190291881561279 -0.1975483596324921 1.190291881561279 Z"
          className={fillNegative}
        />
      </g>
      <g
        id="Path_6"
        data-name="Path 6"
        transform="translate(2.59 9.307) rotate(-13)"
        className={fillNegative}
      >
        <path
          className={fillNegative}
          d="M-1.244,1.791A7.446,7.446,0,0,1,.021.688L4,.083,4,1a12.462,12.462,0,0,0-3.424.2A3.8,3.8,0,0,0-.618,2.208Z"
        />
        <path
          d="M -0.6184133887290955 2.207629203796387 L -1.243763446807861 1.790639281272888 C -1.243763446807861 1.790639281272888 -0.4093233942985535 0.9023592472076416 0.02117660827934742 0.6875492334365845 L 3.996106624603271 0.08268924802541733 L 3.999996662139893 0.9999992251396179 C 3.999996900558472 0.9999992251396179 1.282476663589478 0.8504492044448853 0.5762466192245483 1.200559258460999 C -0.1299833953380585 1.55065929889679 -0.6184133887290955 2.207629203796387 -0.6184133887290955 2.207629203796387 Z"
          stroke="none"
          className={fillNegative}
        />
      </g>
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
