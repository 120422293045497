const appUtility = (function() {
  let timeoutMessage;
  const setTimeoutMessage = str => {
    timeoutMessage = str;
  };

  const getTimeoutMessage = () => {
    return timeoutMessage;
  };

  return {
    setTimeoutMessage: setTimeoutMessage,
    getTimeoutMessage: getTimeoutMessage,
  };
})();

export default appUtility;
