import React from 'react';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class ExampleAbbreviation extends React.Component {
  render() {
    return (
      <dfn>
        <abbr title="for example">
          <Content cmsTag="GLOBAL:eg" copytext="e.g." />
        </abbr>
      </dfn>
    );
  }
}

ExampleAbbreviation.propTypes = {
  items: PropTypes.string,
};

export default ExampleAbbreviation;
