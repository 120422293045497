import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Content,
  RadioGroup,
  Radio,
  CONSTANTS,
  Markdown,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ContactPreferences extends Component {
  onChangePreferences = e => {
    const { updateMarketingPreferences } = this.props;
    updateMarketingPreferences(e);
  };

  render() {
    const { form } = this.props;
    return (
      <React.Fragment>
        <Content
          tag="h3"
          cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:h3-1"
          copytext="Contact preferences"
        />
        <fieldset className="mb-0 check-radio-fieldset" id="preferences">
          <Content
            tag="legend"
            copytext="From time to time we will need to contact you with important information for example, changes to your savings account interest rate, and other regulatory information we are required to send you."
            cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:legend-1"
          />
          <Content
            tag="p"
            copytext="We have an ongoing commitment to reduce our environmental impact and we would like to send this information to you by email. If you are happy for us to do this, please tick the box. Otherwise, we will keep you up to date with important account information by post."
            cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:p1"
          />
          <RadioGroup
            legend=""
            cmsTag="Long-apply:Apply-pages:Step-1:Contact-preferences"
            required={true}
            field="contactPreferences"
            id="contactPreferences"
            validation={form.contactPreferences}
            groupClassName="mb-0"
          >
            <Radio
              key={'email'}
              label="Email"
              cmsTag="GLOBAL:Email"
              id="contactPreferences-e"
              field="contactPreferences"
              onChange={this.onChangePreferences}
              validation={form.contactPreferences}
              value={CONSTANTS.CONTACT_PREFERENCE_EMAIL}
            />
            <Radio
              key={'post'}
              label="Post"
              cmsTag="GLOBAL:Post"
              id="contactPreferences-w"
              field="contactPreferences"
              onChange={this.onChangePreferences}
              validation={form.contactPreferences}
              value={CONSTANTS.CONTACT_PREFERENCE_POST}
            />
          </RadioGroup>
          <Content
            tag="p"
            copytext="We also have a society newsletter which highlights any new savings accounts, mortgages, and other offers that we think you may be interested in by email. You can unsubscribe from the newsletter at any time by clicking unsubscribe on the email."
            cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:p2"
          />
        </fieldset>
        <Markdown
          template={{
            markdown: {
              companySiteName: CONTACTS.companySiteName,
            },
          }}
          cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:section-1"
          markdown={`If you ever need to change your contact preferences, please give us a call, email or pop into one of our branches. You can also update your preferences within $[companySiteName].`}
        />
        <br />
        <br />
        <Markdown
          template={{
            markdown: {
              privacyPolicyURL: CONTACTS.privacyPolicyURL,
            },
          }}
          cmsTag="Long-apply:Apply-pages:Contact-components:Contact-preferences:section-2"
          markdown={`To find out how we use your information, and keep it safe, please read our <a target="_blank" href="$[privacyPolicyURL]">Privacy Policy</a> for more information.`}
        />
      </React.Fragment>
    );
  }
}

ContactPreferences.propTypes = {
  marketingPreferences: PropTypes.object,
  form: PropTypes.object,
  updateMarketingPreferences: PropTypes.func,
};

export default ContactPreferences;
