import PropTypes from 'prop-types';
import React from 'react';
import Alerts from './icons/Alerts';
import AppleStoreLogo from './icons/appleStoreBadge';
import BoxProduct from './icons/BoxProduct';
import Calendar from './icons/Calendar';
import CreditCardIcon from './icons/CreditCardIcon';
import Delete from './icons/Delete';
import Envelope from './icons/Envelope';
import Export from './icons/Export';
import Facebook from './icons/Facebook';
import GooglePlayLogo from './icons/GooglePlayLogo';
import HelpAdIcon from './icons/HelpAdIcon';
import HelpIcon from './icons/HelpIcon';
import Info from './icons/Info';
import LoanIcon from './icons/LoanIcon';
import MoveMoneyMakePayment from './icons/MoveMoneyMakePayment';
import MoveMoneyManagePayee from './icons/MoveMoneyManagePayee';
import MoveMoneyManagePayments from './icons/MoveMoneyManagePayments';
import MoveMoneyOneOffPayment from './icons/MoveMoneyOneOffPayment';
import MoveMoneyPayCard from './icons/MoveMoneyPayCard';
import MoveMoneyStandingOrder from './icons/MoveMoneyStandingOrder';
import MoveMoneyTransfer from './icons/MoveMoneyTransfer';
import MyServicesOverseasNotification from './icons/MyServicesOverseasNotification';
import MyServicesPaperlessStatement from './icons/MyServicesPaperlessStatement';
import MyServicesPersonalDetails from './icons/MyServicesPersonalDetails';
import MyServicesSecuritySettings from './icons/MyServicesSecuritySettings';
import MyServicesOrganisationSettings from './icons/MyServicesOrganisationSettings';
import MyServicesSettings from './icons/MyServicesSettings';
import MyServicesUser from './icons/MyServicesUser';
import NominatedAccountDetails from './icons/NominatedAccountDetails';
import SavingsGoals from './icons/SavingsGoals';
import Print from './icons/Print';
import SavingsIcon from './icons/SavingsIcon';
import Sort from './icons/Sort';
import Chevron from './icons/Chevron';
import Success from './icons/Success';
import Target from './icons/Target';
import TravelInsuranceAd from './icons/TravelInsuranceAd';
import Twitter from './icons/Twitter';
import LinkedIn from './icons/LinkedIn';
import Warning from './icons/Warning';
import Warning2 from './icons/Warning2';
import Dropdown from './icons/Dropdown';
import Thermometer from './icons/Thermometer';
import Instagram from './icons/Instagram';
import Youtube from './icons/Youtube';
import Logo from './icons/Logo';
import InternalTransfer from './icons/InternalTransfer';
import NoticeToWithdraw from './icons/NoticeToWithdraw';
import TransferIntoAnotherAccount from './icons/TransferIntoAnotherAccount';
import FooterLogo from './icons/FooterLogo';
import SavingsGoalCategory1 from './icons/SavingsGoalCategory1';
import SavingsGoalCategory2 from './icons/SavingsGoalCategory2';
import SavingsGoalCategory3 from './icons/SavingsGoalCategory3';
import SavingsGoalCategory4 from './icons/SavingsGoalCategory4';
import SavingsGoalCategory5 from './icons/SavingsGoalCategory5';
import SavingsGoalCategory6 from './icons/SavingsGoalCategory6';
import SavingsGoalCategory7 from './icons/SavingsGoalCategory7';
import SavingsGoalCategory8 from './icons/SavingsGoalCategory8';
import SavingsGoalCategory9 from './icons/SavingsGoalCategory9';
import SavingsGoalCategory10 from './icons/SavingsGoalCategory10';
import SavingsGoalCategory11 from './icons/SavingsGoalCategory11';
import SavingsGoalCategory12 from './icons/SavingsGoalCategory12';
import SavingsGoalCategoryOther from './icons/SavingsGoalCategoryOther';
import BudgetCalculator from './icons/BudgetCalculator';
import NoteIcon from './icons/NoteIcon';
import MakeADeposit from './icons/MakeADeposit';

const Icon = props => {
  switch (props.name) {
    case 'Alerts':
      return <Alerts {...props} />;
    case 'AppleStoreLogo':
      return <AppleStoreLogo {...props} />;
    case 'BoxProduct':
      return <BoxProduct {...props} />;
    case 'Calendar':
      return <Calendar {...props} />;
    case 'CreditCardIcon':
      return <CreditCardIcon {...props} />;
    case 'Envelope':
      return <Envelope {...props} />;
    case 'Export':
      return <Export {...props} />;
    case 'Facebook':
      return <Facebook {...props} />;
    case 'GooglePlayLogo':
      return <GooglePlayLogo {...props} />;
    case 'HelpAdIcon':
      return <HelpAdIcon {...props} />;
    case 'HelpIcon':
      return <HelpIcon {...props} />;
    case 'Info':
      return <Info {...props} />;
    case 'LoanIcon':
      return <LoanIcon {...props} />;
    case 'MoveMoneyMakePayment':
      return <MoveMoneyMakePayment {...props} />;
    case 'MoveMoneyManagePayee':
      return <MoveMoneyManagePayee {...props} />;
    case 'MoveMoneyManagePayments':
      return <MoveMoneyManagePayments {...props} />;
    case 'MoveMoneyOneOffPayment':
      return <MoveMoneyOneOffPayment {...props} />;
    case 'MoveMoneyPayCard':
      return <MoveMoneyPayCard {...props} />;
    case 'MoveMoneyStandingOrder':
      return <MoveMoneyStandingOrder {...props} />;
    case 'MoveMoneyTransfer':
      return <MoveMoneyTransfer {...props} />;
    case 'SavingsGoals':
      return <SavingsGoals {...props} />;
    case 'MyServicesOverseasNotification':
      return <MyServicesOverseasNotification {...props} />;
    case 'MyServicesPaperlessStatement':
      return <MyServicesPaperlessStatement {...props} />;
    case 'MyServicesPersonalDetails':
      return <MyServicesPersonalDetails {...props} />;
    case 'MyServicesSecuritySettings':
      return <MyServicesSecuritySettings {...props} />;
    case 'MyServicesOrganisationSettings':
      return <MyServicesOrganisationSettings {...props} />;
    case 'MyServicesSettings':
      return <MyServicesSettings {...props} />;
    case 'MyServicesUser':
      return <MyServicesUser {...props} />;
    case 'NominatedAccountDetails':
      return <NominatedAccountDetails {...props} />;
    case 'Print':
      return <Print {...props} />;
    case 'SavingsIcon':
      return <SavingsIcon {...props} />;
    case 'Success':
      return <Success {...props} />;
    case 'Target':
      return <Target {...props} />;
    case 'TravelInsuranceAd':
      return <TravelInsuranceAd {...props} />;
    case 'Twitter':
      return <Twitter {...props} />;
    case 'LinkedIn':
      return <LinkedIn {...props} />;
    case 'Warning':
      return <Warning {...props} />;
    case 'Warning2':
      return <Warning2 {...props} />;
    case 'Dropdown':
      return <Dropdown {...props} />;
    case 'Thermometer':
      return <Thermometer {...props} />;
    case 'Instagram':
      return <Instagram {...props} />;
    case 'Youtube':
      return <Youtube {...props} />;
    case 'Logo':
      return <Logo {...props} />;
    case 'InternalTransfer':
      return <InternalTransfer {...props} />;
    case 'NoticeToWithdraw':
      return <NoticeToWithdraw {...props} />;
    case 'TransferIntoAnotherAccount':
      return <TransferIntoAnotherAccount {...props} />;
    case 'FooterLogo':
      return <FooterLogo {...props} />;
    case 'SavingsGoalCategory1':
      return <SavingsGoalCategory1 {...props} />;
    case 'SavingsGoalCategory2':
      return <SavingsGoalCategory2 {...props} />;
    case 'SavingsGoalCategory3':
      return <SavingsGoalCategory3 {...props} />;
    case 'SavingsGoalCategory4':
      return <SavingsGoalCategory4 {...props} />;
    case 'SavingsGoalCategory5':
      return <SavingsGoalCategory5 {...props} />;
    case 'SavingsGoalCategory6':
      return <SavingsGoalCategory6 {...props} />;
    case 'SavingsGoalCategory7':
      return <SavingsGoalCategory7 {...props} />;
    case 'SavingsGoalCategory8':
      return <SavingsGoalCategory8 {...props} />;
    case 'SavingsGoalCategory9':
      return <SavingsGoalCategory9 {...props} />;
    case 'SavingsGoalCategory10':
      return <SavingsGoalCategory10 {...props} />;
    case 'SavingsGoalCategory11':
      return <SavingsGoalCategory11 {...props} />;
    case 'SavingsGoalCategory12':
      return <SavingsGoalCategory12 {...props} />;
    case 'SavingsGoalCategoryOther':
      return <SavingsGoalCategoryOther {...props} />;
    case 'BudgetCalculator':
      return <BudgetCalculator {...props} />;
    case 'NoteIcon':
      return <NoteIcon {...props} />;
    case 'Delete':
      return <Delete {...props} />;
    case 'MakeADeposit':
      return <MakeADeposit {...props} />;
    case 'Sort':
      return <Sort {...props} />;
    case 'Chevron':
      return <Chevron {...props} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.any,
};

export default Icon;
