import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '11.41', height = '7.12', fill = 'text-fill' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 11.41 7.12"
  >
    <path
      className={fill}
      d="M0,1.41L1.41,0l4.29,4.29L10,0l1.41,1.41L5.71,7.12L0,1.41z"
    />
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default SVG;
