import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import {
  Content,
  CONSTANTS,
  FormattedDate,
  AppMeta,
  LinkTag,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SystemDowntimeBlockedPage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { systemDowntime } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Service is not currently available page"
          metaDescription="Service is not currently available page"
        />
        <Content
          tag="h1"
          cmsTag="SystemMessage:Long-apply:downtime:h1"
          copytext="Thank you"
        />
        <div id="system-downtime-page">
          <Content
            cmsTag="SystemMessage:Long-apply:downtime:part-1"
            copytext="Please note that this service is not currently available due to planned system maintenance. The maintenance is due to complete on "
          />
          <FormattedDate
            date={systemDowntime?.BatchEndTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />

          <div className="form-button-group mt-8">
            <LinkTag
              id="system-down-back-button"
              className={`btn btn-secondary`}
              role="button"
              href={CONTACTS.externalHomeURL}
            >
              <Content
                cmsTag="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </LinkTag>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SystemDowntimeBlockedPage.propTypes = {
  systemDowntime: PropTypes.object,
  isSystemReadOnly: PropTypes.func,
};
const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemDowntimeBlockedPage);
