import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LocalIcon } from '@myie/interact-local-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import { CONTACTS } from '@myie/interact-local-dom';

class Acquire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open(CONTACTS.HelpURL);
      return false;
    }
    return true;
  };

  render() {
    return (
      <header className={`top-bar ${this.state.activeClass}`}>
        <Navbar id="main-menu" expand="xl" aria-label="Main menu">
          <NavbarBrand href="/">
            <LocalIcon name="Logo" />
          </NavbarBrand>
        </Navbar>
      </header>
    );
  }
}

Acquire.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(Acquire);
