import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NoMatch, SystemDowntimeBlockedPage } from '@myie/interact-dom';
import Eligibility from './Eligibility';
import { ApplyIndex } from './apply-pages';
import TranchExceeded from './TranchExceeded';

class LongApplyRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/long-apply/eligibility/:id" component={Eligibility} />
        <Route path="/long-apply/apply/:id" component={ApplyIndex} />
        <Route path="/long-apply/not-available" component={TranchExceeded} />
        <Route
          path="/long-apply/downtime"
          component={SystemDowntimeBlockedPage}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}
export default LongApplyRoutes;
