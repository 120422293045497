import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Col, Row, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * Slider component
 */
class Slider extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      placeholder,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      withInput,
      suffixAlert,
      suffixIsDiv,
      dp,
      children,
      validation = {},
      labelClassName = '',
      inputClassName = '',
      groupClassName = '',
      showErrors = true,
      id,
      field,
      onChange,
      onBlur,
      disabled,
      required,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const inputOverride = (e, dp, func) => {
      const { target = {} } = e;
      const { value = '' } = target;
      if (
        dp > 0 &&
        value.indexOf('.') !== -1 &&
        value.length - value.indexOf('.') > dp + 1
      ) {
        return;
      }
      if (!target.validity.valid) {
        return;
      }
      if (!value || !isNaN(parseFloat(value))) {
        func(e);
      }
    };
    let step = dp <= 0 ? '1' : '.' + '0'.repeat(dp - 1) + '1';
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }
    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
          {label}
          {isRequired ? (
            <React.Fragment>
              <span aria-hidden="true">*</span>
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="GLOBAL:-required-field"
                copytext=" (required field)"
              />
            </React.Fragment>
          ) : (
            ''
          )}{' '}
          {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </Label>
        <Row>
          <Col sm={withInput ? 9 : 12}>
            <input
              onBlur={event => inputOverride(event, dp, onBlur)}
              onChange={event => inputOverride(event, dp, onChange)}
              id={id}
              name={fieldName}
              value={value}
              type="range"
              step={step}
              {...rest}
            />
          </Col>
          {withInput ? (
            <Col sm={3}>
              <Input
                className={inputClassName}
                onBlur={event => inputOverride(event, dp, onBlur)}
                onChange={event => inputOverride(event, dp, onChange)}
                invalid={Validate.isInvalid(state)}
                valid={Validate.isValid(state)}
                id={`${id}-input`}
                name={fieldName}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                type="number"
                step={step}
                {...dynamicAriaDescribedBy}
                {...rest}
              />
            </Col>
          ) : (
            ''
          )}
        </Row>
        {suffix ? (
          suffixAlert ? (
            <div
              className="alert alert-info mt-3 w-auto d-inline-block"
              id={`${id}-desc`}
            >
              <p>{suffix}</p>
            </div>
          ) : suffixIsDiv ? (
            <div className="suffix" id={`${id}-desc`}>
              {suffix}
            </div>
          ) : (
            <p className="suffix" id={`${id}-desc`}>
              {suffix}
            </p>
          )
        ) : (
          ''
        )}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Slider.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * Input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * decimal places
   */
  dp: PropTypes.number,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Slider ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Style class name for input
   */
  inputClassName: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * onChange function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
  withInput: PropTypes.any,
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
};

export default contentManaged(Slider);
