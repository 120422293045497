import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill2}
      d="M9,11.6c0.3,0.3,0.7,0.3,0.9,0l3.4-3.4l3.5,3.5l-1.2,1.2c-0.6,0.6-0.2,1.6,0.7,1.6h4c0.4,0,0.6-0.3,0.6-0.6v-4 c0-0.9-1-1.3-1.6-0.7l-1.2,1.2L13.7,6c-0.3-0.3-0.7-0.3-0.9,0L9.4,9.4L7.4,7.3C7.1,7,6.7,7,6.5,7.3L6,7.7C5.8,8,5.8,8.4,6,8.6 C6,8.6,9,11.6,9,11.6z"
    />
    <path
      className={fill}
      d="M21.6,17.8H3.7V5c0-0.4-0.3-0.6-0.6-0.6H2.4C2,4.3,1.8,4.6,1.8,5v13.5c0,0.7,0.6,1.3,1.3,1.3h18.6c0.4,0,0.6-0.3,0.6-0.6 v-0.6C22.3,18.1,22,17.8,21.6,17.8z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
