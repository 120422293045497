import {
  RESET_GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_REQUEST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_FALIURE,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FALIURE,
} from '../actions/addressLookupTypes';

const addressLookupDefinition = (state = {}, action) => {
  switch (action.type) {
    case GET_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressLookupData: action.getAddressListResponse?.result,
        currentAddressIndex: action.currentAddressIndex,
        isFetching: action.isFetching,
        noAddressFound:
          action.getAddressListResponse?.result.confidence === 'No matches'
            ? true
            : false,
      };
    case GET_ADDRESS_LIST_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_GET_ADDRESS_LIST:
      return {
        ...state,
        addressLookupData: null,
        currentAddressIndex: '',
        isFetching: false,
        error: null,
      };
    case GET_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addressDetails: action.getAddressListResponse?.result,
        currentAddressIndex: action.currentAddressIndex,
        isFetching: action.isFetching,
      };
    case GET_ADDRESS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default addressLookupDefinition;
