import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class Colours extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  //Convert RGB code to hex
  rgbToHex = rgb => {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
    );
    return rgb && rgb.length === 4
      ? '#' +
          ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : '';
  };

  //Create an array of the colours from the css, and combine the hext and rgb into a string
  componentDidMount() {
    const colourPanels = document.querySelectorAll('div[class^="brand-color"]');

    let list = [];
    for (let i = 0; i < colourPanels.length; i++) {
      let colourPanel = colourPanels[i];
      const style = getComputedStyle(colourPanel, null);
      const RGBBackgroundColor = style.backgroundColor;
      const HexBackgroundColour = this.rgbToHex(RGBBackgroundColor);
      const backgroundColourCombination = function() {
        return (
          <p>
            {RGBBackgroundColor} <br /> {HexBackgroundColour}
          </p>
        );
      };

      list.push(backgroundColourCombination);
    }

    this.setState({
      testdata: list,
    });
  }

  render() {
    /* This will eventually dynamically cycle through the colours so manual html below wont be needed, but it doesn't work yet.
    
    let items = [];

    for (let i = 0; i < this.state.testdata.length; i++) {
      let a = i + 1;
      let classNameVariable = 'brand-color-' + a;
      items.push(
        <Col xs={2}>
          <div className={classNameVariable}>{this.state.testdata[i]}</div>
        </Col>,
      );
    }*/

    return (
      <section id="colors-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/abstracts/colours.scss</strong>
          </code>
        </p>
        <h3>Main Colours</h3>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-1">
              {typeof this.state.testdata[0] === 'function'
                ? this.state.testdata[0]()
                : this.state.testdata[0]}
            </div>
            Primary color
          </Col>
          <Col xs={2}>
            <div className="brand-color-2">
              {typeof this.state.testdata[1] === 'function'
                ? this.state.testdata[1]()
                : this.state.testdata[1]}
            </div>
            Primary-color 10%
          </Col>
          <Col xs={2}>
            <div className="brand-color-3">
              {typeof this.state.testdata[2] === 'function'
                ? this.state.testdata[2]()
                : this.state.testdata[2]}
            </div>
            Primary color 25%
          </Col>
          <Col xs={2}>
            <div className="brand-color-4 dark">
              {typeof this.state.testdata[3] === 'function'
                ? this.state.testdata[3]()
                : this.state.testdata[3]}
            </div>
            Primary color 50%
          </Col>
          <Col xs={2}>
            <div className="brand-color-5 dark">
              {typeof this.state.testdata[4] === 'function'
                ? this.state.testdata[4]()
                : this.state.testdata[4]}
            </div>
            Primary color 75%
          </Col>
        </Row>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-6 dark">
              {typeof this.state.testdata[5] === 'function'
                ? this.state.testdata[5]()
                : this.state.testdata[5]}
            </div>
            Secondary color
          </Col>
          <Col xs={2}>
            <div className="brand-color-7 dark">
              {typeof this.state.testdata[6] === 'function'
                ? this.state.testdata[6]()
                : this.state.testdata[6]}
            </div>
            Secondary color 10%
          </Col>
          <Col xs={2}>
            <div className="brand-color-8 dark">
              {typeof this.state.testdata[7] === 'function'
                ? this.state.testdata[7]()
                : this.state.testdata[7]}
            </div>
            Secondary color 25%
          </Col>
          <Col xs={2}>
            <div className="brand-color-9 dark">
              {typeof this.state.testdata[8] === 'function'
                ? this.state.testdata[8]()
                : this.state.testdata[8]}
            </div>
            Secondary color 50%
          </Col>
          <Col xs={2}>
            <div className="brand-color-10 dark">
              {typeof this.state.testdata[9] === 'function'
                ? this.state.testdata[9]()
                : this.state.testdata[9]}
            </div>
            Secondary color 75%
          </Col>
        </Row>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-11">
              {typeof this.state.testdata[10] === 'function'
                ? this.state.testdata[10]()
                : this.state.testdata[10]}
            </div>
            Default (Tertiary) color
          </Col>
          <Col xs={2}>
            <div className="brand-color-12">
              {typeof this.state.testdata[11] === 'function'
                ? this.state.testdata[11]()
                : this.state.testdata[11]}
            </div>
            Default (Tertiary) color 10%
          </Col>
          <Col xs={2}>
            <div className="brand-color-13">
              {typeof this.state.testdata[12] === 'function'
                ? this.state.testdata[12]()
                : this.state.testdata[12]}
            </div>
            Default (Tertiary) color 25%
          </Col>
          <Col xs={2}>
            <div className="brand-color-14 dark">
              {typeof this.state.testdata[13] === 'function'
                ? this.state.testdata[13]()
                : this.state.testdata[13]}
            </div>
            Default (Tertiary) color 50%
          </Col>
          <Col xs={2}>
            <div className="brand-color-15 dark">
              {typeof this.state.testdata[14] === 'function'
                ? this.state.testdata[14]()
                : this.state.testdata[14]}
            </div>
            Default (Tertiary) color 75%
          </Col>
        </Row>
        <h3>Text Colours</h3>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-16">
              {typeof this.state.testdata[15] === 'function'
                ? this.state.testdata[15]()
                : this.state.testdata[15]}
            </div>
            Text color
          </Col>
          <Col xs={2}>
            <div className="brand-color-17">
              {' '}
              {typeof this.state.testdata[16] === 'function'
                ? this.state.testdata[16]()
                : this.state.testdata[16]}
            </div>
            Text color 10%
          </Col>
          <Col xs={2}>
            <div className="brand-color-18">
              {typeof this.state.testdata[17] === 'function'
                ? this.state.testdata[17]()
                : this.state.testdata[17]}
            </div>
            Text color 25%
          </Col>
          <Col xs={2}>
            <div className="brand-color-19 dark">
              {typeof this.state.testdata[18] === 'function'
                ? this.state.testdata[18]()
                : this.state.testdata[18]}
            </div>
            Text color 50%
          </Col>
          <Col xs={2}>
            <div className="brand-color-20 dark">
              {typeof this.state.testdata[19] === 'function'
                ? this.state.testdata[19]()
                : this.state.testdata[19]}
            </div>
            Text color 75%
          </Col>
        </Row>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-21 dark">
              {typeof this.state.testdata[20] === 'function'
                ? this.state.testdata[20]()
                : this.state.testdata[20]}
            </div>
            Alt Text color
          </Col>
          <Col xs={2}>
            <div className="brand-color-22 dark">
              {typeof this.state.testdata[21] === 'function'
                ? this.state.testdata[21]()
                : this.state.testdata[21]}
            </div>
            Disabled text color
          </Col>
          <Col xs={2}>
            <div className="brand-color-23">
              {typeof this.state.testdata[22] === 'function'
                ? this.state.testdata[22]()
                : this.state.testdata[22]}
            </div>
            Link color
          </Col>
          <Col xs={2}>
            <div className="brand-color-24">
              {typeof this.state.testdata[23] === 'function'
                ? this.state.testdata[23]()
                : this.state.testdata[23]}
            </div>
            Link color (hover)
          </Col>
          <Col xs={2}>
            <div className="brand-color-25 dark">
              {typeof this.state.testdata[24] === 'function'
                ? this.state.testdata[24]()
                : this.state.testdata[24]}
            </div>
            Link color (disabled)
          </Col>
        </Row>
        <h3>Alert Colours</h3>
        <Row className="color-palette">
          <Col xs={2}>
            <div className="brand-color-26 dark">
              {typeof this.state.testdata[25] === 'function'
                ? this.state.testdata[25]()
                : this.state.testdata[25]}
            </div>
            Success color
          </Col>
          <Col xs={2}>
            <div className="brand-color-27 dark">
              {typeof this.state.testdata[26] === 'function'
                ? this.state.testdata[26]()
                : this.state.testdata[26]}
            </div>
            Info color
          </Col>
          <Col xs={2}>
            <div className="brand-color-28 dark">
              {typeof this.state.testdata[27] === 'function'
                ? this.state.testdata[27]()
                : this.state.testdata[27]}
            </div>
            Warning color
          </Col>
          <Col xs={2}>
            <div className="brand-color-29">
              {typeof this.state.testdata[28] === 'function'
                ? this.state.testdata[28]()
                : this.state.testdata[28]}
            </div>
            Danger color
          </Col>
        </Row>
      </section>
    );
  }
}

Colours.propTypes = {
  items: PropTypes.string,
};

export default Colours;
