import React, { Component } from 'react';
import { AddressLookupQAS } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';

class AddressLookupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState = () => {
    return {
      data: {},
    };
  };

  componentDidUpdate() {}

  onChangeAddressSearchInput = (inputValue, addressIndex) => {
    if (inputValue && inputValue.trim().length > 3) {
      const { getAddressList } = this.props;
      getAddressList({ value: inputValue, addressIndex: addressIndex });
    }
  };

  render() {
    const {
      addressLookupData,
      resetGetAddressList,
      getAddress,
      addressDetails,
      noAddressFound,
      isResumedApplication,
    } = this.props;
    return (
      <AddressLookupQAS
        {...this.props}
        onSelectAddress={this.props.onSelectAddress}
        onChangeAddressSearchInput={this.onChangeAddressSearchInput}
        addressList={addressLookupData?.suggestions}
        resetGetAddressList={resetGetAddressList}
        getAddress={getAddress}
        addressDetails={addressDetails}
        noAddressFound={noAddressFound}
        isResumedApplication={isResumedApplication}
      />
    );
  }
}

AddressLookupContainer.propTypes = {
  account: PropTypes.object,
  resetGetAddressList: PropTypes.func,
  setStage: PropTypes.func,
  getAddressList: PropTypes.func,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  formData: PropTypes.any,
  formIndex: PropTypes.string,
  saveData: PropTypes.func,
  selectedProduct: PropTypes.object,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  updateUrl: PropTypes.func,
  onSelectAddress: PropTypes.func,
  addressLookupData: PropTypes.object,
  onSubmit: PropTypes.func,
  addressDetails: PropTypes.object,
  getAddress: PropTypes.func,
  noAddressFound: PropTypes.any,
  isResumedApplication: PropTypes.bool,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressLookupContainer);
