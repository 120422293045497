import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import { Validate } from '@myie/interact';
import { ContentType } from '../HO_components/contentManaged';

/**
 * HasErrors function
 * check the given object and
 * will return true or false
 */
const HasErrors = validation => {
  const { state = {} } = validation;
  const { approval = {}, dirty } = state;
  if (approval.approved || !approval.failed || !dirty) {
    return true;
  }
  return false;
};

/**
 * Errors component
 * will display error message
 */
class Errors extends React.Component {
  render() {
    const { props } = this;

    const {
      validation = {},
      field,
      contentValue,
      oneError = false,
      ...rest
    } = props;
    const { state = {} } = validation;
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}-${field[1]}`;
    const { approval = {}, dirty } = state;
    const children = [];
    if (!approval.failed || !dirty) {
      return '';
    }
    const errors = Validate.errors(validation);
    errors.forEach(error => {
      let i = 0;
      children.push(
        <p
          className="mb-0"
          id={
            oneError
              ? `one-error-${fieldName}-validation`
              : `error-${fieldName}-${error.rule}-${i}`
          }
          key={`${error.rule}-${i}`}
        >
          {contentValue
            ? contentValue(ContentType.Error, error.rule)
            : error.message}
        </p>,
      );
    });
    return children.length ? (
      <FormFeedback
        {...rest}
        id={`error-${fieldName}-validation`}
        aria-live="assertive"
        aria-relevant="additions removals"
      >
        {children}
      </FormFeedback>
    ) : (
      ''
    );
  }
}

Errors.propTypes = {
  /**
   * Field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Input validation
   */
  validation: PropTypes.object,
  groupName: PropTypes.string,
  contentValue: PropTypes.any,
  oneError: PropTypes.bool,
};

export default Errors;
export { HasErrors };
