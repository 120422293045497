import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '22', height = '11.415', fill = 'primary-fill' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 11.415"
  >
    <path
      className={fill}
      d="M14.879 10l3.293-3.293H0v-2h18.172l-3.293-3.293L16.293 0 22 5.707l-5.708 5.708z"
    ></path>
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default SVG;
