import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Icon from '../svgs';

class SideLink extends React.Component {
  render() {
    const { props } = this;

    const {
      linkTitle = '',
      linkDescription = '',
      linkID = '',
      linkURL = '',
      linkImage = '',
      iconImage,
      iconImageFill,
      iconImageWidth,
      newMessageCount,
      linkVersion = '',
    } = props;

    return (
      <React.Fragment>
        {linkVersion === 'primary' ? (
          <React.Fragment>
            <Link
              id={linkID}
              className="nav-link media primary-icon-link"
              to={linkURL}
            >
              <div className="mr-3 d-flex align-items-center">
                {linkImage && (
                  <img alt={linkImage} src={linkImage} aria-hidden="true" />
                )}
                {iconImage && (
                  <Icon
                    name={iconImage}
                    fill={iconImageFill}
                    width={iconImageWidth}
                  />
                )}

                {newMessageCount > 0 && (
                  <Badge className="new-message-notification-badge">
                    {newMessageCount}
                  </Badge>
                )}
              </div>
              <div className="media-body align-self-center">
                {linkTitle}
                <span className="d-sm-none d-md-none d-lg-block small">
                  {linkDescription}
                </span>
              </div>
              <div className="ml-3 align-self-center">
                <div className="link-arrow"></div>
              </div>
            </Link>
          </React.Fragment>
        ) : (
          ''
        )}
        {linkVersion === 'secondary' ? (
          <React.Fragment>
            <Link
              id={linkID}
              className="nav-link media secondary-icon-link"
              to={linkURL}
            >
              <div className="mr-3 d-flex align-items-center">
                {linkImage && (
                  <img alt={linkImage} src={linkImage} aria-hidden="true" />
                )}
                {iconImage && (
                  <Icon
                    name={iconImage}
                    fill={iconImageFill}
                    width={iconImageWidth}
                  />
                )}

                {newMessageCount > 0 && (
                  <Badge className="new-message-notification-badge">
                    {newMessageCount}
                  </Badge>
                )}
              </div>
              <div className="media-body">
                {linkTitle}
                <span className="d-sm-none d-md-none d-lg-block small">
                  {linkDescription}
                </span>
              </div>
              <div className="ml-3 align-self-center">
                <div className="link-arrow"></div>
              </div>
            </Link>
          </React.Fragment>
        ) : (
          ''
        )}
        {linkVersion === 'light' ? (
          <React.Fragment>
            <Link
              id={linkID}
              className="nav-link media tertiary-icon-link"
              to={linkURL}
            >
              <div className="mr-3 d-flex align-items-center">
                {linkImage && (
                  <img alt={linkImage} src={linkImage} aria-hidden="true" />
                )}
                {iconImage && (
                  <Icon
                    name={iconImage}
                    fill={iconImageFill}
                    width={iconImageWidth}
                  />
                )}

                {newMessageCount > 0 && (
                  <Badge className="new-message-notification-badge">
                    {newMessageCount}
                  </Badge>
                )}
              </div>
              <div className="media-body">
                {linkTitle}
                <span className="d-sm-none d-md-none d-lg-block small">
                  {linkDescription}
                </span>
              </div>
              <div className="ml-3 align-self-center">
                <div className="link-arrow"></div>
              </div>
            </Link>
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

SideLink.propTypes = {
  items: PropTypes.string,
  linkTitle: PropTypes.any,
  linkDescription: PropTypes.string,
  linkURL: PropTypes.string,
  linkImage: PropTypes.any,
  linkID: PropTypes.string,
  newMessageCount: PropTypes.any,
  iconImage: PropTypes.any,
  iconImageFill: PropTypes.string,
  iconImageWidth: PropTypes.string,
  linkVersion: PropTypes.string,
};

export default SideLink;
