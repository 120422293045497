/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */
import {
  Session,
  Connection,
  Settings,
  TwoFactor,
  Utility,
} from '@myie/interact';
import uuid from 'uuid';
import { createUserManager } from 'redux-oidc';

// set global local to current browser language

const Config = async function() {
  // update with google tag manager id
  Settings.gtmId = 'GTM-53BH7TB';
  Settings.iegtmId = 'GTM-MZK9ZD6';
  Settings.ticketExpiry = 10;
  Settings.sessionExpiry = 10;
  // using a common root as default
  let root = 'https://dar-qa-app.darapital-dev.co.uk';
  let site = `${window.location.protocol}//${window.location.hostname}${
    window.location.hostname.toLowerCase() !== 'localhost'
      ? ''
      : ':' + window.location.port
  }`;

  let connectRoot = 'https://connect.qa-darlington.com';
  const client_id = '';
  const client_secret = '';

  const REACT_APP_AUTH_URL = 'https://auth.qa-darlington.com';
  const IDENTITY_ISSUER_URL =  'https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_P7V3VUMUD';

  let cms = "https://content.qa-darlington.com"; // CMU API url not CMU editor
  let auth_url = REACT_APP_AUTH_URL;
  let identity_url = IDENTITY_ISSUER_URL;

  // Check which environment
  let envname = window.location.hostname;
  let env = '.prod';
  
  if (envname.includes('test-')) {
    env = '.test';
  } else if (envname.includes('qa-'))  {
    env = '.qa';
  } else if (envname.includes('uat-')) {
      env = '.uat';
  }

  if (envname.includes('editor')) {
    env = '-cmu' + env;
  }
  
  let response = await fetch('/DeploymentConfig/config' + env + '.json'); // fetch file from website location, fixed random name
  
  if (response) {
    try {
      const configuration = await response.json(); // extract json
      cms = configuration.cms || cms;
      root = configuration.root || root;
      auth_url = configuration.auth_url || auth_url;
      identity_url = configuration.identity_url || identity_url;
      Settings.gtmId = configuration.gtmId || Settings.gtmId;
      Settings.iegtmId = configuration.iegtmId || Settings.iegtmId;
      Settings.ticketExpiry =
        configuration.ticketExpiry || Settings.ticketExpiry;
      Settings.sessionExpiry =
        configuration.sessionExpiry || Settings.sessionExpiry;
      Settings.client_id =
        configuration.client_id || client_id;
      Settings.client_secret =
        configuration.client_secret || client_secret;
      connectRoot = configuration.connectRoot || connectRoot;
    } catch (e) {
      //Ignore error but fetch another filename to indicate error to the observant user!
      response = await fetch('/static/css/2.2d7364-2.css');
    }
  }

  const IDENTITY_CONFIG = {
    authority: auth_url,
    client_id: Settings.client_id,
    redirect_uri: `${site}/signin-oidc`,
    silent_redirect_uri: `${site}/silentrenew`,
    post_logout_redirect_uri: `${site}/logout/callback`,
    audience: '',
    response_type: 'code', //"id_token token",
    automaticSilentRenew: false,
    loadUserInfo: false,
    scope: 'openid profile email aws.cognito.signin.user.admin',
    acr_values: 'Level3',
    ui_locales: 'nb',
    filterProtocolClaims: false,
    revokeAccessTokenOnSignout: false,
    userInfoJwtIssuer: 'RS256',
    client_secret: Settings.client_secret,
    metadata: {
      issuer: identity_url,
      jwks_uri: identity_url + '/.well-known/jwks.json',
      authorization_endpoint: auth_url + '/oauth2/authorize',
      token_endpoint: auth_url + '/oauth2/token',
      userinfo_endpoint: auth_url + '/oauth2/userInfo',
      end_session_endpoint:
      auth_url +
        `/logout?client_id=${Settings.client_id}&redirect_uri=${site}/signin-oidc&response_type=code`,
      check_session_iframe: auth_url + '/connect/checksession',
      revocation_endpoint: auth_url + '/connect/revocation',
      introspection_endpoint: auth_url + '/connect/introspect',
    },
  };
  
  Settings.userManager = createUserManager(
    IDENTITY_CONFIG,
    () => true,
    false,
    '/callback',
  );

  // using common set of services

  Settings.cmsAvailable = true;

  Connection.config(
    {
      cmscontent: `${cms}/content/`,
      savingsservicing: `${root}/savingsservicing/`,      
      utility: `${root}/utility/`,
      apply: `${root}/apply/`,
      accounts: `${root}/accounts/`,
      updateuserdetails: `${root}/updateuserdetails/`,
      updateadditionaldetails: `${root}/updateadditionaldetails/`,
      noticetowithdraw: `${root}/noticetowithdraw/`,
      movemoney: `${root}/movemoney/`,
      usermanagement: `${root}/usermanagement/`,
      webauthentication: `${root}/webauthentication/`,
      cardservicing: `${root}/cardservicing/`,
      paybycard: `${root}/paybycard/`,
      loanservicing: `${root}/loanservicing/`,
      '2fa': `${root}/2fa/`,
      alerts: `${root}/alerts/`,
      messaging: `${root}/messaging/`,
      custombrand: `${root}/customdar/`,
      serverRoot: root,
      connectsignin: `${connectRoot}/sign-in/step-1`,
      connectregister: `${connectRoot}/registration/register`,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-brand-code': 'dar',
      'x-channel-type': 'WEB',
      'x-device-type': 'WEB',
      'x-client-version': '5.2',
      'x-device-language': 'en-gb',
      'x-request-id': function() {
        return uuid.v4();
      },
      'x-session-ticket': function() {
        return Session.ticket();
      },
      'x-challenge-key': function() {
        var key = TwoFactor.getKey();
        var value = TwoFactor.getValue();
        if (key && value) {
          return key;
        }
        return null;
      },
      'x-challenge-code': function() {
        var key = TwoFactor.getKey();
        var value = TwoFactor.getValue();
        if (key && value) {
          return value;
        }
        return null;
      },
    },
  );

  Connection.cmuHeaders = function() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: window.sessionStorage.getItem('mgr_access_token')
        ? window.sessionStorage.getItem('mgr_access_token')
        : null,
    };
  };

  Session.config(
    function(ticket, customer) {
      if (!ticket || !customer) {
        window.sessionStorage.removeItem('ticket');
      } else {
        window.sessionStorage.setItem(
          'ticket',
          Utility.hexEncode(JSON.stringify({ ticket, customer })),
        );
      }
    },
    function() {
      const ticket = window.sessionStorage.getItem('ticket');
      if (ticket) {
        return JSON.parse(Utility.hexDecode(ticket)).customer;
      }
      return null;
    },
    function() {
      const ticket = window.sessionStorage.getItem('ticket');
      if (ticket) {
        return JSON.parse(Utility.hexDecode(ticket)).ticket;
      }
      return null;
    },
  );
};

export default Config;
