import React from 'react';
import { Content, AppMeta, Switch } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import SaveForm from './ResumeForm';
import SaveExpired from '../responses/Expired';
import { storage } from '../storageName';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import { CONTACTS } from '@myie/interact-local-dom';
import stateUtility from '../../../shared/stateManager/stateUtility';

const META_TITLE = {
  ResumeForm: 'Resume Application',
  SaveSuccess: 'Application Saved',
  Failed: 'Resume Application Failed',
};

const META_DESCRIPTION = {
  ResumeForm: 'Enter your details to resume your application.',
  SaveSuccess: 'Your application has been saved.',
  Failed: 'Unfortunately we were unable to retrieve your application.',
};

class ResumeIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  componentDidUpdate() {
    const { history, resume } = this.props;
    if (this.state.stage === 'Success' && resume?.Application) {
      const application = resume.Application
        ? JSON.parse(resume.Application)
        : {};
      history.push(
        `/long-apply/apply/${application.Application.ProductTypeCode}`,
      );
    }
  }

  componentDidMount() {
    const {
      resetSaveLongApplyForLater,
      resetResumeApplicationData,
      resetResumeApplication,
      resetSaveLongApply,
      resetResumeApplicationMemorableWord,
    } = this.props;
    window.scrollTo(0, 0);
    stateUtility.resetComponentState('longApply');
    resetResumeApplication();
    resetSaveLongApplyForLater();
    resetResumeApplicationData();
    resetSaveLongApply();
    resetResumeApplicationMemorableWord();
  }

  componentWillUnmount() {
    const { resetSaveLongApplyForLater, resetResumeApplication } = this.props;
    resetSaveLongApplyForLater();
    resetResumeApplication();
  }

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  initialState = () => {
    return {
      stage: 'ResumeForm',
      message: '',
    };
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, thisState) => {
    const { stage, message } = thisState;
    const {
      resume,
      updateUrl,
      resumeApplicationData,
      resetResumeApplication,
    } = nextProps;

    if (
      resume &&
      (resume.Status === 'Failed' || resume.Status === 'Expired') &&
      stage !== 'Failed'
    ) {
      let stateData = {
        formData: null,
        url: {
          pageStage: 'Failed',
        },
      };
      resetResumeApplication();
      updateUrl(stateData);
      return {
        stage: 'Failed',
        message: '',
      };
    }
    if (resume && resume.Status === 'Success' && stage !== 'Success') {
      const application = resume.Application
        ? JSON.parse(resume.Application)
        : {};

      if (
        !application?.Application?.ApplicationNumber &&
        resume?.Ticket?.Data?.Reference
      )
        application.Application.ApplicationNumber =
          resume.Ticket.Data.Reference;

      resumeApplicationData(application);

      return {
        stage: 'Success',
        message: '',
      };
    }

    if (resume && resume.Status === 'Invalid' && message !== 'Invalid') {
      return {
        message: 'Invalid',
      };
    }

    if (
      resume &&
      resume.Status === 'InvalidCredentials' &&
      message !== 'InvalidCredentials'
    ) {
      return {
        message: 'Invalid',
      };
    }

    if (
      resume &&
      resume.Status === 'AlreadySubmitted' &&
      message !== 'AlreadySubmitted'
    ) {
      return {
        message: 'AlreadySubmitted',
      };
    }

    if (
      resume &&
      resume.Status === 'ProductUnavailable' &&
      stage !== 'LimitExceeded'
    ) {
      return {
        stage: 'LimitExceeded',
      };
    }

    return null;
  };

  // select current stage
  currentStage = () => {
    const { savedData = {}, resume } = this.props;
    switch (this.state.stage) {
      case 'ResumeForm':
        return <SaveForm setStage={this.setStage} savedData={savedData} />;
      case 'Failed':
        return (
          <SaveExpired setStateDynamic={this.setStateDynamic} resume={resume} />
        );

      default:
    }
  };

  render() {
    const { stage, message } = this.state;
    if (stage === 'LimitExceeded') {
      return <Redirect to="/long-apply/not-available" />;
    }
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={META_TITLE[stage]}
          metaDescription={META_DESCRIPTION[stage]}
        />
        {stage === 'ResumeForm' ? (
          <Content
            tag="h1"
            cmsTag="Long-apply:Save-and-resume:Resume-application:Resume-index:Save-form:h1"
            copytext="Resume your application"
          />
        ) : (
          ''
        )}
        {stage === 'SaveSuccess' ? (
          <Content
            tag="h1"
            cmsTag="Long-apply:Save-and-resume:Resume-application:Save-index:Save-success:h1"
            copytext="Application saved"
          />
        ) : (
          ''
        )}
        {stage === 'Failed' ? (
          <Content
            tag="h1"
            cmsTag="Long-apply:Save-and-resume:Resume-application:Save-index:Resume-failed:h1"
            copytext="Application not found"
          />
        ) : (
          ''
        )}
        <Switch
          id="message"
          className="alert alert-danger"
          value={message || ''}
          contents={{
            Invalid: {
              defaultValue:
                'We cannot find a saved application with this reference number and password. Please check and try again.',
            },
            InvalidCredentials: {
              defaultValue:
                'You have entered a common password, please try again.',
            },
            AlreadySubmitted: {
              defaultValue:
                'Your application is being assessed and we will get in touch when we have completed our assessment.',
            },
          }}
        />
        {this.currentStage()}
      </React.Fragment>
    );
  }
}

ResumeIndex.propTypes = {
  accounts: PropTypes.object,
  resume: PropTypes.object,
  resetResumeApplication: PropTypes.func,
  match: PropTypes.any,
  error: PropTypes.any,
  resetSaveLongApplyForLater: PropTypes.func,
  savedData: PropTypes.object,
  isFetching: PropTypes.any,
  history: PropTypes.object,
  resetResumeApplicationData: PropTypes.func,
  resetSaveLongApply: PropTypes.func,
  resetResumeApplicationMemorableWord: PropTypes.func,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapSavingsServicingDispatchToProps },
  )(withRouter(ResumeIndex)),
  storage.name,
);
