import React from 'react';
import PropTypes from 'prop-types';

class Fonts extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="fonts-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/typography.scss</strong>
          </code>
        </p>
        <dl className="dl-horizontal">
          <dt>Font:</dt>
          <dd>Calibri, Arial, Open Sans;</dd>
          <dt className="font-light">Font Light:</dt>
          <dd>Calibri, Arial, Open Sans;</dd>
          <dt>Font Italic:</dt>
          <dd>
            <em>Calibri, Arial, Open Sans;</em>
          </dd>
          <dt>Font SemiBold:</dt>
          <dd className="font-semiBold">Calibri, Arial, Open Sans;</dd>
          <dt>Font Bold:</dt>
          <dd>
            <strong>Calibri, Arial, Open Sans;</strong>
          </dd>
        </dl>
      </section>
    );
  }
}

Fonts.propTypes = {
  items: PropTypes.string,
};

export default Fonts;
