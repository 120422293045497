import React from 'react';
import MetaDefault from './MetaDefault';
import MetaOverride from './MetaOverride';
import PropTypes from 'prop-types';
import contentManaged, {
  ContentType,
} from '../../HO_components/contentManaged';
/**
 * AppMeta component
 */
class AppMeta extends React.Component {
  constructor(props) {
    super(props);
    const { metaDescription, title, managedContent } = props;
    managedContent(
      {
        metaDescription: { defaultValue: metaDescription },
        title: { defaultValue: title },
      },
      true,
    );
    this.state = {
      titleValue: '',
      metaDescriptionValue: '',
      localDescription: '',
      localTitle: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { title, metaDescription, managedContent, contentValue } = props;
    let { titleValue, ...rest } = state;
    if (title !== titleValue) {
      managedContent(
        {
          metaDescription: { defaultValue: metaDescription },
          title: { defaultValue: title },
        },
        true,
      );
      return {
        ...rest,
        titleValue: title,
        metaDescriptionValue: metaDescription,
        localDescription: contentValue(ContentType.Text, 'metaDescription'),
        localTitle: contentValue(ContentType.Text, 'title'),
      };
    }
    return null;
  }
  // select current stage
  currentStage = () => {
    const {
      stage,
      contentValue,
      managedContent,
      contacts,
      ...rest
    } = this.props;
    if (!managedContent()) return false;
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const metaDescription = contentValue(ContentType.Text, 'metaDescription');
    const title = contentValue(ContentType.Text, 'title');
    switch (stage) {
      case 'child':
        return (
          <MetaOverride
            id="meta-date-default"
            contacts={contacts}
            metaDescription={metaDescription}
            title={title}
            {...rest}
          />
        );
      default:
    }
    return (
      <MetaDefault
        id="meta-data"
        contacts={contacts}
        metaDescription={metaDescription}
        title={title}
        {...rest}
      />
    );
  };

  render() {
    return <React.Fragment>{this.currentStage()}</React.Fragment>;
  }
}

AppMeta.propTypes = {
  /**
   * current stage
   */
  stage: PropTypes.string,
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func,
  metaDescription: PropTypes.string,
  title: PropTypes.string,
  contacts: PropTypes.object,
};

export default contentManaged(AppMeta);
