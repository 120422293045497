import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, LinkTag, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Row, Col } from 'reactstrap';

class DecisionReferred extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { resetSaveLongApply } = this.props;
    resetSaveLongApply();
  }

  render() {
    const { saveLongApplyData } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a New Savings Account - Application Referred"
          metaDescription="Your application has been referred. We will contact you once it has been reviewed."
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Apply-pages:Application-responses:Referred:h1"
          copytext="Thank you for your application"
        />
        <Markdown
          cmsTag="Long-apply:Apply-pages:Application-responses:Referred:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nThank you for submitting your application for a new Savings account. Your application will be reviewed and a member of the team will be in touch soon.\n\n If you have any questions, please call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />
        <div className="referral-number-container">
          <Row
            tag="dl"
            title="Your referral details"
            className="account-details-dl mb-0"
          >
            <Col tag="dt" sm="12" md="6">
              <Content
                cmsTag="GLOBAL:Referral-number"
                copytext="Referral number:"
              />
            </Col>
            <Col tag="dd" sm="12" md="6">
              {saveLongApplyData ? saveLongApplyData.ReferenceNumber : ''}
            </Col>
          </Row>
        </div>
        <div className="form-button-group">
          <LinkTag
            id="expired-application-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

DecisionReferred.propTypes = {
  match: PropTypes.any,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  resetSaveLongApply: PropTypes.func,
  formData: PropTypes.object,
  saveLongApplyData: PropTypes.object,
  form: PropTypes.any,
  applicationStatus: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DecisionReferred);
