export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const RESET_ACTIVATION = 'RESET_ACTIVATION';

export const SET_CREDENTIALS_REQUEST = 'SET_CREDENTIALS_REQUEST';
export const SET_CREDENTIALS_SUCCESS = 'SET_CREDENTIALS_SUCCESS';
export const SET_CREDENTIALS_FAILURE = 'SET_CREDENTIALS_FAILURE';

export const SETUP_CREDENTIALS_REQUEST = 'SETUP_CREDENTIALS_REQUEST';
export const SETUP_CREDENTIALS_SUCCESS = 'SETUP_CREDENTIALS_SUCCESS';
export const SETUP_CREDENTIALS_FAILURE = 'SETUP_CREDENTIALS_FAILURE';
export const RESET_SETUP_CREDENTIALS = 'RESET_SETUP_CREDENTIALS';

export const SETUP_CREDENTIALS_ACTIVATE_REQUEST =
  'SETUP_CREDENTIALS_ACTIVATE_REQUEST';
export const SETUP_CREDENTIALS_ACTIVATE_SUCCESS =
  'SETUP_CREDENTIALS_ACTIVATE_SUCCESS';
export const SETUP_CREDENTIALS_ACTIVATE_FAILURE =
  'SETUP_CREDENTIALS_ACTIVATE_FAILURE';
export const RESET_SETUP_CREDENTIALS_ACTIVATE =
  'RESET_SETUP_CREDENTIALS_ACTIVATE';
