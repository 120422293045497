import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import InputMask from 'react-input-mask';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * Date component
 * will display an input field
 * input will be formatted with the passing mask
 */
class Mask extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      placeholder,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      id,
      suffixIsDiv,
      suffixAlert,
      children,
      disabled,
      required,
      validation = {},
      labelClassName = '',
      groupClassName = '',
      mask,
      field,
      maskChar,
      showErrors = true,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }
    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          required ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {required ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <InputMask
          onChange={props.onChange}
          maskChar={maskChar}
          onBlur={props.onBlur}
          mask={mask}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
        >
          {() => (
            <Input
              id={id}
              invalid={Validate.isInvalid(state)}
              valid={Validate.isValid(state)}
              placeholder={placeholder}
              name={fieldName}
              disabled={disabled}
              required={required}
              {...dynamicAriaDescribedBy}
              {...rest}
            />
          )}
        </InputMask>
        {suffix ? (
          suffixAlert ? (
            <div
              className="alert alert-info mt-3 w-auto d-inline-block"
              id={`${id}-desc`}
            >
              <p>{suffix}</p>
            </div>
          ) : suffixIsDiv ? (
            <div className="suffix" id={`${id}-desc`}>
              {suffix}
            </div>
          ) : (
            <p className="suffix" id={`${id}-desc`}>
              {suffix}
            </p>
          )
        ) : (
          ''
        )}
        {children}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

Mask.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * Is input disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Date ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * Any character can be escaped with a backslash
   */
  mask: PropTypes.string.isRequired,
  /**
   * Character to cover unfilled parts of the mask
   */
  maskChar: PropTypes.string,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * onChange function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
};

export default contentManaged(Mask);
