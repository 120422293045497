import React from 'react';
import PropTypes from 'prop-types';
import { Markdown } from '@myie/interact-dom';

class EmailAddress extends React.Component {
  render() {
    const { contacts } = this.props;
    return (
      <Markdown
        cmsTag="GLOBAL:Email-link"
        template={{
          markdown: {
            email: contacts.email,
          },
        }}
        markdown={`<a href="mailto:$[email]">$[email]</a>`}
      />
    );
  }
}

EmailAddress.propTypes = {
  items: PropTypes.string,
  contacts: PropTypes.object,
};

export default EmailAddress;
