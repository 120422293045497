import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'primary-fill',
  fill2 = 'light-fill',
  width = '512',
  height = '512',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 426.7 426.7"
    viewBox="0 0 426.7 426.7"
  >
    <path
      className={fill2}
      d="M42.7,426.7C19.1,426.7,0,407.6,0,384V106.7C0.1,47.8,47.8,0.1,106.7,0H320c58.9,0.1,106.6,47.8,106.7,106.7v128c-0.1,58.9-47.8,106.6-106.7,106.7H124.4c-4.8,0-9.4,1.6-13.2,4.5l-34.7,63.4c-1,1.8-2.2,3.4-3.6,4.8C64.8,422.1,54,426.7,42.7,426.7L42.7,426.7z M106.7,42.7c-35.3,0-64,28.7-64,64v275.5l32.8-59.9c1-1.8,2.2-3.4,3.6-4.8c12-12.1,28.3-18.8,45.3-18.8H320c35.3,0,64-28.7,64-64v-128c0-35.3-28.7-64-64-64H106.7z"
    />
    <path
      className={fill}
      d="M213.3,192c-11.8,0-21.3-9.6-21.3-21.3v-64c0-11.8,9.6-21.3,21.3-21.3c11.8,0,21.3,9.6,21.3,21.3v64C234.7,182.4,225.1,192,213.3,192z"
    />
    <circle className={fill} cx="213.3" cy="234.7" r="21.3" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
