import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';

class Buttons extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="buttons-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/buttons.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>buttons</strong> defined
          within reactstrap. To see an interactive technical specification for
          this component, go to:
          <br />
          <Link to="https://reactstrap.github.io/components/buttons/">
            https://reactstrap.github.io/components/buttons/
          </Link>
          <br />
          <Link to="https://reactstrap.github.io/components/button-group/">
            https://reactstrap.github.io/components/button-group/
          </Link>
          <br />
          <Link to="https://reactstrap.github.io/components/button-dropdown/">
            https://reactstrap.github.io/components/button-dropdown/
          </Link>
        </p>
        <Row>
          <Col xs={4} sm={3}>
            <Button color="primary">Primary</Button>
            <span>Type: primary action</span>
            <span>Class: btn-primary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="secondary">Secondary</Button>
            <span>Type: secondary action</span>
            <span>Class: btn-secondary</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="light">Tertiary (Light)</Button>
            <span>Type: Cancel / back action</span>
            <span>Class: btn-light</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="link">Link</Button>
            <span>Type: link action</span>
            <span>Class: btn-link</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={3}>
            <Button color="primary" disabled>
              Disabled
            </Button>
            <span>Type: Primary disabled action</span>
            <span>Class: any (add attribute of disabled)</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="secondary" disabled>
              Disabled
            </Button>
            <span>Type: Secondary disabled action</span>
            <span>Class: any (add attribute of disabled)</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="light" disabled>
              Disabled
            </Button>
            <span>Type: Light disabled action</span>
            <span>Class: any (add attribute of disabled)</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="link" disabled>
              Link
            </Button>
            <span>Type: Disabled link action</span>
            <span>Class: btn-link (add attribute of disabled)</span>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={3}>
            <Button color="primary" size="lg">
              Large
            </Button>
            <span>Type: Large button</span>
            <span>Class: bt-lg, in addition to any of the colour classes</span>
          </Col>
          <Col xs={4} sm={3}>
            <Button color="primary" size="sm">
              Small
            </Button>
            <span>Type: Small button</span>
            <span>Class: btn-sm, in addition to any of the colour classes</span>
          </Col>
          <Col xs={4} sm={3}>
            <ButtonGroup>
              <Button>Button 1</Button>
              <Button>Button 2</Button>
            </ButtonGroup>

            <span>Type: Toggle state buttons</span>
            <span>Class: stackbutton &amp; gridbutton</span>
          </Col>
        </Row>
      </section>
    );
  }
}

Buttons.propTypes = {
  items: PropTypes.string,
};

export default Buttons;
