import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'secondary-2-fill',
  height = '298.667',
  width = '98.667',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 298.667 298.667"
    height={height}
    width={width}
    xmlSpace="preserve"
  >
    <polygon
      className={fill}
      points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
