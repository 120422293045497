import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Content, Markdown } from '@myie/interact-dom';

class HelpContactSidebar extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open('/help');
      return false;
    }
    return true;
  };

  render() {
    const { contacts } = this.props;
    return (
      <Card color="primary" className="help-card">
        <CardHeader>
          <Content
            tag="h2"
            cmsTag="Shared:Components:Help-contact-sidebar:h2-1"
            copytext="Contact our Online Support Team"
          />
        </CardHeader>
        <CardBody>
          <Markdown
            template={{
              markdown: {
                phone: contacts.phone,
                phoneLink: contacts.phoneLink,
                email: contacts.email,
                openTimes: contacts.openTimes,
                companySiteName: contacts.companySiteName,
              },
            }}
            cmsTag="Shared:Components:Help-contact-sidebar:p1"
            markdown={`\nIf you have a query about $[companySiteName], our Online Support Team are on hand to help. You can email <a href="mailto:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
          />
        </CardBody>
      </Card>
    );
  }
}

HelpContactSidebar.propTypes = {
  items: PropTypes.string,
  contacts: PropTypes.object,
};

export default HelpContactSidebar;
