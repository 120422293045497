import { combineReducers } from 'redux';
import interactReducers from './interactReducers';
import { custombrandAuthenticationDefinition } from '@myie/interact-brand-authentication';
import { longApplyDefinition } from '@myie/interact-brand-savings-servicing';

const includeCustombrandReducers = {
  ...interactReducers,
  longApplyDefinition,
  custombrandAuthenticationDefinition,
};

const appReducer = combineReducers({ ...includeCustombrandReducers });

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = { cmsDefinition: state.cmsDefinition };
  }
  return appReducer(state, action);
};
export default rootReducer;
