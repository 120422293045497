import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from '../actions/registrationTypes';
import { RESET_REGISTRATION } from '../actions/registrationTypes';

const registrationDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_REGISTRATION:
      return {
        ...state,
        registration: {},
        credentialsSetup: {},
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registration: action.registration,
        isFetching: action.isFetching,
      };
    case REGISTER_REQUEST:
    case REGISTER_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default registrationDefinition;
