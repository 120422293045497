import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  Content,
  Text,
  RULES,
  FormErrorList,
  LinkTag,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Validate } from '@myie/interact';

class ResumeForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    window.scrollTo(0, 0);
  }
  initialState = () => {
    return {
      form: {
        reference: {
          rules: {
            title: 'Reference ',
            stop: true,
            required: {
              message: 'Please enter a reference number.',
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
          },
        },
      },
    };
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    var { form } = this.state;
    const { resumeApplication, setResumeApplicationMemorableWord } = this.props;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }

    let request = {
      Reference: form.reference.value,
      MemorableWord: form.password.value,
      ExtendedProperties: [],
    };
    setResumeApplicationMemorableWord(form.password.value);
    resumeApplication(request);
  };

  render() {
    const { form = {} } = this.state;

    return (
      <React.Fragment>
        <Content
          tag="p"
          cmsTag="Long-apply:Save-and-resume:Resume-application:Resume-form:p1"
          copytext="To resume your application please enter your reference number and password. You can find your reference number in the email that we sent you when you last saved your application."
        />
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <form autoComplete="off" onSubmit={this.onSubmitHandler}>
          <Text
            label="Reference number"
            cmsTag="GLOBAL:Reference-number"
            id="reference"
            field="reference"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.reference}
            type="text"
          />
          <Text
            label="Password"
            cmsTag="GLOBAL:Password"
            id="password"
            field="password"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.password}
            type="password"
          />

          <div className="form-button-group">
            <Button id="save-application" type="submit" color="primary">
              <Content
                cmsTag="GLOBAL:Save-application"
                copytext="Resume application"
              />
            </Button>
            <LinkTag
              id="save-application-back-button"
              className={`btn btn-secondary`}
              role="button"
              href={CONTACTS.externalApplyHomeURL}
            >
              <Content
                cmsTag="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </LinkTag>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ResumeForm.propTypes = {
  match: PropTypes.any,
  resetMakeWithdrawal: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
  longApplyStage: PropTypes.string,
  formData: PropTypes.object,
  resumeApplication: PropTypes.func,
  setResumeApplicationMemorableWord: PropTypes.func,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResumeForm);
