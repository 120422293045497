import React, { Component } from 'react';
import PropTypes from 'prop-types';
class Iframe extends Component {
  render() {
    const { url, iframeclassname, title, iframepermissions } = this.props;
    if (url) {
      return (
        <iframe
          src={url}
          className={`${iframeclassname}`}
          title={`${title}`}
          allow={iframepermissions}
        />
      );
    }
    return null;
  }
}
Iframe.propTypes = {
  url: PropTypes.any,
  iframeclassname: PropTypes.any,
  title: PropTypes.any,
  iframepermissions: PropTypes.string,
};
export default Iframe;
