import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ExceededRequests extends React.Component {
  retry = e => {
    const { resetDestinations, resetChallenge } = this.props;
    e.preventDefault();
    resetDestinations();
    resetChallenge();
  };

  cancel = e => {
    const { finishTwoFactor } = this.props;
    e.preventDefault();
    finishTwoFactor();
  };

  render() {
    return (
      <React.Fragment>
        <h1 id="request-cancelled" aria-describedby="alert-request-cancelled">
          <Content
            cmsTag="Two-factor:request-cancelled:h1"
            copytext="Request cancelled"
          />
        </h1>
        <Markdown
          cmsTag="Two-factor:request-cancelled:p1"
          markdown={`\nYou have made too many requests for an authentication code. For security reasons we have cancelled your request.`}
        />
        <Markdown
          cmsTag="Two-factor:request-cancelled:p2"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nPlease try again in 24 hours or contact our Online Support Team. You can email <a href="tel:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          {this.props.isAuthenticated ? (
            <Link
              id="request-cancelled-back-to-home"
              className={`btn btn-secondary`}
              to={'/accounts/list'}
            >
              <Content
                id="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </Link>
          ) : (
            <LinkTag
              id="request-cancelled-back-to-home"
              className={`btn btn-secondary`}
              role="button"
              href={CONTACTS.externalHomeURL}
            >
              <Content
                id="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </LinkTag>
          )}
        </div>
      </React.Fragment>
    );
  }
}

ExceededRequests.propTypes = {
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExceededRequests);
