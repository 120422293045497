import React, { Component } from 'react';
import { Markdown } from '@myie/interact-dom';

class ContactFooter extends Component {
  render() {
    return (
      <Markdown
        tag="p"
        tagClassName="contact-us-container"
        cmsTag="Long-apply:Apply-pages:page-components:Contact-footer:p1"
        template={{
          copytext: {
            contactURL: '#',
          },
        }}
        copytext={`Need help? <a href="$[contactURL]">Contact us</a> `}
      />
    );
  }
}

ContactFooter.propTypes = {};

export default ContactFooter;
