import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, LinkTag, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class DecisionDeclined extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a New Savings Account - Application Declined"
          metaDescription="Your application has been declined."
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Apply-pages:Application-responses:Declined:h1"
          copytext="Thank you for your application"
        />
        <Markdown
          cmsTag="Long-apply:Apply-pages:Application-responses:Declined:section-1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nWe are unable to verify your identity electronically to open your account. Please don’t be alarmed, there are several reasons why this can happen. To continue with your application please contact one of our Branches or Customer Contact Centre on <a href="tel:$[phoneLink]">$[phone]</a> and a member of staff will help. `}
        />
        <div className="form-button-group">
          <LinkTag
            id="expired-application-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

DecisionDeclined.propTypes = {
  match: PropTypes.any,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DecisionDeclined);
