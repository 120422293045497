import * as depositProducts from './actions/depositProducts';
import * as addressLookup from './actions/addressLookup';
import * as systemDowntime from './actions/systemDowntime';

const mapDispatchToProps = {
  ...depositProducts,
  ...addressLookup,
  ...systemDowntime,
};

export default mapDispatchToProps;
