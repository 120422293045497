import React from 'react';
import PropTypes from 'prop-types';
import TwoFactorFlow from './TwoFactorFlow';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { withRouter } from 'react-router-dom';

class TwoFactorPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }
  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  render() {
    const { inProgress, children } = this.props;
    //TwoFactorFlow is displayed overlapping the main content. So it's categorized as a dialog rather than the page content in terms of accessibility.
    return (
      <React.Fragment>
        {inProgress ? (
          <div
            id="two-factor-authentication-pages"
            /* NOTE FOR JEN: leave this here for now 
			aria-live="assertive"
            aria-atomic="true"
            aria-relevant="all"*/
          >
            <TwoFactorFlow />
          </div>
        ) : null}
        <div
          aria-hidden={inProgress || null}
          className={inProgress ? 'd-none' : null}
        >
          {children}
        </div>
      </React.Fragment>
    );
  }
}

TwoFactorPages.propTypes = {
  children: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  inProgress: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TwoFactorPages));
