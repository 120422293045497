import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Content, Text, RULES, FormErrorList } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import appUtility from '../../apply-pages/requestPayload';

class SaveForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    window.scrollTo(0, 0);
    if (this.props.isResumedApplication) {
      this.submitApplication();
    }
  }
  initialState = () => {
    return {
      form: {
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please confirm your password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
            compare: {
              message: 'Please ensure the password fields match.',

              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
      },
    };
  };

  submitApplication = () => {
    const {
      saveLongApplyForLater,
      formData,
      longApplyStage,
      selectedProduct,
      resumeMemorableWord,
      isResumedApplication,
    } = this.props;

    const { form } = this.state;
    let stateData = {
      formData: formData,
      longApplyStage: longApplyStage,
      productCode: selectedProduct ? selectedProduct.Product_Code : '',
    };

    let application = appUtility.getPayload(
      {
        formData: formData,
        selectedProduct,
      },
      true,
    );

    const request = {
      Lastname: formData?.personalDetails?.lastName,
      MemorableWord:
        isResumedApplication && resumeMemorableWord
          ? resumeMemorableWord
          : formData && form?.password?.value
          ? form?.password?.value
          : null,
      Application: JSON.stringify(application),
      Type: 'SaveAndReturn',
      BookMark: null,
      Ticket: {
        Signature: null,
        Data: {
          Reference:
            formData && formData['savedProductCode']
              ? formData['savedProductCode']
              : null,
          Token: null,
          UserId: '{00000000-0000-0000-0000-000000000000}',
          Timestamp: new Date().toUTCString(),
          Type: null,
        },
      },
      ExtendedProperties: [
        {
          Name: 'OriginalFormData',
          Value: JSON.stringify(stateData),
        },
      ],
      CloseApplication: false,
    };
    saveLongApplyForLater(request);
  };

  onChange = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    var { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    var { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    this.submitApplication();
  };

  resumeForm = () => {
    const { updateUrl, formData, resumeForm } = this.props;
    let stateData = {
      formData: formData,
      url: {
        pageStage: formData.stage,
      },
    };
    resumeForm(formData.stage);
    updateUrl(stateData);
  };

  render() {
    const { form = {} } = this.state;
    if (this.props.isResumedApplication) {
      return null;
    } else {
      return (
        <React.Fragment>
          <Content
            tag="p"
            cmsTag="Long-apply:Save-and-resume:Save-application:Save-form:p1"
            copytext="As this is the first time you have saved your application, please enter a password which you can then use to resume your application at a later date. Your word must be between 8 and 50 characters long and must contain at least one upper case, lower case, number and special character (e.g. *!?%/+-)."
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Save-and-resume:Save-application:Save-form:p2"
            copytext="Any saved applications will be deleted from our system after 30 days. When returning to an application, we cannot guarantee the product you selected will still be available."
          />
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <form autoComplete="off" onSubmit={this.onSubmitHandler}>
            <Text
              label="Password"
              descriptionExample="*!?%/+-"
              cmsTag="GLOBAL:Password"
              id="password"
              field="password"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.password}
              type="password"
            />
            <Text
              label="Confirm password"
              cmsTag="GLOBAL:Confirm-password"
              id="confirmPassword"
              field="confirmPassword"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.confirmPassword}
              type="password"
            />

            <div className="form-button-group">
              <Button id="save-application" type="submit" color="primary">
                <Content
                  cmsTag="GLOBAL:Save-application"
                  copytext="Save application"
                />
              </Button>
              <Button
                id="resume-application"
                onClick={this.resumeForm}
                color="secondary"
              >
                <Content
                  cmsTag="GLOBAL:Resume-application"
                  copytext="Resume application"
                />
              </Button>
            </div>
          </form>
        </React.Fragment>
      );
    }
  }
}

SaveForm.propTypes = {
  match: PropTypes.any,
  updateUrl: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
  selectedProduct: PropTypes.object,
  longApplyStage: PropTypes.string,
  formData: PropTypes.object,
  savedData: PropTypes.object,
  resumeForm: PropTypes.func,
  saveLongApplyForLater: PropTypes.func,
  resumeMemorableWord: PropTypes.string,
  isResumedApplication: PropTypes.bool,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveForm);
