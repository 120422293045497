import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DropDown } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import PropTypes from 'prop-types';
import { Content, CONSTANTS } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';

class LongApplyLaunchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        productOptions: {
          rules: {
            stop: true,
            required: {
              message: 'Please select product',
            },
          },
          value: '',
        },
      },
    };
  }

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  componentDidMount() {
    const { getDepositProducts, allDepositProducts } = this.props;
    if (!allDepositProducts) {
      getDepositProducts({
        checkLimitExceeded: true,
        isLongApply: true,
      });
    }
  }

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);

    this.setState({ ...this.state, form });
  };

  buildDropdownOptions() {
    const { allDepositProducts } = this.props;
    let output = [];

    allDepositProducts &&
      allDepositProducts.length &&
      allDepositProducts.map((item, index) => {
        if (
          item.Display_For_Long_Apply === CONSTANTS.DISPLAY_FOR_LONG_APPLY &&
          item.Product_Status === CONSTANTS.PRODUCT_STATUS_ACTIVE
        ) {
          output.push(
            <option
              id={`products-option-${item.Product_Code}-----${index}`}
              key={item.Product_Code}
              value={item.Product_Code}
            >
              {item.Product_Name}
            </option>,
          );
        }
      });
    return output;
  }

  render() {
    const { form } = this.state;
    const { allDepositProducts } = this.props;
    // const products = [
    //   {
    //     Notes:
    //       'SHOW_IN_JOURNEY=TRUE<div>PUBLIC_ID=90_day_notice</div><div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=FALSE</div><div>SHOW_IN_SAVINGS_GOALS=TRUE</div><div>AER=0.95%&nbsp;</div></div>',
    //     ProductName: 'Year Fixed Rate Cash ISA (24)',
    //     AccountTypeCode: 'Fixed Rate ISA',
    //     minimumAge: 18,
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-1YR-FRISA-24-V1.pdf',
    //     InterestPaymentFrequency: 'Yearly',
    //     MinimumOperatingBalance: 1,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '0.95',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQUViZWhqL0xmR3JvRHJ1Z2dWMm9aU0EyUUxYUFJ0Q1R6SnFDWWVmYWZUT1ZvVVdISFIvQ1Fwd0g4eHhHY3ZuQm1PN3ZUcldaY25aUjJBRC9LbjJaWXllSVh6b2ExcHhJRVorQTR6eEhQcFl4NGE3VE5rNmQyUHRSRHplT1dUZXkweXBnbFBpTURGaWlOVUh0WDhqN0UyN0draTlWRGdZMnRaVjJLR3kxMXMvVVFBQUFBS1JlM24rYStpazgxbHBxZkRlenlKb0FBQUFBV3NTVG1pUXNMbktkYzVZOWduK3VpeDFxamJrbGpmcXF3Vnk5V3VYMzZMcDBEZG5VNmZVUldsL0djQWQ4bVJWcWFodmRONm1FTUlueFZTUGEyeGttcW9iaWprVXZaNURIZzVTTnZzbmNJUUFyY3JZWUZoOThFRXhSOFhlWlJJSzJMeHE5SCtVWG1QV2xHRm5NcXJ6QU5XS1ZEZXlROTNrU1c4ay9CN0FEaW5nPT0=',
    //     Product_Code: 'S00019',
    //   },
    //   {
    //     Notes:
    //       'SHOW_IN_JOURNEY=TRUE<div>PUBLIC_ID=90_day_notice</div><div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=FALSE</div><div>SHOW_IN_SAVINGS_GOALS=TRUE</div><div>AER=0.95%&nbsp;</div></div>',
    //     ProductName: '90 Day Notice Account (Issue 1)',
    //     AccountTypeCode: 'Fixed Rate ISA',
    //     minimumAge: 18,
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
    //     InterestPaymentFrequency: 'Monthly',
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '0.95',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQUViZWhqL0xmR3JvRHJ1Z2dWMm9aU0EyUUxYUFJ0Q1R6SnFDWWVmYWZUT1ZvVVdISFIvQ1Fwd0g4eHhHY3ZuQm1PN3ZUcldaY25aUjJBRC9LbjJaWXllSVh6b2ExcHhJRVorQTR6eEhQcFl4NGE3VE5rNmQyUHRSRHplT1dUZXkweXBnbFBpTURGaWlOVUh0WDhqN0UyN0draTlWRGdZMnRaVjJLR3kxMXMvVVFBQUFBS1JlM24rYStpazgxbHBxZkRlenlKb0FBQUFBV3NTVG1pUXNMbktkYzVZOWduK3VpeDFxamJrbGpmcXF3Vnk5V3VYMzZMcDBEZG5VNmZVUldsL0djQWQ4bVJWcWFodmRONm1FTUlueFZTUGEyeGttcW9iaWprVXZaNURIZzVTTnZzbmNJUUFyY3JZWUZoOThFRXhSOFhlWlJJSzJMeHE5SCtVWG1QV2xHRm5NcXJ6QU5XS1ZEZXlROTNrU1c4ay9CN0FEaW5nPT0=',
    //     Product_Code: 'S00020',
    //   },
    //   {
    //     Notes:
    //       '<div>SHOW_IN_JOURNEY =TRUE</div><div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=TRUE</div><div>SHOW_IN_EARLYREINVEST=TRUE</div><div>AER=1.10%</div></div>',
    //     ProductName: '18 Month Fixed Rate Deposit (Issue 1)',
    //     AccountTypeCode: 'Fixed Rate ISA',
    //     minimumAge: 18,
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
    //     InterestPaymentFrequency: 'Monthly',
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '1.10',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQVN4aWFpVWt0ZHp1S1U3TEJ0dnAvWlRrRmJrSmljQ1JqRTgvSk5FSDhRQkdkdldiVDROVWRlK2VnaTk1TG1oNS9BWmYxeGRlMzdsTm1SUzJBSGYvcVVnczZyd3ZjZFFLMndHakVzZVlFazljaFMzV0ZpRjFpWTAwTE9heWp0MS85clVmOUlJWjhoZ2NpdEVvM0dqSGNFRVQwbHR6eXowL1N5QXRTKzloMnhHMFFBQUFBZXFVTVp4bEpoclBvdHdyQ1NxWnhVSUFBQUFDTUs4TEhiaVJ3aXJaY1JycTcvRUI5d1RGUStFbXh6L0IxWnB5bExDNXdWREZVcVAva05IeFhFRnJPVUJDdFJlVUN2ck11NGRUWWFIQVZhaXc5WXV4aUt4Z1JLUTE5OS8zSU5WbkFWd0xiNlEzTmhLVzZLZmU1N204OVFJaEJCNVVEeG5GSjNCUWp1aERlaHFUZlI4V1JJYnRTbHlab21SbE9pZG1VT3p1MEJRPT0=',
    //     Product_Code: 'S00021',
    //   },
    //   {
    //     Notes:
    //       'SHOW_IN_JOURNEY=TRUE<div>PUBLIC_ID=120_day_notice</div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=FALSE</div><div>SHOW_IN_SAVINGS_GOALS=TRUE</div><div>AER=1.05%&nbsp;</div>',
    //     ProductName: '120 Day Notice Account (Issue 1)',
    //     AccountTypeCode: 'ISA',
    //     minimumAge: 18,
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
    //     InterestPaymentFrequency: 'Yearly',
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '1.05',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQUFEaWxqdk84UEhaeXUraWxBVUxnREZvQ2ZmZ0RjQ29pVm5jTEF5dTQ2UUpUbUtPd3BwTmhHYUliek45RjdycGxiQzFwTHMxQkFHTnhpK2llaXhhYjVYL1dYZlAyc0VLWkxsZisvY1Jzblo5K05VaEsvQTBZR2VSdmtyTkZjOWpHVlk5MnR3SEJWQkVaeU1lWjh2RnFuMHRHZUIrd080eUFJOHB3YUdNcmxRMFFBQUFBNU84TnVRSnhxcmNLRmRGTjlQa2JVb0FBQUFCMTBJQmxFMWo0TTBHbkRzaEZzeHhGclBub21RYnBWZ213a0h2SVRzeHpKZXU0L0E2ekZDa21qTGdwRlFoZllqZ0UrNHFwUk5haUtYU3RkS0h0SzdrWHhMY29tVUtBOHdYRWNMczZjdjcxYzNvWnZheTgwWHZXOGpYc3IxZGJwTkxNcmIzY1VRRjNLMDBTSU5XbC9SWWxna1Uxak5sWGprTVBqR1Q4YTg2UGFnPT0=',
    //     Product_Code: 'S00022',
    //   },
    //   {
    //     Notes:
    //       'SHOW_IN_JOURNEY=TRUE<div>PUBLIC_ID=1_YrFixed1_Iss_3</div><div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=TRUE</div></div><div>SHOW_IN_EARLYREINVEST=TRUE</div><div>AER=1.45%</div><div><br></div>',
    //     ProductName: '1 Year Fixed Rate Deposit (Issue 3)',
    //     AccountTypeCode: 'Cash ISA',
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
    //     InterestPaymentFrequency: 'Yearly',
    //     minimumAge: 18,
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '1.45',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQVgydTFCZjRUbkhvR1Q2aG14eDhFM09SYnAwU0N6UFhqTFRIYUladGhMckRuRzd4VDIrZjVTejA3QWhUK1pJUVBVQWIycWpMQndpdmtFamFxVjV5TDlWT2RYYmhUNytHc3pNOUJZc3BYU0k1dXprMGtlaS9hWVBqdGo4VGR4dnd1U0NqTE4raGREVGJ1dnV2clpxZlVtOTFCZ3RIWHpyS3lLaW10cEZFZ281SVFBQUFBQ01NNGRxRnpqWWJNSTJ2bWF4V3duNEFBQUFERTYxazAzdlRJY25hSGUyM0F0VDhFM1hTdFVVSHVVNnIvbmUzTGJiWjRrRWR0RTJKL0NxSE9XMXBYZGNqeitjRWpZYmFLYWFzRFJHdmFhVlhaVCtUUmlvc0VSYURjL2ZlbDEySCthczlNRW0xZkd1U1Vvb2pqa201WE1JRFlXYjkxSk1IVXVic2JQRmxaRTFoWXBWNjUySFdOZVNjT1pCcmFodFFMS05HaytRPT0=',
    //     Product_Code: 'S00022',
    //   },
    //   {
    //     Notes:
    //       'SHOW_IN_JOURNEY=FALSE<div>PUBLIC_ID=Easy_access_account</div><div>SHOW_IN_SHORTAPPLY=TRUE</div><div>SHOW_IN_MATURITY=FALSE</div><div>SHOW_IN_SAVINGS_GOALS=FALSE</div><div>AER=0.75%&nbsp;</div><div>JOURNEY_DISPLAY_NAME="Easy Access Account (Issue 1)"</div>',
    //     ProductName: '7 Day Notice Account',
    //     AccountTypeCode: '',
    //     TermsAndConditions:
    //       'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-7-Day-Notice.pdf',
    //     InterestPaymentFrequency: 'Yearly',
    //     minimumAge: 18,
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '0.75',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQW9oaHlJcE5DVjhEc2h4TDNqYWFKa2N0dnN3dEFhbUIzQkdpZWtwRGQ3UWdDMnVuZlI1enNmcFB2SHBaR0JaVzVTajRnN2NpTmV5a2o0bEhPNHlxdU4yeGJVdDgyYitnMk90RGZnVUhzaWc5SkI5Sm1pZGt5UTBSbUcrQ3pHeW85LzdzR3ZJbTJmQjZqRUp3eTZOZHJQa2w5K0FFRGZsOGxGeDBsSGp4YUVPc1FBQUFBMWczamFyeHNGTDJaTEZFcmloRFhISUFBQUFDUTRhdWZjazdjS3JUZmVIT0krNXpmMmFyZkprRWsxczNZaDAwTnVvWDhFRWozRXlMM05SSWlSaWNvSXJmUjF5NlR2TDB6ZTdjYXhXRUVjNGdiRzNobUMvenhVOTBOMXdEYnVtNC9FbVVzTURlYVU1Nll1bkk1VHAzcU9jWUtnak1SUjl0Ylk5cG5rN0h6Z0Q4UTB4aDZjTjA1V1M4QjlzUnpEd1VMZzVFaFRBPT0=',
    //     Product_Code: 'S00023',
    //   },
    //   {
    //     Notes:
    //       '<div><p class="MsoNormal">SHOW_IN_JOURNEY=FALSE</p><p class="MsoNormal">PUBLIC_ID=</p><p class="MsoNormal">SHOW_IN_SHORTAPPLY=TRUE</p><p class="MsoNormal">SHOW_IN_MATURITY=TRUE</p><p class="MsoNormal">SHOW_IN_EARLYREINVEST=FALSE</p><p class="MsoNormal">AER=1.40%</p></div><div><br></div>',
    //     ProductName: '1 Year Fixed Rate Deposit (Loyalty Issue 1)',
    //     AccountTypeCode: '',
    //     TermsAndConditions:
    //       'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
    //     InterestPaymentFrequency: 'both',
    //     minimumAge: 18,
    //     MinimumOperatingBalance: 200,
    //     MaximumOperatingBalance: 3000,
    //     InterestRate: '1.40',
    //     EncodedKey:
    //       'Ly84QkFna0FBQUJrWm1OaGNHbDBZV3lBQUFBQU5UZjdtTVB0aEJwM2hqaU1LcHZJMm05cldtTkdBNDlWcDRpRDJHZURNS0pOc0hpYlVYcjEzemtDZzlKcHZPVWticHYvR2pZWXc3dklJbmZWaENhblFmSktTTXUreU5qYUJ4aHpSc2MxYjhmbjZpdU9Yb0R3NzNhUXNMVFNVUVlDZ1Uya3R2T0RuUXJjN2kwbTgyQlpUaXBiY2dWcGdQWndvemdoa3Nnd0hXSVFBQUFBcHAvdzJNRmppenA1Mjhjc2lqczJ5WUFBQUFCaTh4NHU2dWh3R2owVDN3RXJNZHZYRzBETGt5U0tQRkFwMzFsVlAwWTVyWVNxTkl5ZVY0aWhSeENBN2R6L0FiNkx3S2NZcGo4RVQyNG52MUNjWVBzdStWUlJVVmZtVUR1ZTdjak1pMU02K1Azd0UvWERLVzNlajJZU25jR0hDdjlEV3ZDRDdGSlA0b0dnMFRPVURsQjh1OWtIemE4T2IwMHNGZTExU2ZDelh3PT0=',
    //     Product_Code: 'S00099',
    //   },
    // ];
    if (!allDepositProducts) {
      return null;
    }
    return (
      <div>
        <Content
          tag="h1"
          cmsTag="Long-Apply-Launch-page"
          copytext="Long Apply Launch page"
        />
        <DropDown
          cmsTag="GLOBAL:Purpose"
          label="Products"
          id="productOptions"
          key={Date.now()} //Force redraw when refreshing due to a bug in the core dropdown component
          field="productOptions"
          onChange={this.onChange}
          validation={form.productOptions}
        >
          <option
            id={`products-option-please-select`}
            key="1"
            value=""
            defaultText="Please select"
          ></option>
          {this.buildDropdownOptions()}
        </DropDown>
        <div className="form-button-group">
          {form && form.productOptions.value ? (
            <Link
              className={`btn btn-primary`}
              role="button"
              to={'long-apply/eligibility/' + form.productOptions.value}
            >
              <Content cmsTag="GLOBAL:view-product" copytext={'Apply'} />
            </Link>
          ) : (
            ''
          )}
          <Link
            className={`btn btn-secondary`}
            role="button"
            to={`/resume-application`}
          >
            <Content
              cmsTag="GLOBAL:Resume-application"
              copytext={'Resume application'}
            />
          </Link>
        </div>
      </div>
    );
  }
}

LongApplyLaunchPage.propTypes = {
  fetchAccountsIfNeeded: PropTypes.any,
  getDepositProducts: PropTypes.func,
  allDepositProducts: PropTypes.any,
  accounts: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LongApplyLaunchPage),
);
