import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  width = '86.67',
  height = '200',
  fillGoalProgressOutline = 'goal-progress-outline',
  fillGoalProgressBackground = 'goal-progress-background',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 208 480"
    viewBox="0 0 208 480"
  >
    <path
      className={fillGoalProgressBackground}
      d="M104,0c30.9,0,56,25.1,56,56v40v16h39.3v16H160v104h39.3v16H160v40.3c12.7,8.1,23.5,18.9,31.6,31.6
		c30.9,48.4,16.8,112.7-31.6,143.6c-48.4,30.9-112.7,16.8-143.6-31.6C-14.5,383.5-0.4,319.2,48,288.3V56C48,25.1,73.1,0,104,0H0v480
		h208V0H104z"
    />
    <path
      className={fillGoalProgressOutline}
      d="M191.6,320c-8.1-12.7-18.9-23.5-31.6-31.6v-40.3h39.3v-16H160v-104h39.3v-16H160v-16v-40c0-30.9-25.1-56-56-56
		c-30.9,0-56,25.1-56,56v232.3C-0.4,319.3-14.5,383.6,16.4,432c30.9,48.4,95.2,62.5,143.6,31.6C208.4,432.6,222.5,368.3,191.6,320z
		 M104,464.1c-48.6,0-88.1-39.3-88.1-87.9c0-31.5,16.8-60.6,44.1-76.4c2.5-1.4,4-4,4-6.9V56.1c0-22.1,17.9-40,40-40
		c22.1,0,40,17.9,40,40v236.8c0,2.9,1.5,5.5,4,6.9c42.1,24.2,56.6,78,32.4,120.2C164.7,447.3,135.5,464.1,104,464.1z"
    />
  </svg>
);

SVG.propTypes = {
  fillGoalProgressOutline: PropTypes.string,
  fillGoalProgressBackground: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
