import React from 'react';
import { Route } from 'react-router-dom';
import Styleguide from './Styleguide';
//import { PrivateRoute } from '@myie/interact-dom';

const Routes = [
  // <PrivateRoute key="/styleguide" path="/styleguide" component={Styleguide} />,
  <Route key="/styleguide" path="/styleguide" component={Styleguide} />,
];

export default Routes;
