import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Markdown } from '@myie/interact-dom';

/**
 * MetaDefault component
 * will apply metadata application wide
 * e.g: page title, meta description, favicon, etc
 */
class MetaDefault extends React.Component {
  render() {
    const { metaDescription, contacts } = this.props;
    const defaultTitle = (
      <Markdown
        cmsTag="GLOBAL:Company-name"
        template={{
          markdown: {
            companyName: contacts.companyName,
          },
        }}
        markdown={`$[companyName]`}
      />
    );
    return (
      <Helmet titleTemplate={defaultTitle} defaultTitle={defaultTitle}>
        <meta name="description" content={metaDescription} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,
        shrink-to-fit=no"
        />
        <meta name="theme-color" content="#009add" />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
        <link
          rel="shortcut icon"
          href={`${process.env.PUBLIC_URL}/favicon.ico`}
        />
      </Helmet>
    );
  }
}

MetaDefault.propTypes = {
  /**
   * meta description
   */
  metaDescription: PropTypes.string.isRequired,
  contacts: PropTypes.object,
};

export default MetaDefault;
