import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * Check box component
 */
class Check extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
    } = props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  render() {
    const { props } = this;

    const {
      showErrors = true,
      suffixIsDiv,
      suffixAlert,
      validation = {},
      oneError = false,
      children,
      id,
      disabled,
      required,
      checked,
      field,
      className = '',
      labelClassName = '',
      groupClassName = '',
      groupName = '',
      removeFormGroup = true,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = oneError
          ? ` one-error-${groupName}-validation`
          : ` error-${fieldName}-validation`;
      }
    }

    return (
      <React.Fragment>
        {removeFormGroup === true ? (
          <FormGroup
            className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
              required ? 'required-input' : ''
            }`.trim()}
          >
            <Input
              invalid={Validate.isInvalid(state)}
              valid={Validate.isValid(state)}
              className={`${className}`.trim()}
              id={id}
              disabled={disabled}
              checked={checked}
              type="checkbox"
              name={fieldName}
              value={value}
              required={required}
              {...dynamicAriaDescribedBy}
              {...rest}
            />
            <Label
              className={`${labelClassName}`.trim()}
              id={`${id}-label`}
              htmlFor={id}
              check
            >
              <span className="custom-control-text">
                {label}{' '}
                {subLabel.length > 0 ? <small>&nbsp;{subLabel}</small> : ''}
                {isRequired ? (
                  <React.Fragment>
                    <span aria-hidden="true">*</span>
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:-required-field"
                      copytext=" (required field)"
                    />
                  </React.Fragment>
                ) : (
                  ''
                )}
                {description.length > 0 ? (
                  <span className="description">{description}</span>
                ) : (
                  ''
                )}
              </span>
            </Label>
            {children}
            {suffix ? (
              suffixAlert ? (
                <div
                  className="alert alert-info mt-3 w-auto d-inline-block"
                  id={`${id}-desc`}
                >
                  <p>{suffix}</p>
                </div>
              ) : suffixIsDiv ? (
                <div className="suffix" id={`${id}-desc`}>
                  {suffix}
                </div>
              ) : (
                <p className="suffix" id={`${id}-desc`}>
                  {suffix}
                </p>
              )
            ) : (
              ''
            )}
            {showErrors ? (
              <Errors
                contentValue={contentValue}
                validation={validation}
                field={field}
              />
            ) : (
              ''
            )}
          </FormGroup>
        ) : (
          <React.Fragment>
            <Input
              invalid={Validate.isInvalid(state)}
              valid={Validate.isValid(state)}
              className={`${className}`.trim()}
              id={id}
              disabled={disabled}
              checked={checked}
              type="checkbox"
              name={fieldName}
              value={value}
              required={required}
              {...dynamicAriaDescribedBy}
              {...rest}
            />
            <Label
              className={`${labelClassName}`.trim()}
              id={`${id}-label`}
              htmlFor={id}
              check
            >
              <span className="custom-control-text">
                {label} {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
                {description.length > 0 ? (
                  <span className="description">{description}</span>
                ) : (
                  ''
                )}
              </span>
            </Label>
            {children}
            {suffix ? (
              suffixAlert ? (
                <div
                  className="alert alert-info mt-3 w-auto d-inline-block"
                  id={`${id}-desc`}
                >
                  <p>{suffix}</p>
                </div>
              ) : (
                <p className="suffix" id={`${id}-desc`}>
                  {suffix}
                </p>
              )
            ) : (
              ''
            )}
            {showErrors ? (
              <Errors
                contentValue={contentValue}
                validation={validation}
                field={field}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Check.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * if check box is ticked
   */
  checked: PropTypes.bool,
  /**
   * Any children of the component
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  /**
   * checkbox description
   */
  description: PropTypes.string,
  /**
   * is checkbox disabled?
   */
  disabled: PropTypes.bool,
  removeFormGroup: PropTypes.bool,
  /**
   * input field
   */
  field: PropTypes.any,
  groupClassName: PropTypes.string,
  /**
   * checkbox id?
   */
  groupName: PropTypes.string,
  id: PropTypes.any,
  oneError: PropTypes.any,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  /**
   * should show errors
   */
  required: PropTypes.any,
  /**
   * Should show errors?
   */
  showErrors: PropTypes.bool,
  subLabel: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Validation
   */
  validation: PropTypes.object,
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
};

export default contentManaged(Check);
