import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '512px', height = '512px', fill = 'primary-fill' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path
        className={fill}
        d="M4253,5080c-78-20-114-37-183-83c-44-29-2323-2296-2361-2349c-21-29-329-1122-329-1168c0-56,65-120,122-120
		c44,0,1138,309,1166,329c15,11,543,536,1174,1168c837,838,1157,1165,1187,1212c74,116,105,270,82,407c-7,39-30,105-53,154
		c-36,76-55,99-182,226s-150,145-226,182C4515,5103,4390,5116,4253,5080z M4543,4808c55-27,258-231,288-288c20-38,24-60,24-140
		c0-121-18-160-132-279l-82-86l-303,303l-303,303l88,84c49,46,108,93,132,105C4342,4852,4458,4851,4543,4808z M4160,4135l295-295
		l-933-932l-932-933l-295,295c-162,162-295,299-295,305c0,13,1842,1855,1855,1855C3861,4430,3998,4297,4160,4135z M2338,1851
		c-37-12-643-179-645-178c-1,1,30,115,68,252c38,138,79,285,91,329l21,78l238-238C2243,1962,2344,1853,2338,1851z"
      />
      <path
        className={fill}
        d="M480,4584c-209-56-370-206-444-414l-31-85V2310C5,617,6,532,23,476c37-120,77-187,167-277c63-63,104-95,157-121
		c146-73,3-69,2113-66l1895,3l67,26c197,77,336,218,401,409c22,64,22,74,25,710c3,579,2,648-13,676c-21,40-64,64-114,64
		c-33,0-49-7-79-34l-37-34l-5-634c-5-631-5-633-28-690c-41-102-118-179-220-220l-57-23l-1834-3c-1211-1-1853,1-1890,8
		c-120,22-227,104-277,213l-29,62v1760v1760l29,63c37,80,122,161,203,194l58,23l630,5c704,6,664,1,700,77c23,48,13,95-31,138l-35,35
		l-642-1C644,4599,526,4596,480,4584z"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SVG;
