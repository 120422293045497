import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Connection } from '@myie/interact';

class DecisionSuccessISA extends Component {
  setupSecurity = () => {
    const { history, formData, preserveDataforSecurityDetails } = this.props;
    let stateData = {
      formData: {
        b_day: formData['personalDetails'].b_day,
      },
    };

    preserveDataforSecurityDetails(stateData);

    let url = `/security-details/retrieve`;
    history.push(url);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { resetSaveLongApply } = this.props;
    resetSaveLongApply();
  }

  render() {
    const { saveLongApplyData } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a New Savings Account - Application Successful"
          metaDescription="Congratulations! You have successfully applied for an account."
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:h1"
          copytext="Your application was successful"
        />
        <Content
          tag="p"
          cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:p1"
          template={{
            copytext: {
              companyName: CONTACTS.companyName,
            },
          }}
          copytext="Thank you for opening a new Savings account with $[companyName]. Please take note of your account number. Your welcome pack will be sent by your preferred contact method within five working days."
        />
        <div className="referral-number-container">
          <Row
            tag="dl"
            title="Your new account details"
            className="account-details-dl mb-0"
          >
            <Col tag="dt" sm="12" md="6">
              <Content
                cmsTag="GLOBAL:Your-account-number"
                copytext="Your account number:"
              />
            </Col>
            <Col tag="dd" sm="12" md="6">
              {saveLongApplyData ? saveLongApplyData.ReferenceNumber : ''}
            </Col>
          </Row>
        </div>
        <section>
          <Content
            tag="h2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:h2-1"
            copytext="Transferring funds"
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:p2"
            copytext="Please check the terms and conditions of the account before making your deposit."
          />
          <Content
            tag="h3"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:h3-1"
            copytext="Funding your account by debit card"
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:p3"
            template={{
              copytext: {
                companySiteName: CONTACTS.companySiteName,
              },
            }}
            copytext="To fund your account by debit card, please register for our online service Darlingtonline. You will be able to make a deposit by debit card using this service."
          />
          <Content
            tag="h3"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:h3-2"
            copytext="Funding your account by bank transfer"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:p4"
            template={{
              markdown: {
                companyName: CONTACTS.companyName,
              },
            }}
            markdown={`\nTo transfer money from your bank to your $[companyName] savings account you need to use the following bank details:`}
          />
          <Row tag="dl" title="Nominated account details">
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              {CONTACTS.sortcode}
            </Col>
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              {CONTACTS.accountNumber}
            </Col>
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content
                cmsTag="GLOBAL:Reference-number"
                copytext="Reference number"
              />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              <Markdown
                template={{
                  markdown: {
                    companyName: CONTACTS.companyName,
                  },
                }}
                cmsTag="GLOBAL:Reference-number-description"
                markdown={`Your 8-digit $[companyName] account number`}
              />
            </Col>
          </Row>
        </section>
        <section className="mt-5 pt-3">
          <Content
            tag="h2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-ISA:h2-2"
            copytext="Transferring funds from a cash ISA"
          />
          <Markdown
            template={{
              markdown: {
                cashISAFormURL: CONTACTS.cashISAFormURL,
              },
            }}
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-Success-ISA:p5"
            markdown={`\nYou can transfer funds from an existing cash ISA that you hold with another provider into your Darlington Building Society ISA. To do this, simply print, complete, and sign an <a target="_blank" href="$[cashISAFormURL]">Cash ISA Transfer Form<span className="sr-only"> (Opens in a new window)</span></a> for each cash ISA you wish to transfer. If you don't have access to a printer you can complete the copy of the form that we will send you in your welcome pack.`}
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:p6"
            copytext="Once completed, return the form to us at:"
          />
          <address>
            <Markdown
              cmsTag="GLOBAL:Address-company-name"
              template={{
                markdown: {
                  companyName: CONTACTS.companyName,
                },
              }}
              markdown="$[companyName]"
            />
            <br />
            <Markdown
              cmsTag="GLOBAL:Address"
              template={{
                markdown: {
                  companyFormattedAddress: CONTACTS.companyFormattedAddress,
                },
              }}
              markdown="$[companyFormattedAddress]"
            />
          </address>
        </section>
        <section className="mt-5">
          <Content
            tag="h2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:h2-3"
            copytext="What happens next?"
          />

          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:p7"
            copytext="Once we've received your completed form we will forward it to your old ISA provider within five business days. Please allow up to 15 days from when you receive your form for the transfer to take place. Once the transfer is completed, we will confirm this in writing."
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:p8"
            markdown={`\nFor more information on transfer timelines please see <a href="https://www.gov.uk/individual-savings-accounts/transferring-your-isa" target="_blank">https://www.gov.uk/individual-savings-accounts/transferring-your-isa<span className="sr-only"> (Opens in a new window)</span></a>.`}
          />
          <Content
            tag="p"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:p9"
            copytext="To access your account you need to create your login details. Please make sure you have your mobile phone to set up security details."
          />
          <Markdown
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                email: CONTACTS.email,
                openTimes: CONTACTS.openTimes,
              },
            }}
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-Success-ISA:p10"
            markdown={`\nIf you have any questions, Please call us with your application number on <a href="tel:$[phoneLink]">$[phone]</a> or email us at <a href="mailto:$[email]">$[email]</a>. Our operating hours are $[openTimes].`}
          />
        </section>
        <div className="form-button-group">
          {this.props.registration?.Status === 'Success' ? (
            <Button
              id="expired-application-back-button"
              onClick={() => {
                this.setupSecurity();
              }}
              color="primary"
            >
              <Content
                cmsTag="GLOBAL:Create-login-details"
                copytext="Create login details"
              />
            </Button>
          ) : (
            <LinkTag
              id="connect-register-link"
              className="btn btn-primary"
              role="button"
              href={Connection.baseUrl('connectregister')}
            >
              <Content
                cmsTag="GLOBAL:Create-login-details"
                copytext="Create login details"
              />
            </LinkTag>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DecisionSuccessISA.propTypes = {
  history: PropTypes.any,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  preserveDataforSecurityDetails: PropTypes.func,
  resetSaveLongApply: PropTypes.func,
  saveLongApplyData: PropTypes.object,
  form: PropTypes.any,
  registration: PropTypes.object,
};

const mapStateToProps = state => {
  const { longApplyDefinition, custombrandAuthenticationDefinition } = state;
  return {
    ...longApplyDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DecisionSuccessISA),
);
