import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M3,11.6c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.5,0.3-0.9,0.2-1.2-0.1c-0.1-0.1-0.2-0.3-0.2-0.6 c0-0.4,0.1-0.6,0.4-0.9c0.4-0.3,0.8-0.7,1.2-1s0.8-0.7,1.2-1C3.6,8.6,4,8.3,4.3,8c0.4-0.4,0.9-0.7,1.3-1.1c0.4-0.3,0.8-0.6,1.1-1 c0.4-0.3,0.8-0.7,1.2-1C8.3,4.6,8.7,4.3,9,4c0.4-0.4,0.9-0.8,1.3-1.1c0.3-0.3,0.7-0.6,1-0.8c0.2-0.2,0.4-0.3,0.6-0.3 c0.2,0,0.4,0,0.5,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.5,0.4,1,0.9,1.5,1.3s1,0.9,1.6,1.3c0.6,0.5,1.2,1,1.7,1.5c0.3,0.3,0.7,0.6,1,0.9 c0,0,0,0,0.1,0.1V7.3c0-1.1,0-2.2,0-3.3c0-0.4,0.2-0.7,0.6-0.9c0.5-0.2,1-0.1,1.2,0.2C20.9,3.6,21,3.8,21,4.1c0,1.6,0,3.3,0,4.9v0.1 c0.5,0.4,1.1,0.9,1.6,1.3c0.2,0.2,0.4,0.3,0.6,0.5c0.4,0.4,0.4,0.8,0.2,1.2c-0.2,0.3-0.4,0.4-0.7,0.4c-0.2,0-0.4,0-0.5-0.2 c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.3-0.2-0.4-0.3v0.1c0,2.4,0,4.9,0,7.3c0,0.8-0.2,1.5-0.7,2.1c-0.4,0.5-1,0.9-1.7,1.1 c-0.3,0.1-0.6,0.1-0.9,0.1c-3.8,0-7.6,0-11.4,0c-0.8,0-1.5-0.2-2.1-0.7c-0.4-0.3-0.6-0.7-0.8-1.1c-0.2-0.3-0.3-0.7-0.3-1.1 c0-0.1,0-0.2,0-0.4c0-2.4,0-4.9,0-7.3L3,11.6"
    />
    <path
      className={fill2}
      d="M15.8,22.1H8.1v-8.2c0-0.7,0.6-1.3,1.3-1.3h5.1c0.7,0,1.3,0.6,1.3,1.3V22.1z"
    />
    <rect x="10.1" y="14.5" className={fillNegative} width="3.8" height="5.8" />
    <path
      className={fillNegative}
      d="M12,4L12,4c-0.5,0.4-1,0.8-1.4,1.2c-0.4,0.3-0.8,0.7-1.1,1C8.9,6.6,8.4,7,7.9,7.4C7.5,7.8,7.1,8.1,6.7,8.5 C6.1,9,5.6,9.4,5,9.9L4.9,10c0,3,0,6,0,8.9c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,0.9,0.3c0.6,0,1.2,0,1.8,0h0.1 v-0.1c0-2,0-4,0-5.9c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0.2-0.5,0.4-0.7c0.3-0.3,0.7-0.4,1.1-0.4c1.5,0,3,0,4.5,0c0.3,0,0.6,0.1,0.9,0.3 c0.5,0.3,0.7,0.8,0.7,1.4c0,2,0,3.9,0,5.9v0.1c0.7,0,1.4,0,2.1,0c0.2,0,0.3-0.1,0.5-0.1c0.5-0.2,0.7-0.6,0.7-1.2c0-3,0-6,0-9 c0,0,0-0.1-0.1-0.1c-0.5-0.4-0.9-0.8-1.4-1.2c-0.4-0.3-0.7-0.6-1.1-0.9C16,7.4,15.6,7,15.1,6.6c-0.4-0.3-0.8-0.7-1.1-1 c-0.5-0.4-0.9-0.8-1.4-1.2C12.4,4.3,12.2,4.1,12,4"
    />
    <rect x="15.8" y="18.4" className={fillNegative} width="1.9" height="3.8" />
    <rect x="6.2" y="18.4" className={fillNegative} width="1.9" height="3.8" />
    <ellipse className={fill} cx="17.8" cy="21.2" rx="1" ry="0.9" />
    <ellipse className={fill} cx="6" cy="21.1" rx="1" ry="1" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
