import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { Validate } from '@myie/interact';
import { connect } from 'react-redux';
import { Content, AppMeta } from '@myie/interact-dom';
import { TwoFactor } from '@myie/interact';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { CONTACTS } from '@myie/interact-local-dom';
import NoDestinations from './NoDestinations';
import Destinations from './Destinations';

class SelectDestination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobNumberNotFound: false,
    };
  }

  componentDidMount() {
    const { getDestinations } = this.props;
    getDestinations({
      ChallengeKey: TwoFactor.getKey(),
    });
  }

  componentWillUnmount() {
    const { resetDestinations } = this.props;
    resetDestinations();
  }

  setMobNumberNotFound = () => {
    this.setState({ ...this.state, mobNumberNotFound: true });
  };

  cancel = () => {
    const { finishTwoFactor } = this.props;
    finishTwoFactor({});
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  getErrorMessage = () => {
    const { form } = this.state;

    let message = null;
    if (form.destination.state && Validate.isInvalid(form.destination.state)) {
      message = (
        <div className="alert alert-danger" role="alert">
          <Content
            cmsTag="Two-factor:Select-destination:alert-1"
            copytext="Please select a mobile phone number."
          />
        </div>
      );
    }

    return message;
  };

  render() {
    const { destinations, error } = this.props;
    const { mobNumberNotFound } = this.state;

    if (error) {
      return <NoDestinations error={true} />;
    }
    if (!destinations) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }
    if (
      mobNumberNotFound ||
      !destinations.Destinations ||
      destinations.Destinations.length === 0
    ) {
      return <NoDestinations />;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Two Factor Authentication Step 1"
          metaDescription="Two Factor Authentication Step 1"
        />
        <h1>
          <Content
            cmsTag="Two-factor:Select-destination:h1"
            copytext="Additional authentication"
          />
        </h1>

        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active={true}>
              <span className="sr-only">
                <Content cmsTag="BREADCRUMB:Active" copytext="Active: " />
              </span>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Select-mobile-number"
                copytext="Select mobile number"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={false}>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Enter-code" copytext="Enter code" />
            </BreadcrumbItem>
          </ol>
        </div>
        <p>
          <Content
            cmsTag="Two-factor:Select-destination:p1"
            copytext={
              'In order to proceed, you must enter an authentication code that we will send to your phone.'
            }
          />
        </p>

        <Destinations
          page="activate"
          setDestination={this.props.setDestination}
          setMobNumberNotFound={this.setMobNumberNotFound}
          mobNumberNotFound={this.mobNumberNotFound}
        />
      </React.Fragment>
    );
  }
}

SelectDestination.propTypes = {
  destinations: PropTypes.any,
  error: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  getDestinations: PropTypes.any,
  resetDestinations: PropTypes.any,
  sendChallenge: PropTypes.any,
  setDestination: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectDestination);
