import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '875', height = '875' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 875 875"
    viewBox="0 0 875 875"
  >
    <path
      className={fill}
      d="M554.942,179.688c-71.971,0 -136.677,-46.875 -237.177,-46.875c-45.851,0 -85.404,9.496 -118.243,22.219c1.991,-5.978 3.608,-15.942 3.608,-22.243c-0,-2.247 -0.211,-5.885 -0.471,-8.118c-3.829,-33.496 -31.516,-59.836 -65.157,-62.018c-40.955,-2.657 -75.001,29.776 -75.001,70.16c-0,26.021 14.146,48.723 35.156,60.881l-0,595.368c-0,12.944 10.493,23.437 23.437,23.437l23.438,0c12.944,0 23.437,-10.493 23.437,-23.437l0,-122.232c55.472,-25.307 104.383,-41.83 187.245,-41.83c71.97,-0 136.677,46.874 237.177,46.874c85.662,0 149.372,-33.13 188.302,-58.566c19.861,-12.975 31.806,-35.109 31.806,-58.832l-0,-351.443c-0,-50.477 -51.656,-84.621 -98.004,-64.624c-52.591,22.688 -107.6,41.279 -159.553,41.279Zm187.245,374.999c-31.909,22.576 -89.098,46.875 -149.796,46.875c-87.81,0 -149.417,-46.875 -237.177,-46.875c-63.517,0 -141.18,13.775 -187.245,35.156l0,-339.843c31.911,-22.576 89.098,-46.874 149.796,-46.874c87.81,-0 149.416,46.874 237.177,46.874c63.385,0 141.093,-25.438 187.245,-46.874l-0,351.561Z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
