import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  //fill = 'primary-fill',
  fill2 = 'light-fill',
  width = '1080',
  height = '1080',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 875 878"
  >
    <path
      d="M578.125,580.522l0,-188.362c0,-24.133 -9.167,-46.167 -24.201,-62.801c26.992,-29.28 41.779,-67.064 41.779,-107.121c0,-87.431 -70.756,-158.203 -158.202,-158.203c-87.432,0 -158.203,70.756 -158.203,158.203c-0,30.589 8.621,59.853 24.718,85.015c-31.885,14.98 -54.015,47.409 -54.015,84.907l-0,57.589c-0,34.63 18.873,64.934 46.875,81.167l-0,49.606c-28.002,16.233 -46.875,46.538 -46.875,81.168l-0,58.594c-0,51.694 42.055,93.75 93.75,93.75l187.499,-0c51.695,-0 93.75,-42.056 93.75,-93.75l0,-58.594c0,-34.63 -18.873,-64.935 -46.875,-81.168Zm-140.624,-446.174c48.54,-0 87.89,39.35 87.89,87.89c-0,48.54 -39.35,87.89 -87.89,87.89c-48.54,0 -87.891,-39.35 -87.891,-87.89c0,-48.54 39.351,-87.89 87.891,-87.89Zm117.187,585.936c-0,12.943 -10.494,23.437 -23.438,23.437l-187.499,0c-12.943,0 -23.438,-10.494 -23.438,-23.437l0,-58.594c0,-12.943 10.495,-23.437 23.438,-23.437l23.437,-0l0,-165.067l-23.437,0c-12.943,0 -23.438,-10.494 -23.438,-23.437l0,-57.589c0,-12.944 10.495,-23.438 23.438,-23.438l140.624,0c12.944,0 23.438,10.494 23.438,23.438l-0,246.093l23.437,-0c12.944,-0 23.438,10.494 23.438,23.437l-0,58.594Z"
      className={fill2}
    />
  </svg>
);

SVG.propTypes = {
  //fill: PropTypes.string,
  fill2: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
