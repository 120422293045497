import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '31.071',
  height = '35.975',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M20.3,6.9h-3.2V5c0-1.1-0.9-1.9-1.9-1.9H8.8C7.7,3,6.9,3.9,6.9,5v1.9H3.7c-1.1,0-1.9,0.9-1.9,1.9V19 c0,1.1,0.9,1.9,1.9,1.9h16.7c1.1,0,1.9-0.9,1.9-1.9V8.8C22.3,7.7,21.4,6.9,20.3,6.9z"
    />
    <rect className={fillNegative} x="8.8" y="5" width="6.4" height="1.9" />
    <path
      className={fillNegative}
      d="M3.9,8.8h16.2c0.1,0,0.2,0.1,0.2,0.2v3H3.7V9C3.7,8.9,3.8,8.8,3.9,8.8z"
    />
    <path
      className={fillNegative}
      d="M20.1,19H3.9c-0.1,0-0.2-0.1-0.2-0.2v-4.9h5.8v1c0,0.5,0.4,1,1,1h3.2c0.5,0,1-0.4,1-1v-1h5.8v4.9 C20.3,18.9,20.2,19,20.1,19z"
    />
    <path
      className={fill2}
      d="M14.1,16.8H10c-0.3,0-0.5-0.2-0.5-0.5v-1.4h5.2v1.4C14.6,16.6,14.4,16.8,14.1,16.8z"
    />
    <rect className={fillNegative} x="9.4" y="13.9" width="5.5" height="1" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
