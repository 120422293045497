import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M21.9,19.2l-2.7-3.5c0.4-0.2,0.8-0.5,1-1c0.3-0.7,0.2-1.5-0.3-2.1l-4.5-5l0.5-0.2c0.4-0.2,0.7-0.7,0.7-1.1s-0.3-0.9-0.7-1.1 L14,4.2l-0.9-1.8C12.9,2,12.5,1.7,12,1.7S11.1,2,10.9,2.5L10,4.2L8.2,5.1C7.8,5.3,7.5,5.7,7.5,6.2s0.3,0.9,0.7,1.1l0.5,0.2l-4.5,5 c-0.5,0.6-0.6,1.4-0.3,2.1c0.2,0.4,0.5,0.8,1,1l-2.7,3.5c-0.4,0.6-0.5,1.4-0.2,2c0.3,0.7,1,1.1,1.7,1.1h16.7c0.7,0,1.4-0.4,1.7-1.1 C22.4,20.5,22.3,19.7,21.9,19.2z"
    />
    <path
      className={fillNegative}
      d="M11.2,5.7c0.1-0.1,0.2-0.2,0.3-0.3l0.5-1l0.5,1c0.1,0.1,0.2,0.2,0.3,0.3l1,0.5l-1,0.5c-0.1,0.1-0.2,0.2-0.3,0.3L12,8l-0.5-1 c-0.1-0.1-0.2-0.2-0.3-0.3l-1-0.5C10.2,6.2,11.2,5.7,11.2,5.7z"
    />
    <path
      className={fillNegative}
      d="M3.7,20.3l4.9-6.4h-3l4.7-5.1l0.6,1.2c0.2,0.4,0.7,0.7,1.1,0.7s0.9-0.3,1.1-0.7l0.6-1.2l4.7,5.1h-3l4.9,6.4 C20.3,20.3,3.7,20.3,3.7,20.3z"
    />
    <circle className={fill2} cx="11" cy="13" r="1" />
    <path
      className={fill2}
      d="M14.2,15.8c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1s1-0.4,1-1C15.2,16.3,14.8,15.8,14.2,15.8z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
