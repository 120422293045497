import brandSetupCredentialsDefinition from './reducers/setupCredentialsDefinition';
import updateSecurityDetailsDefinition from './reducers/updateSecurityDetailsDefinition';
import registrationDefinition from './reducers/registrationDefinition';

const custombrandAuthenticationDefinition = (state = {}, action) => {
  let newState = state;
  newState = brandSetupCredentialsDefinition(newState, action);
  newState = registrationDefinition(newState, action);
  newState = updateSecurityDetailsDefinition(newState, action);
  return newState;
};

export default custombrandAuthenticationDefinition;
