import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, LinkTag, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class RequestFailed extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Request failed"
          metaDescription="Request failed"
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Apply-pages:Application-responses:Failed:h1"
          copytext="Unfortunately your request failed"
        />
        <Markdown
          cmsTag="Long-apply:Apply-pages:Application-responses:Failed:section-1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nPlease call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />

        <div className="form-button-group">
          <LinkTag
            id="failed-application-back-button"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </React.Fragment>
    );
  }
}

RequestFailed.propTypes = {
  match: PropTypes.any,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestFailed);
