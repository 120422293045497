import fetchIntercept from 'fetch-intercept';
import { TwoFactor } from '@myie/interact';
import { mapDispatchToProps } from '@myie/interact-two-factor';
import { mapDispatchToProps as mapDispatchToPropsShared } from '@myie/interact-shared';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { Session } from '@myie/interact';
import { withRouter } from 'react-router-dom';
import { stateUtility } from '@myie/interact-dom';

let lastUrl;
class Interceptor extends React.Component {
  constructor(props) {
    super(props);
    fetchIntercept.register({
      request: function(url, config) {
        // Modify the url or config here
        lastUrl = url;
        return [url, config];
      },

      requestError: function(error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
      },

      response: function(response) {
        const {
          startTwoFactor,
          continueTwoFactor,
          finishTwoFactor,
          history,
        } = props;
        const { status, headers, url } = response;
        const lastRequestTime = new Date().getTime();
        // Don't reset the session timeout if this is the automated message check
        if (!url.endsWith('/twoway/conversations/count')) {
          window.sessionStorage.setItem('lastRequestTime', lastRequestTime);
        }
        const debug = false;
        // all non error
        if (status < 400) {
          const requestUrl = response.url || lastUrl;
          if (TwoFactor.getUrl() === requestUrl) {
            finishTwoFactor();
          }
          return response;
        }

        // errors
        switch (status) {
          case 402: {
            // no server session
            const msg = window.sessionStorage.getItem('timeoutMessage');
            if (!msg) {
              window.sessionStorage.setItem(
                'timeoutMessage',
                'UnauthorizedSignedOut',
              );
              window.location.replace('/signed-out');
            }
            break;
          }
          case 412: // 2fa signal - start 2FA process
            if (headers.get('x-challenge-status') === 'ISSUED') {
              startTwoFactor(
                response.url || lastUrl,
                headers.get('x-challenge-key'),
                headers.get('x-challenge-status'),
              );
              const storage = stateUtility.getPageState();

              let stateData = {
                url: { twoFa: 'active' },
              };
              if (storage && storage.global && storage.global.storage) {
                let page = storage[storage.global.storage];
                if (page && page.url && page.url.pageStage) {
                  stateData.url.pageStage = page.url.pageStage;
                }
              }

              stateUtility.directlyUpdateUrl(
                stateData,
                storage && storage.global && storage.global.storage
                  ? storage.global.storage
                  : '',
                history,
              );
            } else {
              continueTwoFactor(headers.get('x-challenge-status'));
            }

            break;
          default:
            // all other errors
            if (window.location.pathname === '/accounts/list/err' && !debug) {
              const { userLogout } = props;
              clearInterval(window.sessionStorage.getItem('timeoutID'));
              Session.abandon();
              userLogout();
            } else if (!debug) {
              if (
                window.location &&
                window.location.pathname !== '/network-error'
              ) {
                window.location.replace('/network-error');
              }
            }
        }
        // Modify the reponse object if needed
        return response;
      },

      responseError: function(error) {
        // Handle an fetch error
        return Promise.reject(error);
      },
    });
  }

  render() {
    // doesn't really render anything just a wrapper to allow the injection of props
    const { inProgress, children, TwoFactorWrapper } = this.props;
    if (TwoFactorWrapper) {
      return (
        <TwoFactorWrapper inProgress={inProgress}>{children}</TwoFactorWrapper>
      );
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
}

Interceptor.propTypes = {
  TwoFactorWrapper: PropTypes.any,
  children: PropTypes.any,
  history: PropTypes.any,
  userLogout: PropTypes.func,
  continueTwoFactor: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  inProgress: PropTypes.any,
  setChallenge: PropTypes.any,
  setValue: PropTypes.any,
  startTwoFactor: PropTypes.any,
  directlyUpdateUrl: PropTypes.func,
  url: PropTypes.any,
};
export default withRouter(
  connect(
    null,
    { ...mapDispatchToProps, ...mapDispatchToPropsShared },
  )(Interceptor),
);
