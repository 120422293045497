import React, { Component } from 'react';
import { Content } from '@myie/interact-dom';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import CurrentAddress from './CurrentAddress';
import PreviousAddress from './PreviousAddress';
import ResidentialStatus from './ResidentialStatus';
import AddressLookupContainer from '../AddressLookupContainer';

class AddressContainer extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {};
  showManualAddressCurrent = () => {
    const { toggleManualAddress } = this.props;
    toggleManualAddress('current');
  };
  showManualAddressForm1 = () => {
    const { toggleManualAddress } = this.props;
    toggleManualAddress('form1');
  };
  showManualAddressForm2 = () => {
    const { toggleManualAddress } = this.props;
    toggleManualAddress('form2');
  };
  render() {
    const {
      addressTitle,
      addressId,
      addressLabel,
      groupClassName,
      form1,
      form2,
      needFirstPrevAddress,
      needSecondPrevAddress,
    } = this.props;

    return (
      <React.Fragment>
        <fieldset
          id={`${addressId}-address-fieldset`}
          className={`${groupClassName}`}
        >
          <legend className="h3">
            {addressTitle}
            <Content
              cmsTag="Long-apply:Apply-pages:Address-components:legend-1"
              copytext={` address`}
            />
          </legend>

          {this.props.showManualAddress ? (
            <CurrentAddress
              addressId={addressId}
              addressLabel={addressLabel}
              onChangeFlatNumber={this.props.onChangeFlatNumber}
              onChangeHouseDetails={this.props.onChangeHouseDetails}
              form={this.props.form}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
            />
          ) : (
            <AddressLookupContainer
              label="Enter your current address or postcode"
              labelClassName="sr-only"
              id="currentAddressInput"
              formIndex={'current'}
              updateState={this.props.updateState}
              formData={this.props.formData}
              field="currentAddressInput"
              placeholder="Start typing your address"
              validation={this.props.lookupForm.addressInput}
              lookupForm={this.props.lookupForm}
              onSelectAddress={this.props.onSelectAddress}
              isResumedApplication={this.props.isResumedApplication}
            />
          )}

          <Button
            onClick={this.showManualAddressCurrent}
            id="current-address-input-btn"
            color="secondary"
            className="address-manual-button"
            size="sm"
            key={
              this.props.showManualAddress ? 'searchAddress' : 'manualAddress'
            }
          >
            <Content
              cmsTag={
                this.props.showManualAddress
                  ? 'Long-apply:Apply-pages:Address-components:Address-container:Search-for-current-address'
                  : 'Long-apply:Apply-pages:Address-components:Address-containerEnter-current-address-manually'
              }
              copytext={
                this.props.showManualAddress
                  ? 'Search for address'
                  : 'Enter address manually'
              }
            />
          </Button>
          <ResidentialStatus
            addressId={addressId}
            onChangeDate={this.props.onChangeDate}
            addressLabel={addressLabel}
            form={
              this.props.showManualAddress
                ? this.props.form
                : this.props.lookupForm
            }
            formData={this.props.formData}
            onChange={this.props.onChange}
            validation={
              this.props.showManualAddress
                ? this.props.form.currentAddressLivingSince
                : this.props.lookupForm.addressInput
            }
            onBlur={this.props.onBlur}
            formName={'current'}
          />
        </fieldset>

        {needFirstPrevAddress ? (
          <React.Fragment>
            <PreviousAddress
              addressId={'previous'}
              updateState={this.props.updateState}
              formData={this.props.formData}
              addressLabel={'previous'}
              formIndex={'form1'}
              form={form1}
              lookupForm={this.props.lookupFormPrevious1}
              form1={form1}
              form2={form2}
              lookupFormPrevious1={this.props.lookupFormPrevious1}
              lookupFormPrevious2={this.props.lookupFormPrevious2}
              data1={this.props.data1}
              data2={this.props.data2}
              lookupDataPrevious1={this.props.lookupDataPrevious1}
              lookupDataPrevious2={this.props.lookupDataPrevious2}
              addressTitle={'First'}
              onSelectAddress={this.props.onSelectAddress}
              showManualAddress={this.props.showManualAddressPrevious1}
              toggleManualAddress={this.showManualAddressForm1}
            />
          </React.Fragment>
        ) : (
          ''
        )}

        {needSecondPrevAddress ? (
          <React.Fragment>
            <PreviousAddress
              addressId={'previous'}
              updateState={this.props.updateState}
              formData={this.props.formData}
              addressLabel={'previous'}
              formIndex={'form2'}
              form={form2}
              lookupForm={this.props.lookupFormPrevious2}
              form1={form1}
              form2={form2}
              lookupFormPrevious1={this.props.lookupFormPrevious1}
              lookupFormPrevious2={this.props.lookupFormPrevious2}
              data1={this.props.data1}
              data2={this.props.data2}
              lookupDataPrevious1={this.props.lookupDataPrevious1}
              lookupDataPrevious2={this.props.lookupDataPrevious2}
              addressTitle={'Second'}
              onSelectAddress={this.props.onSelectAddress}
              showManualAddress={this.props.showManualAddressPrevious2}
              toggleManualAddress={this.showManualAddressForm2}
            />
          </React.Fragment>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

AddressContainer.propTypes = {
  onChangeDate: PropTypes.func,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  saveData: PropTypes.func,
  updateState: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  lookupFormPrevious1: PropTypes.object,
  lookupFormPrevious2: PropTypes.object,
  onChangeFlatNumber: PropTypes.func,
  onChangeHouseDetails: PropTypes.func,
  addressTitle: PropTypes.string.isRequired,
  addressId: PropTypes.string.isRequired,
  addressLabel: PropTypes.string.isRequired,
  groupClassName: PropTypes.any,
  form: PropTypes.object,
  form1: PropTypes.object,
  form2: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showManualAddress: PropTypes.any,
  showManualAddressPrevious1: PropTypes.bool,
  showManualAddressPrevious2: PropTypes.bool,
  lookupForm: PropTypes.any,
  formData: PropTypes.any,
  onSelectAddress: PropTypes.any,
  addressInput: PropTypes.any,
  lookupDataPrevious1: PropTypes.object,
  lookupDataPrevious2: PropTypes.object,
  data1: PropTypes.object,
  data2: PropTypes.object,
  toggleManualAddress: PropTypes.func,
  needFirstPrevAddress: PropTypes.bool,
  needSecondPrevAddress: PropTypes.bool,
  isResumedApplication: PropTypes.bool,
};

export default AddressContainer;
