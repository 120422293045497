import React from 'react';
import PropTypes from 'prop-types';
import { Markdown } from '@myie/interact-dom';

class ContactNumber extends React.Component {
  render() {
    const { contacts } = this.props;
    return (
      <Markdown
        cmsTag="GLOBAL:Contact-number-link"
        template={{
          markdown: {
            phone: contacts.phone,
            phoneLink: contacts.phoneLink,
          },
        }}
        markdown={`<a href="tel:$[phoneLink]">$[phone]</a>`}
      />
    );
  }
}

ContactNumber.propTypes = {
  items: PropTypes.string,
  contacts: PropTypes.object,
};

export default ContactNumber;
