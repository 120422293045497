import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ fill = 'icon-fill', width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    x="0"
    y="0"
    enableBackground="new 0 0 21.117 32.197"
    viewBox="0 0 210 210"
  >
    <path
      className={fill}
      d="M167.902,17.285l-146.249,84.34c-12.094,6.996 -10.934,24.891 2.003,30.199l40.184,16.629l-0,29.672c-0,16.312 20.812,23.027 30.445,10.054l15.399,-20.777l39.339,16.242c2.075,0.844 4.254,1.266 6.434,1.266c2.883,-0 5.73,-0.738 8.297,-2.18c4.5,-2.531 7.593,-7.031 8.402,-12.129l20.883,-136.124c2.144,-14.098 -12.973,-24.188 -25.137,-17.192Zm-87.187,160.84l-0,-22.711l12.867,5.309l-12.867,17.402Zm74.742,-10.09l-54.07,-22.324l49.289,-71.121c3.761,-5.449 -3.34,-11.777 -8.332,-7.453l-74.356,64.792l-37.898,-15.679l146.25,-84.375l-20.883,136.16Z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
