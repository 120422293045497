import * as React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@myie/interact';
import { Content } from '@myie/interact-dom';

class SignOutCallback extends React.Component {
  signoutRedirectCallback = () => {
    Settings.userManager.signoutRedirectCallback();
  };
  render() {
    this.signoutRedirectCallback();
    return (
      <p aria-live="polite">
        <Content
          cmsTag="GLOBAL:Loading"
          copytext="Please wait while the page loads."
        />
      </p>
    );
  }
}

SignOutCallback.propTypes = {
  userManager: PropTypes.any,
};

export default SignOutCallback;
