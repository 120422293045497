import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    width={width}
    height={height}
  >
    <path
      className={fill}
      d="M16.2,10.8H13V8.5c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1v2.3H7.8V8.5c0-0.5-0.4-1-1-1s-1,0.4-1,1v2.3H5.5 c-1.4,0-2.5,1.1-2.5,2.5v7.6c0,0.8,0.6,1.4,1.4,1.4h15.1c0.8,0,1.4-0.6,1.4-1.4v-7.7c0-1.3-1-2.3-2.3-2.3h-0.5V8.5c0-0.5-0.4-1-1-1 c-0.5,0-1,0.4-1,1C16.2,8.5,16.2,10.8,16.2,10.8z"
    />
    <path
      className={fillNegative}
      d="M19.1,17.6c-0.7-0.2-1.3-0.5-2-0.9c-0.9,0.6-1.7,0.8-2.6,0.8c-0.9,0-1.7-0.3-2.6-0.8c-0.9,0.6-1.7,0.9-2.6,0.9 c-0.9,0-1.7-0.3-2.6-0.8c-0.6,0.4-1.3,0.7-1.9,0.9v2.8h14.2C19.1,20.4,19.1,17.6,19.1,17.6z"
    />
    <path
      className={fillNegative}
      d="M19.1,15.6v-2.5c0-0.3-0.2-0.5-0.5-0.5H5.5c-0.3,0-0.6,0.3-0.6,0.6v2.3c0.5-0.2,0.9-0.5,1.4-0.8 c0.3-0.3,0.8-0.3,1.2,0c0.6,0.5,1.3,0.9,1.9,0.9c0.7,0,1.3-0.3,2-0.9c0.3-0.3,0.8-0.3,1.2,0c0.7,0.5,1.3,0.8,2,0.9 c0.7,0,1.3-0.3,2-0.8c0.3-0.3,0.8-0.3,1.2,0C18.2,15.1,18.6,15.4,19.1,15.6z"
    />
    <path
      className={fill2}
      d="M6.9,1.8c0,0-1.6,2-1.6,2.8c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C8.5,3.7,6.9,1.8,6.9,1.8z"
    />
    <path
      className={fill2}
      d="M12,1.8c0,0-1.6,2-1.6,2.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C13.6,3.7,12,1.8,12,1.8z"
    />
    <path
      className={fill2}
      d="M17.2,1.8c0,0-1.6,2-1.6,2.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C18.8,3.7,17.2,1.8,17.2,1.8z"
    />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
