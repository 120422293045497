import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Content, Text } from '@myie/interact-dom';

class ContactDetails extends Component {
  onChange = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  render() {
    const { form } = this.props;
    return (
      <React.Fragment>
        <Content
          tag="h3"
          cmsTag="Long-apply:Apply-pages:Contact-components:Contact-details:h3-1"
          copytext="Contact details"
        />
        <Text
          label="Mobile number"
          cmsTag="GLOBAL:Mobile-number"
          id="mobilePhoneNumber"
          field="mobilePhoneNumber"
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.mobilePhoneNumber}
        />
        <Text
          label="Daytime phone number"
          cmsTag="GLOBAL:Daytime-phone-number"
          id="daytimePhoneNumber"
          maxLength={'15'}
          field="daytimePhoneNumber"
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.daytimePhoneNumber}
        />
        <Text
          label="Evening phone number"
          cmsTag="GLOBAL:Evening-phone-number"
          id="eveningPhoneNumber"
          field="eveningPhoneNumber"
          maxLength={'15'}
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.eveningPhoneNumber}
        />
        <Text
          label="Email address"
          cmsTag="GLOBAL:Email-address"
          id="emailAddress"
          maxLength={'320'}
          field="emailAddress"
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.emailAddress}
          suffix="We may use this to contact you about your application."
        />
        <Text
          label="Confirm email address"
          cmsTag="GLOBAL:Confirm-email-address"
          id="confirmEmailAddress"
          field="confirmEmailAddress"
          maxLength={'320'}
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.confirmEmailAddress}
        />
      </React.Fragment>
    );
  }
}

ContactDetails.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.object,
};

export default ContactDetails;
