export const CONTACTS = {
  phone: '01325 741049',
  phoneLink: '01325741049',
  email: 'onlinesupport@darlington.co.uk',
  openTimes: 'Monday to Friday, 9.00am to 5.00pm',
  companyName: 'Darlington Building Society',
  companyNameShorthand: 'Darlington',
  companyNamePosessive: "Darlington Building Society's",
  companySiteName: 'Darlingtonline',
  companySiteNamePossessive: "Darlingtonline's",
  companyAddress: 'Sentinel House Morton Road Darlington County Durham DL1 4PT',
  companyFormattedAddress:
    'Sentinel House<br />Morton Road<br />Darlington<br /> County Durham<br />DL1 4PT',
  externalHomeURL: 'https://www.darlington.co.uk/darlingtonline/',
  termsAndConditionsURL:
    'https://www.darlington.co.uk/darlingtonline-terms-and-conditions',
  privacyPolicyURL: 'https://www.darlington.co.uk/privacy',
  savingsTermsAndConditionsURL:
    'https://www.darlington.co.uk/wp-content/uploads/2022/05/Darlingtonline-TCs.pdf',
  FSCSURL: 'https://www.darlington.co.uk/fscs',
  helpURL: 'https://www.darlington.co.uk/help-and-contact/',
  sidebarAdvertURL: 'https://www.darlington.co.uk/',
  registerURL: 'https://www.darlington.co.uk',
  signInURL: 'https://www.darlington.co.uk',
  cashISAFormURL:
    'https://www.darlington.co.uk/cash-isa-transfer-authority-form',
  externalApplyHomeURL: 'https://www.darlington.co.uk/',
  savingsAccountsPage: 'https://www.darlington.co.uk/savings-accounts/',
  minimumAge: '18',
  sortcode: '40-05-30',
  accountNumber: '14575881',
};
