import React from 'react';
import PropTypes from 'prop-types';
import MarkdownToJsx from 'markdown-to-jsx';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * Switch component - Manage content text for based on the status
 * i.e. render output for Alert based on the status
 */
class Switch extends React.Component {
  constructor(props) {
    super(props);
    const { contents, defaultText, managedContent, value } = props;
    const content = {};
    content.defaultText = { defaultValue: defaultText };
    Object.keys(contents).forEach(k => {
      content[k] = contents[k];
    });
    managedContent(content, true);
    this.state = {
      localValue: value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { value, scrolltotop = false } = props;
    let { localValue, ...rest } = state;
    if (value !== localValue) {
      if (scrolltotop) {
        window.scrollTo(0, 0);
      }
      return {
        ...rest,
        localValue: value,
      };
    }
    return null;
  }

  render() {
    const { props } = this;

    const {
      tag = 'div',
      value,
      contentValue,
      managedContent,
      defaultText,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    let text = contentValue(ContentType.Text, value);
    if (!text) {
      text = defaultText;
    }
    if (text) {
      const Component = tag;
      delete rest['contents'];
      if (tag === '<>') {
        return (
          <React.Fragment>
            <MarkdownToJsx>{text}</MarkdownToJsx>
          </React.Fragment>
        );
      }
      return (
        <Component {...rest}>
          <MarkdownToJsx>{text}</MarkdownToJsx>
        </Component>
      );
    }
    return '';
  }
}

Switch.propTypes = {
  id: PropTypes.string,
  contentValue: PropTypes.func.isRequired,
  contents: PropTypes.object.isRequired,
  scrolltotop: PropTypes.bool,
  defaultText: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default contentManaged(Switch);
