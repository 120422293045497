import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Content, CONSTANTS, FormattedDate } from '@myie/interact-dom';

class SystemDowntimeBanner extends Component {
  constructor(props) {
    super(props);
  }
  renderOutput = () => {
    const { systemDowntime } = this.props;
    let output = null;
    if (
      systemDowntime?.CurrentDowntimeStatus ===
      CONSTANTS.SYSTEM_DOWNTIME_STATUS_UPCOMING
    ) {
      output = (
        <Alert color="danger">
          <Content
            cmsTag="SystemMessage:Long-apply:downtime:part-1"
            copytext="Please note that this service will not be available due to planned system maintenance. The maintenance is due between "
          />
          <FormattedDate
            date={systemDowntime.BatchStartTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />
          <Content
            cmsTag="SystemMessage:Long-apply:downtime:part-2"
            copytext=" and "
          />
          <FormattedDate
            date={systemDowntime?.BatchEndTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />
        </Alert>
      );
    }

    return output;
  };
  render() {
    return <>{this.renderOutput()}</>;
  }
}

SystemDowntimeBanner.propTypes = {
  systemDowntime: PropTypes.object,
  isSystemReadOnly: PropTypes.func,
};
const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemDowntimeBanner);
