import React, { Component } from 'react';
import {
  RULES,
  Text,
  Content,
  RadioGroup,
  Radio,
  DropDown,
  CONSTANTS,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { Button } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import {
  PreviousAddressForm2,
  lookupFormPrevious2 as lookupFormPrevious2Object,
} from './addressForm';
import PropTypes from 'prop-types';
import ResidentialStatus from './ResidentialStatus';
import AddressLookupContainer from '../AddressLookupContainer';

class PreviousAddress extends Component {
  constructor(props) {
    super(props);
    let form = null;
    let data = {};
    const { formIndex } = props;
    if (props.showManualAddress) {
      form = props.form;
    } else {
      form = props.lookupForm;
    }

    switch (formIndex) {
      case 'form1':
        if (props.showManualAddress) {
          data = props.data1;
        } else {
          data = props.lookupDataPrevious1;
        }
        break;
      case 'form2':
        if (props.showManualAddress) {
          data = props.data2;
        } else {
          data = props.lookupDataPrevious2;
        }
        break;
      default:
    }

    this.state = {
      data: data,
      form: form,
      showManualAddress: props.showManualAddress,
      formIndex: props.formIndex,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    {
      let form = null;
      if (
        this.props.showManualAddress &&
        !_.isEqual(this.props.form, this.state.form)
      ) {
        form = this.props.form;
        this.setState({ form: form });
      }

      if (
        !this.props.showManualAddress &&
        !_.isEqual(this.props.lookupForm, this.state.form)
      ) {
        if (this.props.formIndex === 'form1') {
          form = this.props.lookupFormPrevious1;
          this.setState({ form: form });
        }
        if (this.props.formIndex === 'form2') {
          form = this.props.lookupFormPrevious2;
          this.setState({ form: form });
        }
      }
    }

    if (prevState.showManualAddress !== this.props.showManualAddress) {
      let form = null;
      if (this.props.showManualAddress) {
        form = this.props.form;
      } else {
        form = this.props.lookupForm;
      }
      this.setState({
        form: form,
        showManualAddress: this.props.showManualAddress,
      });
    }
  }

  updateState = (form, data) => {
    const { updateState, showManualAddress } = this.props;
    const { formIndex } = this.state;
    let generatedForm = {};
    switch (formIndex) {
      case 'form1':
        if (showManualAddress) {
          generatedForm = {
            form1: form,
            data1: data,
          };
        } else {
          generatedForm = {
            lookupFormPrevious1: form,
            lookupDataPrevious1: data,
          };
        }

        break;
      case 'form2':
        if (showManualAddress) {
          generatedForm = {
            form2: form,
            data2: data,
          };
        } else {
          generatedForm = {
            lookupFormPrevious2: form,
            lookupDataPrevious2: data,
          };
        }
        break;
      default:
    }
    updateState(generatedForm);
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  onChangeDate = e => {
    const { name, value } = e.target;
    let { form, formIndex, data } = this.state;
    let { updateState, showManualAddress } = this.props;
    let generatedForm = {};
    let form1 = this.props.form1;
    let form2 = this.props.form2;
    let lookupFormPrevious1 = this.props.lookupFormPrevious1;
    let lookupFormPrevious2 = this.props.lookupFormPrevious2;
    let data1 = this.props.data1;
    let data2 = this.props.data2;
    let lookupDataPrevious1 = this.props.lookupDataPrevious1;
    let lookupDataPrevious2 = this.props.lookupDataPrevious2;

    if (formIndex === 'form1') {
      if (showManualAddress) {
        form = Validate.input(name, value, form1, true);
        data = data1;
      } else {
        form = Validate.input(name, value, lookupFormPrevious1, true);
        data = lookupDataPrevious1;
      }
    }

    if (formIndex === 'form2') {
      if (showManualAddress) {
        form = Validate.input(name, value, form2, true);
        data = data2;
      } else {
        form = Validate.input(name, value, lookupFormPrevious2, true);
        data = lookupDataPrevious2;
      }
    }
    if (!data) {
      data = {};
    }
    data[name] = value;
    this.setState({ ...this.state, form, data });
    let year = parseInt(value.substr(0, 4));
    if (year > 1000 && formIndex === 'form1') {
      //check !form2 && date logic
      let dateofvisit = moment(value);
      let today = moment();

      let diff = moment(today, 'YYYY-MM-DD HH:mm:ss').diff(
        moment(dateofvisit, 'YYYY-MM-DD HH:mm:ss'),
        'days',
      );
      const totalDiff = diff;
      if (totalDiff < 1095) {
        generatedForm = {
          ...(showManualAddress
            ? {
                form1: form,
                data1: data,
                form2: { ...PreviousAddressForm2 },
                data2,
                lookupFormPrevious2: lookupFormPrevious2
                  ? lookupFormPrevious2
                  : lookupFormPrevious2Object,
                lookupDataPrevious2: {},
              }
            : {
                lookupFormPrevious1: form,
                lookupDataPrevious1: data,
                lookupFormPrevious2: lookupFormPrevious2
                  ? lookupFormPrevious2
                  : lookupFormPrevious2Object,
                lookupDataPrevious2: {},
                ...(!form2 && { form2: { ...PreviousAddressForm2 }, data2 }),
              }),
          needSecondPrevAddress: true,
        };
      }
      if (totalDiff >= 1095) {
        generatedForm = {
          ...(showManualAddress
            ? { form1: form, data1: data, form2: null, data2: {} }
            : {
                lookupFormPrevious1: form,
                lookupDataPrevious1: data,
                lookupFormPrevious2: lookupFormPrevious2Object,
                lookupDataPrevious2: {},
              }),
          needSecondPrevAddress: false,
        };
      }
    } else {
      switch (formIndex) {
        case 'form1':
          generatedForm = {
            ...(showManualAddress
              ? { form1: form, data1: data, form2: null, data2: {} }
              : {
                  lookupFormPrevious1: form,
                  lookupDataPrevious1: data,
                  lookupFormPrevious2: null,
                  lookupDataPrevious2: {},
                }),
            needSecondPrevAddress: false,
          };
          break;
        case 'form2':
          generatedForm = {
            ...(showManualAddress
              ? { form2: form, data2: data }
              : { lookupFormPrevious2: form, lookupDataPrevious2: data }),
          };
          break;
        default:
      }
    }

    updateState(generatedForm);
  };

  onChangeFlatNumber = e => {
    const { name, value } = e.target;
    let { form, data, formIndex } = this.state;
    const { addressId } = this.props;
    if (
      !form[`${addressId}HouseName${formIndex}`].value &&
      !form[`${addressId}HouseNumber${formIndex}`].value &&
      !value
    ) {
      form = this.addFlatNumberValidation(form);
    } else {
      form = this.removeFlatNumberValidation(form);
    }

    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
    this.updateState(form, data);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;

    form = Validate?.input(name, value, form, true);
    if (!data) {
      data = {};
    }

    data[name] = value;
    this.setState({ ...this.state, form, data });
    this.updateState(form, data);
  };

  removeFlatNumberValidation = form => {
    const { formIndex } = this.state;
    const { addressId } = this.props;
    let newForm = form;
    let flatNumber = {
      rules: {
        title: `${addressId}FlatNumber${formIndex}`,
        stop: true,
        format: {
          regex: RULES.flatNumber,
          message: 'Please enter a valid flat number.',
        },
      },
      value: form[`${addressId}FlatNumber${formIndex}`].value,
    };
    newForm[`${addressId}FlatNumber${formIndex}`] = flatNumber;
    return newForm;
  };

  onChangeHouseDetails = e => {
    const { name, value } = e.target;
    const { addressId } = this.props;
    let { form, data, formIndex } = this.state;
    if (value) {
      form = this.removeFlatNumberValidation(form);
    }
    form = Validate.input(name, value, form, true);
    if (
      !form[`${addressId}HouseName${formIndex}`].value &&
      !form[`${addressId}HouseNumber${formIndex}`].value
    ) {
      form = this.addFlatNumberValidation(form);
    }

    form = Validate.input(
      `${addressId}FlatNumber${formIndex}`,
      form[`${addressId}FlatNumber${formIndex}`].value,
      form,
      true,
    );
    data[name] = value;
    this.setState({ ...this.state, form, data });
    this.updateState(form, data);
  };

  addFlatNumberValidation = form => {
    const { formIndex } = this.state;
    const { addressId } = this.props;
    let newForm = form;
    newForm[`${addressId}FlatNumber${formIndex}`].rules = {
      title: `${addressId}FlatNumber${formIndex}`,
      stop: true,
      required: {
        message:
          'Please enter a flat number, house number, house name or street name.',
      },
      format: {
        regex: RULES.flatNumber,
        message: 'Please enter a valid flat number.',
      },
    };
    return newForm;
  };

  addUkAddress = UKaddress => {
    const { form, formIndex } = this.state;
    const { addressId } = this.props;
    let newForm = form;
    if (UKaddress === 'yes') {
      newForm[`${addressId}Postcode${formIndex}`] = {};
      newForm[`${addressId}Postcode${formIndex}`].rules = {
        title: `${addressId}Postcode${formIndex}`,
        stop: true,
        required: {
          message: 'Please enter a postcode.',
        },
        format: {
          regex: RULES.postcode,
          message: 'Please enter a valid postcode.',
        },
      };
      newForm = _.omit(newForm, [
        `${addressId}PostZipcode${formIndex}`,
        `${addressId}Country${formIndex}`,
      ]);
    } else {
      newForm[`${addressId}PostZipcode${formIndex}`] = {};
      newForm[`${addressId}PostZipcode${formIndex}`].rules = {
        title: `${addressId}PostZipcode${formIndex}`,
        stop: true,
      };

      newForm[`${addressId}Country${formIndex}`] = {};
      newForm[`${addressId}Country${formIndex}`].rules = {
        title: `${addressId}Country${formIndex}`,
        stop: true,
        required: {
          message: 'Please select a country.',
        },
      };

      newForm = _.omit(newForm, [`${addressId}Postcode${formIndex}`]);
    }

    return newForm;
  };

  onChangeUkAddress = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = this.addUkAddress(value);
    form = Validate.input(name, value, form, true);
    if (!data) {
      data = {};
    }
    data[name] = value;
    this.setState({ ...this.state, form, data });
    this.updateState(form, data);
  };

  render() {
    const { props } = this;
    const { form, formIndex } = this.state;
    if (!form) {
      return null;
    }
    const { addressTitle, addressId, addressLabel, groupClassName } = props;
    return (
      <React.Fragment>
        <fieldset
          id={`${addressId}-${formIndex}-address-fieldset`}
          className={`${groupClassName} previous-address-fieldset`}
        >
          <Content
            tag="legend"
            tagClassName="h3"
            cmsTag="Long-apply:Apply-pages:Address-components:Previous-Address:legend-1"
            copytext="$[addressTitle] previous address"
            template={{
              copytext: {
                addressTitle: addressTitle,
              },
            }}
          />
          {this.props.showManualAddress ? (
            <div
              className="manual-address-container"
              id={`${addressId}${formIndex}-address-manual-container`}
            >
              <RadioGroup
                legend={`Is this a UK address?`}
                cmsTag="GLOBAL:Is-this-a-UK-address"
                required={true}
                field={`${addressId}UKAddress${formIndex}`}
                id={`${addressId}UKAddress${formIndex}`}
                validation={form[`${addressId}UKAddress${formIndex}`]}
              >
                <Radio
                  label="Yes"
                  cmsTag="GLOBAL:Yes"
                  id={`${addressId}UKAddress${formIndex}-yes`}
                  field={`${addressId}UKAddress${formIndex}`}
                  onChange={this.onChangeUkAddress}
                  validation={form[`${addressId}UKAddress${formIndex}`]}
                  value="yes"
                />
                <Radio
                  label="No"
                  cmsTag="GLOBAL:No"
                  id={`${addressId}UKAddress${formIndex}-no`}
                  field={`${addressId}UKAddress${formIndex}`}
                  onChange={this.onChangeUkAddress}
                  validation={form[`${addressId}UKAddress${formIndex}`]}
                  value="no"
                />
              </RadioGroup>
              <Text
                label="House/Flat name or number"
                cmsTag="GLOBAL:Flat-number"
                id={`${addressId}FlatNumber${formIndex}`}
                field={`${addressId}FlatNumber${formIndex}`}
                maxLength={'40'}
                onChange={this.onChangeFlatNumber}
                onBlur={this.onChangeFlatNumber}
                validation={form[`${addressId}FlatNumber${formIndex}`]}
              />

              <Text
                label="Street/Village"
                cmsTag="GLOBAL:Street"
                id={`${addressId}Street${formIndex}`}
                field={`${addressId}Street${formIndex}`}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form[`${addressId}Street${formIndex}`]}
                maxLength={'40'}
              />

              <Text
                label="Town/City"
                cmsTag="GLOBAL:Town-city"
                id={`${addressId}Town${formIndex}`}
                field={`${addressId}Town${formIndex}`}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form[`${addressId}Town${formIndex}`]}
                maxLength={'40'}
              />
              <Text
                label="County"
                cmsTag="GLOBAL:County"
                id={`${addressId}County${formIndex}`}
                field={`${addressId}County${formIndex}`}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form[`${addressId}County${formIndex}`]}
                maxLength={'40'}
              />
              {form[`${addressId}UKAddress${formIndex}`] &&
              form[`${addressId}UKAddress${formIndex}`].value ? (
                <React.Fragment>
                  {form[`${addressId}UKAddress${formIndex}`].value === 'yes' ? (
                    <Text
                      label="Postcode"
                      cmsTag="GLOBAL:Postcode"
                      id={`${addressId}Postcode${formIndex}`}
                      field={`${addressId}Postcode${formIndex}`}
                      onChange={this.onChange}
                      maxLength={'10'}
                      minLength={'7'}
                      onBlur={this.onBlur}
                      validation={form[`${addressId}Postcode${formIndex}`]}
                      groupClassName="half-field"
                    />
                  ) : (
                    <React.Fragment>
                      <DropDown
                        cmsTag="LABEL:Country"
                        label="Country"
                        id={`${addressId}Country${formIndex}`}
                        field={`${addressId}Country${formIndex}`}
                        onChange={this.onChange}
                        validation={form[`${addressId}Country${formIndex}`]}
                      >
                        <option
                          id={`${addressId}Country-option-empty-1-${formIndex}`}
                          key="1"
                          value=""
                          defaultText="Please select"
                        ></option>
                        {CONSTANTS &&
                          CONSTANTS.COUNTRYLIST.map((item, index) => {
                            return this.dropdownOptions(item, index, 'country');
                          })}
                      </DropDown>
                      <Text
                        label="Zip or postcode"
                        cmsTag="GLOBAL:Zip-or-postcode"
                        id={`${addressId}PostZipcode${formIndex}`}
                        field={`${addressId}PostZipcode${formIndex}`}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        validation={form[`${addressId}PostZipcode${formIndex}`]}
                        groupClassName="half-field"
                        maxLength={'10'}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          ) : (
            <AddressLookupContainer
              label="Enter your previous address or postcode"
              labelClassName="sr-only"
              id={`addressInput${formIndex}`}
              updateState={this.props.updateState}
              formData={this.props.formData}
              formIndex={formIndex}
              addressIndex={formIndex}
              field={`addressInput`}
              placeholder="Start typing your address"
              validation={this.props.lookupForm?.addressInput}
              lookupForm={
                formIndex === 'form1'
                  ? this.props.lookupFormPrevious1
                  : this.props.lookupFormPrevious2
              }
              onSelectAddress={this.props.onSelectAddress}
            />
          )}

          <Button
            onClick={this.props.toggleManualAddress}
            id={`${addressId}-${formIndex}-address-input-btn`}
            color="secondary"
            className="address-manual-button"
            size="sm"
            key={
              this.props.showManualAddress ? 'searchAddress' : 'manualAddress'
            }
          >
            <Content
              cmsTag={
                this.props.showManualAddress
                  ? 'Long-apply:Apply-pages:Address-components:Previous-Address:Search-for-address'
                  : 'Long-apply:Apply-pages:Address-components:Previous-Address:Enter-address-manually'
              }
              copytext={
                this.props.showManualAddress
                  ? 'Search for address'
                  : 'Enter address manually'
              }
            />
          </Button>

          <ResidentialStatus
            addressId={addressId}
            onChangeDate={this.onChangeDate}
            addressLabel={addressLabel}
            form={this.state.form}
            formData={this.props.formData}
            onChange={this.onChange}
            onBlur={this.props.onBlur}
            formIndex={this.state.formIndex}
            formName={this.props.formIndex}
          />
        </fieldset>
      </React.Fragment>
    );
  }
}

PreviousAddress.propTypes = {
  addressTitle: PropTypes.string.isRequired,
  addressId: PropTypes.string.isRequired,
  addressLabel: PropTypes.string.isRequired,
  lookupFormPrevious1: PropTypes.object,
  lookupFormPrevious2: PropTypes.object,
  groupClassName: PropTypes.any,
  onChangeHouseDetails: PropTypes.func,
  form1: PropTypes.object,
  form: PropTypes.object,
  onChangeFlatNumber: PropTypes.func,
  data: PropTypes.any,
  updateState: PropTypes.func,
  form2: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  showManualAddress: PropTypes.any,
  formIndex: PropTypes.string,
  lookupForm: PropTypes.any,
  formData: PropTypes.any,
  onSelectAddress: PropTypes.any,
  addressInput: PropTypes.any,
  onChangeDate: PropTypes.func,
  lookupDataPrevious1: PropTypes.object,
  lookupDataPrevious2: PropTypes.object,
  data1: PropTypes.object,
  data2: PropTypes.object,
  toggleManualAddress: PropTypes.func,
};

export default PreviousAddress;
