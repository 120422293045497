import React, { Component } from 'react';
import { /*Content, */ Text } from '@myie/interact-dom';

import PropTypes from 'prop-types';

class CurrentAddress extends Component {
  onChange = e => {
    this.updateForm(e);
  };

  render() {
    const { props } = this;
    const { form } = this.props;
    const { addressId } = props;

    return (
      <div
        className="manual-address-container"
        id={`${addressId}-address-manual-container`}
      >
        <Text
          label="House/Flat name or number"
          cmsTag="GLOBAL:Flat-number"
          id={`${addressId}FlatNumber`}
          field={`${addressId}FlatNumber`}
          onChange={this.props.onChangeFlatNumber}
          onBlur={this.props.onChangeFlatNumber}
          validation={form.currentFlatNumber}
          maxLength={'40'}
        />
        <Text
          label="Street/Village"
          cmsTag="GLOBAL:Street"
          id={`${addressId}Street`}
          field={`${addressId}Street`}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          validation={form.currentStreet}
          maxLength={'40'}
        />
        <Text
          label="Town/City"
          cmsTag="GLOBAL:Town-city"
          id={`${addressId}Town`}
          field={`${addressId}Town`}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          validation={form.currentTown}
          maxLength={'40'}
        />
        <Text
          label="County"
          cmsTag="GLOBAL:County"
          id={`${addressId}County`}
          field={`${addressId}County`}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          validation={form.currentCounty}
          maxLength={'40'}
        />
        <Text
          label="Postcode"
          cmsTag="GLOBAL:Postcode"
          id={`${addressId}Postcode`}
          field={`${addressId}Postcode`}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          validation={form.currentPostcode}
          groupClassName="half-field"
          maxLength={'10'}
        />
        {
          //<button
          //  className="btn-sm btn btn-secondary"
          //  id={`${addressId}-search-address-button`}
          //>
          //  <Content
          //    cmsTag="GLOBAL:Search-for-address"
          //    copytext={`Search for ${addressLabel} address`}
          //</div>  />
          //</button>
        }
      </div>
    );
  }
}

CurrentAddress.propTypes = {
  addressId: PropTypes.string.isRequired,
  addressLabel: PropTypes.string.isRequired,
  onChangeHouseDetails: PropTypes.func,
  form: PropTypes.object,
  onChangeFlatNumber: PropTypes.func,
  data: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default CurrentAddress;
