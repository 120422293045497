import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { SideLink, Content } from '@myie/interact-dom';

class Badges extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    return (
      <section id="badges-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/badges.scss</strong>
          </code>
        </p>
        <p>
          There is a component made for the <strong>side link</strong> defined
          within <code>shared/components/sideLink.js</code>. There is no
          interactive technical guide for this. To see its usage, go to{' '}
          <code>styleguide/components/Badges.js</code>.
        </p>
        <Row>
          <Col xs={4}>
            <h3>Image Links</h3>
            <div className="side-links-container">
              <SideLink
                linkTitle="Messages"
                linkDescription="A quick and easy way to get in touch"
                linkURL="/messaging-twoway/"
                linkID="messages-sidebar-link"
                iconImage="Envelope"
                showMessageCount={true}
                linkVersion="primary"
              />
              <SideLink
                linkTitle="Messages"
                linkDescription="A quick and easy way to get in touch"
                linkURL="/messaging-twoway/"
                linkID="messages-sidebar-link"
                iconImage="Envelope"
                showMessageCount={true}
                linkVersion="secondary"
              />
              <SideLink
                linkTitle="Messages"
                linkDescription="A quick and easy way to get in touch"
                linkURL="/messaging-twoway/"
                linkID="messages-sidebar-link"
                iconImage="Envelope"
                showMessageCount={true}
                linkVersion="light"
              />
            </div>
          </Col>
          <Col xs={4}>
            <h3>Nav bar</h3>
            <nav
              id="main-menu"
              aria-label="Main Menu"
              className="navbar navbar-expand-md"
            >
              <div className="container">
                <button
                  aria-label="Menu"
                  type="button"
                  className="navbar-toggler"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse">
                  <ul className="mr-auto navbar-nav">
                    <li className="nav-item">
                      <a
                        id="messaging-twoway-item"
                        className="nav-link"
                        href="/messaging-twoway/"
                      >
                        Messages{' '}
                        <span className="new-message-notification-badge badge badge-primary">
                          <span className="sr-only">
                            <Content
                              cmsTag="GLOBAL:You-have"
                              copytext="You have "
                            />
                          </span>
                          3
                          <span className="sr-only">
                            <Content
                              cmsTag="GLOBAL:-unread-messages"
                              copytext=" unread messages"
                            />
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </Col>
        </Row>
      </section>
    );
  }
}

Badges.propTypes = {
  items: PropTypes.string,
};

export default Badges;
