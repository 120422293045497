import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * DropDown component
 * will display a dropdown
 */
class DropDown extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
      children: options,
    } = props;
    const _dropDownOptions = {};
    for (let index = 0; index < options.length; index++) {
      const child = options[index];
      if (Array.isArray(child)) {
        for (let i = 0; i < child.length; i++) {
          const c = child[i];
          if (c.type === 'option') {
            const { defaultText, value } = c.props;
            if (defaultText) {
              _dropDownOptions[value || '_unselected_'] = {
                defaultValue: defaultText,
              };
            }
          }
        }
      }
      if (child.type === 'option') {
        const { defaultText, value } = child.props;
        if (defaultText) {
          _dropDownOptions[value || '_unselected_'] = {
            defaultValue: defaultText,
          };
        }
      }
    }

    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
      options: _dropDownOptions,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      managedContent,
      label,
      subLabel,
      description,
      suffix,
      validation,
      children: options,
    } = this.props;
    const _dropDownOptions = {};
    for (let index = 0; index < options.length; index++) {
      const child = options[index];
      if (Array.isArray(child)) {
        for (let i = 0; i < child.length; i++) {
          const c = child[i];
          if (c.type === 'option') {
            const { defaultText, value } = c.props;
            if (defaultText) {
              _dropDownOptions[value || '_unselected_'] = {
                defaultValue: defaultText,
              };
            }
          }
        }
      }
      if (child.type === 'option') {
        const { defaultText, value } = child.props;
        if (defaultText) {
          _dropDownOptions[value || '_unselected_'] = {
            defaultValue: defaultText,
          };
        }
      }
    }
    if (JSON.stringify(prevProps.validation) !== JSON.stringify(validation)) {
      managedContent({
        label: { defaultValue: label },
        subLabel: { defaultValue: subLabel },
        description: { defaultValue: description },
        suffix: { defaultValue: suffix },
        validation: validation,
        options: _dropDownOptions,
      });
    }
  }

  pushOption = (child, options, key, contentValue) => {
    const {
      defaultText,
      children,
      value,
      template: optionTemplate,
      ...rest
    } = child.props;
    if (defaultText) {
      options.push(
        <option key={key} value={value} {...rest}>
          {contentValue(
            ContentType.Option,
            value || '_unselected_',
            optionTemplate,
          )}
        </option>,
      );
    } else {
      options.push(
        <option key={key} value={value} {...rest}>
          {children}
        </option>,
      );
    }
  };

  render() {
    const { props } = this;

    const {
      children: dropDownOptions,
      validation = {},
      labelClassName = '',
      groupClassName = '',
      showErrors = true,
      disabled,
      required,
      suffixAlert,
      suffixIsDiv,
      id,
      field,
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const options = [];
    let key = 0;
    for (let index = 0; index < dropDownOptions.length; index++) {
      const child = dropDownOptions[index];
      if (Array.isArray(child)) {
        for (let i = 0; i < child.length; i++) {
          const c = child[i];
          if (c.type === 'option') {
            this.pushOption(c, options, key, contentValue);
          } else {
            options.push(child);
          }
          key++;
        }
      } else if (child.type === 'option') {
        this.pushOption(child, options, key, contentValue);
      } else {
        options.push(child);
      }
      key++;
    }
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);

    let dynamicProps = {};

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }

    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <Input
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          value={value}
          disabled={disabled}
          required={required}
          type="select"
          name={fieldName}
          id={id}
          {...dynamicProps}
          {...dynamicAriaDescribedBy}
          {...rest}
        >
          {options}
        </Input>
        {suffix ? (
          suffixAlert ? (
            <div
              className="alert alert-info mt-3 w-auto d-inline-block"
              id={`${id}-desc`}
            >
              <p>{suffix}</p>
            </div>
          ) : suffixIsDiv ? (
            <div className="suffix" id={`${id}-desc`}>
              {suffix}
            </div>
          ) : (
            <p className="suffix" id={`${id}-desc`}>
              {suffix}
            </p>
          )
        ) : (
          ''
        )}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </FormGroup>
    );
  }
}

DropDown.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Any children of the component
   */
  children: PropTypes.array.isRequired,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * DropDown disabled or not
   */
  disabled: PropTypes.any,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * DropDown ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * DropDown required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
  /**
   * Input validation
   */
  validation: PropTypes.object,
};

export default contentManaged(DropDown);
