import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { storage } from './storageName';
import queryString from 'query-string';
import loadStateHOC from '../shared/stateManager/loadStateHOC';
import { mapDispatchToProps } from '@myie/interact-brand-authentication';
import {
  Text,
  Switch,
  Content,
  Markdown,
  AppMeta,
  RULES,
  sharedUtility,
  FormErrorList,
  stateUtility,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { CONTACTS } from '@myie/interact-local-dom';
import applyPagesUtility from '../long-apply/apply-pages/utility';
class UpdateSecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      resetUpdateSecurityDetails,
      setupcredentialsActivate,
      savedDataForSecurity,
      stateData,
      urlParams,
    } = this.props;

    if (
      stateData &&
      stateData.RequireActivationTicket &&
      urlParams &&
      urlParams.twoFa &&
      urlParams.twoFa === 'active'
    ) {
      const RequireActivationTicket = {
        RequireActivationTicket: stateData.RequireActivationTicket,
        ExtendedProperties: null,
      };
      setupcredentialsActivate({
        RequireActivationTicket:
          stateData && RequireActivationTicket ? RequireActivationTicket : '',
      });
    }

    this.state = {
      localPasscodeValidation: [],
      message: null,
      updateStatus: null,
      twoFAStatus: null,
      form: {
        username: {
          rules: {
            title: 'username',
            stop: true,
            required: {
              message: 'Please enter a username.',
            },
            format: {
              regex: RULES.usernameOptional,
              message: 'Please enter a valid username.',
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.passwordOptional,
              message: 'Please enter a valid password.',
            },
            isSameAsUsername: {
              message: 'Your username and password cannot be the same.',
              field: 'password',
              comparison: () => {
                return this.state.form.username.value;
              },
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.passwordOptional,
              message: 'Please enter a valid password.',
            },
            compare: {
              message: 'Please ensure the password fields match.',
              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'passcode',
            stop: true,
            required: {
              message: 'Please enter a passcode.',
            },
            format: {
              regex: RULES.memorableNumberOptional,
              message: 'Please enter a valid passcode.',
            },
            isSequential: {
              message: 'Your passcode cannot include sequential numbers.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isAllSame: {
              message:
                'Your passcode cannot be the same number repeated six times.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isDoB: {
              message: 'Your passcode cannot be your date of birth.',
              field: 'memorableNumber',
              comparison: () => {
                return savedDataForSecurity.formData.b_day;
              },
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm passcode',
            stop: true,
            required: {
              message: 'Please enter a passcode.',
            },
            format: {
              regex: RULES.memorableNumberOptional,
              message: 'Please enter a valid passcode.',
            },
            compare: {
              message: 'Please ensure the passcode fields match.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
    resetUpdateSecurityDetails();
    stateUtility.setGlobalValue({ storage: storage.name });
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name]) {
      form = Validate.input(name, value, form);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name] && e.target.value) {
      form = Validate.input(name, value, form, true);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  submit = e => {
    e.preventDefault();
    const { setCredentials, registration, savedDataForSecurity } = this.props;
    let { form, message = {} } = this.state;

    let localValues = [];

    if (form.memorableNumber.value) {
      form = Validate.input(
        'memorableNumber',
        form.memorableNumber.value,
        form,
        true,
      );
      form = Validate.input(
        'confirmMemorableNumber',
        form.confirmMemorableNumber.value,
        form,
        true,
      );
      const checkAllSame = sharedUtility.checkAllSame(
        form.memorableNumber.value,
      )
        ? 'AllSame'
        : '';
      const isSequential = sharedUtility.isSequential(
        form.memorableNumber.value,
      )
        ? 'Sequential'
        : '';
      if (checkAllSame) {
        localValues.push(checkAllSame);
      }
      if (isSequential) {
        localValues.push(isSequential);
      }
    }

    form = Validate.form(form);
    this.setState({
      ...this.state,
      form,
      message,
      localPasscodeValidation: localValues,
    });
    if (!form.approved || localValues.length) {
      window.scrollTo(0, 0);
      return;
    }

    const request = {
      Ticket: registration.Ticket,
      Username: form.username.value,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: '',
        },
        {
          Name: 'Pin',
          Value: form.memorableNumber.value,
          Context: '',
        },
      ],
      TermsAndConditionsDocumentVersion: '1.0',
      ExtendedProperties: {
        AccountId: applyPagesUtility.getAccount(),
        IdentityRequest: {
          DateOfBirth:
            savedDataForSecurity &&
            savedDataForSecurity.formData &&
            savedDataForSecurity.formData.b_day
              ? savedDataForSecurity.formData.b_day
              : '',
        },
      },
    };

    setCredentials(request);

    this.setState({
      ...this.state,
      form,
      message,
    });
    if (!form.approved || localValues.length) {
      window.scrollTo(0, 0);
      return;
    }
  };

  static getDerivedStateFromProps = nextProps => {
    const {
      resetUpdateSecurityDetails,
      resetActivation,
      updateSecurityDetails,
      setupcredentialsActivate,
      credentialsActivate,
      updateState,
    } = nextProps;

    if (
      updateSecurityDetails &&
      updateSecurityDetails.Status === 'RequireActivation'
    ) {
      resetUpdateSecurityDetails();
      updateState({
        RequireActivationTicket:
          updateSecurityDetails && updateSecurityDetails.RequireActivationTicket
            ? updateSecurityDetails.RequireActivationTicket
            : '',
      });

      setupcredentialsActivate({
        RequireActivationTicket:
          updateSecurityDetails && updateSecurityDetails.RequireActivationTicket
            ? updateSecurityDetails.RequireActivationTicket
            : '',
      });
    }
    if (updateSecurityDetails && updateSecurityDetails.Status) {
      if (updateSecurityDetails.Status === 'Success') {
        resetUpdateSecurityDetails();
      }
      return { updateStatus: updateSecurityDetails.Status };
    }
    if (
      credentialsActivate &&
      credentialsActivate.Status &&
      credentialsActivate.Status === 'Success'
    ) {
      resetUpdateSecurityDetails();
      resetActivation();
      return { twoFAStatus: credentialsActivate.Status };
    }
    return null;
  };

  componentWillUnmount = () => {
    // Call reducer function to clear error in redux store
    const { resetComponentState, updateSecurityDetailsReceive } = this.props;

    updateSecurityDetailsReceive({});
    resetComponentState();
  };

  getErrors = () => {
    const { updateSecurityDetails = {} } = this.props;
    let errors = [];
    let fieldErrors = {};
    if (
      updateSecurityDetails &&
      updateSecurityDetails.ExtendedProperties &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Known':
              fieldErrors.password = element;
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Markdown
                    cmsTag="Accounts:Nominated-account:Add-nominated-account:Referred-no-docs:p1"
                    markdown={`You have entered a common password, please <a href="#password">choose another password</a>.`}
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return {
      errorMsg: errors,
      fieldErrors: fieldErrors,
    };
  };

  buildErrorMsg = updateSecurityDetails => {
    var errorsExist = false;
    var errorMsg = [];

    if (
      updateSecurityDetails &&
      updateSecurityDetails.Status === 'InvalidCredential'
    ) {
      errorMsg = this.getErrors().errorMsg;
      errorsExist = true;
    } else {
      if (
        updateSecurityDetails &&
        updateSecurityDetails.Status &&
        updateSecurityDetails.Status !== '' &&
        updateSecurityDetails.Status !== 'Success'
      ) {
        errorsExist = true;

        errorMsg = (
          <Switch
            id="security-details-alert"
            value={updateSecurityDetails.Status || ''}
            tag="div"
            className="alert alert-danger"
            role="alert"
            scrolltotop={true}
            contents={{
              MustHaveCredential: {
                defaultValue:
                  'You must input at least one credential to change.',
              },
              UsernameExists: {
                defaultValue:
                  'Please select another username as your chosen one already exists.',
              },
              UsernameAlreadyExists: {
                defaultValue:
                  'Please select another username as your chosen one already exists.',
              },
              NoRegistered: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
              Blocked: {
                defaultValue:
                  'Access to your account was denied for security reasons.',
              },
              NotEnoughCredentials: {
                defaultValue: 'Not enough credentials were submitted.',
              },
              InvalidCredentials: {
                defaultValue:
                  'You have entered a common password, please try again.',
              },
              InvalidUsername: {
                defaultValue:
                  'Your username must be between 6 and 23 characters.',
              },
            }}
          />
        );
      }
    }

    return { errorMsg: errorMsg, errorsExist: errorsExist };
  };

  render() {
    const { updateSecurityDetails, location } = this.props;

    if (updateSecurityDetails) {
      if (updateSecurityDetails.Status === 'Success') {
        return <Redirect to="/security-details/success" />;
      } else if (updateSecurityDetails.Status === 'InvalidTicket') {
        return <Redirect to="/security-details/timeout" />;
      }
    }
    const currentState = queryString.parse(location.search);
    let { form, message, updateStatus, twoFAStatus } = this.state;
    var errorMsgObj = this.buildErrorMsg(updateSecurityDetails);
    if (updateStatus) {
      switch (updateStatus) {
        case 'UsernameExists':
          message = 'UsernameExists';
          break;
        case 'UsernameAlreadyExists':
          message = 'UsernameAlreadyExists';
          break;
        case 'NoRegistered':
          message = 'NoRegistered';
          break;
        case 'Blocked':
          message = 'Blocked';
          break;
        case 'NotEnoughCredentials':
          message = 'NotEnoughCredentials';
          break;
        case 'InvalidCredentials':
          message = 'InvalidCredentials';
          break;
        case 'InvalidUsername':
          message = 'InvalidUsername';
          break;
        default:
      }
    }

    if (twoFAStatus && twoFAStatus === 'Success') {
      return <Redirect to="/security-details/success" />;
    }
    if (currentState && currentState.twoFa !== 'active') {
      return (
        <div id="security-details-inner">
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Update security details"
            metaDescription="Update security details"
          />
          <h1 id="security-details-title">
            <Content
              cmsTag="Long-apply:Security-details:security-details:h1"
              copytext="Registration"
            />
          </h1>
          {errorMsgObj.errorMsg}
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          {message ? (
            <Alert
              id="reg-error-exists"
              role="alert"
              aria-live="assertive"
              className="invalid-feedback"
            >
              <p>
                <Content
                  cmsTag="Long-apply:Security-details:security-details:No-changes-made"
                  copytext="You have not made any changes to your security details. Please check your changes and try again."
                />
              </p>
            </Alert>
          ) : null}
          <Form
            autoComplete="off"
            id="security-details-form"
            onSubmit={this.submit}
          >
            <section>
              <Text
                id="username"
                label="Create a username"
                description="Your username must be unique and between 6 and 23 characters long, and must contain an upper case letter and a number. You'll need to enter it every time you sign in."
                cmsTag="LABEL:New-username"
                field="username"
                minLength={6}
                maxLength={23}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.username}
                suffix="Please note: If somebody else has already taken this username you will not be able to use it."
              />
            </section>
            <section className="mt-2 pt-2">
              <Text
                id="password"
                description="Your password must be between 8 and 50 characters long and must contain at least one upper case letter, one lower case letter, one number and one special character."
                descriptionExample="*!?%/+-"
                label="Create a password"
                cmsTag="LABEL:New-password"
                field="password"
                maxLength={50}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.password}
                type="password"
              />
              <Text
                id="confirmPassword"
                label="Confirm password"
                cmsTag="LABEL:Confirm-password"
                field="confirmPassword"
                maxLength={50}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmPassword}
                type="password"
              />
            </section>
            <section className="mt-2 pt-2">
              <Text
                id="memorableNumber"
                label="Create a passcode"
                description="Your passcode must be at least 6 numbers long and cannot include your date of birth. Your passcode cannot include sequential numbers and cannot be the same number repeated 6 times. You will be asked for 3 numbers from this code every time you sign in."
                cmsTag="LABEL:New-passcode"
                field="memorableNumber"
                maxLength={6}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.memorableNumber}
                type="password"
                groupClassName="half-field"
              />
              <Text
                id="confirmMemorableNumber"
                label="Confirm passcode"
                cmsTag="LABEL:Confirm-passcode"
                field="confirmMemorableNumber"
                maxLength={6}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmMemorableNumber}
                type="password"
                groupClassName="half-field"
              />
            </section>
            <div className="form-button-group">
              <Button
                id="security-details-submit"
                type="submit"
                color="primary"
              >
                <Content
                  cmsTag="Long-apply:Security-details:security-details:Save-changes"
                  copytext="Save security details"
                />
              </Button>
            </div>
          </Form>
        </div>
      );
    }
    return null;
  }
}

UpdateSecurityDetails.propTypes = {
  retrievedUserName: PropTypes.object,
  setCredentials: PropTypes.any,
  updateSecurityDetails: PropTypes.any,
  updateSecurityDetailsReceive: PropTypes.func,
  login: PropTypes.any,
  savedDataForSecurity: PropTypes.object,
  resetUpdateSecurityDetails: PropTypes.func,
  setupcredentialsActivate: PropTypes.func,
  stateData: PropTypes.any,
  location: PropTypes.any,
  register: PropTypes.any,
  urlParams: PropTypes.any,
  resetComponentState: PropTypes.func,
  registration: PropTypes.object,
};

const mapStateToProps = state => {
  const { custombrandAuthenticationDefinition, longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    // Combine reducer functions
    {
      ...mapDispatchToProps,
    },
  )(UpdateSecurityDetails),
  storage.name,
);
