import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { stateUtility, LinkTag } from '@myie/interact-dom';
import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Connection } from '@myie/interact';

class DecisionSuccessNonISA extends Component {
  setupSecurity = () => {
    const { history, formData, preserveDataforSecurityDetails } = this.props;
    let stateData = {
      formData: {
        b_day: formData['personalDetails'].b_day,
      },
    };

    preserveDataforSecurityDetails(stateData);

    stateUtility.setPageState(
      'securityDetails',
      stateData.formData,
      'formData',
    );
    let url = `/security-details/retrieve`;
    history.push(url);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { resetSaveLongApply } = this.props;
    resetSaveLongApply();
  }

  render() {
    const { saveLongApplyData } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a New Savings Account - Application Successful"
          metaDescription="Congratulations! You have successfully applied for an account."
        />
        <Content
          tag="h1"
          cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:h1"
          copytext="Your application was successful"
        />
        <Markdown
          cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:p1"
          template={{
            markdown: {
              companyName: CONTACTS.companyName,
            },
          }}
          markdown={`\nThank you for opening a new Savings account with $[companyName]. Please take note of your account number. Your welcome pack will be sent by your preferred contact method within five working days. `}
        />
        <div className="referral-number-container">
          <Row
            tag="dl"
            title="Your new account details"
            className="account-details-dl mb-0"
          >
            <Col tag="dt" sm="12" md="6">
              <Content
                cmsTag="GLOBAL:Your-account-number-"
                copytext="Your account number:"
              />
            </Col>
            <Col tag="dd" sm="12" md="6">
              {saveLongApplyData ? saveLongApplyData.ReferenceNumber : ''}
            </Col>
          </Row>
        </div>
        <section>
          <Content
            tag="h2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:h2-1"
            copytext="Transferring funds"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:p2"
            markdown={`\nPlease check the terms and conditions of the account before making your deposit.`}
          />
          <Content
            tag="h3"
            tagClassName="mb-2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:h3-1"
            copytext="Funding your account by debit card"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:p3"
            template={{
              markdown: {
                companyName: CONTACTS.companySiteName,
              },
            }}
            markdown={`\nTo fund your account by debit card, please register for our online service $[companyName]. You will be able to make a deposit by debit card using this service.`}
          />
          <Content
            tag="h3"
            tagClassName="mb-2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:h3-2"
            copytext="Funding your account by bank transfer"
          />
          <Markdown
            template={{
              markdown: {
                companyName: CONTACTS.companyName,
              },
            }}
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:p4"
            markdown={`\nTo transfer money from your bank to your $[companyName] savings account you need to use the following bank details:`}
          />
          <Row tag="dl" title="Nominated account details">
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              {CONTACTS.sortcode}
            </Col>
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              {CONTACTS.accountNumber}
            </Col>
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content
                cmsTag="GLOBAL:Reference-number"
                copytext="Reference number"
              />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              <Markdown
                template={{
                  markdown: {
                    companyName: CONTACTS.companyName,
                  },
                }}
                cmsTag="GLOBAL:Reference-number-description"
                markdown={`Your 8-digit $[companyName] account number`}
              />
            </Col>
          </Row>
        </section>
        <section className="mt-5 pt-3">
          <Content
            tag="h2"
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:h2-2"
            copytext="Registering online"
          />
          <Markdown
            cmsTag="Long-apply:Apply-pages:Application-responses:Decision-success-non-ISA:p5"
            markdown={`\nIf you would like to access your new account online, you will need to create log in details. You can do this by clicking "Create login details". Please make sure you have your mobile phone to hand to complete this process.`}
          />
        </section>
        <div className="form-button-group">
          {this.props.registration?.Status === 'Success' ? (
            <Button
              id="expired-application-back-button"
              onClick={() => {
                this.setupSecurity();
              }}
              color="primary"
            >
              <Content
                cmsTag="GLOBAL:Create-login-details"
                copytext="Create login details"
              />
            </Button>
          ) : (
            <LinkTag
              id="connect-register-link"
              className="btn btn-primary"
              role="button"
              href={Connection.baseUrl('connectregister')}
            >
              <Content
                cmsTag="GLOBAL:Create-login-details"
                copytext="Create login details"
              />
            </LinkTag>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DecisionSuccessNonISA.propTypes = {
  history: PropTypes.any,
  account: PropTypes.any,
  resetSaveLongApply: PropTypes.func,
  savedData: PropTypes.any,
  saveLongApplyData: PropTypes.object,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  form: PropTypes.any,
  preserveDataforSecurityDetails: PropTypes.func,
  registration: PropTypes.object,
};

const mapStateToProps = state => {
  const { longApplyDefinition, custombrandAuthenticationDefinition } = state;
  return {
    ...longApplyDefinition,
    ...custombrandAuthenticationDefinition,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DecisionSuccessNonISA),
);
