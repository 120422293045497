import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '100%', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 330 82"
    version="1.1"
    width={width}
    height={height}
    className="logo-svg"
  >
    <title>{'Darlington Building Society'}</title>
    <path
      className="st0"
      d="M11.9,46.6h4.7c3.6,0,6.5-0.5,8.5-1.4c5.9-2.7,7.4-8.6,7.4-17.2c0-8.5-1.5-13.8-7-16.3  c-2.2-1-5.3-1.4-9.3-1.4h-4.6c-1.6,0-2.4,0.8-2.4,2.7v30.8C9.3,45.8,10,46.6,11.9,46.6z M8.1,54C3.2,54,1,51.9,1,46.8V10.5  C1,5.4,3.3,3,8.3,3H15C21,3,25.2,3.4,28.4,4.4C37,6.9,41,14.8,41,27.9c0,12.3-2.9,20.6-12.9,24.3c-3.7,1.4-10,1.7-13.8,1.7H8.1  L8.1,54z"
    />
    <path
      className="st0"
      d="M63.2,38.2c-3.6,0-5.8,0.4-7.1,1.2c-1.1,0.6-1.6,1.7-1.6,3.7c0,3.3,1.2,5.6,6.6,5.6  c3.1,0,5.3-0.4,6.6-0.9c0.6-0.3,1.1-0.7,1.2-1.2c0.2-0.6,0.3-1.5,0.3-2.4v-5.6C67.7,38.3,65.2,38.2,63.2,38.2z M76.3,51.3  c-0.5,0.7-1.1,1.2-2.3,1.7c-2.8,1.2-8.9,2.1-13.8,2.1C49.3,55,47,49.6,47,43.4c0-4.3,1.2-7,4-8.8c2.3-1.4,6.6-2.2,11.3-2.2  c2.2,0,5.1,0.1,6.9,0.3v-2.4c0-1.4-0.1-3-0.4-3.9c-0.6-1.5-1.9-2.5-6.6-2.5c-2.3,0-5,0.3-7.5,0.8c-0.9,0.1-2.4,0.4-2.9,0.4  c-0.7,0-1.1-0.4-1.4-1.2c-0.3-1.1-0.6-2.9-0.6-3.5c0-1.1,0.6-1.6,1.4-1.9c3.6-1.1,8.3-1.7,12-1.7c6.1,0,9.3,0.8,11.3,2.8  c1.9,1.8,2.4,4.6,2.4,9.5V48C77,49.3,76.9,50.5,76.3,51.3L76.3,51.3z"
    />
    <path
      className="st0"
      d="M90.3,54h-4.6c-1.1,0-1.6-0.6-1.6-1.9V24.8c0-2.4,0.5-3.3,1.9-4.5c2.1-1.6,8.5-3.4,13.7-3.4  c2,0,3.4,0.1,4.2,0.4c0.6,0.2,1.1,0.7,1.1,1.6c0,1-0.1,3.1-0.4,4.2c-0.1,0.7-0.8,1.1-1.6,1.1c-0.8,0-2.9-0.1-3.8-0.1  c-2.4,0-4.6,0.4-5.9,1.1c-0.4,0.2-0.9,0.5-1.1,1c-0.1,0.4-0.3,1-0.3,2v23.9C92,53.3,91.4,54,90.3,54"
    />
    <path
      className="st0"
      d="M119.6,45.8c0.6,1,2,1.7,4.1,2c0.9,0.1,1.3,0.7,1.3,1.6c0,1.1-0.1,2.6-0.3,3.2  c-0.2,1-0.8,1.4-2.1,1.4c-6,0-8.7-1.6-10.3-4.1c-0.9-1.5-1.3-3.9-1.3-7.5V1.9c0-1.1,0.4-1.9,1.7-1.9h4.6c1.3,0,1.6,0.6,1.6,1.9v39.6  C118.9,43.6,119.1,44.8,119.6,45.8"
    />
    <path
      className="st0"
      d="M137.9,54h-4.6c-1.1,0-1.7-0.6-1.7-1.9V19.9c0-1.1,0.4-1.9,1.7-1.9h4.7c1.3,0,1.7,0.7,1.7,1.9  v32.3C139.6,53.4,139,54,137.9,54z M135.5,11.4c-2.6,0-4.5-1-4.5-4.6c0-3.4,1.7-4.8,4.5-4.8c2.8,0,4.5,1.1,4.5,4.7  C140,10.2,138.5,11.4,135.5,11.4L135.5,11.4z"
    />
    <path
      className="st0"
      d="M212.1,26.7c0-1.4-0.4-1.9-1.1-2.2c-1.2-0.4-3.7-0.7-5.5-0.7c-8,0-9.5,3.4-9.5,12.7  c0,8.1,1.7,11.6,7.8,11.6c2.4,0,5-0.2,6.8-1c0.6-0.3,1.1-0.7,1.2-1.2c0.1-0.3,0.2-0.9,0.2-1.5V26.7z M203.5,55  c-10.4,0-15.4-4-15.4-18.2c0-15.5,5.5-19.7,17-19.7c4.7,0,9.1,0.4,12.8,1.5c1.4,0.4,2.2,1.5,2.2,3.4v29.4c0,3.4-0.2,6.8-0.9,9.5  c-2,7.3-10.4,10.2-24,10.2c-1.4,0-2.1-0.5-2.3-1.7c-0.1-0.9-0.2-2.4-0.2-3c0-1.3,0.7-2.1,1.9-2.2c11.4-0.5,14.7-2,16.2-4.4  c0.9-1.4,1.2-3.2,1.2-5.2v-1.2C210.1,54.6,206.9,55,203.5,55L203.5,55z"
    />
    <path
      className="st0"
      d="M242.3,45.2c1,1.4,3.4,1.9,7.1,2.1c1.1,0.1,1.6,0.6,1.6,1.7c0,0.8-0.1,2.4-0.1,3.4  c-0.1,1.1-0.8,1.6-2.1,1.6c-7.2,0-11-0.8-13.5-3.8c-2-2.4-2.4-5.6-2.4-11.2V24.4h-4.2c-1,0-1.7-0.6-1.7-1.7v-3.2  c0-1,0.6-1.8,1.8-1.8h4.2c0,0,0.1-2.6,0.3-7.7c0.1-1.2,0.6-2,1.7-2h4.4c1.1,0,1.6,0.6,1.6,2.1v7.6h7c1.3,0,2.1,0.6,2.1,1.7v3.2  c0,1.3-0.8,1.8-1.9,1.8h-7.2v14.9C241.1,42.4,241.4,44.1,242.3,45.2"
    />
    <path
      className="st0"
      d="M273.6,23.9c-5.7,0-8.6,2.1-8.6,11.9c0,10.5,2.4,12.4,8.4,12.4c5.9,0,8.7-1.6,8.7-12.1  C282.1,25.9,280.1,23.9,273.6,23.9z M273.3,55c-11.1,0-16.2-3.7-16.2-19c0-15.7,7-19,16.8-19c11,0,16.2,2.6,16.2,18.7  C290,51,284.9,55,273.3,55L273.3,55z"
    />
    <path
      className="st0"
      d="M305.1,35.7c0-9.8,2.9-11.8,8.6-11.8c6.4,0,8.4,2,8.4,12.1l0,0.8v15.4c0,1.1,0.5,1.9,1.6,1.9  h4.6c1.2,0,1.6-0.7,1.6-1.9V36l0-0.4c0-16.1-5.2-18.6-16.2-18.6c-9.7,0-16.7,3.2-16.7,18.9l0,0v16.2c0,1.2,0.5,1.9,1.6,1.9h4.7  c1.1,0,1.7-0.7,1.7-1.9L305.1,35.7"
    />
    <path
      className="st0"
      d="M155.8,35.8c0-9.8,3-11.9,8.7-11.9c6.5,0,8.5,2,8.5,12.2l0,0.8v15.5c0,1.2,0.5,1.9,1.7,1.9h4.7  c1.2,0,1.7-0.7,1.7-1.9V36.1l0-0.4c0-16.2-5.3-18.8-16.4-18.8c-9.8,0-16.9,3.2-16.9,19l0,0v16.4c0,1.2,0.5,1.9,1.7,1.9h4.7  c1.2,0,1.7-0.7,1.7-1.9L155.8,35.8"
    />
    <path
      className="st1"
      d="M2.1,75.5c0,0.5,0.2,0.7,0.7,0.7h1.5c0.9,0,1.5-0.1,1.8-0.2c0.6-0.3,1-0.8,1-1.9  c0-0.9-0.4-1.4-1-1.7c-0.4-0.2-1-0.2-1.8-0.2H2.1V75.5z M4.2,70.4c0.8,0,1.4,0,1.7-0.2c0.5-0.2,0.9-0.8,0.9-1.6  c0-1.2-0.5-1.5-1.1-1.7c-0.3-0.1-0.9-0.1-1.5-0.1H2.7c-0.4,0-0.6,0.2-0.6,0.7v3H4.2z M1.8,78C0.6,78,0,77.5,0,76.1V67  C0,65.7,0.5,65,1.8,65h2.1c0.6,0,1.5,0.1,2,0.1c2,0.2,3,1.2,3,3.1c0,1.6-0.6,2.5-1.9,3c1.5,0.3,2.3,1.2,2.3,3c0,1.5-0.5,2.8-1.9,3.4  C6.7,77.9,5.5,78,3.8,78H1.8L1.8,78z"
    />
    <path
      className="st1"
      d="M15,69.5c0-0.3,0.1-0.5,0.4-0.5h1.2c0.3,0,0.4,0.2,0.4,0.5v5.2c0,0.5,0,0.9,0.1,1.1  c0.2,0.4,0.6,0.6,1.5,0.6c0.9,0,1.4-0.1,1.8-0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.5v-6c0-0.3,0.1-0.5,0.4-0.5h1.2  c0.3,0,0.4,0.2,0.4,0.5v6.9c0,0.6-0.2,0.9-0.6,1.1c-1.1,0.5-2.8,0.5-4,0.5c-2.1,0-2.8-0.5-3.1-1.2c-0.2-0.5-0.2-1.2-0.2-2V69.5"
    />
    <path
      className="st1"
      d="M30.6,78h-1c-0.2,0-0.4-0.1-0.4-0.5v-8.1c0-0.3,0.1-0.5,0.4-0.5h1c0.3,0,0.4,0.2,0.4,0.5v8.1  C30.9,77.8,30.8,78,30.6,78z M30,67.4c-0.6,0-1-0.2-1-1.2c0-0.8,0.4-1.2,1-1.2c0.6,0,1,0.3,1,1.2C31,67,30.7,67.4,30,67.4L30,67.4z"
    />
    <path
      className="st1"
      d="M39.5,75.9c0.2,0.3,0.6,0.4,1.2,0.5c0.3,0,0.4,0.2,0.4,0.4c0,0.3,0,0.7-0.1,0.8  c-0.1,0.3-0.2,0.4-0.6,0.4c-1.7,0-2.5-0.4-2.9-1.1c-0.3-0.4-0.4-1-0.4-1.9V64.5c0-0.3,0.1-0.5,0.5-0.5h1.3c0.4,0,0.4,0.2,0.4,0.5  v10.3C39.3,75.3,39.4,75.6,39.5,75.9"
    />
    <path
      className="st1"
      d="M51.1,70.8c0-0.4-0.1-0.5-0.3-0.6c-0.3-0.1-0.8-0.2-1.3-0.2c-2,0-2.5,0.8-2.5,3.3  c0,2.1,0.5,3,2,3c0.6,0,1.2-0.1,1.7-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.3,0.1-0.5V70.8z M53,76.9c-0.1,0.3-0.3,0.4-0.6,0.5  c-0.7,0.3-2.2,0.6-3.4,0.6c-2.7,0-3.9-1-3.9-4.7c0-4,1.4-5,4.3-5c0.6,0,1.3,0.1,1.7,0.3v-4.1c0-0.4,0.2-0.5,0.5-0.5h1.1  c0.3,0,0.4,0.1,0.4,0.5v11.8C53,76.5,53,76.7,53,76.9L53,76.9z"
    />
    <path
      className="st1"
      d="M60.6,78h-1c-0.2,0-0.4-0.1-0.4-0.5v-8.1c0-0.3,0.1-0.5,0.4-0.5h1c0.3,0,0.4,0.2,0.4,0.5v8.1  C60.9,77.8,60.8,78,60.6,78z M60,67.4c-0.6,0-1-0.2-1-1.2c0-0.8,0.4-1.2,1-1.2c0.6,0,1,0.3,1,1.2C61,67,60.7,67.4,60,67.4L60,67.4z"
    />
    <path
      className="st1"
      d="M68.7,77.7h-1.2c-0.3,0-0.4-0.2-0.4-0.5v-7.3c0-0.6,0.2-1,0.6-1.1c1.2-0.5,3.2-0.8,4.6-0.8  c1.8,0,2.4,0.4,2.7,1.2c0.2,0.5,0.2,1.1,0.2,1.8v6.2c0,0.3-0.1,0.5-0.4,0.5h-1.2c-0.3,0-0.4-0.2-0.4-0.5v-5.7c0-0.4,0-0.8-0.1-1.1  c-0.1-0.4-0.5-0.6-1.4-0.6c-1,0-1.7,0.2-2,0.3c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5v6.3C69.1,77.5,69,77.7,68.7,77.7"
    />
    <path
      className="st1"
      d="M87,70.5c0-0.4-0.1-0.5-0.3-0.6c-0.3-0.1-1-0.2-1.4-0.2c-2.1,0-2.5,0.9-2.5,3.3  c0,2.1,0.4,3,2,3c0.6,0,1.3-0.1,1.8-0.3c0.1-0.1,0.3-0.2,0.3-0.3c0-0.1,0.1-0.2,0.1-0.4V70.5z M84.7,77.9c-2.7,0-4-1.1-4-4.7  c0-4,1.4-5.1,4.4-5.1c1.2,0,2.4,0.1,3.3,0.4c0.4,0.1,0.6,0.4,0.6,0.9v7.7c0,0.9-0.1,1.8-0.2,2.5c-0.5,1.9-2.7,2.6-6.2,2.6  c-0.4,0-0.5-0.1-0.6-0.4c0-0.2-0.1-0.6-0.1-0.8c0-0.3,0.2-0.5,0.5-0.6c3-0.1,3.8-0.5,4.2-1.1c0.2-0.4,0.3-0.8,0.3-1.4v-0.3  C86.4,77.8,85.6,77.9,84.7,77.9L84.7,77.9z"
    />
    <path
      className="st1"
      d="M111,74.2c0,2.3-1.2,3.8-4.8,3.8c-1.3,0-2.7-0.2-3.8-0.5c-0.3-0.1-0.4-0.2-0.4-0.5  c0-0.2,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.4,0.5-0.4c0.1,0,0.4,0.1,0.6,0.1c0.9,0.3,1.8,0.4,2.9,0.4c2.3,0,2.6-1,2.6-1.8  c0-0.8-0.2-1.4-2-1.8l-1.7-0.4c-1.9-0.5-2.9-1.6-2.9-3.5c0-2.3,1.2-3.7,4.7-3.7c1,0,2.3,0.1,3.2,0.4c0.2,0.1,0.4,0.2,0.4,0.5  c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.1-0.6-0.1c-1-0.2-1.9-0.3-2.6-0.3c-1.9,0-2.3,0.8-2.3,1.8  c0,0.7,0.2,1.4,1.9,1.8l1.6,0.4C110.3,71.4,111,72.1,111,74.2"
    />
    <path
      className="st1"
      d="M120.1,69.8c-1.4,0-2.1,0.6-2.1,3.1c0,2.8,0.6,3.3,2,3.3c1.4,0,2.1-0.4,2.1-3.2  C122.1,70.4,121.6,69.8,120.1,69.8z M120,78c-2.7,0-3.9-1-3.9-5c0-4.1,1.7-5,4.1-5c2.7,0,3.9,0.7,3.9,4.9C124,76.9,122.8,78,120,78  L120,78z"
    />
    <path
      className="st1"
      d="M133.3,76.2c0.7,0,1.2-0.1,1.7-0.2c0.1,0,0.4-0.1,0.5-0.1c0.1,0,0.3,0.1,0.3,0.3  c0.1,0.3,0.2,0.7,0.2,0.9c0,0.2-0.1,0.4-0.3,0.5c-0.8,0.3-1.8,0.4-2.6,0.4c-2.6,0-4-1.1-4-4.8c0-3.5,1.2-5.2,4.4-5.2  c0.8,0,1.6,0.1,2.2,0.2c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.1,0.8-0.2,1.1c-0.1,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.4-0.1-0.5-0.1  c-0.4-0.1-0.8-0.1-1.4-0.1c-2,0-2.4,1.2-2.4,3.2C131.1,75.1,131.5,76.2,133.3,76.2"
    />
    <path
      className="st1"
      d="M142.6,78h-1c-0.2,0-0.4-0.1-0.4-0.5v-8.1c0-0.3,0.1-0.5,0.4-0.5h1c0.3,0,0.4,0.2,0.4,0.5v8.1  C142.9,77.8,142.8,78,142.6,78z M142,67.4c-0.6,0-1-0.2-1-1.2c0-0.8,0.4-1.2,1-1.2c0.6,0,1,0.3,1,1.2C143,67,142.7,67.4,142,67.4  L142,67.4z"
    />
    <path
      className="st1"
      d="M155.1,72.1c0-0.1,0-0.3,0-0.4c0-1.3-0.3-2-1.8-2c-1.5,0-2.1,0.9-2.1,2.4H155.1z M153.7,76.2  c1,0,1.5-0.1,2.1-0.2c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0.2,0.7,0.2,0.9c0,0.2-0.1,0.3-0.3,0.4  c-0.8,0.4-2,0.5-3.1,0.5c-3.3,0-4.4-1.5-4.4-5c0-3.6,1.5-5,4.3-5c3.4,0,3.7,2,3.7,4.9c0,0.3,0,0.5-0.2,0.6c-0.1,0.1-0.2,0.1-0.4,0.1  h-5.3C151.2,75.8,152.1,76.2,153.7,76.2L153.7,76.2z"
    />
    <path
      className="st1"
      d="M164.9,75.6c0.3,0.3,0.9,0.5,1.8,0.6c0.3,0,0.4,0.2,0.4,0.4c0,0.2,0,0.6,0,0.9  c0,0.3-0.2,0.4-0.5,0.4c-1.8,0-2.8-0.2-3.4-1c-0.5-0.6-0.6-1.4-0.6-2.9v-3.8h-1.1c-0.2,0-0.4-0.1-0.4-0.4V69c0-0.3,0.2-0.5,0.5-0.5  h1.1c0,0,0-0.7,0.1-2c0-0.3,0.1-0.5,0.4-0.5h1.1c0.3,0,0.4,0.2,0.4,0.5v2h1.8c0.3,0,0.5,0.1,0.5,0.4v0.8c0,0.3-0.2,0.5-0.5,0.5h-1.8  v3.8C164.6,74.9,164.7,75.3,164.9,75.6"
    />
    <path
      className="st1"
      d="M171.9,69c0-0.3,0.1-0.5,0.4-0.5h1.2c0.3,0,0.4,0.2,0.4,0.5v5.6c0,0.4,0,0.8,0.1,1.1  c0.1,0.4,0.5,0.6,1.5,0.6c1,0,1.6-0.2,1.9-0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.5V69c0-0.3,0.1-0.5,0.4-0.5h1.2  c0.3,0,0.4,0.2,0.4,0.5v8.2c0,0.9,0,1.6-0.2,2.3c-0.5,1.9-2.6,2.6-6.2,2.6c-0.4,0-0.5-0.1-0.6-0.4c0-0.3-0.1-0.7-0.1-0.8  c0-0.3,0.2-0.5,0.5-0.5c3.2-0.1,4-0.5,4.3-1.3c0.1-0.4,0.2-0.8,0.2-1.3v-0.4c-0.8,0.4-2,0.6-3,0.6c-1.7,0-2.5-0.4-2.7-1.2  c-0.2-0.5-0.2-1.1-0.2-1.8V69"
    />
    <path
      className="st0"
      d="M323.1,61.5c0.4-0.4,0.7-0.5,1.1-0.5c0.4,0,1.4,0.7,2.1,1.2c0.4,0.4,0.6,0.9,0.6,1.5  c0,0.7-0.4,1.4-0.9,2.2c-3.5,6-12.1,15.1-32.4,15.1c-18.6,0-27.7-8.2-32-15.1c-0.5-0.9-0.8-1.5-0.8-2.2c0-0.7,0.4-1,0.8-1.5  c0.6-0.6,1.4-1.2,1.9-1.2c0.4,0,0.9,0.2,1.1,0.6c5.1,6.3,11.9,11.7,28.9,11.7C312.3,73.4,317.6,67.6,323.1,61.5"
    />
  </svg>
);

SVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
