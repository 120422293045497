import {
  SYSTEM_DOWNTIME_MESSAGE_REQUEST,
  GET_SYSTEM_DOWNTIME_MESSAGE_SUCCESS,
  SYSTEM_DOWNTIME_MESSAGE_FAILURE,
  RESET_SYSTEM_DOWNTIME_NEXT_STEP,
} from '../actions/systemDowntimeTypes';
const systemDowntimeDefinition = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_DOWNTIME_MESSAGE_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case GET_SYSTEM_DOWNTIME_MESSAGE_SUCCESS:
      return {
        ...state,
        systemDowntime: action.systemMessages,
        nextStep: action.nextStep,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SYSTEM_DOWNTIME_NEXT_STEP:
      return {
        ...state,
        nextStep: action.nextStep,
        messageArray: action.messageArray,
      };
    case SYSTEM_DOWNTIME_MESSAGE_FAILURE:
    default:
      return state;
  }
};

export default systemDowntimeDefinition;
