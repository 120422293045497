import * as credentialsActions from './actions/activation';
import * as updateSecurityDetailsActions from './actions/updateSecurityDetailsActions';
import * as registrationActions from './actions/registrationActions';

const mapDispatchToProps = {
  ...credentialsActions,
  ...updateSecurityDetailsActions,
  ...registrationActions,
};

export default mapDispatchToProps;
