import React from 'react';
import { Session } from '@myie/interact';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { sessionTimer, LinkTag } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

const RETURN_PAGES = {
  editDetails: {
    path: '/accounts/list',
    text: 'Back to home page',
  },
};

class NetworkError extends React.Component {
  onGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  componentDidMount() {
    if (Session.isAuthenticated()) {
      sessionTimer(this.props, true);
    }
  }

  render() {
    const { contacts, location } = this.props;
    const { search } = location;
    const origin =
      search && queryString.parse(search)
        ? queryString.parse(search).origin
        : null;
    const returnPageOption = RETURN_PAGES[origin];

    return (
      <div id="network-error">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Request failed"
          metaDescription="Request failed"
        />
        <Content
          tag="h1"
          cmsTag="Shared:Routing:Network-error:h1"
          copytext="Unfortunately your request failed"
        />
        <Markdown
          cmsTag="Shared:Routing:Network-error:p1"
          template={{
            markdown: {
              phone: contacts.phone,
              phoneLink: contacts.phoneLink,
              openTimes: contacts.openTimes,
            },
          }}
          markdown={`\nPlease call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />

        {returnPageOption ? (
          <Link
            className={`btn btn-secondary`}
            role="button"
            to={returnPageOption.path}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext={returnPageOption.text}
            />
          </Link>
        ) : (
          <LinkTag
            id="personal-details-cancel"
            className={`btn btn-secondary`}
            role="button"
            href={contacts.externalHomeURL}
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        )}
      </div>
    );
  }
}

NetworkError.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  contacts: PropTypes.object,
};
export default withRouter(
  connect(
    null,
    { ...mapDispatchToProps },
  )(NetworkError),
);
