import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({
  fill = 'goal-fill',
  fill2 = 'goal-fill-2',
  fillNegative = 'goal-fill-negative',
  width = '100%',
  height = '100%',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 20.4 20.7"
    enableBackground="new 0 0 20.4 20.7"
    width={width}
    height={height}
  >
    <g id="Wedding2" transform="translate(-1.956 -2.33)">
      <path
        className={fill2}
        d="M5.4,6C4.9,5.1,4.8,5,4.2,4.2C4.4,4,4.8,3.8,4.9,3.6c0.5-0.4,1-0.8,1.4-1.2 c0.1-0.1,0.1-0.1,0.2-0.1h4.4C11.6,3,12.4,3.5,13.1,4l0,0c-0.5,0.9-0.7,1.2-1.2,2"
      />
      <path
        className={fill}
        d="M11.6,7c3.6,1.6,5.2,5.9,3.6,9.5c-0.4,0.9-1,1.7-1.7,2.3c-0.6-0.3-1-0.9-1.2-1.5c0.9-0.9,1.6-2.1,1.7-3.4 c0.1-0.9-0.1-1.8-0.5-2.6c-0.1,0.1-0.2,0.1-0.4,0.2c-0.9,0.4-1.7,1.1-2.2,2c-0.3,0.5-0.5,1.1-0.6,1.7c-0.2,1.1,0,2.2,0.5,3.2 c0.3,0.7,0.8,1.3,1.4,1.7c0.6,0.5,1.4,0.9,2.2,1c1.1,0.2,2.3,0,3.3-0.5c0.5-0.3,1-0.7,1.4-1.1c0.6-0.6,1-1.4,1.2-2.2 c0.2-0.7,0.2-1.3,0.1-2c-0.1-0.8-0.4-1.6-0.9-2.3c-0.3-0.4-0.7-0.8-1.2-1.1c-0.2-0.1-0.4-0.2-0.6-0.4l-0.1-0.1 c-0.2-0.8-0.5-1.5-0.8-2.2l0,0c1,0.2,1.9,0.6,2.7,1.2c0.9,0.6,1.6,1.4,2.1,2.4c0.3,0.6,0.6,1.3,0.7,2c0.1,0.8,0.1,1.6,0,2.4 c-0.2,0.9-0.5,1.8-1,2.6c-0.4,0.6-0.9,1.2-1.5,1.7c-0.5,0.4-1,0.7-1.6,1c-0.6,0.3-1.2,0.4-1.9,0.5c-1.2,0.2-2.4,0-3.5-0.4 c-0.8-0.3-1.5-0.7-2.1-1.3c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.1-0.1-0.2-0.1c-0.4,0.1-0.9,0.1-1.3,0c-0.8-0.1-1.5-0.2-2.2-0.5 c-0.7-0.3-1.4-0.7-2-1.3c-0.9-0.9-1.6-2-2-3.2C2.2,14.9,2.1,14.5,2,14C2,13.5,2,12.9,2,12.4c0.1-0.5,0.2-1.1,0.4-1.6 c0.4-1,1-2,1.9-2.7c0.5-0.4,1-0.8,1.5-1.1"
      />
      <path
        className={fillNegative}
        d="M7.8,6.1c0.7,0,1.4,0,2.1,0c0.2-0.4,1.1-2,1.1-2l-0.6-0.3H6.9C6.7,3.9,6.7,4,6.5,4.1"
      />
      <path
        className={fillNegative}
        d="M12.4,9.5c-1.6-1.4-3.9-1.7-5.8-0.7c-2.2,1.1-3.3,3.7-2.5,6.1c0.6,2.1,2.5,3.5,4.7,3.6 c-0.6-1.7-0.6-3.5,0.1-5.2C9.5,11.6,10.8,10.3,12.4,9.5"
      />
    </g>
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  fill2: PropTypes.string,
  fillNegative: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
