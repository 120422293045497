import React from 'react';
import PropTypes from 'prop-types';

class CustomAlert extends React.Component {
  onDismiss = () => {
    const { onDismiss } = this.props;

    onDismiss();
  };

  render() {
    const { props } = this;
    const { children, visibility, className, onDismiss = null } = props;
    if (visibility) {
      return (
        <div
          className={`alert-dismissible fade show alert ${
            className ? className : 'alert-info'
          } ''`}
        >
          {onDismiss ? (
            <button
              type="button"
              className="close"
              aria-label="Close message"
              onClick={this.onDismiss}
            >
              <span aria-hidden="true">×</span>
            </button>
          ) : (
            ''
          )}
          {children}
        </div>
      );
    }
    return null;
  }
}

CustomAlert.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.any,
  visibility: PropTypes.any,
  ariaLabelledby: PropTypes.any,
  className: PropTypes.string,
  onDismiss: PropTypes.any,
};

export default CustomAlert;
