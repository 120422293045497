const sharedUtility = (function() {
  let url;
  const setUrl = str => {
    url = str;
  };

  const getUrl = () => {
    return url;
  };

  const checkAllSame = value => {
    if (value) {
      return value.split('').every(char => char === value[0]);
    }
    return null;
  };

  const checkDob = (date, value) => {
    const newValue = String(value);
    const dates = date.split('-');
    const year = String(dates[0]);
    const month = String(dates[1]);
    const newDate = String(dates[2]);

    const DMyearFirst = newDate + month + year.substring(0, 2);
    const DMyearLast = newDate + month + year.substring(2, 4);

    return (
      DMyearFirst.indexOf(String(newValue)) !== -1 ||
      DMyearLast.indexOf(String(newValue)) !== -1
    );
  };

  const isSequential = value => {
    let numbers = '0123456789';
    //If reverse sequence is also needed to be checked
    let numbersRev = '9876543210';
    //Returns false, if the number is in sequence
    return (
      numbers.indexOf(String(value)) !== -1 ||
      numbersRev.indexOf(String(value)) !== -1
    );
  };

  return {
    setUrl: setUrl,
    checkDob: checkDob,
    getUrl: getUrl,
    checkAllSame: checkAllSame,
    isSequential: isSequential,
  };
})();

export default sharedUtility;
