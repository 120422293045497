import React from 'react';
import PropTypes from 'prop-types';
import { Content, Errors } from '@myie/interact-dom';
import { HasErrors } from './Errors';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

/**
 * RadioGroup component
 */
class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    const {
      legend,
      subLegend,
      description,
      suffix,
      managedContent,
      validation,
      id,
    } = props;
    managedContent({
      legend: { defaultValue: legend },
      subLegend: { defaultValue: subLegend },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
      id: { defaultValue: id },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      suffix,
      managedContent,
      legend,
      subLegend,
      description,
      validation,
    } = this.props;
    if (
      prevProps.suffix !== suffix ||
      prevProps.legend !== legend ||
      prevProps.subLegend !== subLegend ||
      prevProps.description !== description ||
      JSON.stringify(prevProps.validation) !== JSON.stringify(validation)
    ) {
      managedContent({
        legend: { defaultValue: legend },
        subLegend: { defaultValue: subLegend },
        description: { defaultValue: description },
        suffix: { defaultValue: suffix },
        validation: validation,
      });
    }
  }

  render() {
    const { props } = this;

    const {
      children,
      suffixIsDiv,
      suffixAlert,
      groupClassName = '',
      legendClassName = '',
      ariaDescribedBy = '',
      validation = {},
      contentValue,
      managedContent,
      field,
      showErrors = true,
      disabled,
      id,
      required,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const legend = contentValue(ContentType.Text, 'legend');
    const subLegend = contentValue(ContentType.Text, 'subLegend');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);

    return (
      <React.Fragment>
        <fieldset
          id={id}
          aria-describedby={ariaDescribedBy ? ariaDescribedBy : null}
          className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
            required ? 'required-input' : ''
          }`.trim()}
        >
          <legend
            className={`${legendClassName} ${
              !HasErrors(validation) ? 'is-invalid' : ''
            } legend`.trim()}
          >
            {legend}

            {subLegend.length > 0 ? <small>&nbsp;{subLegend}</small> : ''}
            {isRequired ? (
              <React.Fragment>
                {legend ? <span aria-hidden="true">*</span> : ''}

                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </legend>
          <div {...rest}>{children}</div>
          {suffix ? (
            suffixAlert ? (
              <div
                className="alert alert-info mt-3 w-auto d-inline-block"
                id={`${id}-desc`}
              >
                <p>{suffix}</p>
              </div>
            ) : suffixIsDiv ? (
              <div className="suffix" id={`${id}-desc`}>
                {suffix}
              </div>
            ) : (
              <p className="suffix" id={`${id}-desc`}>
                {suffix}
              </p>
            )
          ) : (
            ''
          )}
          {showErrors && field ? (
            <Errors
              contentValue={contentValue}
              validation={validation}
              field={field}
            />
          ) : (
            ''
          )}
        </fieldset>
      </React.Fragment>
    );
  }
}

RadioGroup.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Radio id
   */
  id: PropTypes.any.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Text input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Any children of the component
   */
  children: PropTypes.any,
  /**
   * Date description text
   */
  description: PropTypes.string,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Style class name for legend
   */
  legendClassName: PropTypes.string,
  /**
   * Style class name for aria-describedby
   */
  ariaDescribedBy: PropTypes.string,
  /**
   * Sub legend text
   */
  legend: PropTypes.any,
  /**
   * Sub legend text
   */
  subLegend: PropTypes.string,
  validation: PropTypes.object,
  field: PropTypes.any,
  showErrors: PropTypes.any,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
};

export default contentManaged(RadioGroup);
