import _ from 'lodash';
import { CONSTANTS } from '@myie/interact-dom';
const appUtility = (function() {
  const getPayload = (data, isSaveForLater = false) => {
    const { formData, selectedProduct } = data;
    let personalDetails = null;

    let currentAddress = null;
    let lookupAddress = null;

    let firstPrevAddress = null;
    let lookupFirstPrevAddress = null;

    let secondPrevAddress = null;
    let lookupSecondPrevAddress = null;

    let financeDetails = null;
    let accountDetails = null;

    if ('addressDetails' in formData) {
      if (
        formData['addressDetails'].lookupForm &&
        !_.isEmpty(formData['addressDetails'].lookupForm)
      ) {
        lookupAddress = formData['addressDetails'].lookupForm.addressInput;
      } else if (!_.isEmpty(formData['addressDetails'].form)) {
        currentAddress = formData['addressDetails'].form;
      }

      if (
        formData['addressDetails'].lookupFormPrevious1 &&
        !_.isEmpty(formData['addressDetails'].lookupFormPrevious1)
      ) {
        lookupFirstPrevAddress =
          formData['addressDetails'].lookupFormPrevious1.addressInput;
      } else if (!_.isEmpty(formData['addressDetails'].form1)) {
        firstPrevAddress = formData['addressDetails'].form1;
      }

      if (
        formData['addressDetails'].lookupFormPrevious2 &&
        !_.isEmpty(formData['addressDetails'].lookupFormPrevious2)
      ) {
        lookupSecondPrevAddress =
          formData['addressDetails'].lookupFormPrevious2.addressInput;
      } else if (!_.isEmpty(formData['addressDetails'].form2)) {
        secondPrevAddress = formData['addressDetails'].form2;
      }
    }

    if ('personalDetails' in formData) {
      personalDetails = formData.personalDetails;
    }

    if ('yourFinances' in formData) {
      financeDetails = formData.yourFinances;
    }

    if ('accountPreferences' in formData) {
      accountDetails = formData.accountPreferences;
    }

    let addressPayload_default = {
      FlatNumber: '',
      BuildingNumber: '',
      BuildingName: '',
      Street: '',
      VillageOrDistrict: '',
      TownOrCity: '',
      County: '',
      Country: CONSTANTS.COUNTRY_CODE_UK,
      PostCode: '',
      ManualAddress: false,
      UKAddress: true,
      ExtendedProperties: [{}, {}, {}],
    };

    let addressPayload_current = _.cloneDeep(addressPayload_default);
    let addressPayload_firstPrev = _.cloneDeep(addressPayload_default);
    let addressPayload_secondPrev = _.cloneDeep(addressPayload_default);

    const buildAddressPayload = () => {
      /*-------------Building Current address----------------*/
      if (currentAddress) {
        addressPayload_current.FlatNumber = currentAddress?.currentFlatNumber
          ? currentAddress?.currentFlatNumber
          : '';
        addressPayload_current.BuildingNumber = currentAddress?.currentHouseNumber
          ? currentAddress?.currentHouseNumber
          : '';
        addressPayload_current.BuildingName = currentAddress?.currentHouseName
          ? currentAddress?.currentHouseName
          : '';
        addressPayload_current.Street = currentAddress?.currentStreet
          ? currentAddress?.currentStreet
          : '';
        addressPayload_current.VillageOrDistrict = currentAddress?.currentVillage
          ? currentAddress?.currentVillage
          : '';
        addressPayload_current.TownOrCity = currentAddress?.currentTown
          ? currentAddress?.currentTown
          : '';
        addressPayload_current.County = currentAddress?.currentCounty
          ? currentAddress?.currentCounty
          : '';
        addressPayload_current.Country = CONSTANTS.COUNTRY_CODE_UK;
        addressPayload_current.PostCode = currentAddress?.currentPostcode
          ? currentAddress?.currentPostcode
          : '';
        addressPayload_current.ManualAddress = true;

        // BFPO address mapping
        addressPayload_current.AddressLine1 = `${currentAddress?.currentFlatNumber ||
          ''} ${currentAddress?.currentStreet || ''}`;
        addressPayload_current.AddressLine2 = currentAddress?.currentTown || '';
        addressPayload_current.AddressLine3 =
          currentAddress?.currentCounty || '';
        addressPayload_current.AddressPC =
          currentAddress?.currentPostcode || '';
        addressPayload_current.AddressCountry = CONSTANTS.COUNTRY_CODE_UK;
      } else if (lookupAddress) {
        addressPayload_current.BuildingName = lookupAddress.address_line_1;
        addressPayload_current.Street = lookupAddress.address_line_2;

        addressPayload_current.VillageOrDistrict = lookupAddress.address_line_3;
        addressPayload_current.TownOrCity = lookupAddress.locality;
        addressPayload_current.PostCode = lookupAddress.postal_code;
        addressPayload_current.Country =
          lookupAddress?.country === 'UNITED KINGDOM'
            ? CONSTANTS.COUNTRY_CODE_UK
            : lookupAddress?.country;
        addressPayload_current.County = lookupAddress.region;
        addressPayload_current.ManualAddress = false;

        // BFPO address mapping
        addressPayload_current.AddressLine1 = lookupAddress.address_line_1;
        addressPayload_current.AddressLine2 = lookupAddress.address_line_2;
        addressPayload_current.AddressLine3 = lookupAddress.address_line_3;
        addressPayload_current.AddressPC = lookupAddress.postal_code;
        addressPayload_current.AddressCountry = CONSTANTS.COUNTRY_CODE_UK;
      }

      /*-------------Building First previous address----------------*/
      if (firstPrevAddress) {
        let PostCode = '';
        if (firstPrevAddress.previousUKAddressform1 === 'yes') {
          PostCode = firstPrevAddress.previousPostcodeform1;
          addressPayload_firstPrev.UKAddress = true;
        } else {
          PostCode = firstPrevAddress.previousPostZipcodeform1;
          addressPayload_firstPrev.UKAddress = false;
        }
        addressPayload_firstPrev.FlatNumber = firstPrevAddress.previousFlatNumberform1
          ? firstPrevAddress.previousFlatNumberform1
          : '';
        addressPayload_firstPrev.BuildingNumber = firstPrevAddress.previousHouseNumberform1
          ? firstPrevAddress.previousHouseNumberform1
          : '';
        addressPayload_firstPrev.BuildingName = firstPrevAddress.previousHouseNameform1
          ? firstPrevAddress.previousHouseNameform1
          : '';
        addressPayload_firstPrev.Street = firstPrevAddress.previousStreetform1
          ? firstPrevAddress.previousStreetform1
          : '';
        addressPayload_firstPrev.VillageOrDistrict = firstPrevAddress.previousVillageform1
          ? firstPrevAddress.previousVillageform1
          : '';
        addressPayload_firstPrev.TownOrCity = firstPrevAddress.previousTownform1
          ? firstPrevAddress.previousTownform1
          : '';
        addressPayload_firstPrev.County = firstPrevAddress.previousCountyform1
          ? firstPrevAddress.previousCountyform1
          : '';
        addressPayload_firstPrev.Country =
          firstPrevAddress.previousUKAddressform1 === 'yes'
            ? CONSTANTS.COUNTRY_CODE_UK
            : firstPrevAddress.previousCountryform1;
        addressPayload_firstPrev.PostCode = PostCode;
        addressPayload_firstPrev.ManualAddress = true;

        // BFPO address mapping
        addressPayload_firstPrev.AddressLine1 = `${firstPrevAddress.previousFlatNumberform1 ||
          ''} ${firstPrevAddress.previousStreetform1 || ''}`;
        addressPayload_firstPrev.AddressLine2 =
          firstPrevAddress.previousTownform1 || '';
        addressPayload_firstPrev.AddressLine3 =
          firstPrevAddress.previousCountyform1 || '';
        addressPayload_firstPrev.AddressPC = PostCode;
        addressPayload_firstPrev.AddressCountry =
          firstPrevAddress.previousUKAddressform1 === 'yes'
            ? CONSTANTS.COUNTRY_CODE_UK
            : firstPrevAddress.previousCountryform1;
      } else if (lookupFirstPrevAddress) {
        addressPayload_firstPrev.AddressLine1 =
          lookupFirstPrevAddress.address_line_1;
        addressPayload_firstPrev.BuildingName =
          lookupFirstPrevAddress.address_line_1;
        addressPayload_firstPrev.Street = lookupFirstPrevAddress.address_line_2;
        addressPayload_firstPrev.AddressLine2 =
          lookupFirstPrevAddress.address_line_2;
        addressPayload_firstPrev.VillageOrDistrict =
          lookupFirstPrevAddress.address_line_3;
        addressPayload_firstPrev.PostTown = lookupFirstPrevAddress.locality;
        addressPayload_firstPrev.PostCode = lookupFirstPrevAddress.postal_code;
        addressPayload_firstPrev.Country =
          lookupFirstPrevAddress.country === 'UNITED KINGDOM'
            ? CONSTANTS.COUNTRY_CODE_UK
            : lookupFirstPrevAddress?.country;
        addressPayload_firstPrev.County = lookupFirstPrevAddress.region;
        addressPayload_firstPrev.ManualAddress = false;

        // BFPO address mapping
        addressPayload_firstPrev.AddressLine1 =
          lookupFirstPrevAddress.address_line_1;
        addressPayload_firstPrev.AddressLine2 =
          lookupFirstPrevAddress.address_line_2;
        addressPayload_firstPrev.AddressLine3 =
          lookupFirstPrevAddress.address_line_3;
        addressPayload_firstPrev.AddressPC = lookupFirstPrevAddress.postal_code;
        addressPayload_firstPrev.AddressCountry =
          lookupFirstPrevAddress.country === 'UNITED KINGDOM'
            ? CONSTANTS.COUNTRY_CODE_UK
            : lookupFirstPrevAddress?.country;
      }

      /*-------------Building Second previous address----------------*/
      if (secondPrevAddress) {
        let PostCode = '';
        if (secondPrevAddress.previousUKAddressform2 === 'yes') {
          PostCode = secondPrevAddress.previousPostcodeform2;
          addressPayload_secondPrev.UKAddress = true;
        } else {
          PostCode = secondPrevAddress.previousPostZipcodeform2;
          addressPayload_secondPrev.UKAddress = false;
        }
        addressPayload_secondPrev.FlatNumber = secondPrevAddress.previousFlatNumberform2
          ? secondPrevAddress.previousFlatNumberform2
          : '';
        addressPayload_secondPrev.BuildingNumber = secondPrevAddress.previousHouseNumberform2
          ? secondPrevAddress.previousHouseNumberform2
          : '';
        addressPayload_secondPrev.BuildingName = secondPrevAddress.previousHouseNameform2
          ? secondPrevAddress.previousHouseNameform2
          : '';
        addressPayload_secondPrev.Street = secondPrevAddress.previousStreetform2
          ? secondPrevAddress.previousStreetform2
          : '';
        addressPayload_secondPrev.VillageOrDistrict = secondPrevAddress.previousVillageform2
          ? secondPrevAddress.previousVillageform2
          : '';
        addressPayload_secondPrev.TownOrCity = secondPrevAddress.previousTownform2
          ? secondPrevAddress.previousTownform2
          : '';
        addressPayload_secondPrev.County = secondPrevAddress.previousCountyform2
          ? secondPrevAddress.previousCountyform2
          : '';
        addressPayload_secondPrev.Country =
          secondPrevAddress.previousUKAddressform2 === 'yes'
            ? CONSTANTS.COUNTRY_CODE_UK
            : secondPrevAddress.previousCountryform2;
        addressPayload_secondPrev.PostCode = PostCode;
        addressPayload_secondPrev.ManualAddress = true;

        // BFPO address mapping
        addressPayload_secondPrev.AddressLine1 = `${secondPrevAddress.previousFlatNumberform2 ||
          ''} ${secondPrevAddress.previousStreetform2 || ''}`;
        addressPayload_secondPrev.AddressLine2 =
          secondPrevAddress.previousTownform2 || '';
        addressPayload_secondPrev.AddressLine3 =
          secondPrevAddress.previousCountyform2 || '';
        addressPayload_secondPrev.AddressPC = PostCode;
        addressPayload_secondPrev.AddressCountry =
          secondPrevAddress.previousUKAddressform2 === 'yes'
            ? CONSTANTS.COUNTRY_CODE_UK
            : secondPrevAddress.previousCountryform2;
      } else if (lookupSecondPrevAddress) {
        addressPayload_secondPrev.AddressLine1 =
          lookupSecondPrevAddress.address_line_1;
        addressPayload_secondPrev.BuildingName =
          lookupSecondPrevAddress.address_line_1;
        addressPayload_secondPrev.AddressLine2 =
          lookupSecondPrevAddress.address_line_2;
        addressPayload_secondPrev.Street =
          lookupSecondPrevAddress.address_line_2;
        addressPayload_secondPrev.VillageOrDistrict =
          lookupSecondPrevAddress.address_line_3;
        addressPayload_secondPrev.PostTown = lookupSecondPrevAddress.locality;
        addressPayload_secondPrev.PostCode =
          lookupSecondPrevAddress.postal_code;
        addressPayload_secondPrev.Country =
          lookupSecondPrevAddress.country === 'UNITED KINGDOM'
            ? CONSTANTS.COUNTRY_CODE_UK
            : lookupSecondPrevAddress?.country;
        addressPayload_secondPrev.County = lookupSecondPrevAddress.region;
        addressPayload_secondPrev.ManualAddress = false;

        // BFPO address mapping
        addressPayload_secondPrev.AddressLine1 =
          lookupSecondPrevAddress.address_line_1;
        addressPayload_secondPrev.AddressLine2 =
          lookupSecondPrevAddress.address_line_2;
        addressPayload_secondPrev.AddressLine3 =
          lookupSecondPrevAddress.address_line_3;
        addressPayload_secondPrev.AddressPC =
          lookupSecondPrevAddress.postal_code;
        addressPayload_secondPrev.AddressCountry =
          lookupSecondPrevAddress.country === 'UNITED KINGDOM'
            ? CONSTANTS.COUNTRY_CODE_UK
            : lookupSecondPrevAddress?.country;
      }
    };

    buildAddressPayload();

    const obj = {
      status: data?.status,
      Application: {
        stage:
          formData && formData['stage'] ? formData['stage'] : 'personalDetails',
        ApplicationNumber:
          formData && formData['savedProductCode']
            ? formData['savedProductCode']
            : null,
        DepositFundsOption: accountDetails?.depositMethod,
        ProductTypeCode: selectedProduct?.Product_Code,
        DepositAmount: {
          Amount: accountDetails?.depositAmount,
          ExtendedProperties: [{}, {}, {}],
        },
        PayInterestIntoAnotherAccount: accountDetails?.interestPaymentLocation,
        InterestPaymentFrequency: accountDetails?.interestPaymentFrequency
          ? accountDetails?.interestPaymentFrequency
          : selectedProduct?.Interest_Frequency,
        Applicants: [
          {
            Address: {
              Address: addressPayload_current,
              TimeInAddress: {
                FromDate:
                  currentAddress && currentAddress?.currentAddressLivingSince
                    ? currentAddress?.currentAddressLivingSince
                    : formData['addressDetails']?.lookupForm
                        ?.currentAddressLivingSince,
                ExtendedProperties: [{}, {}, {}],
              },
              ExtendedProperties: [
                {
                  ...(isSaveForLater && {
                    needFirstPrevAddress:
                      formData['addressDetails']?.needFirstPrevAddress,
                  }),
                },
                {
                  ...(isSaveForLater && {
                    needSecondPrevAddress:
                      formData['addressDetails']?.needSecondPrevAddress,
                  }),
                },

                {},
              ],
            },
            PreviousAddresses: [
              {
                Address: addressPayload_firstPrev,
                TimeInAddress: {
                  FromDate:
                    firstPrevAddress &&
                    firstPrevAddress?.previousAddressLivingSinceform1
                      ? firstPrevAddress?.previousAddressLivingSinceform1
                      : formData['addressDetails']?.lookupFormPrevious1
                          ?.previousAddressLivingSinceform1,
                  ExtendedProperties: [{}, {}, {}],
                },
                ExtendedProperties: [{}, {}, {}],
              },
              {
                Address: addressPayload_secondPrev,
                TimeInAddress: {
                  FromDate:
                    secondPrevAddress &&
                    secondPrevAddress?.previousAddressLivingSinceform2
                      ? secondPrevAddress?.previousAddressLivingSinceform2
                      : formData['addressDetails']?.lookupFormPrevious2
                          ?.previousAddressLivingSinceform2,
                  ExtendedProperties: [{}, {}, {}],
                },
                ExtendedProperties: [{}, {}, {}],
              },
            ],
            CorrespondenceDetails: {
              AllowContactByPost:
                personalDetails?.contactPreferences ===
                CONSTANTS.CONTACT_PREFERENCE_POST
                  ? true
                  : false,
              AllowContactByEmail:
                personalDetails?.contactPreferences ===
                CONSTANTS.CONTACT_PREFERENCE_EMAIL
                  ? true
                  : false,
              EmailAddress: personalDetails?.emailAddress,
              PhoneNumbers: [
                {
                  Number: personalDetails?.mobilePhoneNumber,
                  FullNumber: personalDetails?.mobilePhoneNumber,
                  Type: 'MOBILE',
                  ExtendedProperties: [{}, {}, {}],
                },
                {
                  Number: personalDetails?.daytimePhoneNumber
                    ? personalDetails?.daytimePhoneNumber
                    : '',
                  FullNumber: personalDetails?.daytimePhoneNumber
                    ? personalDetails?.daytimePhoneNumber
                    : '',
                  Type: 'DAYTIME',
                  ExtendedProperties: [{}, {}, {}],
                },
                {
                  Number: personalDetails?.eveningPhoneNumber
                    ? personalDetails?.eveningPhoneNumber
                    : '',
                  FullNumber: personalDetails?.eveningPhoneNumber
                    ? personalDetails?.eveningPhoneNumber
                    : '',
                  Type: 'EVENING',
                  ExtendedProperties: [{}, {}, {}],
                },
              ],
              ExtendedProperties: [
                {
                  Name: 'PreferredContactMethod',
                  Value: personalDetails?.contactPreferences,
                },
              ],
            },
            BirthDetails: {
              DateOfBirth: personalDetails?.b_day,
              ExtendedProperties: [{}, {}, {}],
            },
            IsPrimaryApplicant: true,
            Title: personalDetails?.title,
            FirstName: personalDetails?.firstName,
            MiddleName: personalDetails?.middleName,
            LastName: personalDetails?.lastName,
            Gender:
              personalDetails?.gender && personalDetails.gender === 'male'
                ? 'M'
                : 'F',
            MaritalStatus: personalDetails?.maritalStatus,
            NationalInsuranceNumber: personalDetails?.nationalInsuranceNumber,
            Country: personalDetails?.confirmUK
              ? CONSTANTS.COUNTRY_CODE_UK
              : '',
            CountryOfResidence: personalDetails?.confirmUK
              ? CONSTANTS.COUNTRY_CODE_UK
              : '',
            CountryOfNationality: personalDetails?.nationality,
            IsUKResident: personalDetails?.confirmUK,

            TaxInformation: {
              AdditionalOverseasTaxInformation: [
                {
                  TaxResidenceCountry: financeDetails?.TaxCountry1
                    ? financeDetails?.TaxCountry1
                    : '',
                  TaxpayerIdentificationNumber: financeDetails?.TaxIdentification1
                    ? financeDetails?.TaxIdentification1
                    : '',
                  ExtendedProperties: [{}, {}, {}],
                },
                {
                  TaxResidenceCountry: financeDetails?.TaxCountry2
                    ? financeDetails?.TaxCountry2
                    : '',
                  TaxpayerIdentificationNumber: financeDetails?.TaxIdentification2
                    ? financeDetails?.TaxIdentification2
                    : '',
                  ExtendedProperties: [{}, {}, {}],
                },
                {
                  TaxResidenceCountry: financeDetails?.TaxCountry3
                    ? financeDetails?.TaxCountry3
                    : '',
                  TaxpayerIdentificationNumber: financeDetails?.TaxIdentification3
                    ? financeDetails?.TaxIdentification3
                    : '',
                  ExtendedProperties: [{}, {}, {}],
                },
                {
                  TaxResidenceCountry: financeDetails?.TaxCountry4
                    ? financeDetails?.TaxCountry4
                    : '',
                  TaxpayerIdentificationNumber: financeDetails?.TaxIdentification4
                    ? financeDetails?.TaxIdentification4
                    : '',
                  ExtendedProperties: [{}, {}, {}],
                },
              ],
              ExtendedProperties: [{}, {}, {}],
            },
            ExtendedProperties: [{}, {}, {}],
          },
        ],
        ExtendedProperties: [{}, {}, {}],
      },
      ExtendedProperties: [
        {
          Name: 'ApplicationType',
          Value: 'LongApply',
        },
      ],
    };

    return obj;
  };

  return {
    getPayload: getPayload,
  };
})();

export default appUtility;
