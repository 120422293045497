/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { cmsDefinition  } from '@myie/interact-cms'
import { sharedDefinition  } from '@myie/interact-shared'
import { twoFactorDefinition  } from '@myie/interact-two-factor'
import { messagingSystemDefinition  } from '@myie/interact-messaging-system'


const interactReducers = { cmsDefinition, sharedDefinition, twoFactorDefinition, messagingSystemDefinition }

export default interactReducers
