import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Content,
  Markdown,
  EmailAddress,
  ContactNumber,
} from '@myie/interact-dom';
import { LocalIcon } from '@myie/interact-local-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Acquire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open(CONTACTS.HelpURL);
      return false;
    }
    return true;
  };

  render() {
    return (
      <footer>
        <div className="quick_links">
          <div className="constraint_wide"></div>
        </div>
        <div className="footer">
          <div className="constraint_wide">
            <div className="footer_column">
              <a
                className="footer-logo-link"
                href="/"
                title="Darlington Building Society"
              >
                <LocalIcon name="FooterLogo" />
              </a>
              <address className="address">
                <Markdown
                  cmsTag="Footer:Address"
                  markdown={`Darlington Building Society   
                    Sentinel House, Morton Road,   
                    Darlington, County Durham, DL1 4PT.`}
                />
              </address>
              <p className="telephone">
                <Content cmsTag="Footer:Telephone:-" copytext="Tel: " />
                <ContactNumber contacts={CONTACTS} />
                <br />
                <Content cmsTag="Footer:Email:-" copytext="Email: " />
                <EmailAddress contacts={CONTACTS} />
              </p>
            </div>
            <div className="footer_column">
              <p className="legal">
                <Content
                  cmsTag="Footer:p:legal"
                  copytext="Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Registered Number 205895."
                />
              </p>
              <div className="footer__imagelink">
                <a
                  href="https://www.fscs.org.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fscs-logo"
                    src="/fscs-logo.jpg"
                    alt="FSCS Protected"
                  />
                  <small>
                    <Content
                      cmsTag="Footer:p:FSCS"
                      copytext="Financial Services Compensation Scheme"
                    />
                  </small>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="below_footer">
          <div className="constraint_wide">
            <div className="menu-legal-container">
              <ul id="legal-menu" className="menu">
                <li
                  id="menu-item-487"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-487"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/accessibility/"
                  >
                    Accessibility
                  </a>
                </li>
                <li
                  id="menu-item-486"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-486"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/privacy/"
                  >
                    Privacy
                  </a>
                </li>
                <li
                  id="menu-item-1111"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1111"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/cookies/"
                  >
                    Cookies
                  </a>
                </li>
                <li
                  id="menu-item-1112"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1112"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/site-terms-and-conditions/"
                  >
                    Site Terms and Conditions
                  </a>
                </li>
                <li
                  id="menu-item-1113"
                  className="menu-item
                        menu-item-type-post_type menu-item-object-page
                        menu-item-1113"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/society-legals/"
                  >
                    Society Legals
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Acquire.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(Acquire);
