import React from 'react';
import PropTypes from 'prop-types';

const SVG = ({ width = '30', height = '30' }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 33.4 28.6"
    width={width}
    height={height}
  >
    <polygon points="16.7,22.1 26.3,9.8 20.2,9.8 20.2,0 13.2,0 13.2,9.8 7.1,9.8 16.7,22.1 16.7,22.1" />
    <path d="M30.4,18v7.6H3V18H0v8.2c0,1.4,1.1,2.5,2.5,2.5h28.5c1.4,0,2.5-1.1,2.5-2.5V18H30.4z" />
  </svg>
);

SVG.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SVG;
