import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, AppMeta, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Connection } from '@myie/interact';
class UpdateSecurityDetailsTimeout extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="update-security-details-invalid">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Update security details invalid"
          metaDescription="Update security details invalid"
        />
        <h1 id="update-security-details-invalid-title">
          <Content
            cmsTag="Authentication::Update-security-details:Update-security-details-invalid:h1"
            copytext="Setup security details failed"
          />
        </h1>
        <Markdown
          cmsTag="Authentication::Update-security-details:Update-security-details-invalid:p1"
          markdown={`\nSetup of security details failed, please try registering.`}
        />
        <div className="form-button-group">
          <LinkTag
            id="connect-signin-link"
            className="btn btn-secondary"
            role="button"
            href={Connection.baseUrl('connectregister')}
          >
            <Content
              cmsTag="Long-apply:Go-to-sign-in"
              copytext="Go to Registration"
            />
          </LinkTag>
        </div>
      </div>
    );
  }
}

UpdateSecurityDetailsTimeout.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSecurityDetailsTimeout);
